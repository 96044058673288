import React from 'react'
import Week from './Week'
import { useFetch } from '../../../../utility/hooks'
import { useCurrentUser } from '../../../../stores/auth'
import { Loader } from '../../../../components'

export default function CheckpointsUrlDisplay({
	url,
	allowCreateDelete,
	allowUpdate,
	isMainPage,
}: {
	url: string,
	allowCreateDelete: boolean,
	allowUpdate: boolean,
	isMainPage?: boolean,
}): React$Node {
	const currentUser = useCurrentUser()
	const { data, fetching, error } = useFetch(url, { shouldFetch: !!currentUser })
	const checkpoints = data?.checkpoints
	return (
		<>
			{fetching && <Loader />}
			{error && 'ERROR'}
			{!fetching &&
				!error &&
				(checkpoints ? (
					<Week
						allowCreateDelete={allowCreateDelete}
						checkpoints={checkpoints}
						allowUpdate={allowUpdate}
						isMainPage={isMainPage}
					/>
				) : (
					'Could not fetch checkpoints'
				))}
		</>
	)
}
