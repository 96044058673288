import { FILTER_TYPES } from '../../../../utility/constants'
import type { ClientType, SchoolType } from '../../../../stores/types'
import Calendar from './Calendar'
import CheckboxFilter from './CheckboxFilter'
import Dropdown from './Dropdown'
import { useFilterStore, useClientStore, selectors } from '../../../../stores'

type CheckBoxArguments = {| type: $Values<typeof FILTER_TYPES>, label: string |}
type DropDownArguments<U> = {
	type: $Values<typeof FILTER_TYPES>,
	selectChain: { useStore: any, fn: any }[],
	placeholder: string,
	getLabel: (value: ?U) => string,
	getValue: (value: ?U) => string,
}

export const CHECK_BOXES: { [filterType: string]: CheckBoxArguments } = {
	[FILTER_TYPES.FINISHED]: {
		type: FILTER_TYPES.FINISHED,
		label: 'Show finished',
	},
	[FILTER_TYPES.STARTED]: { type: FILTER_TYPES.STARTED, label: 'Show unfinished' },
	[FILTER_TYPES.PREPPED]: { type: FILTER_TYPES.PREPPED, label: 'Show not started' },
}

export const DROP_DOWNS: { [filterType: string]: DropDownArguments<*> } = {
	[FILTER_TYPES.SCHOOL]: {
		type: FILTER_TYPES.SCHOOL,
		selectChain: [{ useStore: useClientStore, fn: selectors.getSchools }],
		placeholder: 'Select School...',
		getLabel: (value: ?SchoolType) => (value ? value.schoolName : ''),
		getValue: (value: ?SchoolType) => (value ? value.schoolId : ''),
	},
	[FILTER_TYPES.USER]: {
		type: FILTER_TYPES.USER,
		selectChain: [
			{ useStore: useFilterStore, fn: selectors.getSchoolFilterValue },
			{ useStore: useClientStore, fn: selectors.getClients },
		],
		placeholder: 'Select User...',
		getLabel: (value: ?ClientType) => (value ? `${value.firstName} ${value.lastName}` : ''),
		getValue: (value: ?ClientType) => (value ? value._id : ''),
	},
}
export const VISIBLE_FILTER_DATA = {
	CHECK_BOXES: {
		values: CHECK_BOXES,
		component: CheckboxFilter,
	},
	DROP_DOWNS: {
		values: DROP_DOWNS,
		component: Dropdown,
	},
}

export { Calendar, CheckboxFilter, Dropdown }
