import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { getFieldNamesForResource, type ResourceType, AVAILABLE_RESOURCES } from './resources'
import type { TableQueryProps } from '../../../components/basic/AdvancedTable'
import type { Criteria } from './Criteria/localTypes'

type QueryStatus = {
	...TableQueryProps,
	criteria: ?Criteria,
}

/**
 * Fetch a report for a specific resource from the server.
 * Also passes the following additional query data: page index, page size, filter value, sort values
 * @returns {Promise<{ list: mixed[], totalPages: number }>} a promise with the data in a list and total pages worth of data
 */
export async function fetchReportForResource({
	queryKey,
}: {
	queryKey: [string, QueryStatus],
}): Promise<{ list: mixed[], totalEntries: number, resource: ?ResourceType }> {
	const [resourceStr, { pageIndex, pageSize, sortBy, criteria }] = queryKey
	const resource = resourceStr.replace('report-', '')
	if (!AVAILABLE_RESOURCES[resource]) {
		return Promise.resolve({ list: [], totalEntries: 0, resource: null })
	}
	const fields = getFieldNamesForResource(((resource: any): ResourceType)) // we know resource is a resource type
	const query = new URLSearchParams()
	query.append('batchSize', `${pageSize}`)
	query.append('index', `${pageIndex}`)
	fields.forEach(value => {
		query.append('fields', value)
	})
	sortBy.forEach(value => {
		query.append('sort', `${value.desc ? '-' : ''}${value.id}`)
	})
	if (criteria) {
		if (Object.keys(criteria).length > 0) {
			query.append('criteria', JSON.stringify(criteria))
		}
	}

	return NetworkCommunicator.GET(`reports/${resource}?${query.toString()}`).then(res => ({
		...res,
		resource,
	}))
}
