import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { useCurrentUser } from '../../stores/auth'
import styled from 'styled-components'
import { PageContainer, Loader } from '../../components'
import { LiveMissions, MissionModal } from './components'
import { Link } from 'react-router-dom'
import { useOkrs } from '../../resources/okrs'

import { CheckpointsUrlDisplay } from '../Goals/Checkpoints'
import OkrsList from '../Goals/Okrs/OkrsList'
import { getOkrOwnerFromFilters, getCurrentQuarter } from '../Goals/Okrs/helpers'

import { H3 } from '@blueprintjs/core'

const Grid = styled.div`
	height: 100%;
	display: grid;
	grid-gap: 1vw;
	padding: 0.5vw;
	grid-template-columns: 30% 1fr;
	grid-template-rows: 1fr 1fr;
	> div {
		border: ${props => `1px solid ${props.theme.primary}`};
		border-radius: 6px;
		padding: 12px;
	}
	#left-side {
		background-color: ${props => props.theme.primary};
		color: ${({ theme }) => theme.white};
		grid-row: 1 / 3;
		grid-column: 1;
	}
	#top-right {
		grid-row: 1;
		grid-column: 2;
	}
	#bottom-right {
		grid-row: 2;
		grid-column: 2;
	}
`

export default function Home(): React$Node {
	const [selectedMissionId, setSelectedMissionId] = useQueryParam('mission-info', StringParam)
	const currentUser = useCurrentUser()
	const queryParams = { employeeId: currentUser?.id }
	const { isLoading, error, okrs } = useOkrs({
		queryParams,
		queryOptions: { enabled: !!currentUser },
	})

	if (!currentUser) return null
	const owner = getOkrOwnerFromFilters({}, currentUser)

	return (
		<PageContainer column>
			<Grid>
				<div id="left-side">
					<LiveMissions
						selectedMissionId={selectedMissionId}
						onSelectMission={setSelectedMissionId}
					/>
				</div>
				<div id="top-right">
					<H3>
						<Link to="checkpoints">Checkpoints</Link>
					</H3>
					<CheckpointsUrlDisplay url="checkpoints" allowCreateDelete allowUpdate isMainPage />
				</div>
				<div id="bottom-right">
					<H3>
						<Link to="okrs">OKRs</Link>
					</H3>
					{isLoading ? <Loader /> : null}
					{error ? 'ERROR' : null}
					{!isLoading &&
						!error &&
						(okrs ? (
							<OkrsList
								queryOverrides={queryParams}
								okrs={okrs}
								owner={owner}
								quarter={getCurrentQuarter()}
								allowOkrCreation
								isMainPage
								condensed
								currentUser={currentUser}
							/>
						) : (
							'No Okrs '
						))}
				</div>
			</Grid>
			{selectedMissionId && (
				<MissionModal
					id={selectedMissionId}
					onClose={() => {
						setSelectedMissionId(null)
					}}
				/>
			)}
		</PageContainer>
	)
}
