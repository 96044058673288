import type { ProxyData } from '../../../../../utility/dotProxy'
import type { Range, ComposableFieldType } from '../clientTypes'
import type {
	Criteria,
	ForceDisplayFields,
	UpdateCriteria,
	UpdateForceDisplayFields,
} from '../localTypes'

import { Loader } from '../../../../../components/basic'
import { get, getProxyData, minimizeDelete } from '../../../../../utility/dotProxy'
import { FIELD_TYPES } from '../clientTypes'
import { useCriteriaForm } from '../hooks'

import { CriteriaBox, Row } from '../sharedComponents'

import CriteriaForm from './CriteriaForm'
import { NumberRangeEntry } from './rangeEntries/numeric'

import React from 'react'

/**
 * ComposableEntry - render the form relating to another resource as described by the fieldType
 *
 * @param  {Object} props - the react props
 * @param  {Criteria} props.currentCriteria - the current criteria
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {ForceDisplayFields} props.forceDisplayFields - the fields which are forced to render even though they may not exist in the currentCriteria
 * @param  {UpdateForceDisplayFields} props.updateForceDisplayFields - a callback to update the fields to force being displayed
 * @param  {ProxyData<{ count?: Range<?number>, criteria?: Criteria }>} props.currentPath - the location in the criteria object the field's data is located
 * @param  {String[]} props.displayPath - the path to display in front of the field name when rendering a field
 * @param  {ComposableFieldType} props.fieldType - the current field being rendered
 *
 * @returns React$Node
 */
export function ComposableEntry({
	updateCriteria,
	currentCriteria,
	forceDisplayFields,
	updateForceDisplayFields,
	currentPath,
	displayPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	forceDisplayFields: ForceDisplayFields,
	updateForceDisplayFields: UpdateForceDisplayFields,
	currentPath: ProxyData<{ count?: Range<?number>, criteria?: Criteria }>,
	fieldType: ComposableFieldType,
	displayPath: string[],
}): React$Node {
	const { data: resourceMap, isLoading, error } = useCriteriaForm()

	if (isLoading) {
		return <Loader />
	}
	if (error) {
		return <div>An error occurred while loading the form</div>
	}
	if (!resourceMap) {
		return null
	}
	const resource = resourceMap[fieldType.resource]
	if (!resource) {
		return <div>No resource form found for resource {fieldType.resource}</div>
	}

	const nextDisplayPath = fieldType.isExpectedOneToMany
		? []
		: [...displayPath, fieldType.displayName]

	const countProxyData = getProxyData((criteria: Criteria) => get(currentPath, criteria)?.count)
	const _showCount = get(countProxyData, currentCriteria) || get(countProxyData, forceDisplayFields)
	return (
		<>
			{_showCount ? (
				<CriteriaBox doBox={fieldType.isExpectedOneToMany}>
					<Row
						displayPath={[...nextDisplayPath, 'count']}
						onDelete={() => {
							updateCriteria(oldData => minimizeDelete(countProxyData, oldData))
							updateForceDisplayFields(oldData => minimizeDelete(countProxyData, oldData))
						}}>
						<NumberRangeEntry
							updateCriteria={updateCriteria}
							currentCriteria={currentCriteria}
							currentPath={countProxyData}
							fieldType={{ type: FIELD_TYPES.NUMBER, displayName: 'count' }}
						/>
					</Row>
				</CriteriaBox>
			) : null}
			<CriteriaForm
				updateCriteria={updateCriteria}
				currentCriteria={currentCriteria}
				forceDisplayFields={forceDisplayFields}
				updateForceDisplayFields={updateForceDisplayFields}
				displayPath={nextDisplayPath}
				resource={resource}
				resourceMap={resourceMap}
				currentPath={getProxyData((criteria: Criteria) => get(currentPath, criteria)?.criteria)}
				fieldType={fieldType}
			/>
		</>
	)
}
