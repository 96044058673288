import React, { useEffect, useState } from 'react'
import moment from 'moment'
import StarRatings from 'react-star-ratings'
import { Loader, FlexWrapper, Copiable } from '../../../components'
import { READABLE_CONTROL_SETS, type MissionDetailType, type Event } from '../../../stores/types'
import { reduceObject, prettifyTypeEnum } from '../../../utility/functions'
import styled from 'styled-components'
import 'styled-components/macro'
import { useMissionStore, useClientStore, selectors } from '../../../stores'
import {
	Dialog,
	Classes,
	Card,
	Divider,
	HTMLTable,
	Popover,
	PopoverInteractionKind,
	Position,
	Callout,
	Breadcrumbs,
	Breadcrumb,
	Tag,
} from '@blueprintjs/core'
import { eventTypesEnum } from '../../../stores/types'
import { MissionCategoryIconMap, MissionCategoryToReadableCategoryMap } from './shared/constants'

export default function MissionDetailModal({
	id,
	onClose,
}: {
	id: string,
	onClose?: () => void,
}): React$Node {
	const mission: ?MissionDetailType = useMissionStore(state => selectors.getMission(state, id))
	const [isOpen, setIsOpen] = useState(true)
	const fetchMission = useMissionStore(selectors.getMissionFetch)
	const handleClose = () => {
		setIsOpen(false)
		onClose && onClose()
	}
	useEffect(() => {
		fetchMission(id)
	}, [id, fetchMission])

	return (
		<Dialog
			isOpen={isOpen}
			onClose={handleClose}
			title={'Mission Info'}
			css="width: 60vw !important;">
			<div className={Classes.DIALOG_BODY}>
				{mission ? <MissionInfo mission={mission} /> : <Loader withMsg />}
			</div>
		</Dialog>
	)
}

const CenteredText = styled.div`
	margin: auto 0;
	text-align: center;
	width: fit-content;
`

const StarProps = {
	numberOfStars: 5,
	starDimension: '20px',
	starSpacing: '0px',
}

// This number could be as high as 50 without crashing blueprint, but then the list becomes unusable anyways because it's not scrollable.
// 20 displays a lot of events while not exceeding the height of a small window.
const MAX_EVENTS = 20

function MissionInfo({ mission }: { mission: MissionDetailType }) {
	const {
		grades,
		score,
		controlSet,
		events,
		simulationName,
		code,
		missionCategory,
		ranOnWeb,
	} = mission
	const impression = mission.impression
	const students = mission.fullState?.students || {}
	const isMissionLive =
		events.length > 0 ? events[events.length - 1].type === eventTypesEnum.START_MISSION : false
	let criticalEvents = events.map(({ type, timestamp, meta }: Event) => {
		const prettyType = prettifyTypeEnum(type)
		const prettyDate = moment(timestamp).format('MMM Do YYYY, h:mm a')
		return {
			text: (
				<div>
					{prettyType}...{prettyDate}
				</div>
			),
			timestamp: prettyDate,
			type: prettyType,
			icon: EVENTS_TO_ICONS[type],
			meta,
		}
	})
	if (criticalEvents.length > MAX_EVENTS) {
		const originalLength = criticalEvents.length
		criticalEvents = [
			...criticalEvents.slice(0, MAX_EVENTS / 2),
			// This event is going to go into the dropdown list, not the displayed breadcrumbs, so it only needs `text`
			{
				text: (
					<div css="display: flex; justify-content: center;">
						--- {originalLength - MAX_EVENTS} other events ---
					</div>
				),
			},
			...criticalEvents.slice(-(MAX_EVENTS / 2)),
		]
	}
	const CategoryIcon = StyledMissionCategoryIconMap[missionCategory]
	return (
		<Parent>
			<FlexWrapper css="align-items: center;">
				<Title as="h4"> Simulation Name:</Title>
				<Card css="width: fit-content; padding: 4px !important;">{simulationName}</Card>
				<Divider />
				<Title as="h4"> Mission code:</Title>
				<Card css="width: fit-content; padding: 4px !important;">{code}</Card>
			</FlexWrapper>

			<HTMLTable css="width: 100%;" condensed>
				<thead>
					<tr>
						<th>Type</th>
						<th>Grade</th>
						<th>Score</th>
						<th>Class size</th>
						{isMissionLive && <th>Connected Students</th>}
						<th>Absent Students</th>
						<th>Delivery Method</th>
						<th>App Type</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{getMissionType(controlSet)}</td>

						<td>{stringifyGrades(grades)}</td>
						<td>{score} pts</td>
						<td>{Object.keys(students).length}</td>
						{isMissionLive && (
							<td>
								{reduceObject(
									students,
									(total, student) => (student.connected ? total + 1 : total),
									0
								)}
							</td>
						)}
						<td>
							{reduceObject(students, (total, student) => (student.absent ? total + 1 : total), 0)}
						</td>
						<StyledMissionCategory>
							{CategoryIcon ? <CategoryIcon /> : null}
							{MissionCategoryToReadableCategoryMap[missionCategory] || missionCategory}
						</StyledMissionCategory>
						<td>
							<Tag>{ranOnWeb ? 'WEB' : 'DESKTOP'}</Tag>
						</td>
					</tr>
				</tbody>
			</HTMLTable>

			<FlexTable>
				<div>
					<Title as="h4">Mission Control</Title>
					{mission.flyer ? (
						<UserCard id={mission.flyer._id} />
					) : (
						<div css="font-style: italic;">None</div>
					)}
				</div>

				{mission.flyer?._id !== mission.owner._id && (
					<div>
						<Title as="h4">Owner</Title>
						<UserCard id={mission.owner._id} />
					</div>
				)}
			</FlexTable>

			<FlexWrapper css="align-items: center;">
				<Title as="h4">Critical Events</Title>
				<Breadcrumbs breadcrumbRenderer={renderBreadcrumb} items={criticalEvents} />
			</FlexWrapper>
			<FlexWrapper css="align-items: center">
				<Title as="h4">{impression ? 'Review' : 'No survey content available at this time'}</Title>
				{impression && (
					<FlexTable>
						<Card css="width: fit-content;">
							<FlexWrapper column>
								<FlexWrapper spaceBetween>
									<Title>Students: </Title>
									{impression.averageRating && impression.averageRating > -1 ? (
										<StarRatings
											// adjust from 0-3 scale to 1-5 scale
											rating={impression.averageRating * (4 / 3) + 1}
											{...StarProps}
										/>
									) : (
										'N/A'
									)}
								</FlexWrapper>
								<Divider />
								<FlexWrapper spaceBetween>
									<Title>Teacher: </Title>
									{impression.teacherResponse ? (
										<StarRatings rating={impression.teacherResponse.missionRating} {...StarProps} />
									) : (
										'N/A'
									)}
								</FlexWrapper>
							</FlexWrapper>
						</Card>
						{(impression.teacherResponse?.missionImprovementSuggestions ||
							impression.teacherResponse?.generalFeedback ||
							impression.teacherResponse?.technicalDifficulties) && (
							<Card css="flex: 1; flex-grow: 1; ">
								{impression.teacherResponse?.missionImprovementSuggestions && (
									<ListCallout intent="none" title="Suggestions" icon="comment">
										{impression.teacherResponse.missionImprovementSuggestions}
									</ListCallout>
								)}

								{impression.teacherResponse?.generalFeedback && (
									<ListCallout intent="none" title="General Feedback" icon="comment">
										{impression.teacherResponse.generalFeedback}
									</ListCallout>
								)}

								{impression.teacherResponse &&
									impression.teacherResponse.technicalDifficulties &&
									impression.teacherResponse.technicalDifficulties.length > 0 && (
										<ListCallout intent="warning" title="Reported Issues" icon="warning-sign">
											{impression.teacherResponse.technicalDifficulties}
										</ListCallout>
									)}
							</Card>
						)}
					</FlexTable>
				)}
			</FlexWrapper>
		</Parent>
	)
}

function UserCard({ id }: { id: string }) {
	const client = useClientStore(state => selectors.getClientDetail(state, id))
	const fetchClientDetails = useClientStore(selectors.getClientDetailFetch)
	useEffect(() => {
		fetchClientDetails(id)
	}, [id, fetchClientDetails])

	return (
		<Card css="flex: 1;">
			<FlexWrapper>
				{client ? (
					<>
						<CenteredText> {client.firstName + ' ' + client.lastName} </CenteredText>
						<Divider />
						<CenteredText>
							<Copiable value={client.email} />
						</CenteredText>
						<Divider />
						<CenteredText css="margin: auto 0;">
							{' '}
							{client.schoolName + ', ' + client.state}{' '}
						</CenteredText>
						<Divider />
						<CenteredText css="margin: auto 0;">
							{client.roles.map(role => prettifyTypeEnum(role.role)).join(', ')}{' '}
						</CenteredText>
					</>
				) : (
					<FlexedLoading>
						<Loader />
						Loading User...
					</FlexedLoading>
				)}
			</FlexWrapper>
		</Card>
	)
}

function renderBreadcrumb({
	icon,
	timestamp,
	type,
	meta,
}: {
	type: string,
	icon: string,
	timestamp: string,
	meta: {},
}) {
	return (
		<Popover
			popoverClassName={Classes.TOOLTIP}
			interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
			content={
				<span>
					{timestamp}
					<EventMetaDetails meta={meta} />
				</span>
			}
			position={Position.BOTTOM}>
			<Breadcrumb icon={icon} css="overflow: show;">
				{type}
			</Breadcrumb>
		</Popover>
	)
}

/**
 * Displays a details header, along with the info in the given meta object.
 */
function EventMetaDetails({ meta }: { meta: ?{} }) {
	return (
		<div css="padding-top: var(--spacing);">
			<b>Details</b>
			{!meta || Object.keys(meta).length === 0 ? (
				<div>None</div>
			) : (
				Object.keys(meta).map(metaKey => (
					<div key={metaKey}>
						{prettifyTypeEnum(metaKey)}: {prettifyTypeEnum(meta[metaKey])}
					</div>
				))
			)}
		</div>
	)
}

export const getMissionType = (controlSet: string): string => {
	if (controlSet === READABLE_CONTROL_SETS.JUNIOR_PLUS) return 'Mission.io Controls (4+)'
	if (controlSet === READABLE_CONTROL_SETS.JUNIOR) return 'Mission.io Controls (K-3)'
	return 'N/A'
}

const stringifyGrades = (grades: string[]) => {
	const gradesArray = grades
	if (gradesArray.length > 0) {
		return gradesArray.join(', ')
	}
	return 'N/A'
}

const EVENTS_TO_ICONS = {
	[eventTypesEnum.BEGIN_PREP]: 'add',
	[eventTypesEnum.OPEN_MISSION]: 'log-in',
	[eventTypesEnum.RESTORE_MISSION]: 'repeat',
	[eventTypesEnum.START_MISSION]: 'play',
	[eventTypesEnum.END_MISSION]: 'endorsed',
	[eventTypesEnum.SAVE_FOR_LATER]: 'pause',
}

const FlexTable = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: -8px;
	flex-grow: 1;
	& > * {
		margin-top: 8px;
	}
	& > :not(:last-child) {
		margin-right: ${({ theme }) => theme.spacing2x};
	}
`

const Title = styled.span`
	margin-right: 8px;
`

const Parent = styled.div`
	& > * {
		margin-bottom: 10px;
	}
`

const FlexedLoading = styled.div`
	display: flex;
	flex-direction: row;
`

const ListCallout = styled(Callout)`
	:not(:last-child) {
		margin-bottom: 8px;
	}
`

const StyledMissionCategory = styled.td`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const StyleMissionCategoryIcon = icon => styled(icon)`
	${({ theme }) => `
		width: ${theme.baseFontSize};
		height: ${theme.baseFontSize};
		margin-right: ${theme.spacing};
	`}
`

const StyledMissionCategoryIconMap: typeof MissionCategoryIconMap = ((): typeof MissionCategoryIconMap => {
	const map = {}
	Object.keys(MissionCategoryIconMap).forEach((category: string) => {
		map[category] = StyleMissionCategoryIcon(MissionCategoryIconMap[category])
	})
	return map
})()
