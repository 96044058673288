import React from 'react'

import type { Option } from '../../../../../../utility/hooks'

import { useAutomatedSimulations } from '../../../../../../services/hooks'
import { toIdMap } from '../../../../../../utility/functions'
import { useMemo } from 'react'
import { MassiveMultiSelect } from './MassiveMultiSelect'
import type { Simulation } from '../../../../../../services/hooks'

/**
 * SimulationSelector - a form which allows the user to select simulations from a searchable multi-select box
 *
 * @param {Object} props - the react props
 * @param {?Array<string>} props.selection - the selected simulationIds
 * @param {(newSelection: ?Array<string>) => any} props.onChange - a callback when the selection changes
 *
 * @returns React$Node
 */
export default function SimulationSelector({
	selection,
	onChange,
}: {
	selection: ?Array<string>,
	onChange: (newSelection: ?Array<string>) => any,
}): React$Node {
	const { data: simulations } = useAutomatedSimulations()
	const [simulationLookup, simulationOptions] = useMemo(
		() => [
			toIdMap(simulations || []),
			simulations?.map(simulationToOption).sort((a, b) => a.label.localeCompare(b.label)),
		],
		[simulations]
	)

	return (
		<MassiveMultiSelect
			selectedOptions={selection ?? []}
			onChange={onChange}
			valueMapper={values =>
				values.map(value =>
					simulationToOption(
						simulationLookup[value] ?? {
							_id: value,
							name: 'Unknown',
						}
					)
				)
			}
			options={simulationOptions}
		/>
	)
}

/**
 * simulationToOption - convert a simulation object to a select input option
 *
 * @param {Simulation} simulation - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function simulationToOption({ name, _id }: Simulation): Option {
	return {
		value: _id,
		label: name,
	}
}
