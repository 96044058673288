import React from 'react'
import FindClients from './FindClients/FindClients'
import RoleRequests from './RoleRequests/RoleRequests'
import LicenseList from './Licenses/LicenseList'
import VerifyingSchool from './VerifyingSchool/VerifyingSchool'
import AddSchool from '../AddSchool/AddSchool'
import styled from 'styled-components/macro'
import PageWithSideNav from '../../components/PageWithSideNav'

const tabs = {
	search: {
		id: 'search',
		name: 'Search',
		header: 'Client Search',
		component: styled(FindClients)`
			width: max(350px, 50%);
		`,
	},
	'role-requests': {
		id: 'role-requests',
		name: 'Role Requests',
		header: 'Role Requests',
		component: RoleRequests,
	},
	licenses: {
		id: 'licenses',
		name: 'License Management',
		header: 'License Management',
		component: LicenseList,
		roleRequired: 'LICENSE_MANAGER',
	},
	'verify-schools': {
		id: 'verify-schools',
		name: 'Verify Schools',
		header: 'Verifying User Created Schools',
		component: VerifyingSchool,
	},
	addSchool: {
		id: 'addSchool',
		name: 'Add School',
		header: 'Add New School',
		component: AddSchool,
	},
}

export default function Clients(): React$Node {
	return <PageWithSideNav tabs={tabs} />
}
