// @flow
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { CSVLink } from 'react-csv'
import {
	useTable,
	useSortBy,
	useGlobalFilter,
	usePagination,
	useAsyncDebounce,
	useFilters,
} from 'react-table'
import {
	Icon,
	HTMLTable,
	Button,
	FormGroup,
	NumericInput,
	HTMLSelect,
	ButtonGroup,
	Divider,
	Tooltip,
	InputGroup,
} from '@blueprintjs/core'
import styled from 'styled-components/macro'
import GlobalFilter from './GlobalFilter'
import { formatToCsvColumns, formatToCsvData } from '../../utility/functions'
import { noop } from '../../utility/functions'

export type TableQueryProps = {|
	pageIndex: number,
	pageSize: number,
	sortBy: Array<{ id: string, desc: boolean }>,
	isAllData?: boolean | void,
|}
// We will show this many rows of data per page initially.
export const INITIAL_PAGE_SIZE = 10
const DEBOUNCE_TIME_PERIOD = 300

export type TableColumns = {
	Header: React$ComponentType<*> | string,
	Cell?: React$ComponentType<*>,
	accessor: string,
	hideCSV?: boolean,
	csvWriter?: mixed => string,
	disableSortBy?: boolean,
	/* disableFilters is only necessary if the advanced table prop `enableColumnFilters` is true and you wish to disable filtering for a column */
	disableFilters?: boolean,
}

type AdvancedTableProps<D> = {
	columns: Array<TableColumns>,
	data: Array<D>,
	rowProps?: (row: { id: string, original: D }) => { [string]: mixed },
	manualCount?: number,
	fetchData?: TableQueryProps => void | Promise<any>,
	loading?: boolean,
	topRight?: React$Node,
	bordered?: boolean,
	interactive?: boolean,
	striped?: boolean,
	disableMultiSort?: boolean,
	enableColumnFilters?: boolean,
}

type AsyncCSVButtonProps = {
	columns: Array<TableColumns>,
	fetchData: TableQueryProps => void | Promise<any>,
}

type SyncCSVButtonProps = {
	columns: Array<TableColumns>,
	data: Array<{ values: { [key: string]: string } }>,
	children: string,
}
const initialState = { pageIndex: 0, pageSize: INITIAL_PAGE_SIZE }

/**
 * An advanced table to show columns and their data using react-table.
 * In this component, data is paginated, columns can be sorted, multi-sorted, and global filter appears in the top left corner above the table
 * An optional prop is allowed, topRight, if the caller wishes to render something in the top right corner above the table
 * (across from the global search input)
 */
export default function Table<D>({
	columns,
	data,
	topRight,
	rowProps,
	manualCount,
	fetchData,
	loading,
	disableMultiSort,
	enableColumnFilters,
	...tableProps
}: AdvancedTableProps<D>): React$Node {
	const manualFetching = Boolean(fetchData)
	// Get table props based on if we need to fetch data manually
	const toolkitTableProps = useMemo(() => {
		const props = {}
		props.columns = columns
		props.data = data
		props.initialState = initialState
		props.disableMultiSort = !!disableMultiSort
		if (manualFetching) {
			props.manualPagination = true
			props.manualSortBy = true
			props.pageCount = manualCount ? Math.ceil(manualCount / INITIAL_PAGE_SIZE) : -1
		}
		if (enableColumnFilters) {
			props.defaultColumn = { Filter: DefaultColumnFilter }
			props.defaultCanFilter = true
		}
		return props
	}, [columns, data, manualFetching, manualCount, disableMultiSort, enableColumnFilters])

	const plugins = useMemo(() => {
		const plugins = [useGlobalFilter]
		if (enableColumnFilters) {
			plugins.push(useFilters)
		}
		plugins.push(useSortBy)
		plugins.push(usePagination)
		return plugins
	}, [enableColumnFilters])

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		rows,
		state: { pageIndex, pageSize, sortBy, globalFilter },
		// global filter
		preGlobalFilteredRows,
		setGlobalFilter,
		// pagination
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
	} = useTable(toolkitTableProps, ...plugins)

	// Debounce the fetchData callback
	const fetchDataDebounced = useAsyncDebounce(fetchData || noop, DEBOUNCE_TIME_PERIOD)
	// Listen for changes in pagination and use the state to fetch our new data
	useEffect(() => {
		if (manualFetching) {
			fetchDataDebounced({ pageIndex, pageSize, sortBy })
		}
	}, [manualFetching, fetchDataDebounced, pageIndex, pageSize, sortBy])

	const headerGroup = headerGroups.length > 0 && headerGroups[0]
	return (
		<>
			<div css="padding: 0 1rem; display: flex; flex-direction: row; justify-content: space-between;">
				<div css="display: flex; align-items: center;">
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
						placeholderText={'Search...'}
					/>
					<span css="margin-left: 16px;">
						{loading ? (
							// Use our custom loading state to show a loading indicator
							<i>Loading...</i>
						) : (
							<i>{`Showing ${page.length} ${
								pageCount > 1 ? `of ${manualCount || rows.length}` : ''
							} results`}</i>
						)}
					</span>
				</div>

				{topRight && <div>{topRight}</div>}
			</div>
			<HTMLTable {...getTableProps(tableProps)} css="width: 100%;">
				<thead>
					{headerGroup && (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th
									key={column.id}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									title={
										disableMultiSort
											? 'Multi-sort is currently disabled'
											: 'Toggle sorting, hold shift for multi-sort'
									}>
									{column.disableSortBy ? (
										<Tooltip
											minimal
											placement="top"
											content="Sort is temporary disabled for this column">
											<ColumnHeader column={column} />
										</Tooltip>
									) : (
										<ColumnHeader column={column} />
									)}
									{/* Render the columns filter UI */}
									{enableColumnFilters && (
										<div
											onClick={event => {
												// Make sure clicking on filter does not apply sort on column
												event.stopPropagation()
											}}>
											{column.canFilter && column.Filter && column.render('Filter')}
										</div>
									)}
								</th>
							))}
						</tr>
					)}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row)
						return (
							<tr key={row.id} {...row.getRowProps(rowProps ? rowProps(row) : {})}>
								{row.cells.map(cell => {
									return (
										<td key={cell.value} {...cell.getCellProps()}>
											{cell.value === null || cell.value === undefined || cell.value === 'NA' ? (
												<Na>--</Na>
											) : (
												cell.render('Cell')
											)}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</HTMLTable>
			{data.length && (
				<ButtonGroup>
					<SyncCSVButton data={page} columns={columns}>
						Download Current Page
					</SyncCSVButton>

					{fetchData ? (
						<AsyncCSVButton columns={columns} fetchData={fetchData} />
					) : (
						<SyncCSVButton data={preGlobalFilteredRows} columns={columns}>
							Download All Data
						</SyncCSVButton>
					)}
				</ButtonGroup>
			)}
			{pageOptions.length > 0 && (
				<Pagination
					gotoPage={gotoPage}
					canPreviousPage={canPreviousPage}
					previousPage={previousPage}
					canNextPage={canNextPage}
					nextPage={nextPage}
					pageCount={pageCount}
					pageSize={pageSize}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					setPageSize={setPageSize}
				/>
			)}
		</>
	)
}

type PaginationProps = {
	gotoPage: number => void,
	canNextPage: boolean,
	canPreviousPage: boolean,
	previousPage: () => void,
	nextPage: () => void,
	pageIndex: number,
	pageCount: number,
	pageSize: number,
	pageOptions: number[],
	setPageSize: number => void,
}

// A component to render the header for the column in a table.
function ColumnHeader({ column }: { column: any }) {
	return (
		<div css="display: flex; justify-content: space-between;">
			{column.render('Header')}
			<span>
				{column.isSorted ? (
					column.isSortedDesc ? (
						<Icon icon="caret-down" title="Sorted Descending" />
					) : (
						<Icon icon="caret-up" title="Sorted Ascending" />
					)
				) : (
					''
				)}
			</span>
		</div>
	)
}

// Define a default UI for filtering
function DefaultColumnFilter({
	column: { filterValue, setFilter },
}: {
	column: {
		filterValue: mixed,
		setFilter: (filterValue: mixed) => void,
	},
}) {
	return (
		<InputGroup
			value={filterValue || ''}
			onChange={e => {
				setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
			}}
			leftIcon={'search'}
			placeholder={`Search by column...`}
		/>
	)
}

/**
 * A component which allows navigating pages in the AdvancedTable
 */
function Pagination({
	gotoPage,
	canNextPage,
	nextPage,
	canPreviousPage,
	previousPage,
	pageIndex,
	pageOptions,
	pageCount,
	setPageSize,
	pageSize,
}: PaginationProps) {
	return (
		<PaginationDisplay>
			<ButtonGroup>
				<Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} minimal>
					{'<<'}
				</Button>
				<Button onClick={() => previousPage()} disabled={!canPreviousPage} minimal>
					{'<'}
				</Button>
				<Button onClick={() => nextPage()} disabled={!canNextPage} minimal>
					{'>'}
				</Button>
				<Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} minimal>
					{'>>'}
				</Button>
			</ButtonGroup>
			<span>
				Page{' '}
				<strong>
					{pageIndex + 1} of {pageOptions.length}
				</strong>{' '}
			</span>
			<Divider />
			<FormGroup inline label="Go to page: " css="margin-bottom: 0 !important;">
				<NumericInput
					value={pageIndex + 1}
					min={1}
					max={pageOptions.length || 1} // max can never be lower than the min
					onValueChange={value => {
						const page = value ? value - 1 : 0
						gotoPage(page)
					}}
					selectAllOnFocus={true}
				/>
			</FormGroup>
			<HTMLSelect
				value={pageSize}
				onChange={e => {
					setPageSize(Number(e.target.value))
				}}>
				{[10, 20, 30, 40, 50].map(pageSize => (
					<option key={pageSize} value={pageSize}>
						Show {pageSize}
					</option>
				))}
			</HTMLSelect>
		</PaginationDisplay>
	)
}

/**
 * Gets a name for a csv file based on the current time
 * @returns {string} the file name
 * */
const getCSVFileName = () => {
	const now = new Date()
	const name = `${now.toLocaleDateString()}:${now.toLocaleTimeString()}.csv`
	return name
}

/**
 * A component thats renders in case of manualFetching of AdvancedTable data
 * it takes columns template and fetchData function, and exports all
 * table data to CSV
 */
const AsyncCSVButton = ({ fetchData, columns }: AsyncCSVButtonProps) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [data, setData] = useState<?Array<{ [key: string]: string }>>(null)
	const [err, setErr] = useState<boolean>(false)
	const csvRef = useRef()
	const filename = useMemo(getCSVFileName, [])

	const handleFetchData = async () => {
		setLoading(true)
		setData(null)
		try {
			const res = await fetchData({
				pageIndex: 0,
				pageSize: 0,
				sortBy: [],
				isAllData: true,
			})
			if (res?.list) {
				const data = formatToCsvData(columns, res.list)
				setData(data)
				return
			}
		} catch (err) {
			setErr(true)
		}
		setLoading(false)
	}
	useEffect(() => {
		if (data && csvRef.current) {
			// Set a timeout to avoid race condition caused by react-csv where the download link takes a moment to build
			setTimeout(() => {
				csvRef.current.link.click()
				setLoading(false)
			}, 100)
		}
	}, [data])

	if (err) {
		setTimeout(() => {
			setErr(false)
		}, 6000)
		return <span>Failed to load data</span>
	}

	return (
		<>
			{loading ? (
				<Button disabled>...loading</Button>
			) : (
				<Button
					onClick={() => {
						handleFetchData()
					}}>
					All data as CSV
				</Button>
			)}

			<CSVLink
				css="display: hidden"
				ref={csvRef}
				data={data || []}
				filename={filename}
				headers={formatToCsvColumns(columns)}
			/>
		</>
	)
}

/**
 * A component thats export csv in case of sync fetching data
 */

const SyncCSVButton = ({ data, columns, children }: SyncCSVButtonProps) => {
	const filename = useMemo(getCSVFileName, [])
	const csvData = useMemo(
		() =>
			data
				? formatToCsvData(
						columns,
						data.map(item => item.values)
				  )
				: [],
		[data, columns]
	)
	return (
		<CSVLink
			className="bp3-button"
			data={csvData}
			filename={filename}
			headers={formatToCsvColumns(columns)}>
			{children}
		</CSVLink>
	)
}

const PaginationDisplay = styled.div`
	padding: 16px 0;
	display: flex;
	flex-direction: row;
	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	& > :not(:last-child) {
		margin-right: 16px;
	}
`

const Na = styled.span`
	opacity: 0.2;
`
