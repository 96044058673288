import type { BinnedClientData, Key, Statistics } from './types'
import { getAllStats, getStatsAtLocation, type StatsWithPaths } from './statsHelpers'
import {
	getBasicStatsData,
	LINE_SELECTOR,
	type BoxPlotSelector,
	type LineSelector,
} from './selectors'
import { DECIMAL_PLACES } from './constants'
import type { ConversionMap } from './converters'

export type BoxPlotData = { x: string, y: [?number, ?number, ?number, ?number, ?number] }[]
export type LinPlotData = {| name: string, data: Array<?number> |}[]

type ApexChartData = { series: any, options: any }

const CHART_HEIGHT = 350

const DEFAULT_BOX_PLOT_OPTIONS = {
	chart: {
		type: 'boxPlot',
		height: CHART_HEIGHT,
		events: {},
		animations: {
			enabled: false,
		},
	},
	tooltip: {
		followCursor: true,
	},
	title: {
		text: '',
	},
	yaxis: {
		decimalsInFloat: DECIMAL_PLACES,
	},
}

const DEFAULT_LINE_PLOT_OPTIONS = {
	chart: {
		height: CHART_HEIGHT,
		type: 'line',
		zoom: {
			enabled: false,
		},
		animations: {
			enabled: false,
		},
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		curve: 'straight',
	},
	tooltip: {
		y: {
			formatter: value => (value != null ? round(value, DECIMAL_PLACES).toLocaleString() : null),
		},
	},
	title: {
		text: '',
	},
	yaxis: {
		decimalsInFloat: DECIMAL_PLACES,
	},
}

/**
 * getBoxPlotSeries - get the box plot series data for a the given location
 *
 * @param {ClientStats} clientStats - the container with the location
 * @param {BoxPlotSelector} selector - a callback to transform statistics into the wanted ranges
 * @param {boolean} includeOutliers - true if should include outliers, false otherwise
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {BoxPlotData} - the series data for apex charts
 */
function getBoxPlotSeries(
	stats: StatsWithPaths[],
	selector: BoxPlotSelector,
	includeOutliers: boolean,
	conversionMap: ConversionMap
): BoxPlotData {
	return (stats || []).map(({ path, ...rest }) => ({
		x: path.join('.'),
		y: !getBasicStatsData(rest, includeOutliers, conversionMap).samples
			? [null, null, null, null, null]
			: selector(rest, includeOutliers, conversionMap),
	}))
}

/**
 * getLinePlotSeries - get the line plot series data for the given location
 *
 * @param {BinnedClientData} bins - all the bins to track the stats of the location over
 * @param {Key} location - the location in the stats to tack
 * @param {LineSelector} selector - a callback to get the wanted value out of the statistics
 * @param {boolean} includeOutliers - true if should include outliers, false otherwise
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {{ series: LinPlotData, xAxisLabels: string[] }} - the series data for apex charts
 */
function getLinePlotSeries(
	bins: BinnedClientData,
	location: Key,
	selector: LineSelector,
	includeOutliers: boolean,
	conversionMap: ConversionMap
): { series: LinPlotData, xAxisLabels: string[] } {
	const lines: { [name: string]: (?number)[] } = {}
	const binKeys = getSortedBinsIds(bins)
	binKeys.forEach((key, index) => {
		getAllStats(location, bins[key].stats).forEach(({ path, ...stats }) => {
			const name = path.join('.')
			if (!lines[name]) {
				lines[name] = new Array(binKeys.length).fill(null)
			}
			if (getBasicStatsData(stats, includeOutliers, conversionMap).samples) {
				lines[name][index] = selector(stats, includeOutliers, conversionMap)
			}
		})
	})
	return {
		series: Object.keys(lines).map(lineName => ({ name: lineName, data: lines[lineName] })),
		xAxisLabels: binKeys,
	}
}

/**
 * getBoxPlotSeries - get the apex-charts box plot data for a the given location
 *
 * @param {BinnedClientData} bins - all the bins to track the stats of the location over
 * @param {string} selectedBinId - the currently selected bin
 * @param {Key} location - the current location to get data for
 * @param {BoxPlotSelector} selector - a callback used to get the boxPlot data from the current statistics
 * @param {(newLocation: Key) => void} setLocation - a callback used to set the current location that stats are being displayed for
 * @param {boolean} includeOutliers - true if should include outliers, false otherwise
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {BoxPlotData} - the box plot data for apex charts
 */
export function getBoxPlot(
	bins: BinnedClientData,
	selectedBinId: string,
	location: Key,
	selector: BoxPlotSelector,
	setLocation: (newLocation: Key) => void,
	includeOutliers: boolean,
	conversionMap: ConversionMap
): ApexChartData {
	const allData = getAllStats(location, bins[selectedBinId]?.stats)
	return {
		series: [
			{
				type: 'boxPlot',
				data: getBoxPlotSeries(allData, selector, includeOutliers, conversionMap),
			},
		],
		options: {
			...DEFAULT_BOX_PLOT_OPTIONS,
			chart: {
				...DEFAULT_BOX_PLOT_OPTIONS.chart,
				events: {
					...DEFAULT_BOX_PLOT_OPTIONS.chart.events,
					markerClick: function(event, chartContext, { seriesIndex, dataPointIndex, config }) {
						setLocation(allData[dataPointIndex].path)
					},
				},
			},
			tooltip: {
				...DEFAULT_BOX_PLOT_OPTIONS.tooltip,
				custom: ({ series, seriesIndex, dataPointIndex, w }) => {
					// remove path from the stats
					// eslint-disable-next-line no-unused-vars
					const { path, ...stats } = allData[dataPointIndex]
					return getBoxPlotToolTips(stats, includeOutliers, conversionMap)
				},
			},
		},
	}
}

/**
 * getBoxPlotToolTips - get the tool tips to show when a user hovers over a box plot
 *
 * @param {Statistics} stats - the stats for the element being hovered over in the plot
 * @param {boolean} includeOutliers - weather or not outlier should be included in the tooltip
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {string} - the html
 */
function getBoxPlotToolTips(
	stats: Statistics,
	includeOutliers: boolean,
	conversionMap: ConversionMap
): string {
	return `<div className="arrow_box">
 <div>${includeOutliers ? 'Including Outliers' : 'Excluding Outliers'}</div>
 ${stats.type ? `<div>Type: ${stats.type}</div>` : ''}
 <div>min: ${round(LINE_SELECTOR.min(stats, includeOutliers, conversionMap), DECIMAL_PLACES)}</div>
 <div>q1: ${round(LINE_SELECTOR.q1(stats, includeOutliers, conversionMap), DECIMAL_PLACES)}</div>
 <div>median: ${round(
		LINE_SELECTOR.median(stats, includeOutliers, conversionMap),
		DECIMAL_PLACES
 )}</div>
 <div>q3: ${round(LINE_SELECTOR.q3(stats, includeOutliers, conversionMap), DECIMAL_PLACES)}</div>
 <div>max: ${round(LINE_SELECTOR.max(stats, includeOutliers, conversionMap), DECIMAL_PLACES)}</div>
 <div>mean: ${round(
		LINE_SELECTOR.average(stats, includeOutliers, conversionMap),
		DECIMAL_PLACES
 )}</div>
 <div>std. deviation: ${round(
		LINE_SELECTOR.standardDeviation(stats, includeOutliers, conversionMap),
		DECIMAL_PLACES
 )}</div>
 <hr />
 <div>Samples: ${LINE_SELECTOR.sampleCount(stats, includeOutliers, conversionMap) ?? 0}</div>
 <div>Outlier Count: ${LINE_SELECTOR.outlierCount(stats, includeOutliers, conversionMap) ??
		0} (below: ${LINE_SELECTOR.outlierCountBelowMin(stats, includeOutliers, conversionMap) ??
		0}, above: ${LINE_SELECTOR.outlierCountAboveMax(stats, includeOutliers, conversionMap) ??
		0})</div>
 <div>Outlier Range: [${round(
		LINE_SELECTOR.outliersLowerRange(stats, includeOutliers, conversionMap),
		DECIMAL_PLACES
 )} , ${round(
		LINE_SELECTOR.outliersUpperRange(stats, includeOutliers, conversionMap),
		DECIMAL_PLACES
	)}]</div>
</div>`
}

/**
 * getLinePlot - get the apex-charts line plot data for a the given location
 *
 * @param {BinnedClientData} bins - all the bins to track the stats of the location over
 * @param {Key} location - the current location to get data for
 * @param {BoxPlotSelector} selector - a callback used to get the line chart data from the current statistics
 * @param {boolean} includeOutliers - true if should include outliers, false otherwise
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {BoxPlotData} - the box plot data for apex charts
 */
export function getLinePlot(
	bins: BinnedClientData,
	location: Key,
	selector: LineSelector,
	includeOutliers: boolean,
	conversionMap: ConversionMap
): ApexChartData {
	const { series, xAxisLabels } = getLinePlotSeries(
		bins,
		location,
		selector,
		includeOutliers,
		conversionMap
	)
	return {
		series,
		options: {
			...DEFAULT_LINE_PLOT_OPTIONS,
			xaxis: {
				categories: xAxisLabels,
			},
		},
	}
}

/**
 * getComparisonLinePlot - get the apex-charts line plot data for comparing data at a given location across multiple queries
 *
 * @param {{ bins: ?BinnedClientData, statsName: string }[]} queryData - the bins for every query being compared along with their names
 * @param {Key} location - the current location to get data for
 * @param {BoxPlotSelector} selector - a callback used to get the line chart data from the current statistics
 * @param {boolean} includeOutliers - true if should include outliers, false otherwise
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {ApexChartData} - the box plot data for apex charts as well as the bin ordering
 */
export function getComparisonLinePlot(
	queryData: { bins: ?BinnedClientData, statsName: string }[],
	location: Key,
	selector: LineSelector,
	includeOutliers: boolean,
	conversionMap: ConversionMap
): ApexChartData {
	const combinedBins: BinnedClientData = {}

	// All elements at an index in the arrays in any key are derived from the same `binData` element at the given index
	const statsToDisplay: { [binId: string]: Array<?Statistics> } = {}

	queryData.forEach(({ bins }: { bins: ?BinnedClientData }, index: number) => {
		if (!bins) {
			return
		}
		Object.keys(bins).forEach(binId => {
			const bin = bins[binId]
			combinedBins[binId] = bin

			if (!statsToDisplay[binId]) {
				statsToDisplay[binId] = new Array(queryData.length).fill(null)
			}
			statsToDisplay[binId][index] = getStatsAtLocation(location, bin.stats)
		})
	})

	const binOrdering = getSortedBinsIds(combinedBins)

	const series = []
	queryData.forEach(({ statsName }: { statsName: string }, index: number) => {
		const seriesData = { name: statsName, data: new Array(binOrdering.length).fill(null) }

		for (let i = 0; i < binOrdering.length; i++) {
			const stats: ?Statistics = statsToDisplay[binOrdering[i]][index]

			seriesData.data[i] = stats ? selector(stats, includeOutliers, conversionMap) : null
		}

		series.push(seriesData)
	})

	return {
		series,
		options: {
			...DEFAULT_LINE_PLOT_OPTIONS,
			xaxis: {
				categories: binOrdering,
			},
		},
	}
}

/**
 * getComparisonBoxPlot - get the apex-charts box plot data for comparing statistics at a particular location across multiple queries
 *
 * @param {{ bins: ?BinnedClientData, statsName: string }[]} queryData - the bins for every query being compared along with their names
 * @param {string} selectedBinId - the currently selected bin
 * @param {Key} location - the current location to get data for
 * @param {BoxPlotSelector} selector - a callback used to get the boxPlot data from the current statistics
 * @param {boolean} includeOutliers - true if should include outliers, false otherwise
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {BoxPlotData} - the box plot data for apex charts
 */
export function getComparisonBoxPlot(
	queryData: { bins: ?BinnedClientData, statsName: string }[],
	selectedBinId: string,
	location: Key,
	selector: BoxPlotSelector,
	includeOutliers: boolean,
	conversionMap: ConversionMap
): ApexChartData {
	const seriesData = queryData.map(({ bins, statsName }) => {
		const statsAtLocation = getStatsAtLocation(location, bins?.[selectedBinId]?.stats)
		return {
			x: statsName,
			y:
				statsAtLocation &&
				getBasicStatsData(statsAtLocation, includeOutliers, conversionMap).samples
					? selector(statsAtLocation, includeOutliers, conversionMap)
					: [null, null, null, null, null],
			stats: statsAtLocation,
		}
	})

	return {
		series: [
			{
				type: 'boxPlot',
				data: seriesData,
			},
		],
		options: {
			...DEFAULT_BOX_PLOT_OPTIONS,
			tooltip: {
				...DEFAULT_BOX_PLOT_OPTIONS.tooltip,
				custom: ({ series, seriesIndex, dataPointIndex, w }) => {
					const { stats } = seriesData[dataPointIndex]

					return stats
						? getBoxPlotToolTips(stats, includeOutliers, conversionMap)
						: `<div>No Data</div>`
				},
			},
		},
	}
}

/**
 * round - round the number to the given decimal place
 *
 * @param {?number} number - the number to round
 * @param {number} decimalPlaces - the number of decimal placed to round to
 *
 * @return {number} - the rounded number
 */
function round(number: ?number, decimalPlaces: number): string {
	const offset = 10 ** decimalPlaces
	return (Math.round((number ?? 0) * offset) / offset).toLocaleString()
}

/**
 * getSortedBinsIds - get the binIds in sorted order based on the bins sortKey's
 *
 * @param {BinnedClientData} bins - the bins to get the sorted binIds for
 *
 * @return {string[]} - the sorted bin Ids
 */
export function getSortedBinsIds(bins: BinnedClientData): string[] {
	return Object.keys(bins).sort((a, b) => bins[a].sortKey - bins[b].sortKey)
}
