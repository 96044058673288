import type { MissionType } from '../../stores/types'

export function missionHasIssue(mission: MissionType): boolean {
	const issue: ?string = mission?.teacherResponse?.technicalDifficulties
	return issue ? issue.trim() !== '' : false
}

export function missionHasSuggestion(mission: MissionType): boolean {
	const suggestions: ?string = mission?.teacherResponse?.missionImprovementSuggestions
	return suggestions ? suggestions.trim() !== '' : false
}

export function missionHasFeedback(mission: MissionType): boolean {
	const feedback: ?string = mission?.teacherResponse?.generalFeedback
	return feedback ? feedback.trim() !== '' : false
}
