import useAuthStore, { selectors as authSelectors } from './auth'
import useMissionStore, { selectors as missionSelectors } from './missions'
import useClientStore, { selectors as clientSelectors } from './clients'
import useFilterStore, { selectors as filterSelectors } from './filters'

export { useAuthStore, useMissionStore, useClientStore, useFilterStore }

export const selectors = {
	...authSelectors,
	...missionSelectors,
	...clientSelectors,
	...filterSelectors,
}
