import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { EnumFieldType, Enumeration } from '../../clientTypes'
import type { Criteria, UpdateCriteria } from '../../localTypes'

import { get, minimizeDelete, assign } from '../../../../../../utility/dotProxy'

import { FlexRowCenterer } from '../../sharedComponents'
import { IncludeExcludingSwitch } from '../includingExcludingSwitch'
import { EnumSelection } from './base'

import React, { useEffect, useState } from 'react'

/**
 * EnumEntry - a react component for allowing users to multi-select from a list of options provided by the server
 *
 * @param {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - the current criteria
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data for ths selection
 * @param  {EnumFieldType} props.fieldType - the EnumFieldType containing the options the user can pick from
 *
 * @returns React$Node
 */
export function EnumEntry({
	updateCriteria,
	currentCriteria,
	currentPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<Enumeration<string>>,
	fieldType: EnumFieldType,
}): React$Node {
	const valueMapper = ({ displayName, value }: { displayName: string, value: string }) => ({
		label: displayName,
		value,
	})

	const options = fieldType.options.map(valueMapper)
	const valueToOption = {}
	const labelToOption = {}
	options.forEach(option => {
		valueToOption[option.value] = option
		labelToOption[option.label] = option
	})

	const [excludingFallback, setExcludingFallback] = useState(false)
	const currentField = get(currentPath, currentCriteria)
	useEffect(() => {
		if (!currentField) {
			return
		}
		if (currentField.include) {
			setExcludingFallback(false)
			return
		}
		if (currentField.exclude) {
			setExcludingFallback(true)
			return
		}
	}, [currentField])

	return (
		<FlexRowCenterer>
			<IncludeExcludingSwitch
				excluding={excludingFallback}
				onChange={excluding => {
					if (currentField) {
						const array = currentField?.include ? currentField?.include : currentField.exclude ?? []
						updateCriteria((oldCriteria: Criteria) =>
							assign(currentPath, oldCriteria, excluding ? { exclude: array } : { include: array })
						)
					}
					setExcludingFallback(excluding)
				}}
			/>
			<EnumSelection
				excluding={excludingFallback}
				onChange={selectedOptions => {
					if (!selectedOptions.length) {
						updateCriteria((oldCriteria: Criteria) => minimizeDelete(currentPath, oldCriteria))
						return
					}
					let field = { ...currentField }
					if (field.include) {
						field.include = selectedOptions
					} else if (field.exclude) {
						field.exclude = selectedOptions
					} else if (excludingFallback) {
						field = { exclude: selectedOptions }
					} else {
						field = { include: selectedOptions }
					}
					updateCriteria((oldCriteria: Criteria) => assign(currentPath, oldCriteria, field))
				}}
				options={fieldType.options.map(valueMapper)}
				selectedOptions={currentField?.include ? currentField.include : currentField?.exclude ?? []}
				valueMapper={value => valueToOption[value]}
			/>
		</FlexRowCenterer>
	)
}
