import { useQuery, useQueryClient, type UseQueryResult } from 'react-query'
import NetworkCommunicator from './NetworkCommunicator'
import { useQueries } from 'react-query'
import { type ClientType } from '../stores/types'
import type { SchoolData } from '../routes/Clients/FindClients/School'

export type School = {
	_id: string,
	name: string,
	state: string,
}

export type District = { ncesId: string, district: string }

export type Simulation = {
	_id: string,
	name: string,
}

const DO_NOT_REFETCH_QUERY_OPTIONS: {| cacheTime: number, staleTime: number |} = {
	cacheTime: Infinity,
	staleTime: Infinity,
}

const MAX_DATE = new Date(Number.MAX_SAFE_INTEGER / 1.5).getTime()

/**
 * useClients - get the list of all clients. This is an expensive operation so it will not refetch nor refresh.
 *
 * @return {UseQueryResult<ClientType[]>}
 */
export function useClients(): UseQueryResult<ClientType[]> {
	return useQuery(
		['clients'],
		() => NetworkCommunicator.GET('users').then(response => response.users),
		{ ...DO_NOT_REFETCH_QUERY_OPTIONS }
	)
}

type AllFetchedSchoolsCache = {
	schools: School[],
	fetchedSchoolIds: Set<string>,
}

export const MIN_SCHOOL_SEARCH_STRING_LENGTH = 4
const MANUALLY_MANAGED_ALL_SCHOOLS_KEY = ['all-schools-manual']

/**
 * useSchools - get the list of all schools which contain the STARTING {MIN_SCHOOL_SEARCH_STRING_LENGTH} characters of the search string
 *
 * @param {string} searchString - the search string (NOTE: only the first MIN_SCHOOL_SEARCH_STRING_LENGTH will be searched for)
 *
 * @return {UseQueryResult<School[]>}
 */
export function useSchools(searchString: string): UseQueryResult<School[]> {
	const queryClient = useQueryClient()

	const actualSearchString = searchString.slice(0, MIN_SCHOOL_SEARCH_STRING_LENGTH)

	return useQuery(
		['schools', actualSearchString],
		() => {
			if (searchString.length < MIN_SCHOOL_SEARCH_STRING_LENGTH) {
				return []
			}

			return NetworkCommunicator.GET(`schools?text=${actualSearchString}`).then(
				(schools: School[]) => {
					schools.forEach(school => {
						queryClient.setQueryDefaults(['school', school._id], {
							...DO_NOT_REFETCH_QUERY_OPTIONS,
						})
						queryClient.setQueryData(['school', school._id], school)
					})

					queryClient.setQueryDefaults(MANUALLY_MANAGED_ALL_SCHOOLS_KEY, {
						...DO_NOT_REFETCH_QUERY_OPTIONS,
					})
					queryClient.setQueryData(
						MANUALLY_MANAGED_ALL_SCHOOLS_KEY,
						(data: ?AllFetchedSchoolsCache): AllFetchedSchoolsCache => {
							if (!data) {
								return {
									schools,
									fetchedSchoolIds: new Set(schools.map(({ _id }) => _id)),
								}
							}

							const newSchools = schools.filter(({ _id }) => !data.fetchedSchoolIds.has(_id))
							if (!newSchools.length) {
								return data
							}

							const newFetchedSchoolIds = new Set(data.fetchedSchoolIds)
							newSchools.forEach(({ _id }) => newFetchedSchoolIds.add(_id))
							return {
								schools: [...data.schools, ...newSchools],
								fetchedSchoolIds: newFetchedSchoolIds,
							}
						},
						{ updatedAt: MAX_DATE }
					)

					return schools
				}
			)
		},
		{
			...DO_NOT_REFETCH_QUERY_OPTIONS,
			retry: 0,
		}
	)
}

/**
 * useSchoolsByIds - get the schools with the corresponding ids
 *
 * @param {string[]} schoolIds - the ids of the schools to get
 *
 * @return { Array<UseQueryResult<SchoolData>> }
 */
export function useSchoolsByIds(schoolIds: string[]): Array<UseQueryResult<SchoolData>> {
	const queryClient = useQueryClient()

	return useQueries(
		schoolIds.map(schoolId => ({
			queryKey: ['school', schoolId],
			queryFn: () =>
				NetworkCommunicator.GET(`schools/${schoolId}`).then(({ school }) => {
					queryClient.setQueryDefaults(MANUALLY_MANAGED_ALL_SCHOOLS_KEY, {
						...DO_NOT_REFETCH_QUERY_OPTIONS,
					})
					queryClient.setQueryData(
						MANUALLY_MANAGED_ALL_SCHOOLS_KEY,
						(data: ?AllFetchedSchoolsCache): AllFetchedSchoolsCache => {
							if (!data) {
								return {
									schools: [school],
									fetchedSchoolIds: new Set([school._id]),
								}
							}

							if (data.fetchedSchoolIds.has(school._id)) {
								return data
							}

							const newFetchedSchoolIds = new Set(data.fetchedSchoolIds)
							newFetchedSchoolIds.add(school._id)
							return {
								schools: [...data.schools, school],
								fetchedSchoolIds: newFetchedSchoolIds,
							}
						},
						{ updatedAt: MAX_DATE }
					)

					return school
				}),
			retry: 0,
			...DO_NOT_REFETCH_QUERY_OPTIONS,
		}))
	)
}

/**
 * useAllFetchedSchools - get all the schools which have been fetched by other queries from the server
 *
 * @return {School[]}
 */
export function useAllFetchedSchools(): School[] {
	const queryClient = useQueryClient()
	return queryClient.getQueryData(MANUALLY_MANAGED_ALL_SCHOOLS_KEY)?.schools || []
}

export const MIN_DISTRICT_SEARCH_STRING_LENGTH = 3

type AllFetchedDistrictsCache = {
	districts: District[],
	fetchedDistrictIds: Set<string>,
}

const MANUALLY_MANAGED_ALL_DISTRICT_KEY = ['all-districts-manually-managed']

/**
 * useDistricts - get the list of all districts which contain the STARTING {MIN_DISTRICT_SEARCH_STRING_LENGTH} characters of the search string
 *
 * @param {string} searchString - the search string (NOTE: only the first MIN_DISTRICT_SEARCH_STRING_LENGTH will be searched for)
 *
 * @return {UseQueryResult<District[]>}
 */
export function useDistricts(searchString: string): UseQueryResult<District[]> {
	const queryClient = useQueryClient()

	const actualSearchString = searchString.slice(0, MIN_DISTRICT_SEARCH_STRING_LENGTH)

	return useQuery(
		['districts', actualSearchString],
		() => {
			if (searchString.length < MIN_DISTRICT_SEARCH_STRING_LENGTH) {
				return []
			}

			return NetworkCommunicator.GET(`schools/districts?text=${actualSearchString}`).then(
				(districts: District[]) => {
					districts.forEach((district: District) => {
						queryClient.setQueryDefaults(['district', district.ncesId], {
							...DO_NOT_REFETCH_QUERY_OPTIONS,
						})
						queryClient.setQueryData(['district', district.ncesId], district)
					})

					queryClient.setQueryDefaults(MANUALLY_MANAGED_ALL_DISTRICT_KEY, {
						...DO_NOT_REFETCH_QUERY_OPTIONS,
					})
					queryClient.setQueryData(
						MANUALLY_MANAGED_ALL_DISTRICT_KEY,
						(data: ?AllFetchedDistrictsCache): AllFetchedDistrictsCache => {
							if (!data) {
								return {
									districts,
									fetchedDistrictIds: new Set(districts.map(({ ncesId }) => ncesId)),
								}
							}

							const newDistricts = districts.filter(
								({ ncesId }) => !data.fetchedDistrictIds.has(ncesId)
							)
							if (!newDistricts.length) {
								return data
							}

							const newFetchedDistrictIds = new Set(data.fetchedDistrictIds)
							districts.forEach(({ ncesId }) => newFetchedDistrictIds.add(ncesId))
							return {
								districts: [...data.districts, ...districts],
								fetchedDistrictIds: newFetchedDistrictIds,
							}
						}
					)

					return districts
				}
			)
		},
		{
			retry: 0,
			...DO_NOT_REFETCH_QUERY_OPTIONS,
		}
	)
}

/**
 * useDistrictsByIds - get the districts with the corresponding ncesIds
 *
 * @param {string[]} districtIds - the ncesIds of the districts to get
 *
 * @return { Array<UseQueryResult<District>> }
 */
export function useDistrictsByIds(districtIds: string[]): Array<UseQueryResult<District>> {
	const queryClient = useQueryClient()

	return useQueries(
		districtIds.map(districtId => ({
			queryKey: ['district', districtId],
			queryFn: () =>
				NetworkCommunicator.GET(`schools/districts/${districtId}`).then(
					({ district }: { district: { name: string, ncesId: string } }) => {
						const formattedDistrict: District = {
							district: district.name,
							ncesId: district.ncesId,
						}
						queryClient.setQueryDefaults(MANUALLY_MANAGED_ALL_DISTRICT_KEY, {
							...DO_NOT_REFETCH_QUERY_OPTIONS,
						})
						queryClient.setQueryData(
							MANUALLY_MANAGED_ALL_DISTRICT_KEY,
							(data: ?AllFetchedDistrictsCache): AllFetchedDistrictsCache => {
								if (!data) {
									return {
										districts: [formattedDistrict],
										fetchedDistrictIds: new Set([district.ncesId]),
									}
								}

								if (data.fetchedDistrictIds.has(district.ncesId)) {
									return data
								}

								const newFetchedSchoolIds = new Set(data.fetchedDistrictIds)
								newFetchedSchoolIds.add(district.ncesId)
								return {
									districts: [...data.districts, formattedDistrict],
									fetchedDistrictIds: newFetchedSchoolIds,
								}
							}
						)

						return formattedDistrict
					}
				),
			retry: 0,
			...DO_NOT_REFETCH_QUERY_OPTIONS,
		}))
	)
}

/**
 * useAllFetchedDistricts - get all the districts which have been fetched by other queries from the server
 *
 * @return {District[]}
 */
export function useAllFetchedDistricts(): District[] {
	const queryClient = useQueryClient()
	return queryClient.getQueryData(MANUALLY_MANAGED_ALL_DISTRICT_KEY)?.districts || []
}

/**
 * useAutomatedSimulations - get the list of all automated simulations. This will not refetch nor refresh.
 *
 * @return {UseQueryResult<District[]>}
 */
export function useAutomatedSimulations(): UseQueryResult<Simulation[]> {
	return useQuery(
		['simulations'],
		() => NetworkCommunicator.GET('simulations/automated').then(res => res.simulations),
		{
			...DO_NOT_REFETCH_QUERY_OPTIONS,
		}
	)
}
