import { Icon, Intent } from '@blueprintjs/core'
import React from 'react'

export default function BooleanDisplay({ success }: { success: boolean }): React$Node {
	return success ? (
		<Icon icon="tick-circle" intent={Intent.SUCCESS} />
	) : (
		<Icon icon="disable" intent={Intent.DANGER} />
	)
}
