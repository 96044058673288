import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { AbsoluteDate, EitherDateType } from '../../clientTypes'
import type { Criteria, UpdateCriteria } from '../../localTypes'

import { assign, get, minimizeDelete } from '../../../../../../utility/dotProxy'
import { DateTimeInputBase } from '../../../../AnalyticsOfAnalytics/customInputs/DateTimeInput'
import { DATE_TYPES } from '../../clientTypes'

import { DEFAULT_RELATIVE_DATE_FROM, RelativeDateEntry } from './relativeDate'
import { Button } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'

/**
 * DateRenderer - a component used to render a form for inputting a date (and time)
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data for ths range
 *
 * @returns React$Node
 */
export function DateRenderer({
	updateCriteria,
	currentCriteria,
	currentPath,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?EitherDateType>,
}): React$Node {
	const dateType = get(currentPath, currentCriteria)

	const setTimeStamp = (newDate: ?EitherDateType) => {
		if (newDate == null) {
			updateCriteria((oldData: Criteria) => minimizeDelete(currentPath, oldData))
			return
		}
		updateCriteria((oldData: Criteria) => assign(currentPath, oldData, newDate))
	}

	if (dateType?.type === 'RELATIVE_DATE') {
		return (
			<FlexRow>
				<RelativeDateEntry
					criteria={currentCriteria}
					updateCriteria={updateCriteria}
					currentPath={
						// $FlowIgnore[prop-missing]
						// $FlowIgnore[incompatible-type-arg] - this is checked above and will not be rendered if it is not a relative date
						currentPath
					}
				/>
				<Button
					icon="calendar"
					title="use absolute date"
					onClick={() =>
						updateCriteria((oldData: Criteria) => minimizeDelete(currentPath, oldData))
					}
				/>
			</FlexRow>
		)
	}
	return (
		<FlexRow>
			<AbsoluteDateForm value={dateType} onChange={setTimeStamp} />
			<Button
				icon="automatic-updates"
				title="use relative date"
				onClick={() => {
					setTimeStamp({
						type: DATE_TYPES.RELATIVE_DATE,
						data: {
							from: DEFAULT_RELATIVE_DATE_FROM,
						},
					})
				}}
			/>
		</FlexRow>
	)
}

/**
 * AbsoluteDateForm - a form for entering an absolute date
 *
 * @param  {?AbsoluteDate} value - the current absolute date data
 * @param  {(newValue: ?AbsoluteDate) => any} onChange - a callback for when a new date is selected
 *
 * @return React$Node
 */
function AbsoluteDateForm({
	value,
	onChange,
}: {
	value: ?AbsoluteDate,
	onChange: (newValue: ?AbsoluteDate) => any,
}) {
	return (
		<StyledDateTimeInputBase
			allowDelete={false}
			value={value?.date}
			onChange={(newTimestamp: ?number) => {
				if (newTimestamp == null) {
					onChange(null)
					return
				}
				onChange({
					type: DATE_TYPES.ABSOLUTE_DATE,
					date: newTimestamp,
				})
			}}
		/>
	)
}

const StyledDateTimeInputBase = styled(DateTimeInputBase)`
	display: flex;
	flex-direction: row;
`

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;

	gap: 4px;
`
