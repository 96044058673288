/**
 * scrollToElement - scroll to the element with the `id` attribute value of `elementId`
 *
 * @param {string} elementId - the id of the element to scroll into view
 */
export function scrollToElement(elementId: string) {
	// we have to wait two updates because we store the state in the url
	requestAnimationFrame(() => {
		requestAnimationFrame(() => {
			document.getElementById(elementId)?.scrollIntoView()
		})
	})
}
