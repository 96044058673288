import React from 'react'
import { Callout } from '@blueprintjs/core'
import { useQuery } from 'react-query'
import { Loader } from '../../../components'
import RoleRequest from './RoleRequest'
import type { RoleRequestUser } from '../../../stores/types'
import 'styled-components/macro'
import NetworkCommunicator from '../../../services/NetworkCommunicator'

/**
 * Gets the list of users with role requests from the database, then creates a UI to approve/deny each role request
 * On any approve/denial we refresh the list of role requests
 */
export default function RoleRequests(): React$Node {
	const { isLoading, error, data } = useQuery('users/role-requests', ctx => {
		return NetworkCommunicator.GET(ctx.queryKey)
	})

	if (isLoading) {
		return <Loader />
	}
	if (error) {
		return <p>Error loading employee page</p>
	}

	const users: RoleRequestUser[] = data?.roleRequests ?? []
	const uniqueRoleRequests = new Map()
	users.forEach(user =>
		user.roles
			.filter(role => role.verified === false && role.reasonForRejection == null)
			.forEach(role => {
				const id: string = role.role.concat('-', user._id)
				const roleRequest = {
					user: user,
					role: role,
				}
				if (!uniqueRoleRequests.has(id)) {
					uniqueRoleRequests.set(id, roleRequest)
				}
			})
	)
	return (
		<div>
			<Callout title="Important Note" intent="warning">
				Approving a School Administrator or District Administrator role here will give the user
				access to all analytics for the school or district that they are in respectively. Verify
				that the user name, email, school and district match information on the school website,
				paying particular attention to the email address.
				<p />
				If the user wants to run missions at their school, and has requested School Administrator,
				you should reject the role request and give them the School Mission Control role through the
				Client Search.
			</Callout>
			<div css="display: flex; flex-wrap: wrap; overflow: auto">
				{Array.from(uniqueRoleRequests).map(([id, request]) => (
					<div key={`role-requests-${id}`}>
						<RoleRequest user={request.user} role={request.role.role} />
					</div>
				))}
			</div>
		</div>
	)
}
