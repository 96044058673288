import React from 'react'

import { assign, get } from '../../../../utility/dotProxy'
import { Checkbox } from '@blueprintjs/core'

import type { ProxyData } from '../../../../utility/dotProxy'
import type { SharedInputFields } from '../../AnalyticsOfAnalytics/customInputs/types'

/**
 * CheckBoxForm - a custom input for handling boolean proxies
 *
 * @param {Object} props - the react props
 * @param {ProxyData<boolean>} props.valueProxy - a proxyData describing the location of the numeric value to show and update
 * @param {T} props.values - the base container of the value
 * @param {string} props.label - the label for the checkbox
 * @param {UpdateCallback<T>} props.onChange - a callback used to update the base container
 *
 * @return {React$Node}
 */
export function CheckBoxForm<T>({
	valueProxy,
	label,
	values,
	onChange,
}: {
	valueProxy: ProxyData<boolean>,
	label: string,
	...SharedInputFields<T>,
}): React$Node {
	return (
		<Checkbox
			checked={get(valueProxy, values) ?? false}
			onChange={e => onChange((oldValues: T) => assign(valueProxy, oldValues, e.target.checked))}
			label={label}
		/>
	)
}
