import React from 'react'
import { PageContainer, Loader } from '../../components'
import { useOkrs } from '../../resources/okrs'
import { CheckpointsUrlDisplay } from './Checkpoints'
import { Card, H3 } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import OkrsList from './Okrs/OkrsList'
import { useCurrentUser } from '../../stores/auth'
import 'styled-components/macro'
import { getOkrOwnerFromFilters, getCurrentQuarter } from '../Goals/Okrs/helpers'

export default function Goals(): React$Node {
	const currentUser = useCurrentUser()
	const queryParams = { employeeId: currentUser?.id }
	const { okrs, isLoading, error } = useOkrs({
		queryParams: queryParams,
		queryOptions: { enabled: !!currentUser },
	})
	if (!currentUser) return null
	const owner = getOkrOwnerFromFilters({}, currentUser)
	return (
		<PageContainer column css="overflow-y: auto;">
			<Card>
				<H3>
					<Link to="checkpoints">Checkpoints</Link> <i>- Goals for this week</i>
				</H3>
				<CheckpointsUrlDisplay url="checkpoints" allowCreateDelete={true} allowUpdate={true} />
			</Card>
			<br />
			<Card>
				<H3>
					<Link to="okrs">OKRs</Link> <i>- Objectives & Key Results for the Quarter</i>
				</H3>
				{isLoading && <Loader />}
				{error && 'ERROR'}
				{!isLoading &&
					!error &&
					(okrs ? (
						<OkrsList
							queryOverrides={queryParams}
							okrs={okrs}
							owner={owner}
							quarter={getCurrentQuarter()}
							allowOkrCreation={true}
							currentUser={currentUser}
						/>
					) : (
						'No Okrs '
					))}
			</Card>
		</PageContainer>
	)
}
