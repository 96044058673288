import { useState, useMemo, useEffect, useCallback, useRef } from 'react'

import NetworkCommunicator from '../services/NetworkCommunicator'
import { useLocation, useHistory } from 'react-router-dom'

/**
 * Returns a query from react router dom where one can access all the current queries on the current url route.
 * Call inside a react functional component.
 */
export function useRouteQuery(): URLSearchParams {
	return new URLSearchParams(useLocation().search)
}

/**
 * Returns a function that when called with the right payload updates search parameters on the current url path.
 * There are two ways the url path could be updated:
 * (1) a search parameter can be removed form the url.
 * (2) a search parameter can be added/changed on a url.
 * Usage:
 * const updateParameter = useSearchParameterUpdate()
 * Suppose the current url path is `/clients&tab=licenses&edit=1234`
 *
 * // Remove the parameter `edit=1234` from the current url
 * updateParameter({type: 'DELETE', paramKey: 'edit'})
 *
 * // Add a parameter `view-users=true` to the current url.
 * updateParameter({type:'SET', paramKey: 'view-users', paramValue: 'true'})
 */
export function useSearchParameterUpdate(): (
	| {
			type: 'SET',
			paramKey: string,
			paramValue: string,
	  }
	| {
			type: 'DELETE',
			paramKey: string,
	  }
) => void {
	const history = useHistory()
	const location = useLocation()
	return useCallback(
		(
			payload:
				| { type: 'SET', paramKey: string, paramValue: string }
				| { type: 'DELETE', paramKey: string }
		) => {
			const params = new URLSearchParams(location.search)
			if (payload.type === 'DELETE') {
				params.delete(payload.paramKey)
			} else if (payload.type === 'SET') {
				params.set(payload.paramKey, payload.paramValue)
			}
			history.replace({ pathname: location.pathname, search: params.toString() })
		},
		[history, location]
	)
}

/**
 * A hook that will fetch the given route, returning data about the call such as `fetching`, `error`, and `data`.
 */
export function useFetch(
	route: string,
	{ shouldFetch = true }: { shouldFetch?: boolean } = {}
): { fetching: boolean, error?: ?Error, data: ?any } {
	const [fetching, setFetching] = useState(false)
	const [error, setError] = useState(null)
	const [data, setData] = useState()

	useEffect(() => {
		if (!shouldFetch) {
			return
		}
		let subscribed = true
		setFetching(true)
		setError(null)
		NetworkCommunicator.GET(route)
			.then(response => {
				if (subscribed) {
					setData(response)
				}
			})
			.catch(err => {
				if (subscribed) {
					setError(err)
				}
				console.log(err)
			})
			.finally(() => {
				if (subscribed) {
					setFetching(false)
				}
			})
		return () => {
			subscribed = false
		}
	}, [route, shouldFetch])

	return { fetching, error, data }
}

type UseRestorableStateReturnType<T> = [T, ((T => T) | T) => void, () => void, (T) => void, boolean]

/**
 * A hook that allows restoring state to a stored backup value. The caller passes in
 * the initial value, which will be used as the state value and the original backup value.
 @return {Array} An array of 4 values which are
	{state} The piece of state that gets updated
	{setState} The setState function, like from `useState`
	{restore} A restore function. Call this to revert state to the backup value
	{updateFallback} Update the stored backup value with a given value
	{inTrueState} Value representing whether the true value and visible value are equal
 */
export function useRestorableState<T>(initialValue: T): UseRestorableStateReturnType<T> {
	// The current value, update as the state updater is called
	const [visibleValue, setVisibleValue] = useState(initialValue)
	// The true value. This value is updated when calling commit
	const [trueValue, setTrueValue] = useState(initialValue)

	const restore = useCallback(() => {
		setVisibleValue(trueValue)
	}, [trueValue])

	const updateFallback = useCallback((value: T) => {
		setTrueValue(value)
	}, [])

	return [visibleValue, setVisibleValue, restore, updateFallback, trueValue === visibleValue]
}

// function to help check previous state
export function usePrevious<T>(value: ?T): ?T {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	}, [value])
	return ref.current
}

const LIMITED_OPTION_ID = 'KEEP_SEARCHING!'

export type Option = {|
	value: string,
	label: string,
|}

/**
 * COPIED FROM LOGIN-CLIENT
 * 
 * A helper function for react-select to limit the number of the options displayed in the dropdown. Given the full list of
 * options, this hook will provide filtering logic for react-select, and also limit the number of options to `maxDisplayedOptions`.
 * If not all filtered options are shown, an extra option in the form of `LimitedOption` is added to the resulting options list. This
 * `LimitedOption` needs to be manually handled when rendering options to display the helper text.
 * The code is modified from this comment on a GitHub issue https://github.com/JedWatson/react-select/issues/3128#issuecomment-812619125
 * @param {Object} obj Options for the function
 * @param {Array<Option>} obj.options The original list of react-select options
 * @param {number} obj.maxDisplayedOptions The maximum number of options that should be displayed
 * @param {Function} obj.getOptionSearchText A function that will get the searchable text for a given option from the list
 * @param {(newSearchString: string) => void} obj.onSearchStringChange - callback for when the search string changes
 * @param {boolean} obj.needsMoreText - true if user needs to input for text for options to display, false/undefined otherwise
 * @return {Object} result
 * @return {Array<Option | LimitedOption>} result.options   The list of filtered and limited options, potentially with a LimitedOption at the end.
 *                             Its size will not be greater than `maxDisplayedOptions` + 1.
 * @return {Object} result.reactSelectProps            Extra props that need to be added to the reactSelect that should be limited
	options: Array<T | LimitedOption>,
	reactSelectProps: {|
		onInputChange: () => mixed,
		isOptionDisabled: (T | LimitedOption) => boolean,
		filterOption: () => mixed, // Disable react-select filter
	|},
 */
export function useLimitedOptions({
	options,
	maxDisplayedOptions,
	getOptionSearchText,
	onSearchStringChange,
	needsMoreText,
}: {
	options: Array<Option>,
	maxDisplayedOptions: number,
	getOptionSearchText: Option => string,
	onSearchStringChange?: (newSearchString: string) => void,
	needsMoreText?: boolean,
}): {
	options: $ReadOnlyArray<Option>,
	reactSelectProps: {|
		onInputChange: (newSearchString: string) => mixed,
		isOptionDisabled: Option => boolean,
		filterOption: () => mixed, // Disable react-select filter
	|},
} {
	const [inputValue, setInputValue] = useState('')

	const filteredOptions: $ReadOnlyArray<Option> = useMemo(() => {
		if (!inputValue) {
			return options
		}

		const matchByStart = []
		const matchByInclusion = []

		const regByInclusion = new RegExp(escapeRegExpString(inputValue), 'i')
		const regByStart = new RegExp(`^${escapeRegExpString(inputValue)}`, 'i')

		for (const option of options) {
			const searchText = getOptionSearchText(option)
			if (regByInclusion.test(searchText)) {
				if (regByStart.test(searchText)) {
					matchByStart.push(option)
				} else {
					matchByInclusion.push(option)
				}
			}
		}

		return [...matchByStart, ...matchByInclusion]
	}, [inputValue, options, getOptionSearchText])

	const slicedOptions: $ReadOnlyArray<Option> = useMemo(() => {
		if (needsMoreText) {
			return [{ value: LIMITED_OPTION_ID, label: 'Continue typing for more options' }]
		}
		let options = filteredOptions.slice(0, maxDisplayedOptions)

		if (filteredOptions.length > maxDisplayedOptions) {
			options.push({
				value: LIMITED_OPTION_ID,
				label: 'Type into the search for more options',
			})
		}
		return options
	}, [filteredOptions, maxDisplayedOptions, needsMoreText])

	return {
		options: slicedOptions,
		reactSelectProps: {
			onInputChange: value => {
				setInputValue(value)
				if (onSearchStringChange) {
					onSearchStringChange(value)
				}
			},
			isOptionDisabled: option => !!option.value && option.value === LIMITED_OPTION_ID,
			filterOption: () => true, // Disable react-select filter
		},
	}
}

/**
 * Escapes a string so that it can be used literally in a regular expression.
 * Copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 * @param {string} string The string to escape
 * @return {string} The escaped string
 */
function escapeRegExpString(string: string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
