// @flow
import React, { useState, useEffect } from 'react'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import 'styled-components/macro'
import styled from 'styled-components'

import { Loader, FlexWrapper, AppToaster } from '../../../components'
import EditSchool from '../FindClients/EditSchool'
import ReactSelect, { createFilter } from 'react-select'

import { FormGroup, Card, Elevation, Intent, Callout, Divider, Button } from '@blueprintjs/core'
import { createGoogleQuery } from '../helpers/createGoogleQuery'
import { createPrompt } from '../helpers/createPrompt'

import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { fetchSchools } from '../Licenses/networkCalls'

export type CustomSchoolData = {|
	_id: string,
	name: string,
	ncesId: string,
	phone: string,
	district: string,
	street: string,
	city: string,
	state: string,
	zip: string,
|}

export type MatchedSchoolData = {
	schoolId: string,
	schoolName: string,
}

/**
 * This component fetch all Not Verified schools and allows to user set Verified to true
 */
export default function VerifyingSchool(): React$Node {
	const queryClient = useQueryClient()

	const [schoolForVerifying, setSchoolForVerifying] = useState<CustomSchoolData | null>(null)
	const [matchedPublicSchools, setMatchedPublicSchools] = useState<MatchedSchoolData[]>([])
	const [isEdit, setIsEdit] = useState<boolean>(false)

	const { isLoading, error, data } = useQuery('notYetVerified', () =>
		NetworkCommunicator.GET(`schools/not-yet-verified`)
	)
	const notVerifiedSchools = data?.notVerified

	const handleChangeSchool = (value: CustomSchoolData) => {
		const objectOrderTemplate = {
			name: '',
			state: '',
			district: '',
			ncesId: '',
			city: '',
			street: '',
			zip: '',
			phone: '',
			_id: '',
		}
		const orderedValue: CustomSchoolData = Object.assign(objectOrderTemplate, value)
		setSchoolForVerifying(orderedValue)
	}

	const handleVerifyingSchool = useMutation(
		() =>
			NetworkCommunicator.PUT(`schools/${schoolForVerifying?._id || ''}`, {
				body: {
					editableSchool: {
						verified: true,
						schoolType: 'custom',
					},
				},
			}),
		{
			onSuccess: () => {
				setSchoolForVerifying(null)
				queryClient.invalidateQueries('notYetVerified')
				AppToaster.warning({
					message: 'The school was successfully verified!',
				})
			},
			onError: () => {
				AppToaster.danger({
					message: 'Something went wrong, please try again!',
				})
			},
		}
	)

	useEffect(() => {
		if (!schoolForVerifying) {
			return
		}
		const { name, state, ncesId } = schoolForVerifying
		fetchSchools(
			name.substring(0, 3),
			{ abbreviation: state },
			schools => {
				setMatchedPublicSchools(schools.filter(item => item.schoolId !== schoolForVerifying._id))
			},
			ncesId
		)
	}, [schoolForVerifying])

	useEffect(() => {
		if (!isEdit) {
			setSchoolForVerifying(null)
			queryClient.invalidateQueries('notYetVerified')
		}
	}, [isEdit, queryClient])

	useEffect(() => {
		if (error) {
			AppToaster.danger({
				message: 'Something went wrong, please try again!',
			})
		}
	}, [error])

	if (isEdit && schoolForVerifying) {
		return (
			<Card css="max-width: 500px" elevation={Elevation.TWO} role="container">
				<EditSchool
					schoolData={{ ...schoolForVerifying, schoolType: 'custom' }}
					setShowEdit={setIsEdit}
					onEdit={editedSchool => {
						queryClient.invalidateQueries('notYetVerified')
						setSchoolForVerifying(editedSchool)
					}}
				/>
			</Card>
		)
	}

	return (
		<>
			<CalloutContainer title="Important Note" intent="warning">
				This is a list of all schools that have been input by users but have not been verified by an
				Mission.io employee. Please verify the data for each school on the school’s website before
				verifying the school.
			</CalloutContainer>
			<Card css="max-width: 500px" elevation={Elevation.TWO} role="container">
				{handleVerifyingSchool.isLoading || isLoading ? (
					<Loader />
				) : (
					<>
						<FormGroup label="Schools" labelFor="schools">
							<ReactSelect
								inputId="schools"
								name="schools"
								value={schoolForVerifying}
								onChange={value => {
									setMatchedPublicSchools([])
									handleChangeSchool(value)
								}}
								getOptionLabel={school => school.name}
								getOptionValue={school => school}
								options={notVerifiedSchools}
								filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
								isClearable
								isSearchable
							/>
						</FormGroup>
						{schoolForVerifying ? (
							<>
								<Divider />
								{schoolForVerifying.name && (
									<Link>
										<a
											href={createGoogleQuery(schoolForVerifying.name)}
											target="_blank"
											rel="noopener noreferrer">
											Google “{schoolForVerifying.name}”
										</a>
									</Link>
								)}
								<SchoolDataContainer spaceBetween row>
									{Object.keys(schoolForVerifying).map((key, index) => {
										if (['_id', '__v', 'verified'].includes(key)) {
											return null
										}
										return (
											<>
												<SchoolDataItem key={key} spaceBetween>
													<div>{createPrompt(key)}: </div>
													<div css="font-size: 16px">{schoolForVerifying[key]}</div>
												</SchoolDataItem>
											</>
										)
									})}
								</SchoolDataContainer>
								<Divider />
							</>
						) : null}

						{schoolForVerifying?.name && matchedPublicSchools.length ? (
							<>
								<DataPoint column>
									<p>
										Before verifying, confirm that the school is not a duplicate of one of these
										preexisting schools with similar names:
									</p>
									<MatchedContainer>
										{matchedPublicSchools.map((item, index) => {
											return (
												<MatchedItem key={item?.schoolId + item?.schoolName}>
													{item?.schoolName}
												</MatchedItem>
											)
										})}
									</MatchedContainer>
								</DataPoint>
							</>
						) : null}
					</>
				)}
				<DataPoint spaceBetween>
					<span></span>
					<div>
						<EditBtn
							disabled={!schoolForVerifying?.name}
							intent={Intent.WARNING}
							onClick={() => setIsEdit(true)}>
							Edit
						</EditBtn>

						<Button
							intent={Intent.PRIMARY}
							disabled={!schoolForVerifying?.name}
							onClick={() => {
								handleVerifyingSchool.mutate()
							}}>
							Verify {schoolForVerifying?.name || 'School'}
						</Button>
					</div>
				</DataPoint>
			</Card>
		</>
	)
}

const Link = styled.div`
	margin: 5px 0 0;
	text-decoration: underline;
`

const DataPoint = styled(FlexWrapper)`
	padding-top: 8px;
`

const EditBtn = styled(Button)`
	margin: 0 15px;
`

const SchoolDataItem = styled(FlexWrapper)`
	flex-direction: row;
	padding: 5px 0;
`

const SchoolDataContainer = styled(FlexWrapper)`
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
`

const CalloutContainer = styled(Callout)`
	margin: 10px 0 20px;
`

const MatchedContainer = styled.div`
	position: relative;
	display: flex;
	justified-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
`

const MatchedItem = styled.div`
	position: relative;
	font-size: 14px;
	padding: 4px 8px;
	margin: 4px 6px 4px 0;
	border-radius: 3px;
	background: rgba(206, 217, 224, 0.5);
	color: rgba(92, 112, 128, 0.6);

    line-height: 1.3em; /* Sets line height to 1.5 times text size */
    max-height: 3em; /* Sets the div height to 2x line-height (3 times text size) */
    white-space: normal; /* Wrap lines of text */
    overflow: hidden; /* Hide text that goes beyond the boundaries of the div */
    text-overflow: ellipsis;
 }
`
