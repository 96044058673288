import type { AlgorithmDefinition } from './sharedTypes'

export type CardState = {
	isAfterCulminatingMoment: boolean,
	isInvestigation: boolean,
	isNavigation: boolean,
	animation: number,
	durationOnScreen: number,
	isReusable: boolean,
	newAssets: number[],
	isLoopingAfterIntro: boolean,
}

export const CARD_ALGORITHM_DEFINITION: AlgorithmDefinition<CardState> = {
	name: 'Card Calculator-inator',
	fields: {
		isAfterCulminatingMoment: {
			weight: 3,
			label: 'After Culminating Moment',
			type: 'boolean',
			default: false,
		},
		isInvestigation: {
			weight: 4,
			label: 'Investigation',
			type: 'boolean',
			default: false,
		},
		isNavigation: {
			weight: 2,
			label: 'Navigation',
			type: 'boolean',
			default: false,
		},
		isReusable: {
			weight: 5,
			label: 'Reusable',
			type: 'boolean',
			default: false,
		},
		isLoopingAfterIntro: {
			type: 'boolean',
			label: 'Looping After Intro',
			weight: -2,
			default: false,
		},
		animation: {
			weight: 1,
			max: 5,
			label: 'Animation',
			type: 'number',
			default: 0,
		},
		durationOnScreen: {
			weight: (value: number): number => {
				if (value <= 10) {
					return 1
				} else if (value <= 30) {
					return 2
				} else if (value <= 120) {
					return 3
				} else if (value <= 180) {
					return 4
				} else {
					return 5
				}
			},
			label: 'Duration on Screen (sec)',
			type: 'number',
			default: 5,
		},
		newAssets: {
			weight: 1,
			label: 'New Assets',
			type: 'number_array',
			default: [],
			defaultValue: 2,
		},
	},
}
