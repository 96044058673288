// @flow
import React from 'react'
import { getProxyData } from '../../../../utility/dotProxy'
import type {
	Criteria,
	ForceDisplayFields,
	UpdateCriteria,
	UpdateForceDisplayFields,
} from './localTypes'
import { useCriteriaForm } from './hooks'
import ComposedCriteriaForm from './fieldComponents/CriteriaForm'
import { Loader } from '../../../../components/basic'
import type { ComposableFieldType } from './clientTypes'
import { CriteriaBox } from './sharedComponents'
import styled from 'styled-components'

/**
 * CriteriaForm - render a criteria form for the given resource
 *
 * @param  {Object} props - the react props
 * @param  {Criteria} props.currentCriteria - the current criteria
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {ForceDisplayFields} props.forceDisplayFields - the fields which are forced to render even though they may not exist in the currentCriteria
 * @param  {UpdateForceDisplayFields} props.updateForceDisplayFields - a callback to update the fields to force being displayed
 * @param  {String} props.resourceName - the resource to render fields for
 * @param  {ComposableFieldType} props.fieldType - the current field being rendered
 *
 * @returns React$Node
 */
export function CriteriaForm({
	currentCriteria,
	updateCriteria,
	forceDisplayFields,
	updateForceDisplayFields,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	forceDisplayFields: ForceDisplayFields,
	updateForceDisplayFields: UpdateForceDisplayFields,
	fieldType: ComposableFieldType,
}): React$Node {
	const { data: resourceMap, isLoading, error } = useCriteriaForm()
	if (isLoading) {
		return <Loader />
	}
	if (error) {
		return <div>An error occurred while fetching the form to render</div>
	}
	if (!resourceMap) {
		return <div>No form was given to render</div>
	}
	const resourceName = fieldType.resource
	const resource = resourceMap[resourceName]
	if (!resource) {
		return <div>Unknown resource {resourceName}</div>
	}
	return (
		<NoBoxCriteriaBox doBox={true}>
			<ComposedCriteriaForm
				updateCriteria={updateCriteria}
				currentCriteria={currentCriteria}
				forceDisplayFields={forceDisplayFields}
				updateForceDisplayFields={updateForceDisplayFields}
				resource={resource}
				resourceMap={resourceMap}
				displayPath={[]}
				showFieldSelector={true}
				currentPath={getProxyData((criteria: Criteria) => criteria)}
				fieldType={fieldType}
			/>
		</NoBoxCriteriaBox>
	)
}

const NoBoxCriteriaBox = styled(CriteriaBox)`
	background-color: #0000;
`
