import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import 'styled-components/macro'

import { Card, Tag } from '@blueprintjs/core'
import OkrProgress from './OkrProgress'

import Owners from './Owners'
import type { SimpleOkr as Okr } from '../../../stores/types'

const StyledCard = styled(Card)`
	display: grid;
	grid-template-columns: 1fr 1.5fr 0.5fr;
	align-items: center;
	${({ theme, temp }) => temp && `&& {background-color: ${theme.secondary};}`}
`
const SliderContainer = styled.div`
	width: 40vh;
`

export default function SimpleOkr({
	okr,
	className,
	temp,
	canUpdate,
	noLink,
}: {
	okr: Okr,
	className?: string,
	temp?: boolean,
	canUpdate?: boolean,
	noLink?: boolean,
}): React$Node {
	return (
		<StyledCard className={className} temp={temp ? temp.toString() : ''}>
			<SimpleTitle okrId={okr.id} title={okr.title || <i>No Name</i>} showLink={!noLink} />
			<SliderContainer>
				<OkrProgress value={okr.progress} okrId={okr.id} canUpdate={canUpdate ?? false} />
			</SliderContainer>
			<Owners owners={okr.owners || []} okrId={okr.id} />
			<div css="width: 100px;">{temp && <Tag>Creating...</Tag>}</div>
		</StyledCard>
	)
}

const SimpleTitle = ({
	okrId,
	title,
	showLink,
}: {
	okrId: number,
	title: string,
	showLink: boolean,
}) => {
	if (showLink) {
		return <Link to={'/okrs/' + okrId}>{title}</Link>
	} else return title
}
