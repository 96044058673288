import React from 'react'
import { Switch } from '@blueprintjs/core'
import styled from 'styled-components'

/**
 * IncludeExcludingSwitch - a react component toggle used for the select if they are including or excluding entries
 *
 * @param  {Object} props - the react props
 * @param  {boolean} props.excluding - true if the user is excluding entires, false otherwise
 * @param  {(excluding: boolean) => any} props.onChange - a callback for when the user changes whether the entries should be included or excluded
 *
 * @returns React$Node
 */
export function IncludeExcludingSwitch({
	excluding,
	onChange,
}: {
	excluding?: ?boolean,
	onChange: (excluding?: boolean) => any,
}): React$Node {
	return (
		<StyledSwitch
			checked={!excluding}
			onChange={e => onChange(!e.target.checked)}
			innerLabelChecked="including"
			innerLabel="excluding"
		/>
	)
}

const StyledSwitch = styled(Switch)`
	margin-bottom: 0 !important;
`
