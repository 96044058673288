export const DEFAULT_STATE = {
	name: 'Utah',
	abbreviation: 'UT',
	stateCode: '49',
}

export default [
	{
		name: 'Alabama',
		abbreviation: 'AL',
		stateCode: '01',
	},
	{
		name: 'Alaska',
		abbreviation: 'AK',
		stateCode: '02',
	},
	// {
	// 	name: 'American Samoa',
	// 	abbreviation: 'AS',
	// },
	{
		name: 'Arizona',
		abbreviation: 'AZ',
		stateCode: '04',
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR',
		stateCode: '05',
	},
	{
		name: 'California',
		abbreviation: 'CA',
		stateCode: '06',
	},
	{
		name: 'Colorado',
		abbreviation: 'CO',
		stateCode: '08',
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT',
		stateCode: '09',
	},
	{
		name: 'Delaware',
		abbreviation: 'DE',
		stateCode: '10',
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC',
		stateCode: '11',
	},
	// {
	// 	name: 'Federated States Of Micronesia',
	// 	abbreviation: 'FM',
	// },
	{
		name: 'Florida',
		abbreviation: 'FL',
		stateCode: '12',
	},
	{
		name: 'Georgia',
		abbreviation: 'GA',
		stateCode: '13',
	},
	// {
	// 	name: 'Guam',
	// 	abbreviation: 'GU',
	// },
	{
		name: 'Hawaii',
		abbreviation: 'HI',
		stateCode: '15',
	},
	{
		name: 'Idaho',
		abbreviation: 'ID',
		stateCode: '16',
	},
	{
		name: 'Illinois',
		abbreviation: 'IL',
		stateCode: '17',
	},
	{
		name: 'Indiana',
		abbreviation: 'IN',
		stateCode: '18',
	},
	{
		name: 'Iowa',
		abbreviation: 'IA',
		stateCode: '19',
	},
	{
		name: 'Kansas',
		abbreviation: 'KS',
		stateCode: '20',
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY',
		stateCode: '21',
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA',
		stateCode: '22',
	},
	{
		name: 'Maine',
		abbreviation: 'ME',
		stateCode: '23',
	},
	// {
	// 	name: 'Marshall Islands',
	// 	abbreviation: 'MH',
	// },
	{
		name: 'Maryland',
		abbreviation: 'MD',
		stateCode: '24',
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA',
		stateCode: '25',
	},
	{
		name: 'Michigan',
		abbreviation: 'MI',
		stateCode: '26',
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN',
		stateCode: '27',
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS',
		stateCode: '28',
	},
	{
		name: 'Missouri',
		abbreviation: 'MO',
		stateCode: '29',
	},
	{
		name: 'Montana',
		abbreviation: 'MT',
		stateCode: '30',
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE',
		stateCode: '31',
	},
	{
		name: 'Nevada',
		abbreviation: 'NV',
		stateCode: '32',
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH',
		stateCode: '33',
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ',
		stateCode: '34',
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM',
		stateCode: '35',
	},
	{
		name: 'New York',
		abbreviation: 'NY',
		stateCode: '36',
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC',
		stateCode: '37',
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND',
		stateCode: '38',
	},
	// {
	// 	name: 'Northern Mariana Islands',
	// 	abbreviation: 'MP',
	// },
	{
		name: 'Ohio',
		abbreviation: 'OH',
		stateCode: '39',
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK',
		stateCode: '40',
	},
	{
		name: 'Oregon',
		abbreviation: 'OR',
		stateCode: '41',
	},
	// {
	// 	name: 'Palau',
	// 	abbreviation: 'PW',
	// },
	{
		name: 'Pennsylvania',
		abbreviation: 'PA',
		stateCode: '42',
	},
	// {
	// 	name: 'Puerto Rico',
	// 	abbreviation: 'PR',
	// },
	{
		name: 'Rhode Island',
		abbreviation: 'RI',
		stateCode: '44',
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC',
		stateCode: '45',
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD',
		stateCode: '46',
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN',
		stateCode: '47',
	},
	{
		name: 'Texas',
		abbreviation: 'TX',
		stateCode: '48',
	},
	{
		name: 'Utah',
		abbreviation: 'UT',
		stateCode: '49',
	},
	{
		name: 'Vermont',
		abbreviation: 'VT',
		stateCode: '50',
	},
	// {
	// 	name: 'Virgin Islands',
	// 	abbreviation: 'VI',
	// },
	{
		name: 'Virginia',
		abbreviation: 'VA',
		stateCode: '51',
	},
	{
		name: 'Washington',
		abbreviation: 'WA',
		stateCode: '53',
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV',
		stateCode: '54',
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI',
		stateCode: '55',
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY',
		stateCode: '56',
	},
	{
		name: 'International',
		abbreviation: 'INT',
		stateCode: '-01',
	},
]
