const runningOnMissionIO = window.location.hostname.endsWith('mission.io')
type Config = {|
	isProd: boolean,
	petraServerUrl: string,
	runningOnMissionIO: boolean,
|}
const domain = runningOnMissionIO ? '.mission.io' : '.infinid.us'

let config: Config

if (process.env.NODE_ENV === 'production') {
	const isStaging = process.env.REACT_APP_ENV === 'staging'
	config = {
		isProd: true,
		petraServerUrl: isStaging
			? `https://staging-petra-server${domain}`
			: `https://petra-server${domain}`,
		runningOnMissionIO,
	}
} else {
	config = {
		isProd: false,
		petraServerUrl: 'http://localhost:8200',
		runningOnMissionIO,
	}
}

export default config
