import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { Range, EitherDateType, FieldType } from '../../clientTypes'
import type { Criteria, UpdateCriteria } from '../../localTypes'

import { RangeEntry } from '.'

import { DateRenderer } from '../dates'

import React from 'react'

/**
 * DateRangeEntry - adds a range entry for entering dates
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {FieldType} props.fieldType - the field type which caused this component to be rendered
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data for ths range
 *
 * @returns React$Node
 */
export function DateRangeEntry({
	updateCriteria,
	currentCriteria,
	currentPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?Range<?EitherDateType>>,
	fieldType: FieldType,
}): React$Node {
	return (
		<RangeEntry
			updateCriteria={updateCriteria}
			currentCriteria={currentCriteria}
			currentPath={currentPath}
			formRenderer={DateRenderer}
			fieldType={fieldType}
		/>
	)
}
