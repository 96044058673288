import React from 'react'
import { Button, Classes } from '@blueprintjs/core'
import type { SharedInputFields } from './types'
import { get, assign, minimizeDelete, type ProxyData } from '../../../../utility/dotProxy'

/**
 * DateTimeInputBase  - the base input for submitting a date and time
 *
 * @param {Object} props - the react props
 * @param {?number} value - the currently selected date
 * @param {(newValue: ?number) => void} onChange - a callback when the selected date changes
 * @param {Date} minDate - the minimum selectable date (defaults to January 1, 2018)
 *
 * @return {React$Node}
 */
export function DateTimeInputBase({
	value,
	onChange,
	className,
	allowDelete = true,
	minDate = new Date(2018, 0, 1),
}: {
	value: ?number,
	onChange: (newValue: ?number) => any,
	className?: ?string,
	allowDelete?: boolean,
	minDate?: Date,
}): React$Node {
	const date = value != null ? new Date(value) : null

	return (
		<div className={className}>
			<input
				type="date"
				className={Classes.INPUT}
				value={getDateTime(date) ?? null}
				onChange={e => {
					let newDate = new Date()
					const segments = e.target.value.split('-')
					newDate.setFullYear(Number(segments[0]), Number(segments[1]) - 1, Number(segments[2]))
					if (date) {
						newDate.setHours(date.getHours(), date.getMinutes(), 0, 0)
					} else {
						newDate.setHours(6, 0, 0, 0)
					}
					onChange(newDate.getTime())
				}}
				min={getDateTime(minDate)}
			/>
			<input
				type="time"
				className={Classes.INPUT}
				value={date ? `${padZero(date.getHours(), 2)}:${padZero(date.getMinutes(), 2)}` : ''}
				onChange={e => {
					let newDate = new Date(date ?? 0)
					if (date) {
						newDate.setFullYear(date.getFullYear())
						newDate.setMonth(date.getMonth())
						newDate.setDate(date.getDate())
					}

					const [hours, minutes] = e.target.value.split(':')
					newDate.setHours(Number(hours))
					newDate.setMinutes(Number(minutes))

					onChange(newDate.getTime())
				}}
			/>
			{allowDelete ? (
				<Button icon="cross" onClick={() => onChange(null)} disabled={value == null} />
			) : null}
		</div>
	)
}

/**
 * DateTimeInput - a custom input for date a time
 *
 * @param {Object} props - the react props
 * @param {ProxyData<?string>} props.valueProxy - a proxyData describing the location of the timeStamp to show and update
 * @param {T} props.values - the base container of the value
 * @param {UpdateCallback<T>} props.onChange - a callback used to update the base container
 *
 * @return {React$Node}
 */
export default function DateTimeInput<T>({
	valueProxy,
	values,
	onChange,
}: {
	valueProxy: ProxyData<?number>,
	...SharedInputFields<T>,
}): React$Node {
	const timeStamp = get(valueProxy, values)

	const setTimeStamp = (timeStamp: ?number) => {
		if (timeStamp == null) {
			onChange((oldData: T) => minimizeDelete(valueProxy, oldData))
			return
		}
		onChange((oldData: T) => assign(valueProxy, oldData, timeStamp))
	}

	return <DateTimeInputBase value={timeStamp} onChange={setTimeStamp} />
}

/**
 * getDateTime - get the input value for the "date" input type from a date object
 *
 * @param {?Date} date - the date to convert to the "date" input type value string
 *
 * @return {?string} - a string to use as the "value" for a "date" input type
 */
function getDateTime(date: ?Date): ?string {
	if (!date) {
		return ''
	}

	return `${padZero(date.getFullYear(), 4)}-${padZero(date.getMonth() + 1, 2)}-${padZero(
		date.getDate(),
		2
	)}`
}

/**
 * padZero - convert the number to a string and add `0`s to the left until the targetLength is reached
 *
 * @param {number} num - the number to convert and pad
 * @param {number} targetLength - the length to make the string
 *
 * @return {string}
 */
function padZero(num: number, targetLength: number): string {
	let res = String(num)
	while (res.length < targetLength) {
		res = '0' + res
	}
	return res
}
