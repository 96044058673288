import { prettifyCamelCase, prettifyTypeEnum } from '../../../utility/functions'
import type { TableColumns } from '../../../components/basic/AdvancedTable'
import { Tooltip, Tag, HTMLSelect, InputGroup, Button } from '@blueprintjs/core'
import moment from 'moment'
import React from 'react'
import 'styled-components/macro'
import type { ReportField } from '../alerts/types'

const UNAVAILABLE_ENTRY_IDENTIFIER = 'NA'

const getArrayLength = (arr: mixed): string =>
	Array.isArray(arr) ? String(arr.length) : UNAVAILABLE_ENTRY_IDENTIFIER
const getDateMoment = (value: mixed): string => {
	if (typeof value === 'string' && value === UNAVAILABLE_ENTRY_IDENTIFIER) {
		return UNAVAILABLE_ENTRY_IDENTIFIER
	}
	if (value instanceof Date || typeof value === 'number' || typeof value === 'string') {
		return moment(value).format('l')
	}
	return UNAVAILABLE_ENTRY_IDENTIFIER
}
const joinArrayOfStrings = (arr: mixed, deliminator?: string = ','): string =>
	Array.isArray(arr) ? arr.join(deliminator) : UNAVAILABLE_ENTRY_IDENTIFIER
const getBoolean = (value: mixed): string => {
	if (value === true) {
		return 'Yes'
	}
	if (value === false) {
		return 'No'
	}
	return UNAVAILABLE_ENTRY_IDENTIFIER
}

/**
 * Gets a form field component for a filter in a report query.
 * Any filter listed in the AVAILABLE_RESOURCES `allowedFilters` lists below can be supported in this function.
 * By default the filter returned from this function will be a simple text input field.
 */
export const FilterSelector = ({
	filterType,
	value,
	onChange,
}: {
	filterType: string,
	value: mixed,
	onChange: mixed => void,
}): React$Node => {
	switch (filterType) {
		case 'licenseType': {
			const options = ['FREE', 'PAID'].map(key => ({
				value: key,
				label: prettifyTypeEnum(key),
			}))
			return (
				<HTMLSelect value={value || ''} onChange={e => onChange(e.target.value)} minimal>
					<option value={''} selected>
						Select a License...
					</option>
					{options.map(({ label, value }) => (
						<option key={label} value={value}>
							{label}
						</option>
					))}
				</HTMLSelect>
			)
		}
		default: {
			return (
				<InputGroup
					clearable
					value={value || ''}
					onChange={e => onChange(e.target.value)}
					placeholder={prettifyCamelCase(filterType)}
					rightElement={
						<Button
							icon="cross"
							minimal
							onClick={() => {
								onChange(undefined)
							}}
						/>
					}
				/>
			)
		}
	}
}

type Field =
	| string
	| {| name: string, toCSV?: mixed => string, disableSort?: true, displayName?: string |}
export const AVAILABLE_RESOURCES: {
	[string]: { allowedFilters: string[], fields: Field[], criteriaResource: string },
} = {
	simulation: {
		criteriaResource: 'SIMULATION',
		allowedFilters: ['missionDateRange'],
		fields: [
			'name',
			'duration',
			{ name: 'public', toCSV: getBoolean },
			{ name: 'grades', toCSV: joinArrayOfStrings },
			'controlSet',
			'subjectMatter',
			{
				name: 'standards',
				toCSV: (item: any): string =>
					Array.isArray(item) ? item.map(data => data.type).join(',') : 'NA',
			},
			'timesRun',
			{ name: 'feedback', toCSV: getArrayLength },
			'avgTeacherRating',
			'avgStudentRating',
			'avgMissionScore',
			'avgStudentScore',
			'highScore',

			// SAVE FOR LATER
			/* 'avgQuestionScore',
			'avgApplicationScore',
			'avgCollaborationScore',
			'avgCTScore',
			'avgGritScore',
			'avgInitiativeScore', 
			*/
		],
	},
	user: {
		criteriaResource: 'USER',
		allowedFilters: ['missionDateRange', 'licenseType', 'state', 'schoolName'],
		fields: [
			'firstName',
			'lastName',
			'email',
			'schoolName',
			'districtName',
			'state',
			'licenseType',
			{ name: 'createdAt', toCSV: getDateMoment },
			{ name: 'trainingComplete', toCSV: getBoolean },
			{ name: 'lastLogin', toCSV: getDateMoment },
			'loginCount',
			'classesCreated',
			'missionsCreated',
			'flightsDirected',
			{ name: 'feedback', toCSV: getArrayLength },
			{ name: 'lastMissionDate', toCSV: getDateMoment },
			{ name: 'emailVerified', toCSV: getBoolean },
			'avgTeacherRating',
			'avgStudentRating',
			'avgMissionScore',
			'avgStudentScore',
			'districtNcesId',
			'schoolId',
			'role',
			'licenseOrganizationType',
		],
	},
	school: {
		criteriaResource: 'SCHOOL',
		allowedFilters: [], // TODO: Consider adding 'missionDateRange' as a filter once we support fields related to missions for this report
		fields: [
			/* ITERATION 1
			--------------*/
			'name',
			'district',
			'totalUsers',
			{ name: 'licenseType', toCSV: joinArrayOfStrings },
			'totalSchoolAdmins',
			'totalFlightDirectors',

			/* ITERATION 2
			--------------
			// mission totals
			'missionsRan',
			// user + missions
			'totalUsersWhoRanMissions',
			'lastMissionDate',
			// mission average
			'avgTeacherRating',
			'avgStudentRating',
			'avgMissionScore',
			// SAVE FOR LATER
			'avgQuestionScore',
			'avgApplicationScore',
			'avgCollaborationScore',
			'avgCTScore',
			'avgGritScore',
			'avgInitiativeScore',
			*/
		],
	},
	feedback: {
		criteriaResource: 'IMPRESSIONS',
		allowedFilters: ['missionDateRange'],
		fields: [
			{ name: 'flightDirectorFirstName', disableSort: true },
			{ name: 'flightDirectorLastName', disableSort: true },
			{ name: 'schoolName', disableSort: true },
			'generalFeedback',
			{
				name: 'ranOnWeb',
				displayName: 'App Type',
				toCSV: value => (value === true ? 'WEB' : 'DESKTOP'),
			},
			'missionRating',
			'avgStudentRating',
			{ name: 'createdOn', toCSV: getDateMoment },
			'code',
			{ name: 'isRemote', toCSV: getBoolean },
			{ name: 'missionName', disableSort: true },
			{ name: 'controlSet', disableSort: true },
			{ name: 'flightDirectorEmail', disableSort: true },
		],
	},
}

export type ResourceType = $Keys<typeof AVAILABLE_RESOURCES>

const BooleanValue = props => (props.value === true ? 'Yes' : 'No')
const DateMoment = props => getDateMoment(props.value)
// Shortens a string for the table so it doesn't exceed 30 characters: when user hovers over text shows the whole string
const ShortenedString = (props: { value: string }) => {
	let str = props.value
	let charCap = 30
	if (str.length <= charCap) {
		return str
	}
	let splitWords = str.slice(0, charCap + 1).split(/\s+/)
	if (str[charCap + 1] !== ' ') {
		splitWords.pop()
	}
	return (
		<Tooltip content={<div css="width: 30vw;">{str}</div>}>{splitWords.join(' ') + '...'}</Tooltip>
	)
}
const RanOnWebTag = (props: { value: ?boolean }) => <Tag>{props.value ? 'WEB' : 'DESKTOP'}</Tag>

function FeedbackCell(props: {
	value: any,
	title: string,
	setView: ({ title: string, feedbackList: any }) => void,
}) {
	const clickable = Array.isArray(props.value) && props.value.length > 0
	return (
		<div
			css={clickable ? 'cursor: pointer;' : ''}
			onClick={() => clickable && props.setView({ title: props.title, feedbackList: props.value })}>
			{Array.isArray(props.value) ? props.value.length : props.value}
		</div>
	)
}

/* A map of resource field names that require special rendering in order to be displayed as cells in a table.
 * Resource field names map directly to ReactComponent which expect the field value to be in props (props.value)
 * Add to this map if a resource field needs to rendered as something other than a
 * number or string value
 */
const specialRenderedCells: { [string]: React$ComponentType<*> } = {
	date: DateMoment,
	createdOn: DateMoment,
	standards: props => props.value.map(standard => standard.type || standard.name).join(', '),
	grades: props => joinArrayOfStrings(props.value, ', '),
	public: BooleanValue,
	trainingComplete: BooleanValue,
	emailVerified: BooleanValue,
	ranOnWeb: RanOnWebTag,
	isRemote: BooleanValue,
	lastMissionDate: DateMoment,
	createdAt: DateMoment,
	lastLogin: DateMoment,
	generalFeedback: ShortenedString,
}

type HOCProps = { setFeedbackViewData: () => void }

/**
 * A map of resource fields to high order components. A high order component will take props
 * provided by a calling function and return a component that takes both the provided props and the
 * cell props provided by react table. This is useful if clicking a cell needs to trigger a state
 * change for the parent component. (Clicking a cell of issues reported should open a modal with all issues reported)
 */
const highOrderCells: {
	[string]: (HOCProps) => React$ComponentType<*>,
} = {
	feedback: ({ setFeedbackViewData }: { setFeedbackViewData: () => any }) =>
		function _FeedbackCell(props) {
			return <FeedbackCell {...props} title="Feedback" setView={setFeedbackViewData} />
		},
}

/**
 * Gets all the field names in string format for a given resource
 * @param {ResourceType} resource
 * @returns {string[]} all the field names for the resource
 */
export function getFieldNamesForResource(resource: ResourceType): string[] {
	return AVAILABLE_RESOURCES[resource].fields.map(getFieldName)
}

/**
 * Gets the name for a field object.
 * @param {Field} field
 * @returns {string}
 */
function getFieldName(field: Field): string {
	let fieldName = typeof field === 'string' ? field : field.name
	return fieldName
}

/**
 * Get column values for the AdvancedTable component based on fields in the AVAILABLE_RESOURCES map
 * @param {ResourceType} resource the resource name
 * @param {{setFeedbackViewData: () => void }} highOrderComponentProps these props will get passed to cells that need to be wrapped in a high order component.
 * @returns Array<TableColumns>
 */
export function getColumnsFromResource(
	resource: ResourceType,
	highOrderComponentProps: HOCProps
): Array<TableColumns> {
	const fields = AVAILABLE_RESOURCES[resource].fields
	return fields.map(field => {
		let fieldName = getFieldName(field)
		const column = {}
		column.accessor = fieldName
		let displayName = prettifyCamelCase(fieldName)
		if (typeof field === 'object' && field.hasOwnProperty('displayName')) {
			displayName = field.displayName || fieldName
		}
		column.Header = displayName
		if (typeof field === 'object' && field.hasOwnProperty('toCSV')) {
			column.csvWriter = field.toCSV
		}
		if (specialRenderedCells[fieldName]) {
			column.Cell = specialRenderedCells[fieldName]
		}
		if (highOrderCells[fieldName]) {
			column.Cell = highOrderCells[fieldName](highOrderComponentProps)
		}
		if (typeof field === 'object' && field.hasOwnProperty('disableSort')) {
			column.disableSortBy = true
		}
		return column
	})
}

// a mapping of field types to TableColumns overrides
const TYPES_TO_COLUMNS_DATA = {
	DATE: { toCSV: getDateMoment, Cell: DateMoment },
	BOOLEAN: { toCSV: getBoolean, Cell: BooleanValue },
}

/**
 * getColumnsFromServerFields - get report table columns from field data provided by the server
 *
 * @param  {ReportField[]} fields - the report fields to create columns for
 *
 * @returns Array<TableColumns> - an array of table column
 */
export function getColumnsFromServerFields(fields: ReportField[]): Array<TableColumns> {
	const columns: Array<TableColumns> = []
	fields.forEach(({ fieldName, disableSort, type }) => {
		let column: TableColumns = { accessor: fieldName, Header: prettifyCamelCase(fieldName) }
		if (disableSort) {
			column.disableSortBy = true
		}

		if (type && TYPES_TO_COLUMNS_DATA[type]) {
			column = { ...column, ...TYPES_TO_COLUMNS_DATA[type] }
		}

		columns.push(column)
	})
	return columns
}
