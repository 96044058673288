import styled, { type StyledComponent } from 'styled-components'
import { H4, Intent, Button } from '@blueprintjs/core'
import * as React from 'react'

export const ResourceName: StyledComponent<any, Theme, HTMLDivElement> = styled.div`
	font-weight: bold;
	font-size: 1.25em;
	top: 0;
	margin-top: 0.25em;
	position: sticky;
	height: min-content;
	white-space: no-wrap;

	> .bp3-heading {
		color: ${({ theme }) => theme.primary};
	}
`

/**
 * Row - a row in the form, this is a helper component to help with positioning the children
 *
 * @param  {Object} props - the react props
 * @param  {React$Node} props.children - the
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {?() => void} props.onDelete - a callback which occurs when the user deletes the row
 *
 * @returns React$Node
 */
export function Row({
	children,
	displayPath = [],
	onDelete,
}: {
	children: React$Node,
	displayPath?: Array<string>,
	onDelete?: () => void,
}): React$Node {
	const labelToDisplay = displayPath.join(' ')
	return (
		<>
			{labelToDisplay ? (
				<ResourceNameWrapper useAllRows={!onDelete}>
					<ResourceName>
						<Label>{labelToDisplay}</Label>
					</ResourceName>
				</ResourceNameWrapper>
			) : null}
			{children}
			{onDelete ? (
				<StyledButton icon="trash" intent={Intent.DANGER} minimal onClick={onDelete} />
			) : (
				''
			)}
		</>
	)
}

export const FlexRow: StyledComponent<any, any, HTMLDivElement> = styled.div`
	display: flex;
	flex-direction: row;
`

export const FlexRowCenterer: StyledComponent<any, any, any> = styled(FlexRow)`
	align-items: center;
`

const Label = styled(H4)`
	white-space: nowrap;
`

const StyledButton = styled(Button)`
	align-self: flex-start;
	grid-column: delete-start / delete-end;
`

const ResourceNameWrapper = styled.div`
	margin-left: 4px;
	margin-right: 4px;

	grid-column: identifier-start / identifier-end;
	${({ useAllRows }) => (useAllRows ? 'grid-row: 1 / 3;' : '')}
`

const CriteriaBox_ = styled.div`
	border-radius: 2px;
	background-color: #2244ff10;
	padding: 8px;
	width: 100%;
	display: grid;
	grid-template-columns: [row-start identifier-start] auto [identifier-end field-start] 1fr [field-end delete-start] auto [delete-end row-end];
	grid-column: field-start / row-end;
	grid-row-gap: 8px;
`

/**
 * CriteriaBox - a wrapper around what fields can possibly be boxed together
 *
 * @param {Object} props - the react props
 * @param  {boolean} props.doBox - true if the children should be placed into a box, false otherwise
 * @param  {React$Node} props.children - the children which can be placed into a box
 * @param  {?string} props.className - css class names if style overriding is wanted
 *
 * @returns React$Node
 */
export function CriteriaBox({
	doBox,
	children,
	className,
}: {
	doBox: boolean,
	children: React$Node,
	className?: ?string,
}): React$Node {
	if (doBox) {
		return <CriteriaBox_ className={className}>{children}</CriteriaBox_>
	}
	return children
}
