import {
	useMutation,
	useQueryClient,
	useQuery,
	type UseMutationResult,
	type UseQueryResult,
} from 'react-query'
import { rolesEnum } from '../../../stores/types'
import { AppToaster } from '../../../components'
import {
	removeUserNetworkCall,
	roleNetworkCall,
	revokeInviteNetworkCall,
	addInvitesNetworkCall,
	fetchPermissionDefaults,
	fetchSimulationGroups,
} from './networkCalls'

import type { LicenseClient, PermissionsFlow, LicenseType, SimulationGroup } from './types'

type GetUsersReturnType = {
	users: LicenseClient[],
	pendingInvitations: string[],
}

// Fetch permission defaults after this amount of time
const PERMISSION_DEFAULTS_STALE_TIME = 24 * 60 * 60 * 1000

/**
 * Fetches license permission defaults from the server once every PERMISSION_DEFAULTS_STALE_TIME
 * This allows us to avoid unnecessary network calls.
 */
export function usePermissionDefaults(): ?{ [LicenseType]: PermissionsFlow } {
	const { data } = useQuery('license-permission-defaults', fetchPermissionDefaults, {
		staleTime: PERMISSION_DEFAULTS_STALE_TIME,
	})
	return data
}

/**
 * useSimulationGroups - get all the simulation groups from the server using react query
 *
 * @return {UseQueryResult<SimulationGroup[]>} - react query result with all the simulation groups
 */
export function useSimulationGroups(): UseQueryResult<SimulationGroup[]> {
	return useQuery('simulation-groups', fetchSimulationGroups, {
		staleTime: PERMISSION_DEFAULTS_STALE_TIME,
	})
}

/**
 * React query's useMutation adds an email address as an invite for a given license
 * Optimistically updates the cached query of invitations for a license by adding the email to the invites list
 * and then resets to previous value if the network call fails.
 * Usage:
 * addInvitesMutation = useAddInvitesMutation(licenseId, cb)
 * // Invoke mutation
 * addInvitesMutation.mutate({emails: ['email@test.com', 'email2@test.com']})
 * @param {string} licenseId
 * @param {(?string[]) => void} onSuccess function to call when mutation succeeds, takes optional parameter of emails that didn't succeed
 */
export function useAddInvitesMutation(
	licenseId: string,
	onSuccess: (?(string[])) => void
): UseMutationResult<any> {
	const queryClient = useQueryClient()
	return useMutation(payload => addInvitesNetworkCall({ ...payload, licenseId }), {
		onMutate: async payload => {
			await queryClient.cancelQueries('get-users')
			const previousValue = queryClient.getQueryData('get-users')
			queryClient.setQueryData('get-users', old => {
				return addInvites(old, payload.emails)
			})
			return previousValue
		},
		onError: (_, variables, previousValue) => {
			AppToaster.danger({
				message: `Failed to send emails to the addresses: ${variables.emails.join(',')}`,
			})
			return queryClient.setQueryData('get-users', previousValue)
		},
		onSuccess: data => {
			if (data.failedEmails) {
				onSuccess(data.failedEmails)
				// Remove failed emails from current list of invitations
				queryClient.setQueryData('get-users', old => {
					let newState = { ...old }
					data.failedEmails.forEach(email => {
						newState = removeInvite(newState, email)
					})
					return newState
				})
				AppToaster.warning({
					message: `Failed to invite the email addresses: ${data.failedEmails.join(',')}`,
				})
			} else {
				onSuccess()
			}
			queryClient.invalidateQueries('get-users')
		},
	})
}

/**
 * React query's useMutation removes an email address from the invitations to join a given license.
 * Optimistically updates the cached query of invitations for a license by removing the invite
 * and then resets to previous value if the network call fails.
 * Usage:
 * removeInviteMutation = useRemoveInviteMutation(email, licenseId)
 * // Invoke mutation
 * removeInviteMutation.mutate()
 * @param {string} email
 * @param {string} licenseId
 */
export function useRemoveInviteMutation(email: string, licenseId: string): UseMutationResult<any> {
	const queryClient = useQueryClient()
	return useMutation(() => revokeInviteNetworkCall({ email, licenseId }), {
		onMutate: async payload => {
			await queryClient.cancelQueries('get-users')
			const previousValue = queryClient.getQueryData('get-users')
			queryClient.setQueryData('get-users', old => {
				return removeInvite(old, email)
			})
			return previousValue
		},
		onError: (_, variables, previousValue) => {
			AppToaster.danger({
				message: `Failed to delete invitation for ${email}.`,
			})
			return queryClient.setQueryData('get-users', previousValue)
		},
		onSuccess: () => {
			queryClient.invalidateQueries('get-users')
		},
	})
}

/**
 * React query's useMutation removes a given client from a given license.
 * Optimistically updates the cached query of users on a license by removing the user
 * and then resets to previous value if the network call fails.
 * On success invalidates queries for get licenses (a removed user causes the creation of a new license)
 * and invalidates queries for get-users on a license
 * Usage:
 * removeUserMutation = useRemoveUserMutation(client, licenseId)
 * // Invoke mutation
 * removeUserMutation.mutate()
 * @param {LicenseClient} client
 * @param {string} licenseId
 * @returns {{ mutate: () => void, isLoading: boolean }}
 */
export function useRemoveUserMutation(
	client: LicenseClient,
	licenseId: string
): { mutate: () => void, isLoading: boolean } {
	const queryClient = useQueryClient()
	const clientId = client._id
	const clientName = `${client.firstName} ${client.lastName}`
	return useMutation(() => removeUserNetworkCall({ clientId, licenseId }), {
		onMutate: async payload => {
			await queryClient.cancelQueries('get-users')
			const previousValue = queryClient.getQueryData('get-users')
			queryClient.setQueryData('get-users', old => {
				return removeUser(old, clientId)
			})
			return previousValue
		},
		onError: (_, variables, previousValue) => {
			AppToaster.danger({
				message: `Failed to remove ${clientName} from this license.`,
			})
			return queryClient.setQueryData('get-users', previousValue)
		},
		onSuccess: () => {
			queryClient.invalidateQueries('get-users')
		},
	})
}

/**
 * React query's useMutation changes a given clients LICENSE_ADMIN role.
 * Optimistically updates the cached query of users on a license by adding or
 * removing the LICENSE_ADMIN role (depending on the mutation callback)
 * and then resets to previous value if the network call fails.
 *
 * Usage:
 * adminRoleMutation = useAdminRoleMutation(client, licenseId)
 * // Invoke mutation
 * adminRoleMutation({ makeAdmin: true })
 * @param {LicenseClient} client
 * @param {string} licenseId
 * @returns {{ mutate: ({makeAdmin: boolean}) => void, isLoading: boolean }}
 */
export function useAdminRoleMutation(
	client: LicenseClient,
	licenseId: string
): UseMutationResult<any> {
	const queryClient = useQueryClient()
	const clientId = client._id
	const clientName = `${client.firstName} ${client.lastName}`
	return useMutation(({ makeAdmin }) => roleNetworkCall({ clientId, licenseId, makeAdmin }), {
		onMutate: async payload => {
			await queryClient.cancelQueries('get-users')
			const previousValue = queryClient.getQueryData('get-users')
			queryClient.setQueryData('get-users', old => {
				return updateRole(old, payload.makeAdmin, client._id)
			})
			return previousValue
		},
		onError: (_, variables, previousValue) => {
			AppToaster.danger({
				message: `Failed to ${
					variables.makeAdmin
						? `make ${clientName} a license admin.`
						: `revoke license admin privileges for ${clientName}.`
				} `,
			})
			return queryClient.setQueryData('get-users', previousValue)
		},
		onSuccess: () => {
			queryClient.invalidateQueries('get-users')
		},
	})
}

/**
 * Updates the roles of a client on the react query client state for the query 'get-users'
 * @param {GetUsersReturnType} state a readonly state that represents what is returned from get-licenses
 * @param {boolean} makeAdmin whether or not the client should be made an admin
 * @param {string} clientId
 * @returns {GetUsersReturnType} the updated state
 */
function updateRole(
	state: GetUsersReturnType,
	makeAdmin: Boolean,
	clientId: string
): GetUsersReturnType {
	let newRoles
	const index = state.users.findIndex(client => client._id === clientId)
	if (index === -1) {
		return state
	}
	if (makeAdmin) {
		newRoles = [...state.users[index].roles, { verified: true, role: rolesEnum.LICENSE_ADMIN }]
	} else {
		newRoles = state.users[index].roles.filter(
			roleData => roleData.role !== rolesEnum.LICENSE_ADMIN
		)
	}
	return {
		...state,
		data: [
			...state.users.slice(0, index),
			{
				...state.users[index],
				roles: newRoles,
			},
			...state.users.slice(index + 1),
		],
	}
}

/**
 * Removes a client from the react query state returned from the query 'get-users'
 * @param {GetUsersReturnType} state
 * @param {string} clientId
 * @returns GetUsersReturnType
 */
function removeUser(state: GetUsersReturnType, clientId: string): GetUsersReturnType {
	const index = state.users.findIndex(client => client._id === clientId)
	if (index === -1) {
		return state
	}
	return { ...state, users: [...state.users.slice(0, index), ...state.users.slice(index + 1)] }
}

/**
 * Removes an email address from pending invitations on the query state for 'get-users'
 * @param {GetUsersReturnType} state
 * @param {string} email
 * @returns GetUsersReturnType
 */
function removeInvite(state: GetUsersReturnType, email: string): GetUsersReturnType {
	return {
		...state,
		pendingInvitations: state.pendingInvitations.filter(pendingEmail => pendingEmail !== email),
	}
}

/**
 * Adds all email addresses given to the list of pending invitations on the query state for 'get-users'
 * @param {GetUsersReturnType} state
 * @param {string} email
 * @returns GetUsersReturnType
 */
function addInvites(state: GetUsersReturnType, emails: string[]): GetUsersReturnType {
	return {
		...state,
		pendingInvitations: [...state.pendingInvitations, ...emails],
	}
}
