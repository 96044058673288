import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { debounce } from '../../../utility/functions'

import type {
	LicenseFlow,
	DistrictType,
	LicenseOwner,
	PermissionsFlow,
	LicenseType,
	SimulationGroup,
} from './types'

/**
 * A network call to mark a license user as a license admin
 * @param {{clientId: string, licenseId: string, makeAdmin: boolean}}
 */
export const roleNetworkCall = ({
	clientId,
	licenseId,
	makeAdmin,
}: {
	clientId: string,
	licenseId: string,
	makeAdmin: boolean,
}): Promise<any> => {
	return NetworkCommunicator.PUT(
		`users/edit-roles?id=${clientId}&newRole=${
			makeAdmin ? 'LICENSE_ADMIN' : 'NON_LICENSE_ADMIN'
		}&licenseId=${licenseId}`
	)
}

/**
 * A network call to remove an invitation to a license for the given email address
 * @param {{email: string, licenseId: string}}
 */
export const addInvitesNetworkCall = ({
	emails,
	licenseId,
}: {
	emails: string[],
	licenseId: string,
}): Promise<any> => {
	return NetworkCommunicator.POST(`users/invite?licenseId=${licenseId}`, {
		body: { emailAddresses: emails },
	})
}

/**
 * A network call to remove an invitation to a license for the given email address
 * @param {{email: string, licenseId: string}}
 */
export const revokeInviteNetworkCall = ({
	email,
	licenseId,
}: {
	email: string,
	licenseId: string,
}): Promise<any> => {
	return NetworkCommunicator.PUT(`users/revoke-invitation?email=${email}&licenseId=${licenseId}`)
}

/**
 * A network call to remove a user from the given license
 * @param {{clientId: string, licenseId: string}}
 */
export const removeUserNetworkCall = ({
	clientId,
	licenseId,
}: {
	clientId: string,
	licenseId: string,
}): Promise<any> => {
	return NetworkCommunicator.PUT(`users/remove-license-user?id=${clientId}&licenseId=${licenseId}`)
}

/**
 * A network call to get all users on a given license
 * @param {string} licenseId
 */
export const getUsersForLicenseCall = (licenseId: string): Promise<any> => {
	return NetworkCommunicator.GET(`licenses/users?id=${licenseId}`)
}

/**
 * A network call to fetch a map of permission defaults based on license type
 */
export function fetchPermissionDefaults(): Promise<{ [LicenseType]: PermissionsFlow }> {
	return NetworkCommunicator.GET(`licenses/permission-defaults`).then(
		({ permissionDefaults }) => permissionDefaults
	)
}

/**
 * Takes the parameters for a license and if provided with a non-empty string ID updates an existing license
 * Otherwise creates a new license
 */
export function postOrUpdateLicense(license: LicenseFlow<LicenseOwner>): Promise<any> {
	const isUpdatingLicense = Boolean(license._id)

	if (isUpdatingLicense) {
		return NetworkCommunicator.PUT(`licenses/${license._id}`, { body: { ...license } })
	} else {
		return NetworkCommunicator.POST('licenses', {
			body: {
				owner: license.owner,
				description: license.description,
				permissionsOverride: license.permissionsOverride,
				expiresOn: license.expiresOn,
				deleted: license.deleted,
			},
		})
	}
}

/**
 * Fetches schools from the server based on a query string and a U.S. state
 * Calls a given callback function with the result from the query
 * if we add districtId to the query, we get only PublicSchools
 * @param {string} inputValue
 * @param {{abbreviation: string}} state
 * @param {(Array<{ schoolName: string, schoolId: string }>) => void} callback
 * @param {string} districtId id of district
 *  */
function fetchSchoolsWithoutDebounce(
	inputValue: string,
	state: ?{ abbreviation: string },
	callback: (Array<{ schoolName: string, schoolId: string }>) => void,
	districtId?: string = ''
): void {
	if (!state?.abbreviation || inputValue.length < 3) {
		callback([])
		return
	}
	const urlToFetchAllSchools = `schools?text=${inputValue}&state=${state.abbreviation}`
	const urlToFetchOnlyPublicSchools = `schools?text=${inputValue}&state=${state.abbreviation}&districtId=${districtId}`

	const url = districtId ? urlToFetchOnlyPublicSchools : urlToFetchAllSchools
	NetworkCommunicator.GET(url).then(response => {
		callback(
			response.map(school => ({
				district: school.district,
				schoolName: school.name,
				schoolId: school._id,
				street: school.street,
				city: school.city,
			}))
		)
	})
}
export const fetchSchools: (
	inputValue: string,
	state: ?{ abbreviation: string },
	callback: (Array<{ schoolName: string, schoolId: string }>) => void,
	districtId?: string | void
) => void = debounce(fetchSchoolsWithoutDebounce, 500)
/**
 * Fetches districts from the server based on a query string and a U.S. state
 * Calls a given callback function with the result from the query
 * @param {string} inputValue
 * @param {{abbreviation: string}} state
 * @param {{(Array<DistrictType>) => void}} callback
 * @param {*} */
function fetchDistrictsWithoutDebounce(
	inputValue: string,
	state: ?{ abbreviation: string },
	callback: (Array<DistrictType>) => void
) {
	if (state) {
		NetworkCommunicator.GET(
			`schools/districts?text=${inputValue}&state=${state.abbreviation}`
		).then(response => {
			callback(response)
		})
	} else {
		callback([])
	}
}
export const fetchDistricts: (
	inputValue: string,
	state: ?{ abbreviation: string },
	callback: (Array<DistrictType>) => void
) => void = debounce(fetchDistrictsWithoutDebounce, 500)

/**
 * fetchSimulationGroups - fetch all simulation groups from the server
 *
 * @return {Promise<SimulationGroup[]>} - all the simulation groups
 */
export function fetchSimulationGroups(): Promise<SimulationGroup[]> {
	return NetworkCommunicator.GET(`licenses/simulation-groups`).then(({ groups }) => groups)
}
