import React from 'react'
import { useEffect, useState } from 'react'
import { Classes, Button, Intent } from '@blueprintjs/core'
import type { AlertFormData } from './types'
import { AlertForm } from './AlertForm'
import styled from 'styled-components'
import { useAlert, useSaveAlert } from './hooks'
import { Loading } from '../AnalyticsOfAnalytics/sharedComponents'

/**
 * AlertEditor - a component to coordinate editing an alert by the alert's id
 *
 * @param {Object} props - the react props
 * @param  {string} props.alertId - the id of the alert to edit
 * @param  {(alert: AlertFormData) => void} props.onSave - a callback to save the edited alert
 *
 * @returns React$Node
 */
export function AlertEditorForId({
	alertId,
	onSaved,
}: {
	alertId: string,
	onSaved: () => void,
}): React$Node {
	const { isLoading, error, data: alert } = useAlert(alertId)
	const [alertFormData, setAlertFormData] = useState((null: ?AlertFormData))
	const { isLoading: isSaving, mutate: saveAlert } = useSaveAlert(alertId, () => {
		onSaved()
	})

	useEffect(() => {
		if (alertFormData || !alert) {
			return
		}
		setAlertFormData({
			criteria: alert.criteria,
			documentType: alert.documentType,
			name: alert.name,
			notifiers: alert.notifiers,
		})
	}, [alert, alertFormData])

	if (isLoading || !alertFormData || isSaving) {
		return <Loading />
	}

	if (error) {
		return <div>There was an error loading the alert</div>
	}

	return (
		<>
			<div className={Classes.DIALOG_HEADER}>Edit Alert</div>
			<div className={Classes.DIALOG_BODY}>
				<AlertForm
					currentAlertData={(alertFormData: AlertFormData)}
					updateAlertData={updater => {
						setAlertFormData((oldData: ?AlertFormData) => {
							if (!oldData) {
								return oldData
							}
							return updater(oldData)
						})
					}}
				/>
			</div>
			<Footer className={Classes.DIALOG_FOOTER}>
				<Button onClick={() => saveAlert(alertFormData)} intent={Intent.PRIMARY}>
					Save
				</Button>
			</Footer>
		</>
	)
}

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
`
