import React, { useCallback, useState } from 'react'
import 'styled-components/macro'
import { Card, Elevation, FormGroup, Button } from '@blueprintjs/core'
import { AppToaster, Select } from '../../../../components/basic'
import { prettifyTypeEnum } from '../../../../utility/functions'
import { AVAILABLE_RESOURCES, type ResourceType } from '../resources'
import { CriteriaForm } from '../Criteria'
import { useQueryParam, JsonParam, StringParam } from 'use-query-params'
import type { Criteria } from '../Criteria/localTypes'
import { FIELD_TYPES } from '../Criteria/clientTypes'

/**
 * A sandbox to allow users to customize and generate a report.
 */
export default function Sandbox({
	fetchResource,
}: {
	fetchResource: ({ resource: ?ResourceType, criteria: ?Criteria }) => void,
}): React$Node {
	const [resource, _setResource] = useQueryParam('resource', StringParam)
	const [criteria, _setCriteria] = useQueryParam('criteria', JsonParam)
	const [forceDisplayFields, _setForceDisplayFields] = useState({})

	const setCriteria = useCallback(
		(updater: (oldCriteria: ?Criteria) => Criteria) =>
			_setCriteria(oldCriteria => updater(oldCriteria ?? {})),
		[_setCriteria]
	)
	const setResource = useCallback(
		resourceType => {
			_setResource(resourceType)
			_setCriteria({})
			_setForceDisplayFields({})
		},
		[_setCriteria, _setResource, _setForceDisplayFields]
	)

	const ready = Boolean(resource)

	const generateReport = useCallback(() => {
		if (!resource) {
			AppToaster.warning({ message: 'Select a resource first' })
			return
		}
		fetchResource({ resource, criteria })
	}, [resource, fetchResource, criteria])

	return (
		<Card elevation={Elevation.TWO} css="display: flex; justify-content: space-between;">
			<div css="display: flex; flex-direction: column; padding: 4px;">
				<ResourceSelector
					onChange={setResource}
					value={resource}
					label="Select a Resource"
					css="margin-right: 15px !important;"
				/>
				<Button
					css="align-self: flex-start; margin-top: 23px; position: sticky; top: 0.5em; "
					disabled={!ready}
					onClick={generateReport}>
					Generate Report
				</Button>
			</div>
			<div css="display: flex;">
				{resource && AVAILABLE_RESOURCES[resource]?.criteriaResource && (
					<CriteriaForm
						currentCriteria={criteria}
						updateCriteria={setCriteria}
						forceDisplayFields={forceDisplayFields}
						updateForceDisplayFields={_setForceDisplayFields}
						resourceName={AVAILABLE_RESOURCES[resource]?.criteriaResource}
						fieldType={{
							type: FIELD_TYPES.COMPOSABLE,
							resource: AVAILABLE_RESOURCES[resource].criteriaResource,
							isExpectedOneToMany: true,
							displayName: resource.toLocaleLowerCase(),
						}}
					/>
				)}
			</div>
		</Card>
	)
}

export const ResourceSelector = ({
	className,
	onChange,
	value,
	labelInfo,
	label = 'Select a resource',
	helperText,
	disabled = false,
	resources = Object.keys(AVAILABLE_RESOURCES),
}: {
	className?: string,
	value: ?ResourceType,
	onChange: (?ResourceType) => void,
	labelInfo?: string,
	label?: string,
	helperText?: string,
	disabled?: boolean,
	resources?: ResourceType[],
}): React$Node => {
	const formProps = {
		labelInfo,
		label,
		helperText,
		disabled,
	}

	return (
		<FormGroup {...formProps} className={className}>
			<Select
				placeholder="Resource..."
				getOptionLabel={prettifyTypeEnum}
				options={resources}
				clearable={true}
				value={value}
				onChange={onChange}
			/>
		</FormGroup>
	)
}
