import type { ProxyData } from '../../../utility/dotProxy'
import type { SharedInputFields } from '../AnalyticsOfAnalytics/customInputs/types'
import type { AlertFormData, Notifier, UpdateAlertData } from './types'

import { assign, get, getProxyData } from '../../../utility/dotProxy'

import React from 'react'
import { useState } from 'react'
import { Label, Card, Button, Intent, H4 } from '@blueprintjs/core'
import styled from 'styled-components'
import { CriteriaForm } from '../Generator/Criteria'
import type { Criteria, ForceDisplayFields } from '../Generator/Criteria/localTypes'
import { FIELD_TYPES } from '../Generator/Criteria/clientTypes'
import { AVAILABLE_RESOURCES } from '../Generator/resources'
import { ResourceSelector } from '../Generator/Sandbox'
import { CheckBoxForm } from './customFields/CheckBoxForm'
import { StringForm } from './customFields/StringForm'
import { hasEmailDomain } from '../../../utility/emails'

/**
 * AlertForm - a form used to input data for an alert
 *
 * @param {Object} props - the react props
 * @param {AlertFormData} props.currentAlertData - the current alert data
 * @param {UpdateAlertData} props.updateAlertData - the callback to update the current alert data
 *
 * @returns React$Node
 */
export function AlertForm({
	currentAlertData,
	updateAlertData,
}: {
	currentAlertData: AlertFormData,
	updateAlertData: UpdateAlertData,
}): React$Node {
	const [forceDisplayFields, setForceDisplayFields] = useState(({}: ForceDisplayFields))

	return (
		<Card>
			<Label>
				<H4>Name</H4>
				<StringForm
					values={currentAlertData}
					onChange={updateAlertData}
					valueProxy={getProxyData((trace: AlertFormData) => trace.name)}
				/>
			</Label>
			<H4>Filters</H4>
			<CriteriaRow>
				<ResourceSelector
					onChange={(newResource: ?string) => {
						if (!newResource) {
							return
						}
						setForceDisplayFields({})
						updateAlertData((oldAlertData: AlertFormData) =>
							assign(
								getProxyData((trace: AlertFormData) => trace.documentType),
								assign(
									getProxyData((trace: AlertFormData) => trace.criteria),
									oldAlertData,
									{}
								),
								newResource
							)
						)
					}}
					value={currentAlertData.documentType}
					label="Select a Resource"
					css="margin-right: 15px !important;"
				/>
				<div>
					<div>Filters</div>
					<CriteriaForm
						currentCriteria={currentAlertData.criteria ?? {}}
						forceDisplayFields={forceDisplayFields}
						updateForceDisplayFields={setForceDisplayFields}
						updateCriteria={(updater: (oldData: Criteria) => Criteria) => {
							updateAlertData((oldAlertData: AlertFormData) =>
								assign(
									getProxyData((trace: AlertFormData) => trace.criteria),
									oldAlertData,
									updater(oldAlertData.criteria) ?? {}
								)
							)
						}}
						resourceName={currentAlertData.documentType}
						fieldType={{
							type: FIELD_TYPES.COMPOSABLE,
							resource: AVAILABLE_RESOURCES[currentAlertData.documentType].criteriaResource,
							isExpectedOneToMany: true,
							displayName: currentAlertData.documentType.toLocaleLowerCase(),
						}}
					/>
				</div>
			</CriteriaRow>
			<NotifierListForm
				values={currentAlertData}
				onChange={updateAlertData}
				valueProxy={getProxyData((trace: AlertFormData) => trace.notifiers)}
			/>
		</Card>
	)
}

/**
 * NotifierListForm - a form for inputting the notifier list for the alert
 *
 * @param {Object} props - the react props
 * @param {ProxyData<boolean>} props.valueProxy - a proxyData describing the location of the notifier list in the alert
 * @param {T} props.values - the base container of the value
 * @param {UpdateCallback<T>} props.onChange - a callback used to update the base container
 *
 * @returns React$Node
 */
function NotifierListForm<T>({
	valueProxy,
	values,
	onChange,
}: {
	valueProxy: ProxyData<Notifier[]>,
	...SharedInputFields<T>,
}): React$Node {
	const currentNotifications: Notifier[] = get(valueProxy, values) ?? []

	return (
		<StyledNotifiersWrapper>
			<H4>Notifications Sent To</H4>
			{currentNotifications.map((notifier, index) => (
				<NotifierCard key={String(index)}>
					<NotifierForm
						values={currentNotifications[index]}
						onDelete={() => {
							onChange((oldValues: T) => {
								const newNotifiers: Notifier[] = [...(get(valueProxy, oldValues) ?? [])]
								newNotifiers.splice(index, 1)
								return assign(valueProxy, oldValues, newNotifiers)
							})
						}}
						valueProxy={getProxyData((trace: Notifier) => trace)}
						onChange={(updater: (oldNotification: Notifier) => Notifier) =>
							onChange((oldValues: T) => {
								const oldNotifiers: Notifier[] = get(valueProxy, oldValues) ?? []
								const newNotifier = updater(oldNotifiers[index])

								if (!newNotifier) {
									return oldValues
								}
								const newNotifiers = [...oldNotifiers]
								newNotifiers[index] = newNotifier
								return assign(valueProxy, oldValues, newNotifiers)
							})
						}
					/>
				</NotifierCard>
			))}
			<div>
				<Button
					onClick={() => {
						onChange((oldValues: T) =>
							assign(
								valueProxy,
								oldValues,
								([
									...(get(valueProxy, oldValues) ?? []),
									{
										onAdd: false,
										onDelete: false,
										target: {
											type: 'EMAIL',
											sendTo: '',
										},
									},
								]: Notifier[])
							)
						)
					}}
					icon="plus"
					intent={Intent.PRIMARY}>
					Add
				</Button>
			</div>
		</StyledNotifiersWrapper>
	)
}

/**
 * NotifierForm - a form for inputting data into notifiers
 *
 * @param {Object} props - the react props
 * @param {ProxyData<boolean>} props.valueProxy - a proxyData describing the location of the notifier to show and update
 * @param {T} props.values - the base container of the value
 * @param {UpdateCallback<T>} props.onChange - a callback used to update the base container
 * @param {() =< any} props.onDelete - a callback used to delete the notification
 *
 * @returns React$Node
 */
function NotifierForm<T>({
	valueProxy,
	values,
	onChange,
	onDelete,
}: {
	valueProxy: ProxyData<Notifier>,
	onDelete: () => any,
	...SharedInputFields<T>,
}): React$Node {
	const currentNotifications: ?Notifier = get(valueProxy, values)
	if (!currentNotifications) {
		return null
	}

	const emailProxy = getProxyData((trace: Notifier) => trace.target.sendTo)
	const currentEmail = get(emailProxy, values)

	return (
		<>
			<GrowRow>
				<RowLabel>
					<div>Email:</div>
					<StringForm
						onChange={onChange}
						values={values}
						invalidMessage={
							currentEmail &&
							!(
								hasEmailDomain(currentEmail, 'infinidlearning.com') ||
								hasEmailDomain(currentEmail, 'mission.io')
							)
								? 'must be an email with the `@infinidlearning.com` or `@mission.io` domain'
								: null
						}
						className={'string-form-style'}
						valueProxy={emailProxy}
					/>
				</RowLabel>
			</GrowRow>
			<ButtonRow>
				<CheckBoxForm
					values={values}
					valueProxy={getProxyData((trace: Notifier) => trace.onAdd)}
					onChange={onChange}
					label="Notify On Addition"
				/>
				<CheckBoxForm
					values={values}
					valueProxy={getProxyData((trace: Notifier) => trace.onDelete)}
					onChange={onChange}
					label="Notify On Remove"
				/>
				<Button onClick={onDelete} icon="trash" intent={Intent.DANGER}>
					Delete
				</Button>
			</ButtonRow>
		</>
	)
}

const CriteriaRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	align-items: center;
`

const GrowRow = styled(Row)`
	flex: 1 1 0;
	width: 100%;
`

const ButtonRow = styled(Row)`
	justify-content: flex-end;
	width: 100%;
`

const NotifierCard = styled(Card)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	width: max-content;
	&& {
		padding: 8px;
	}

	&&& label {
		margin-bottom: 0;
	}

	&&& input {
		margin-top: 0;
	}
`

const RowLabel = styled(Label)`
	&& {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;
		flex: 1 1 0;
	}

	& .string-form-style {
		flex: 1 1 0;
	}
`

const StyledNotifiersWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`
