import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import styled from 'styled-components'
import { PageContainer } from '../../components'
import { SearchBar, Results, MissionModal } from '../Missions/components'

const Grid = styled.div`
	height: 100%;
	display: grid;
	grid-gap: 1vw;
	padding: 0.5vw;
	grid-template-columns: 90% 1fr;
	grid-template-rows: auto 1fr;
	> div {
		border: ${props => `1px solid ${props.theme.primary}`};
		border-radius: 6px;
		padding: 12px;
	}

	#top-right {
		grid-row: 1;
		grid-column: 1;
	}
	#bottom-right {
		grid-row: 2;
		grid-column: 1;
		overflow: auto;
	}
`

export default function Missions(): React$Node {
	const [selectedMissionId, setSelectedMissionId] = useQueryParam('mission-info', StringParam)

	return (
		<PageContainer column>
			<Grid>
				<div id="top-right">
					<SearchBar />
				</div>
				<div id="bottom-right">
					<Results onSelectMission={setSelectedMissionId} selectedMissionId={selectedMissionId} />
				</div>
			</Grid>
			{selectedMissionId && (
				<MissionModal
					id={selectedMissionId}
					onClose={() => {
						setSelectedMissionId(null)
					}}
				/>
			)}
		</PageContainer>
	)
}
