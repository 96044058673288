// @flow
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { H2, Button, HTMLSelect, FormGroup, Classes, Intent, Position } from '@blueprintjs/core'
import { Popover2, Classes as PopoverClasses } from '@blueprintjs/popover2'
import type { FullOkr as Okr } from './types'
import type { OkrOwner, SimpleOkr } from '../../../stores/types'
import { useCurrentUser } from '../../../stores/auth'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { AppToaster } from '../../../components/basic/AppToaster'
import { getOwnerOptions } from './Owners'
import { useOkrs, OKRS_QUERY_KEY } from '../../../resources/okrs'
import 'styled-components/macro'

import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
/**
 * A button that displays the parent OKR. When pressed, it allows selecting a new parent OKR in a popup. The parent OKR is
 * saved on selection.
 */
export default function ParentOkrSelectorPopup({ okr }: { okr: Okr }): React$Node {
	const [isOpen, setIsOpen] = useState(false)
	const queryClient = useQueryClient()

	return (
		<Popover2
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement={Position.LEFT}
			interactionKind="click"
			popoverClassName={PopoverClasses.POPOVER2_CONTENT_SIZING}
			content={
				<ParentOkrSelector
					excludedOkrIds={new Set([okr.id, okr.parent?.id])}
					quarter={okr.quarter}
					onSave={selectedParentOkrId => {
						NetworkCommunicator.PUT(`okrs/${okr.id}`, {
							body: { parentOkrId: selectedParentOkrId || null },
						})
							.then(() => {
								queryClient.invalidateQueries(OKRS_QUERY_KEY)
								setIsOpen(false)
							})
							.catch(e => {
								AppToaster.danger({
									message: 'Something went wrong, please try again!',
								})
							})
					}}
					onCancel={() => setIsOpen(false)}
				/>
			}>
			<Button onClick={() => setIsOpen(true)}>{okr.parent?.title ?? 'None'}</Button>
		</Popover2>
	)
}

/**
 * A component that allows selecting an OKR. It allows selecting an owner to filter OKRs, and then selecting an OKR owned by that owner.
 * `onSave` is called when the Save button is pressed. `onCancel` is called when the Cancel button is pressed.
 */
function ParentOkrSelector({
	excludedOkrIds,
	quarter,
	onSave,
	onCancel,
}: {
	excludedOkrIds: Set<?number>,
	quarter: string,
	onSave: (?number) => mixed,
	onCancel: () => mixed,
}) {
	const [owner, setOwner] = useState<?OkrOwner>()
	const [selectedParentOkr, setSelectedParentOkr] = useState<?SimpleOkr>()

	return (
		<div>
			<H2>Select Parent OKR</H2>
			<FormGroup label="Owner" labelFor="new-owner">
				<OwnerSelector
					id="new-owner"
					onSelect={newOwner => {
						if (newOwner && newOwner !== owner) {
							setOwner(newOwner)
							setSelectedParentOkr(null)
						}
					}}
					owner={owner}
				/>
			</FormGroup>
			<FormGroup label="OKR" labelFor="new-okr-selector">
				<OkrSelector
					id="new-okr-selector"
					owner={owner}
					quarter={quarter}
					okr={selectedParentOkr}
					onSelect={setSelectedParentOkr}
					excludedOkrIds={excludedOkrIds}
					css="width: 100%;"
				/>
			</FormGroup>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<Button onClick={onCancel}>Cancel</Button>
				<Button
					intent={Intent.PRIMARY}
					onClick={() => {
						onSave(selectedParentOkr?.id)
					}}>
					Save
				</Button>
			</div>
		</div>
	)
}

/**
 * Lets you select from a list of possible okr owners. This is a controlled component, controlled by the props `onSelect` and `owner`.
 */
function OwnerSelector({
	id,
	onSelect,
	owner,
}: {
	owner: ?OkrOwner,
	onSelect: (?OkrOwner) => void,
	id: string,
}) {
	const user = useCurrentUser()
	/**
	 * Gets a unique id for the given OkrOwner
	 */
	function getOwnerOptionId(owner: ?OkrOwner): string {
		if (!owner) {
			return ''
		}

		return owner.type + '--' + owner.id
	}
	const options: OkrOwner[] = getOwnerOptions({ user: user, includeCompany: true })
	return (
		<HTMLSelect
			id={id}
			onChange={e => {
				const [ownerType, ownerId] = e.currentTarget.value.split('--')
				const selectedOwner = options.find(
					option => String(option.id) === ownerId && option.type === ownerType
				)
				onSelect(selectedOwner)
			}}
			value={getOwnerOptionId(owner)}>
			<option value="" />
			{options.map(option => (
				<option value={getOwnerOptionId(option)} key={getOwnerOptionId(option)}>
					{option.name}
				</option>
			))}
		</HTMLSelect>
	)
}

/**
 * An okr selector that lets you select from a list of okrs owned by the `owner` provided in props. This is a controlled
 * component, so the selected okr is managed with the props `okr` and `onSelect`. Any OKRs whose id appears in `excludedOkrIds`
 * will not appear in the list of selectable OKRs.
 * @param {?OkrOwner} props.owner Selectable OKRs will be filtered to only include those owned by this owner
 * @param {string} props.quarter Selectable OKRs will be filtered to only include those in this quarter
 * @param {?SimpleOkr} props.okr The currently selected OKR
 * @param {Function} props.onSelect When an OKR is selected, this function is called with the selected OKR
 * @param {Set<?number>} props.excludedOkrIds ids of OKRs that should not be selectable
 */
function OkrSelector({
	owner,
	quarter,
	okr,
	onSelect,
	excludedOkrIds,
	id,
	className,
}: {
	owner: ?OkrOwner,
	quarter: string,
	okr: ?SimpleOkr,
	onSelect: (?SimpleOkr) => mixed,
	excludedOkrIds?: Set<?number>,
	id?: string,
	className?: string,
}) {
	const queryParams = {}
	queryParams.quarter = quarter
	switch (owner?.type) {
		case 'TEAM':
			queryParams.teamId = owner.id
			break
		case 'EMPLOYEE':
			queryParams.employeeId = owner.id
			break
		case 'COMPANY':
			queryParams.company = 1
			break
	}

	const { okrs } = useOkrs({
		queryParams,
		queryOptions: { enabled: !!owner },
	})

	const options = okrs ? okrs.filter(okr => !excludedOkrIds?.has(okr.id)) : []

	return (
		<HTMLSelect
			onChange={e => {
				const selectedOkr = options.find(option => String(option.id) === e.currentTarget.value)
				onSelect(selectedOkr)
			}}
			value={okr?.id}
			id={id}
			className={className}>
			<option />
			{options.map(option => (
				<option value={option.id} key={option.id}>
					{option.title}
				</option>
			))}
		</HTMLSelect>
	)
}
