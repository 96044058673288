// @flow

// THIS IS SIMILAR TO THE `dot-prop` PACKAGE BUT USING proxy's TO BETTER TRACK FLOW TYPES

export type ProxyData<U> = {
	path: ?(string[]),
	// this attribute (`value`) is purely to help with flow typing, DO NOT READ OR DEPEND ON THIS ATTRIBUTE
	value: U,
}
import { produce } from 'immer'

const PATH_SYMBOL = Symbol('PathSymbol')

/**
 * getProxyData - this call generates the proxyData which describes the path for the value retrieved in the proxyGenerator
 *
 * @param {(proxyTrace: T) => U): ?ProxyData<U>} proxyGenerator - a callback used to generate the data needed for the other functions. Explicitly type the
 *   parameter when you use this function, and return the value you want to get a proxy for. NOTE: proxyTrace is not actually type `T`, you will have to use the action
 *   data for conditionals.
 *
 * 'dot-prop' package: 'hello.world'
 * using this function: (trace: {hello: ?{world?: ?number}}) => trace.hello.world
 *
 *
 * conditional example:
 *
 * type State = {fields: {field1: number, field2: number}, useField: number}
 * const data = {fields: {field1: 1, field2: 2}, useField: 1}
 * const proxyData = getProxyData((trace: State) => {
 *   if (data.useField === 1) { // we have to use the `data` variable because the `trace` does not actually contain the `data` values
 *     return trace.fields.field1
 *   }
 *   return trace.fields.field2
 * })
 */
export function getProxyData<T, U>(proxyGenerator: (proxyTrace: T) => U): ProxyData<U> {
	const generateProxy = (parents: string[]) =>
		new Proxy(
			// $FlowFixMe[invalid-computed-prop] flow does not handle symbols correctly
			{ [PATH_SYMBOL]: parents },
			{
				get: (obj, prop: string) => {
					// $FlowFixMe[invalid-computed-prop] flow does not handle symbols correctly
					const path = obj[PATH_SYMBOL]
					if (prop !== PATH_SYMBOL) {
						return generateProxy([...path, prop])
					}
					return path
				},
			}
		)

	return {
		// $FlowFixMe[incompatible-use] flow does not handle symbols correctly
		path: proxyGenerator((generateProxy([]): any))?.[PATH_SYMBOL],
		// $FlowIgnore this value here is strictly for helping flow typing
		value: 'DO_NOT_USE_THIS_IS_PURELY_FOR_FLOW_TYPING',
	}
}

/**
 * get - get the value from the target at the location described by proxyData.
 *
 * @param {?ProxyData<U>} proxyData - the proxyData describing the location to get the value from
 * @param {T} target - the container to get the value from
 *
 * @return {?U} - the value
 *
 * Plain JS: target?.hello?.world
 * dot-prop' package: getProperty(target = {hello: {world: 'a'}}, 'hello.world')
 */
export function get<T, U>(proxyData: ?ProxyData<U>, target: T): ?U {
	const proxyPath = proxyData?.path
	if (!proxyPath) {
		return undefined
	}
	let current = target
	for (let i = 0; i < proxyPath.length; i++) {
		const segment = proxyPath[i]
		if (!current || typeof current !== 'object') {
			return undefined
		}
		current = current[segment]
	}
	// $FlowIgnore[incompatible-return] this will be the correct type if the type fed into `getProxyData` was correct
	return current
}

/**
 * assign - assign the attribute that the proxyData points to inside the target to the given value. Will create the path described in the
 *    proxyData if the path does not exist and the patch can be created. Will throw an error if the assignment could not be done.
 *
 *
 * @param {?ProxyData<U>} proxyData - the proxy data describing the location in the target to place the value
 * @param {T} target - the **IMMUTABLE** container to place the value into
 * @param {any} value - the value to place
 *
 * @return {T} - the updated target with the value placed at the location described by the proxy data
 *
 * 'dot-prop' package: setProperty(target = {}, 'hello.world', value)
 * plain JS: target.hello.world = value // this crashes if the attributes are not setup correctly
 */
export function assign<T, U>(proxyData: ?ProxyData<U>, target: T, value: any): T {
	const proxyPath = proxyData?.path
	if (!proxyPath?.length) {
		return target
	}

	if (!target || typeof target !== 'object') {
		throw new Error(`Can not assign a value to a target which is not an object: ${String(target)}`)
	}

	return produce(target, (draft: mixed) => {
		let current = draft
		for (let i = 0; i < proxyPath.length - 1; i++) {
			const segment = proxyPath[i]
			// $FlowIgnore[incompatible-use] we known current is an object
			if (!current[segment]) {
				// $FlowIgnore[incompatible-use] we known current is an object
				current = current[segment] = {}
				continue
			}
			// $FlowIgnore[incompatible-use] we known current is an object
			if (typeof current[segment] === 'object') {
				current = current[segment]
				continue
			}
			throw new Error(
				`Can not assign a value to a target which is not an object: ${String(
					target
				)}, path: ${proxyPath.slice(0, i + 1).join('.')}`
			)
		}

		const attribute = proxyPath[proxyPath.length - 1]
		// $FlowIgnore[incompatible-use] we known current is an object
		current[attribute] = value
	})
}

/**
 * minimizeDelete - delete the value at the location described by proxyData and remove any empty objects that deleting the value may have created (similar to
 *   when `{minimize: true}` is set in a mongo document).
 *
 * @param {?ProxyData<U>} proxyData - the proxy data describing the attribute to delete
 * @param {T} target - the **immutable** object to delete the value from
 *
 * @return {T} - the target with the value removed
 *
 * 'dot-prop' package: deleteProperty(target = {}, 'hello.world')
 */
export function minimizeDelete<T, U>(proxyData: ?ProxyData<U>, target: T): T {
	const proxyPath = proxyData?.path
	if (!proxyPath?.length) {
		return target
	}

	if (!target || typeof target !== 'object') {
		throw new Error(
			`Can not delete an attribute in a target which is not an object: ${String(target)}`
		)
	}
	return produce(target, (draft: T) => {
		let current = draft
		const parents = [current]
		for (let i = 0; i < proxyPath.length - 1; i++) {
			const segment = proxyPath[i]
			if (
				current &&
				typeof current === 'object' &&
				// $FlowIgnore[incompatible-use] it is fine if current is an array at this point
				typeof current[segment] === 'object'
			) {
				current = current[segment]
				parents.push(current)
				continue
			}
			// value being accessed already does not exist
			return target
		}

		const attributeName = proxyPath[proxyPath.length - 1]
		if (Array.isArray(current)) {
			// $FlowIgnore[prop-missing] we can mutate the object because we are using immer
			current.splice(attributeName, 1)
		} else {
			// $FlowIgnore[incompatible-use] we known current is an object
			// $FlowIgnore[cannot-write]
			delete current[attributeName]
		}

		for (let j = parents.length - 1; j >= 1; j--) {
			// $FlowIgnore[not-an-object] we known this is an object
			if (!Object.keys(parents[j]).length) {
				const parent = parents[j - 1]
				// $FlowIgnore[incompatible-use] we known parent is an object
				// $FlowIgnore[cannot-write]
				delete parent[proxyPath[j - 1]]
			}
		}
	})
}
