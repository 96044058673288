// @flow
import React from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { Switch } from '@blueprintjs/core'
import { assign, get, minimizeDelete, type ProxyData } from '../../../../utility/dotProxy'
import { useLimitedOptions, type Option } from '../../../../utility/hooks'
import { Row } from '../sharedComponents'
import { INCLUDE_EXCLUDE_OPERATIONS } from '../constants'

/**
 * MultiReactSelect - a custom input for handling multiselect with large numbers of options
 *
 * @param {Object} props - the react props
 * @param {ProxyData<?string>} props.valueProxy - a proxyData describing the location of the selected values
 * @param {(selectedValues: Array<string>) => Array<{ value: string, label: string }>} props.valueMapper - a callback to convert the selected options into the options to display
 * @param {?ProxyData<?$Keys<typeof INCLUDE_EXCLUDE_OPERATIONS>>} props.proxyOperations? - a proxyData describing the location of the operation that describe how the values should be handled
 * @param {?Array<{ value: string, label: string }>} props.options? - all the possible options that the user can pick from (undefined/null if loading options)
 * @param {number} props.optionDisplayCount? - the number of options to display to the user at once
 * @param {T} props.values - the base container of the value
 * @param {UpdateCallback<T>} props.onChange - a callback used to update the base container
 * @param {UpdateCallback<T>} props.onSearchStringChange - a callback used when the text the user is searching for changes
 * @param {boolean} props.needsMoreText - true if the user must input more text to see options, false/undefined otherwise
 *
 * @return {React$Node}
 */
export function MultiReactSelect<T>({
	proxyValue,
	defaultValue,
	proxyOperation,
	options,
	values,
	onChange,
	onSearchStringChange,
	valueMapper,
	optionDisplayCount = 100,
	needsMoreText,
}: {
	proxyValue: ProxyData<?Array<string>>,
	proxyOperation?: ProxyData<?$Keys<typeof INCLUDE_EXCLUDE_OPERATIONS>>,
	defaultValue: string,
	options: ?Array<Option>,
	values: T,
	onChange: ((oldData: T) => T) => void,
	onSearchStringChange?: (newSearchString: string) => void,
	valueMapper: (selectedValues: Array<string>) => Array<{ value: string, label: string }>,
	optionDisplayCount?: number,
	needsMoreText?: boolean,
}): React$Node {
	const selectedValues = get(proxyValue, values)

	const { options: similarOptions, reactSelectProps } = useLimitedOptions({
		options: options || [],
		maxDisplayedOptions: optionDisplayCount,
		getOptionSearchText: ({ label }) => label,
		onSearchStringChange,
		needsMoreText,
	})
	const including = proxyOperation
		? get(proxyOperation, values) !== INCLUDE_EXCLUDE_OPERATIONS.EXCLUDE
		: true
	return (
		<ReactSelectRow>
			{proxyOperation ? (
				<Switch
					checked={including}
					onChange={e => {
						onChange((oldData: T) => {
							if (e.target.checked) {
								return minimizeDelete(proxyOperation, oldData)
							}
							return assign(proxyOperation, oldData, INCLUDE_EXCLUDE_OPERATIONS.EXCLUDE)
						})
					}}
					innerLabelChecked="including"
					innerLabel="excluding"
				/>
			) : null}
			<StyledReactSelect
				value={selectedValues ? valueMapper(selectedValues) : []}
				isMulti={true}
				options={similarOptions}
				onChange={selected => {
					onChange((oldData: T) =>
						selected.length
							? assign(
									proxyValue,
									oldData,
									selected.map(({ value }) => value)
							  )
							: minimizeDelete(proxyValue, oldData)
					)
				}}
				isLoading={!options}
				className="basic-multi-select"
				classNamePrefix="select"
				placeholder={
					proxyOperation
						? including
							? 'Including All (Select Option To Filter...)'
							: 'Excluding None (Select Option To Filter...)'
						: null
				}
				getOptionLabel={({ label }) => label}
				{...reactSelectProps}
			/>
		</ReactSelectRow>
	)
}

const ReactSelectRow = styled(Row)`
	width: 100%;
	min-width: 40em;
`

const StyledReactSelect = styled(ReactSelect)`
	flex: 1 1 100px;
`
