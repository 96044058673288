// @flow
import React, { useEffect, useState } from 'react'

import { mapEntries } from '../../../utility/functions'
import {
	rolesEnum,
	friendlyRoleNamesEnum,
	type ClientDetail,
	type RoleEnum,
	type UserRole,
} from '../../../stores/types'
import { Loader, FlexWrapper, AppToaster } from '../../../components'
import styled from 'styled-components'
import 'styled-components/macro'
import { useClientStore, selectors } from '../../../stores'
import { useCurrentUser } from './../../../stores/auth'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import {
	Button,
	ButtonGroup,
	Card,
	Elevation,
	Intent,
	Tag,
	Switch,
	H4,
	Icon,
	Tooltip,
	EditableText,
} from '@blueprintjs/core'
import SchoolSearch from './SchoolSearch'
import states from '../Licenses/states'
import RoleRequest from '../RoleRequests/RoleRequest'
import type { StyledComponent } from 'styled-components'
import { useSchoolsByIds } from '../../../services/hooks'

const ClientControl: StyledComponent<{}, Theme, HTMLDivElement> = styled.div`
	color: ${props => props.theme.primary};
`

export default function Client({ id }: { id: string }): React$Node {
	const client = useClientStore(state => selectors.getClientDetail(state, id))
	const fetchClientDetails = useClientStore(selectors.getClientDetailFetch)
	const [loading, setLoading] = useState(false)
	const [isEditing, setIsEditing] = useState(false)
	const school = useSchoolsByIds(client?.schoolId == null ? [] : [client?.schoolId])?.[0]?.data
	useEffect(() => {
		fetchClientDetails(id)
	}, [id, fetchClientDetails])

	useEffect(() => {
		setIsEditing(false)
	}, [id])

	const callNetworkToUpdateClient = updatedClient => {
		setLoading(true)
		NetworkCommunicator.PUT(`users/${id}`, {
			body: {
				user: updatedClient,
			},
		})
			.then(() => {
				fetchClientDetails(id)
			})
			.catch(e => {
				console.error('could not do update for mission control', e)
				AppToaster.show({
					message: 'Failed to update user',
					intent: Intent.WARNING,
					icon: 'error',
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const DATA_POINTS = client
		? {
				totalMissionsCreated: {
					prompt: 'Total Missions Created',
					value: client.totalMissionsCreated,
				},
				totalMissionsFlown: {
					prompt: 'Total Missions Flown',
					value: client.totalMissionsFlown,
				},
				averageSatisfaction: {
					prompt: 'Average Satisfaction',
					value: `${getAverageRating(client) || 'No Survey Data'}`,
				},
				school: {
					prompt: 'School',
					value: !school
						? client.schoolName ?? '--'
						: `${school.name ?? '--'}, ${school.state ?? school.locationState ?? '--'}`,
				},
				state: {
					prompt: 'State',
					value: client.state ?? '--',
				},
				email: {
					prompt: 'Email',
					value: client.email,
				},
		  }
		: {}
	return (
		<Card css="height: fit-content;" elevation={Elevation.TWO}>
			{client && !loading ? (
				<ClientControl>
					{!isEditing ? (
						<>
							<FlexWrapper spaceBetween style={{ marginBottom: '8px' }}>
								<h2>
									{client.firstName} {client.lastName}
								</h2>
								<div>
									{client.roles.map(
										role =>
											role.verified &&
											RolesToDisplay[role.role] && (
												<Tag
													key={role.role}
													minimal
													round
													intent={RolesToDisplay[role.role].intent}
													css="margin-left: 4px;">
													{friendlyRoleNamesEnum[role.role]}
												</Tag>
											)
									)}
								</div>
							</FlexWrapper>

							{mapEntries(DATA_POINTS, (data, key) => (
								<DataPoint key={key} spaceBetween>
									<span>{data.prompt} </span>
									{data.value}
								</DataPoint>
							))}
							<div css="display: flex; justify-content: flex-end;">
								<Button onClick={() => setIsEditing(true)} css="margin-top: 8px;">
									Edit
								</Button>
							</div>
							{client.roles.some(role => roleIsPendingRoleRequest(role)) && (
								<>
									<H4>Pending Role Requests</H4>
									<div css="display: flex; flex-wrap: wrap; & > div:first-child { margin-left: 0px; }">
										{client.roles.map(
											role =>
												RolesToDisplay[role.role] &&
												roleIsPendingRoleRequest(role) && (
													<RoleRequest
														key={role.role}
														role={role.role}
														user={client}
														onResolve={() => fetchClientDetails(id)}
													/>
												)
										)}
									</div>
								</>
							)}
						</>
					) : (
						<EditClient
							client={client}
							onSave={updatedClient => {
								callNetworkToUpdateClient(updatedClient)
								setIsEditing(false)
							}}
							onCancel={() => setIsEditing(false)}
						/>
					)}
				</ClientControl>
			) : (
				<Loader />
			)}
		</Card>
	)
}

/**
 * A component that allows editing certain values on the given `client`. Provides a "Save" and "Cancel" button. Only the edited fields are
 * passed to `onSave` when the save button is pressed.
 * @param {ClientDetail} client The client to edit
 * @param {($Shape<ClientDetail>) => mixed} onSave Function that is called with the updated values when the "Save" button is pressed
 * @param {Function} onCancel Function that is called when the "Cancel" button is pressed
 */
function EditClient({
	client,
	onSave,
	onCancel,
}: {
	client: ClientDetail,
	onSave: ($Shape<ClientDetail>) => mixed,
	onCancel: () => mixed,
}): React$Node {
	const [stateAbbreviation, setStateAbbreviation] = useState<?string>(client.state)
	const [schoolId, setSchoolId] = useState(client.schoolId)
	const [roles, setRoles] = useState<UserRole[]>(client.roles)
	const [firstName, setFirstName] = useState<string>(client.firstName)
	const [lastName, setLastName] = useState<string>(client.lastName)
	const schoolIdChanged = schoolId !== client.schoolId

	return (
		<>
			<FlexWrapper spaceBetween style={{ marginBottom: '8px' }}>
				<h2>
					<EditableText
						value={firstName}
						onChange={setFirstName}
						minWidth={0}
						css="margin-right: 8px;"
					/>
					<EditableText value={lastName} onChange={setLastName} minWidth={0} />
				</h2>
			</FlexWrapper>
			<RolesEditor
				roles={roles}
				onChange={roles => setRoles(roles)}
				schoolIdChanged={schoolIdChanged}
			/>
			<SchoolSearch
				state={states.find(state => stateAbbreviation === state.abbreviation)}
				onSelectState={state => setStateAbbreviation(state?.abbreviation)}
				setPublicSchoolId={setSchoolId}
				initialSchool={
					client.schoolName && client.schoolId
						? { schoolName: client.schoolName, schoolId: client.schoolId }
						: null
				}
				inline
			/>
			<div css="display: flex; justify-content: flex-end;">
				<ButtonGroup>
					<Button onClick={onCancel}>Cancel</Button>
					<Button
						intent={Intent.PRIMARY}
						onClick={() => {
							const newValues = {}
							if (firstName && firstName !== client.firstName) {
								newValues.firstName = firstName
							}
							if (lastName && lastName !== client.lastName) {
								newValues.lastName = lastName
							}
							const schoolIdChanged = schoolId !== client.schoolId
							if (schoolIdChanged) {
								newValues.schoolId = schoolId
							}
							const rolesChanged =
								roles.length !== client.roles.length ||
								roles.some(updatedRole => everyRoleIsDifferent(updatedRole, client.roles))

							if (rolesChanged && !schoolIdChanged) {
								newValues.roles = roles
							}
							if (Object.keys(newValues).length === 0) {
								AppToaster.warning({
									message: 'No changes made',
								})
								onCancel()
								return
							}
							onSave(newValues)
						}}>
						Save
					</Button>
				</ButtonGroup>
			</div>
		</>
	)
}

// Roles that should be displayed and editable in the Client component
const RolesToDisplay = {
	[rolesEnum.DEPRECATED_SCHOOL_ADMIN]: { intent: Intent.PRIMARY },
	[rolesEnum.SCHOOL_ADMIN]: { intent: Intent.WARNING },
	[rolesEnum.DISTRICT_ADMIN]: { intent: Intent.DANGER },
}

type RolesEditorProps = {
	roles: UserRole[],
	onChange: (UserRole[]) => mixed,
	schoolIdChanged: boolean,
}

/**
 * A component that allows editing the roles in `RolesToDisplay`. Each role is displayed as a toggle. If `schoolIdChanged`,
 * all roles are displayed as unchecked, and a message is displayed to tell the user that roles can't be edited when the school
 * is changed.
 * @param {UserRole[]} role The initial roles
 * @param {(UserRole[]) => mixed} onChange Function that is called with the new roles array anytime the roles are updated
 * @param {boolean} schoolIdChanged Whether the client's school id has been changed. `true` will make the roles uneditable
 */
function RolesEditor({ roles, onChange, schoolIdChanged }: RolesEditorProps) {
	const currentUser = useCurrentUser()

	if (!currentUser) {
		return 'Cannot update roles without being signed in'
	}

	/**
	 * Add a verified role to the roles array. If the role currently exists in the array as a
	 * pending role request, does nothing.
	 */
	function addRole(role: RoleEnum) {
		const currentRoleIndex = roles.findIndex(clientRole => clientRole.role === role)

		if (currentRoleIndex !== -1) {
			if (roles[currentRoleIndex].verified || roleIsPendingRoleRequest(roles[currentRoleIndex])) {
				return
			}
			onChange([
				...roles.slice(0, currentRoleIndex),
				{ ...roles[currentRoleIndex], verified: true, verifier: currentUser.email },
				...roles.slice(currentRoleIndex + 1),
			])
			return
		}

		onChange([...roles, { role: role, verified: true }])
	}

	/**
	 * Remove a role from the roles array. If the role currently exists in the array as a
	 * pending role request, does nothing.
	 */
	function removeRole(role: RoleEnum) {
		if (
			roles.some(clientRole => clientRole.role === role && roleIsPendingRoleRequest(clientRole))
		) {
			return
		}
		onChange(
			roles
				.map(clientRole => {
					return clientRole.role === role ? null : clientRole
				})
				.filter(Boolean)
		)
	}

	return (
		<>
			{Object.keys(RolesToDisplay).map(roleName => {
				const role = roles.find(clientRole => clientRole.role === roleName)

				return (
					<div key={roleName} css="display: flex;">
						<Switch
							label={friendlyRoleNamesEnum[roleName]}
							checked={schoolIdChanged ? false : role?.verified ?? false}
							onChange={e => {
								if (e.currentTarget.checked) {
									if (role && roleIsRejected(role)) {
										const confirmed = window.confirm(
											`This role was previously rejected. Are you sure you want to give the "${friendlyRoleNamesEnum[roleName]}" role to this client?`
										)
										if (!confirmed) {
											return
										}
									}
									addRole(roleName)
								} else {
									removeRole(roleName)
								}
							}}
							disabled={schoolIdChanged || (role && roleIsPendingRoleRequest(role))}
						/>
						{role && roleIsPendingRoleRequest(role) && (
							<Tooltip
								content={`This user currently has a pending role request for "${
									friendlyRoleNamesEnum[rolesEnum[role.role]]
								}"`}>
								<Icon icon="info-sign" css="margin-left: 8px;" />
							</Tooltip>
						)}
						{role && roleIsRejected(role) && (
							<Tooltip
								content={
									<>
										The “{friendlyRoleNamesEnum[rolesEnum[role.role]]}” role was previously rejected
										by {role.verifier || 'an unknown employee'}.
										<br />
										<br />
										<b>Reason:</b> {role.reasonForRejection || 'Not given'}
									</>
								}>
								<Icon icon="warning-sign" css="margin-left: 8px;" intent={Intent.DANGER} />
							</Tooltip>
						)}
					</div>
				)
			})}
			{schoolIdChanged && (
				<i>
					Cannot update roles while updating a client’s school. These roles will all be removed.
				</i>
			)}
		</>
	)
}

/**
 * Whether the given role was previously rejected
 */
function roleIsRejected(role: UserRole): boolean {
	return !role.verified && role.reasonForRejection != null
}

/**
 * Whether the given role would represent a pending role request
 */
function roleIsPendingRoleRequest(role: UserRole): boolean {
	return !role.verified && role.reasonForRejection == null
}

/**
 * Tells whether the given `role` is different from every role in the `rolesArray`.
 */
function everyRoleIsDifferent(role: UserRole, rolesArray: UserRole[]): boolean {
	return rolesArray.every(
		roleFromArray => role.role !== roleFromArray.role || role.verified !== roleFromArray.verified
	)
}

/**
 *
 * @param {*} client
 */
function getAverageRating(client: ClientDetail): ?number {
	const length = client.surveyResponses.length
	const total = client.surveyResponses.reduce(
		(rating, response) => rating + response.missionRating,
		0
	)
	return length === 0 ? null : Math.round((total / length) * 10) / 10
}

const DataPoint = styled(FlexWrapper)`
	padding-top: 8px;
`
