import create from 'zustand'
import type { ClientType, SchoolType, ClientDetail } from './types'
import { values, mapEntries, toIdMap } from '../utility/functions'
import NetworkCommunicator from '../services/NetworkCommunicator'

export type Store = {
	clientMap: ?IdMap<ClientType>,
	clientDetail: ?ClientDetail,
	schools: ?(SchoolType[]),
	fetch: () => void,
	fetchDetailForOne: (clientId: string) => void,
}

const [useStore, api] = create((set): Store => ({
	clientMap: {},
	clientDetail: null,
	schools: [],
	fetch: () => {
		set({ clients: [] })
		NetworkCommunicator.GET('users')
			.then(response => {
				let schools = null
				let clients: ?(ClientType[]) = response.users
				if (clients) {
					schools = mapClientSchools(clients)
				}
				set({ clientMap: toIdMap(clients || []), schools })
			})
			.catch(err => console.error(err))
	},
	fetchDetailForOne: (clientId: string) => {
		set({ clientDetail: null })
		NetworkCommunicator.GET(`users/${clientId}`)
			.then(response => {
				set({ clientDetail: response.user })
			})
			.catch(err => console.error(err))
	},
}))

export const selectors = {
	getClients: (state: Store, schoolId: ?string): ClientType[] => {
		const clients: ClientType[] = state.clientMap ? values(state.clientMap) : []
		clients.sort((a, b) => sortByField(a.lastName, b.lastName))
		return schoolId ? clients.filter(client => client.schoolId === schoolId) : clients
	},
	getSchools: (state: Store): SchoolType[] => {
		return state.schools || []
	},
	getClientDetail: (state: Store, clientId: ?string) => {
		const clientDetail = state.clientDetail
		if (clientDetail && clientDetail._id !== clientId) return null
		return clientDetail
	},
	getClientFetch: (state: Store) => {
		return state.fetch
	},
	getClientDetailFetch: (state: Store) => {
		return state.fetchDetailForOne
	},
	getClient: (state: Store, clientId: string): ?ClientType => {
		return state.clientMap ? state.clientMap[clientId] : null
	},
}

function mapClientSchools(clients: ClientType[]): SchoolType[] {
	const schools = {}
	clients.forEach(({ schoolId, schoolName }: ClientType) => {
		if (schoolId && schoolName && !schools[schoolId]) {
			schools[schoolId] = { schoolId, schoolName }
		}
	})
	return mapEntries(schools, school => school).sort((a, b) =>
		sortByField(a.schoolName, b.schoolName)
	)
}

function sortByField(a: string, b: string): number {
	if (a < b) return -1
	if (a > b) return 1
	return 0
}

export { api as __test_api }

export default useStore
