import React from 'react'
import { Link } from 'react-router-dom'
import 'styled-components/macro'
import { Card, Classes, H3 } from '@blueprintjs/core'
import styled from 'styled-components'
import { Copiable, FlexWrapper } from '../../components'
import type { SimpleTeam } from '../../resources/employees'

export default function TeamList({ teams }: { teams: SimpleTeam[] }): React$Node {
	return (
		<>
			{teams.map((team: SimpleTeam) => {
				return (
					<React.Fragment key={team.id}>
						<StyledH3>
							{team.id ? <Link to={`teams/${team.id}`}>{team.name}</Link> : team.name}
						</StyledH3>
						<Team>
							{team.members.map(employee => {
								return (
									<EmployeeCard key={employee.id}>
										<FlexWrapper spaceBetween>
											<Link to={`/employees/${employee.id}`}>
												{employee.firstName} {employee.lastName}
											</Link>{' '}
											<LabelsList values={employee.teams} /> <Copiable value={employee.email} />
										</FlexWrapper>
									</EmployeeCard>
								)
							})}
						</Team>
					</React.Fragment>
				)
			})}
		</>
	)
}

function LabelsList({ values }: { values: Array<{ name: string, id: string }> }) {
	return (
		<div css="align-self: center;">
			{values.map(value => (
				<span key={`${value.id}-label`} css="margin-left: 4px;" className={Classes.TAG}>
					{value.name}
				</span>
			))}
		</div>
	)
}

const Team = styled.div`
	width: 100%;
	align-items: center;
	a {
		width: 20%;
		text-align: left;
	}
`

const EmployeeCard = styled(Card)`
	width: 100%;
	margin-bottom: 8px;
`

const StyledH3 = styled(H3)`
	&& {
		color: ${({ theme }) => theme.primary};
	}
`
