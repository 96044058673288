import type { User } from '../../../stores/auth'
import type { SimpleOkr, OkrOwner } from '../../../stores/types'
import type { QueryParams, Owner } from './types'
import { getYearAndQuarter, makeQuarterString } from '../../../utility/functions'

const FOUNDER_ENUM = 'FOUNDER'

/**
 * Given the query params from the url, we can determine who owns the OKRs currently shown on the page.
 * This function gets an Owner object with some details of the owner.
 *
 * @return {?Owner} The owner of the OKRs on the current page. If the user cannot create
 * 						OKRs for the current page, this function returns null
 */
export function getOkrOwnerFromFilters(query: QueryParams, user: User): Owner | null {
	// Default to myself
	let displayName = user.displayName
	let type = 'EMPLOYEE'
	let id = user.id

	const { company, teamId, employeeId } = query

	if (company) {
		if (!user.roles.includes(FOUNDER_ENUM)) {
			return null
		}
		displayName = 'Company'
		type = 'COMPANY'
		id = 1
	} else if (teamId) {
		const team = user.teams.find(team => team.id === teamId)
		if (!team) {
			return null
		}
		displayName = team.name
		type = 'TEAM'
		id = teamId
	} else if (employeeId && Number(employeeId) !== user.id) {
		return null
	}

	return {
		displayName,
		type,
		id,
	}
}

/**
 * Gets the current quarter in the quarter format '2019Q4'
 */
export function getCurrentQuarter(): string {
	const [year, quarter] = getYearAndQuarter()
	return makeQuarterString(year, quarter)
}

/**
 * Given an employee and okr owner, determines whether that employee is associated with the given owner
 * @param {User} user
 * @param {OkrOwner} owner
 */
export function userIsOwner(user: User, owner: OkrOwner): boolean {
	return (
		(owner.type === 'EMPLOYEE' && owner.id === user.id) ||
		(owner.type === 'TEAM' && user.teams.some(team => team.id === owner.id)) ||
		(owner.type === 'COMPANY' && isUserFounder(user))
	)
}

/**
 * checks if a user owns an okr. The user owns the okr if:
 * 1. Any of the okr's owners are the user itself
 * 2. Any of the okr's owners are teams that the user is a part of,
 * 3. Any of the okr's owners are the company and the user has a FOUNDER role
 */
export function userOwnsOkr(user: User, okr: $ReadOnly<{ owners?: ?(OkrOwner[]) }>): boolean {
	return !!okr.owners && okr.owners.some(owner => userIsOwner(user, owner))
}

/**
 * Determines whether the user is founder
 * @param {User} user
 */
export function isUserFounder(user: User): boolean {
	return user.roles.includes(FOUNDER_ENUM)
}
/**
 * Tells whether the given employee can delete an okr
 * If the okr has multiple owners, (i.e. the EMPLOYEE user is not the sole owner of the okr)
 * 	 this function returns false.
 * If the first owner of the okr is the company and the user has a FOUNDER role,
 * 	 this function returns true
 */
export function canDeleteOkr(user: User, okr: SimpleOkr): boolean {
	if (okr.owners?.length !== 1) {
		return false
	}

	const soleOwner = okr.owners?.[0]
	if (!soleOwner) {
		return false
	}
	if (soleOwner.type === 'COMPANY') return isUserFounder(user)
	return soleOwner.id === user.id && soleOwner.type === 'EMPLOYEE'
}
