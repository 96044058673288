import React from 'react'
import ReactSelect, { createFilter } from 'react-select'
import states from '../../routes/Clients/Licenses/states'

type StateValue = {
	name: string,
	abbreviation: string,
	stateCode: string,
}

type StateSelectorProps = {
	onChangeState: StateValue => StateValue | void,
	className?: string,
	classNamePrefix?: string,
	stateValue?: ?string,
}

/**
 * This component for searching and returning States
 * @param {function} onChangeState call back thats returns StateValue
 * @param {string | null} stateValue string that much the name of the state
 * @param {string} className class of the select container
 * @param {string} classNamePrefix class of the select itself
 */
export default function StateSelector({
	onChangeState,
	className = 'react-select-container',
	classNamePrefix = 'react-select',
	stateValue = null,
}: StateSelectorProps): React$Node {
	return (
		<ReactSelect
			inputId="state"
			name="state"
			value={{ name: [stateValue] }}
			onChange={onChangeState}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.abbreviation}
			options={states}
			filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
			isClearable
			isSearchable
			className={className}
			classNamePrefix={classNamePrefix}
		/>
	)
}
