import React from 'react'
import styled from 'styled-components'
import { FlexWrapper, Loader } from '../../../components'
import { usePastMissions } from '../../../stores/missions'
import { MissionListItem } from './shared'

const List = styled(FlexWrapper)`
	overflow-y: auto;
	overflow-x: hidden;
`

export default function Results({
	selectedMissionId,
	onSelectMission,
}: {
	selectedMissionId: string,
	onSelectMission: string => mixed,
}): React$Node {
	const { missions } = usePastMissions()

	return (
		<List column>
			{missions ? (
				missions.length > 0 ? (
					missions.map(mission => (
						<MissionListItem
							key={mission._id}
							mission={mission}
							selected={selectedMissionId === mission._id}
							onSelect={() => onSelectMission(mission._id)}
						/>
					))
				) : (
					<FlexWrapper alignCenter>
						<h3>No missions found</h3>
					</FlexWrapper>
				)
			) : (
				<Loader />
			)}
		</List>
	)
}
