import React, { useState } from 'react'
import { Select, PageContainer, FlexWrapper, Copiable, Tabs } from '../../components'
import { useRestorableState } from '../../utility/hooks'
import { Button, EditableText, Divider, Card, Tag, MenuItem } from '@blueprintjs/core'
import { AppToaster } from '../../components'
import { DatePicker } from '@blueprintjs/datetime'
import { MultiSelect } from '@blueprintjs/select'
import 'styled-components/macro'
import { prettifyTypeEnum } from '../../utility/functions'
import { OkrsList, CheckpointsWeek } from '../Goals'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import { useTeams, useUpdateEmployeeTeams } from './../../resources/employees'
import styled from 'styled-components'

import { getCurrentQuarter, getOkrOwnerFromFilters, isUserFounder } from '../Goals/Okrs/helpers'
import type { User } from '../../stores/auth'
import type { EmployeeType } from '../../stores/types'

export function updateEmployee(
	id: number,
	body: { birthday?: string, phoneNumber?: string, tShirtSize?: string }
): Promise<any> {
	return NetworkCommunicator.PUT(`employees/${id}`, {
		body: { ...body },
	})
}

export function handleUpdateEmployee(
	id: number,
	birthday?: Date,
	phoneNumber?: string,
	tShirtSize?: string,
	birthdayUpdateFallback: Date => void,
	phoneNumberUpdateFallback: string => void,
	shirtUpdateFallback: string => void,
	setIsEditingDate: boolean => void
) {
	let body = {}
	if (birthday) {
		const birthdayString = birthday.toISOString().slice(0, 10)
		body.birthday = birthdayString
	}
	if (phoneNumber) {
		body.phoneNumber = phoneNumber
	}
	if (tShirtSize) {
		body.tShirtSize = tShirtSize
	}

	updateEmployee(id, body)
		.then(() => {
			if (tShirtSize) {
				shirtUpdateFallback(tShirtSize)
			}
			if (birthday) {
				birthdayUpdateFallback(birthday)
			}
			if (phoneNumber) {
				phoneNumberUpdateFallback(phoneNumber)
			}
			setIsEditingDate(false)
		})
		.catch(() => {
			AppToaster.show({
				message:
					'Failed to update employee data. Make sure you have the correct permissions and try again',
				intent: 'danger',
			})
		})
}

const getTabs = (employee: EmployeeType, currentUser: User) => [
	{
		id: 'CHECKPOINT_TAB',
		panel: (
			<CheckpointsWeek
				checkpoints={employee.checkpoints}
				allowCreateDelete={currentUser.id === employee.id}
				allowUpdate={currentUser.id === employee.id}
			/>
		),
		title: 'Current Checkpoints',
	},
	{
		id: 'OKR_TAB',
		panel: (
			<OkrsList
				queryOverrides={{ employeeId: employee.id }}
				okrs={employee.okrs}
				owner={getOkrOwnerFromFilters({}, currentUser)}
				quarter={getCurrentQuarter()}
				allowOkrCreation={currentUser.id === employee.id}
				currentUser={currentUser}
			/>
		),
		title: 'Okrs',
	},
]

export default function EmployeeInfo({
	employee,
	currentUser,
}: {
	employee: EmployeeType,
	currentUser: User,
}): React$Node {
	const [
		shirtSize,
		setShirtSize,
		restoreShirtSize,
		shirtUpdateFallback,
		isShirtSizeUnchanged,
	] = useRestorableState(employee.tShirtSize ? employee.tShirtSize : 'Unknown')
	const [
		birthday,
		setBirthday,
		restoreBirthday,
		birthdayUpdateFallback,
		isBirthdayUnchanged,
	] = useRestorableState(employee.birthday ? new Date(employee.birthday) : null)
	const [
		phoneNumber,
		setPhoneNumber,
		restorePhoneNumber,
		phoneNumberUpdateFallback,
		isPhoneNumberUnchanged,
	] = useRestorableState(employee.phoneNumber ? employee.phoneNumber : 'Unknown')
	const [isEditingDate, setIsEditingDate] = useState(false)

	const tabs = getTabs(employee, currentUser)

	const employeeData = [
		{ label: 'Email', value: <Copiable value={employee.email} /> },
		{
			label: 'Employee Number',
			value: employee.employeeNumber,
			hidden: !employee.employeeNumber,
		},
		{
			label: 'Teams',
			value: <TeamsList employee={employee} canEdit={isUserFounder(currentUser)} />,
		},
		{
			label: 'Roles',
			value:
				employee.roles &&
				employee.roles.length > 0 &&
				employee.roles.map(role => (
					<Tag className="spaced" key={role}>
						{prettifyTypeEnum(role)}
					</Tag>
				)),
			hidden: !employee.roles || employee.roles.length === 0,
		},
	]

	const canEditData = employee.id === currentUser.id

	return (
		<PageContainer column>
			<Grid>
				<h1 css="grid-area: header; text-align: center;">
					{employee.firstName} {employee.lastName}
				</h1>
				<Info>
					{employeeData.map(
						(data, index) =>
							!data.hidden && (
								<div key={`employee-data-${index}`} css="margin: 8px 0px;">
									<FlexWrapper spaceBetween>
										<label>{data.label}:</label>
										<div>{data.value}</div>
									</FlexWrapper>
									<Divider />
								</div>
							)
					)}
					<Wrapper spaceBetween>
						<label>Phone Number:</label>
						<EditableText
							value={phoneNumber}
							onChange={setPhoneNumber}
							disabled={!canEditData}
							css="span{display:flex; justify-content: flex-end;}"
						/>
					</Wrapper>
					<Divider />
					<Wrapper spaceBetween>
						<label css="padding: 4px 0px">T-Shirt Size:</label>
						{canEditData ? (
							<Select
								css="text-align:right;"
								value={shirtSize}
								disabled={!canEditData}
								onChange={value => {
									if (value) {
										setShirtSize(value)
									}
								}}
								placeholder={shirtSize || "Men's S"}
								options={[
									"Men's S",
									"Men's M",
									"Men's L",
									"Men's XL",
									"Men's XXL",
									"Men's XXXL",
									"Women's XS",
									"Women's S",
									"Women's M",
									"Women's L",
									"Women's XL",
									"Women's XXL",
									"Women's XXXL",
								]}
								getOptionLabel={size => size}
								filterable={false}></Select>
						) : (
							<div css="text-align:right; padding: 4px 2px 4px 0px">{shirtSize}</div>
						)}
					</Wrapper>
					<Divider />
					<Wrapper spaceBetween>
						<label css="padding: 5px 0px">Birthday:</label>
						{canEditData && isEditingDate ? (
							<DatePicker
								onChange={value => {
									setBirthday(value)
								}}
								value={birthday}
								minDate={new Date(1910, 1, 1)}
								maxDate={new Date(new Date().getFullYear(), 11, 31)}
								css="text-align:right;"></DatePicker>
						) : (
							<Birthday
								useHover={canEditData}
								onClick={() => {
									if (canEditData) {
										setIsEditingDate(true)
									}
								}}>
								{birthday
									? [
											birthday.getUTCMonth() + 1,
											birthday.getUTCDate(),
											birthday.getUTCFullYear(),
									  ].join('/')
									: 'Unknown'}
							</Birthday>
						)}
					</Wrapper>
					<Divider />
					{!(isShirtSizeUnchanged && isPhoneNumberUnchanged && isBirthdayUnchanged) && (
						<>
							<Button
								icon="saved"
								onClick={() => {
									handleUpdateEmployee(
										employee.id,
										!isBirthdayUnchanged && birthday ? birthday : undefined,
										!isPhoneNumberUnchanged ? phoneNumber : undefined,
										!isShirtSizeUnchanged ? shirtSize : undefined,
										birthdayUpdateFallback,
										phoneNumberUpdateFallback,
										shirtUpdateFallback,
										setIsEditingDate
									)
								}}>
								Save
							</Button>
							<Button
								icon="ban-circle"
								onClick={() => {
									restoreShirtSize()
									restorePhoneNumber()
									restoreBirthday()
									setIsEditingDate(false)
								}}>
								Cancel
							</Button>
						</>
					)}
				</Info>
				<Card css="grid-area: main">
					<Tabs tabs={tabs} />
				</Card>
			</Grid>
		</PageContainer>
	)
}

const Grid = styled.div`
	display: grid;
	height: 80%;
	grid-template:
		'header header' auto
		'info main' 1fr
		/ 25% 1fr;
	grid-gap: ${({ theme }) => theme.spacing};
`

const Wrapper = styled(FlexWrapper)`
	height: auto;
`

const Birthday = styled.div`
	text-align: right;
	margin: 1px 0px;
	padding: 3px 4px;
	border-radius: 2px;
	border-style: solid;
	border-width: thin;
	border-color: transparent;
	${({ useHover }) => (useHover ? `:hover{border-color: silver}` : ``)}
`

const Info = styled(Card)`
	overflow-y: auto;
	height: 100%;
	grid-area: info;
	.spaced {
		margin: 2px;
	}
`

/**
 * A list of the given employee's teams. If `canEdit` is set to true, the list becomes editable
 * @param {boolean} props.canEdit Whether the teams list is editable
 * @param {EmployeeType} props.employee The employee for this teams list
 */
function TeamsList({ canEdit = false, employee }: { canEdit?: boolean, employee: EmployeeType }) {
	const { teams: allTeams } = useTeams({ includeUnassigned: false })
	const [isEditing, setIsEditing] = useState(false)
	const [teams, setTeams, restoreTeams, updateTeamsFallback] = useRestorableState(employee.teams)

	const updateTeamsMutation = useUpdateEmployeeTeams()

	function isTeamSelected(team) {
		return teams.findIndex(t => team.id === t.id) > -1
	}

	return (
		<div css="display: flex; align-items: start;">
			{isEditing ? (
				<MultiSelect
					css="align-self: center;"
					selectedItems={teams}
					items={allTeams}
					tagRenderer={team => team.name}
					noResults={'Employee is on all teams'}
					itemRenderer={(team, { modifiers, handleClick }) =>
						isTeamSelected(team) ? null : (
							<MenuItem
								onClick={handleClick}
								text={team.name}
								icon={isTeamSelected(team) ? 'tick' : 'blank'}
								disabled={modifiers.disabled}
								active={modifiers.active}
							/>
						)
					}
					itemsEqual="id"
					onItemSelect={team => {
						setTeams(teams => [...teams, { id: team.id, name: team.name }])
					}}
					tagInputProps={{
						onRemove(_, i) {
							setTeams(teams => {
								const newTeams = [...teams]
								newTeams.splice(i, 1)
								return newTeams
							})
						},
						disabled: !isEditing,
					}}
				/>
			) : teams.length > 0 ? (
				<div css="display: flex; flex-wrap: wrap;">
					{teams.map(team => (
						<Tag className="spaced" key={team.id}>
							{team.name}
						</Tag>
					))}
				</div>
			) : (
				<span css="margin-right: 8px; align-self: center;">None</span>
			)}
			{canEdit && (
				<div>
					<Button
						small
						icon={isEditing ? 'saved' : 'edit'}
						onClick={() => {
							if (isEditing) {
								updateTeamsMutation.mutate(
									{ employeeId: employee.id, teams },
									{
										onSuccess: () => {
											updateTeamsFallback(teams)
										},
										onError: () => {
											restoreTeams()
											AppToaster.danger({ message: 'Failed to update teams' })
										},
									}
								)
							}
							setIsEditing(editing => !editing)
						}}
					/>
					{isEditing && (
						<Button
							small
							icon="cross"
							onClick={() => {
								restoreTeams()
								setIsEditing(false)
							}}
						/>
					)}
				</div>
			)}
		</div>
	)
}
