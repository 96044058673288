// @flow
import React from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import type { Option } from '../../../../../../utility/hooks'
import { useLimitedOptions } from '../../../../../../utility/hooks'

/**
 * MassiveMultiSelect - a wrapper around ReactSelect to handle massive amounts of options
 *
 * @param {Object} props - the react props
 * @param {?Array<string>} props.selectedOptions - the current selected values
 * @param {?Array<Option>} props.options - the available options
 * @param {(selectedValues: ?Array<string>) => any}  props.onChange - a callback when the selected values changes
 * @param {(newSearchString: string) => any} props.onSearchStringChange - a callback for when the search string changes
 * @param {(valuesToGetOptionsFor: string) => Array<Option>} props.valueMapper - a callback used for mapping values to options
 * @param {?number} props.optionDisplayCount? - the maximum number of options to display
 * @param {?boolean} props.needsMoreText? - set to true if needs more text to generate options, false or not set otherwise
 *
 * @return {React$Node}
 */
export function MassiveMultiSelect({
	selectedOptions,
	options,
	onChange,
	onSearchStringChange,
	valueMapper,
	optionDisplayCount = 100,
	needsMoreText,
}: {
	selectedOptions: ?Array<string>,
	options: ?Array<Option>,
	onChange: (selectedValues: ?Array<string>) => any,
	onSearchStringChange?: (newSearchString: string) => any,
	valueMapper: (selectedValues: Array<string>) => Array<Option>,
	optionDisplayCount?: number,
	needsMoreText?: boolean,
}): React$Node {
	const { options: similarOptions, reactSelectProps } = useLimitedOptions({
		options: options || [],
		maxDisplayedOptions: optionDisplayCount,
		getOptionSearchText: ({ label }) => label,
		onSearchStringChange,
		needsMoreText,
	})
	return (
		<StyledReactSelect
			value={selectedOptions ? valueMapper(selectedOptions) : []}
			isMulti={true}
			options={similarOptions}
			onChange={selected => {
				onChange(selected.map(({ value }) => value))
			}}
			isLoading={!options}
			className="basic-multi-select"
			classNamePrefix="select"
			placeholder={'Select Options To Filter...'}
			getOptionLabel={({ label }) => label}
			{...reactSelectProps}
		/>
	)
}

const StyledReactSelect = styled(ReactSelect)`
	flex: 1 1 100px;
	min-width: 20vw;
`
