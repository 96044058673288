import React from 'react'
import shallow from 'zustand/shallow'
import { Button, Intent } from '@blueprintjs/core'
import { FlexWrapper } from '../../../components'
import type { Filter, FilterType } from '../../../stores/types'
import { mapEntries } from '../../../utility/functions'
import { CHECK_BOXES, DROP_DOWNS, Calendar, CheckboxFilter, Dropdown } from './filters'
import styled from 'styled-components'
import 'styled-components/macro'

import { useFilterStore, selectors } from '../../../stores'
import { usePastMissions } from '../../../stores/missions'

export default function SearchBar(): React$Node {
	const filters = useFilterStore(selectors.getActiveFilters)
	const [addGroup, removeKeys] = useFilterStore(
		state => [state.addGroup, state.removeKeys],
		shallow
	)

	const { refetch: fetchPastMissions } = usePastMissions({ enabled: false, filters: filters })

	const sharedProps: { select: (IdMap<Filter>) => void, unselect: (FilterType[]) => void } = {
		select: filters => addGroup(filters),
		unselect: filterKeys => removeKeys(filterKeys),
	}

	return (
		<FlexWrapper row>
			<FlexWrapper column>
				<Calendar {...sharedProps} />
				<FilterGroup>
					{mapEntries(CHECK_BOXES, value => (
						<CheckboxFilter
							key={value.type}
							{...{ value, filterType: value.type, ...sharedProps }}
						/>
					))}
				</FilterGroup>
			</FlexWrapper>
			<div css="width: 100%;">
				<FilterGroup>
					{mapEntries(DROP_DOWNS, value => (
						// $FlowFixMe Flow can't tell which value type, school or client is being passed to the drop down
						<Dropdown key={value.type} {...{ value, filterType: value.type, ...sharedProps }} />
					))}
					<Button intent={Intent.PRIMARY} onClick={fetchPastMissions}>
						Run Search
					</Button>
				</FilterGroup>
			</div>
		</FlexWrapper>
	)
}

const FilterGroup = styled(FlexWrapper)`
	padding-left: 12px;
	flex-direction: column;
	justify-content: space-around;
`
