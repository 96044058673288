import React from 'react'
import styled from 'styled-components'
import 'styled-components/macro'
import { Icon, Classes } from '@blueprintjs/core'

import SimpleOkr from './SimpleOkr'
import MainOkr from './MainOkr'
import { userOwnsOkr } from './helpers'
import type { ParentOkr } from '../../../stores/types'
import type { FullOkr } from './types'
import type { User } from '../../../stores/auth'

const ParentsContainer = styled.div`
	display: flex;
	flex-direction: column-reverse;
	width: 80%;
	.${Classes.CARD} {
		padding: 8px;
		a {
			opacity: 0.6;
		}
	}
`

export default function OkrPage({
	okr,
	currentUser,
}: {
	okr: FullOkr,
	currentUser: User,
}): React$Node {
	const allowOkrCreation = userOwnsOkr(currentUser, okr)
	return (
		<div>
			{okr.parent && (
				<ParentsContainer>
					<Parents okr={okr.parent} currentUser={currentUser} />
				</ParentsContainer>
			)}
			<MainOkr okr={okr} canUpdate={allowOkrCreation} currentUser={currentUser} />
		</div>
	)
}

function Parents({ okr, currentUser }: { okr: ParentOkr, currentUser: User }) {
	return (
		<>
			<ConnectorIcon />
			<SimpleOkr okr={okr} canUpdate={userOwnsOkr(currentUser, okr)} />
			{okr.parent && <Parents okr={okr.parent} currentUser={currentUser} />}
		</>
	)
}

function ConnectorIcon() {
	return <Icon icon="arrow-down" css="margin-left: 10%; color: rgba(16, 22, 26, .15);" />
}
