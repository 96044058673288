const brandColors = {
	brandDark: '#182026',
	brandGrey: '#5C7080',
	brandWhite: '#F5F8FA',
	brandBlack: '#10161A',
}

const fontSans = "'Source Sans Pro', Arial, sans-serif"

const theme: Theme = {
	/* Color Theme */
	...brandColors,
	primary: '#106BA3',
	secondary: '#BFCCD6',
	accent: '#48AFF0',
	danger: '#A82A2A',
	white: 'white',

	// Typography
	fontMono: "'Courier' monospace",
	fontSans,
	fontSerif: "'Bree Serif', Times, serif",
	baseFontColor: brandColors.brandBlack,
	baseFontFamily: fontSans,
	baseFontSize: '16px',
	fontNormal: 400,
	fontSemibold: 600,
	fontBold: 700,
	baseLineHeight: 1.5,
	headings: '(6, 5, 4, 3, 2, 1)',

	// Borders
	baseBorder: `1px solid ${brandColors.brandGrey}`,
	baseRadius: '0.25rem',

	// Shadows
	baseBoxShadow: `rgba(144, 137, 125, 0.2) 0 0 8px`,

	// Spacing
	spacing: '8px',
	spacing2x: '16px',
	spacing3x: '24px',
	spacingHalf: '4px',
}

export default theme
