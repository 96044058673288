import React from 'react'

import type { Option } from '../../../../../../utility/hooks'
import type { ClientType } from '../../../../../../stores/types'

import { useClients } from '../../../../../../services/hooks'
import { toIdMap } from '../../../../../../utility/functions'
import { useMemo } from 'react'
import { MassiveMultiSelect } from './MassiveMultiSelect'

/**
 * UserSelector - a form which allows the user to select users from a searchable multi-select box
 *
 * @param {Object} props - the react props
 * @param {?Array<string>} props.selection - the selected userIds
 * @param {(newSelection: ?Array<string>) => any} props.onChange - a callback when the selection changes
 *
 * @returns React$Node
 */
export default function UserSelector({
	selection,
	onChange,
}: {
	selection: ?Array<string>,
	onChange: (newSelection: ?Array<string>) => any,
}): React$Node {
	const { data: clients } = useClients()
	const [clientLookup, clientOptions] = useMemo(
		() => [
			toIdMap(clients || []),
			clients?.map(clientToOption).sort((a, b) => a.label.localeCompare(b.label)),
		],
		[clients]
	)
	return (
		<MassiveMultiSelect
			selectedOptions={selection ?? []}
			onChange={onChange}
			valueMapper={values =>
				values.map(value =>
					clientToOption(
						clientLookup[value] ?? {
							_id: value,
							email: 'unknown',
							firstName: 'unknown user',
							lastName: value,
						}
					)
				)
			}
			options={clientOptions}
		/>
	)
}

/**
 * clientToOption - convert a client object to a select input option
 *
 * @param {ClientType} client - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function clientToOption(client: ClientType): Option {
	return {
		value: client._id,
		label: `${client.firstName} ${client.lastName} (${client.email} - ${client.schoolName ??
			'Unknown School'})`,
	}
}
