import type { AlertFormData, BasicAlertData, InDepthAlertData } from './types'
import type { UseMutationResult, UseQueryResult } from 'react-query'

import { AppToaster } from '../../../components/basic/AppToaster'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { useMutation, useQuery, useQueryClient } from 'react-query'

/**
 * useAlerts - get an overview list of all the alerts in the database
 *
 * @returns UseQueryResult<BasicAlertData[]>
 */
export function useAlerts(): UseQueryResult<BasicAlertData[]> {
	return useQuery(
		['alerts'],
		() => NetworkCommunicator.GET('alerts').then(res => res.baseAlertData),
		{
			staleTime: Infinity,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
			onError: () => {
				AppToaster.danger({
					message: 'Failed to get alerts',
				})
			},
		}
	)
}
/**
 * useAlert - get the in depth data about the alert with the given alertId
 *
 * @param  {string} alertId - the id of the alert to get information for
 *
 * @returns UseQueryResult<InDepthAlertData>
 */
export function useAlert(alertId: string): UseQueryResult<InDepthAlertData> {
	return useQuery(
		['alert', alertId],
		() => NetworkCommunicator.GET(`alerts/${alertId}`).then(res => res.alertData),
		{
			staleTime: Infinity,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
			onError: () => {
				AppToaster.danger({
					message: `Failed to get alert ${alertId}`,
				})
			},
		}
	)
}

/**
 * useRefreshAlert - a hook used to rerun the criteria of an alert and update the alert's table
 *
 * @param  {string} alertId - the id of the alert to update
 *
 * @returns UseMutationResult<any, any, any>
 */

export function useRefreshAlert(alertId: string): UseMutationResult<any, any, any> {
	const queryClient = useQueryClient()
	return useMutation(
		['alert', alertId, 'refresh'],
		() => NetworkCommunicator.GET(`alerts/${alertId}/refresh`),
		{
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['alerts'] })
				queryClient.invalidateQueries({ queryKey: ['alert', alertId] })
			},
			onError: () => {
				AppToaster.danger({
					message: 'Failed to update alert list',
				})
			},
		}
	)
}

/**
 * useSaveAlert - a callback used to save the alert with the new field data
 *
 * @param  {string} alertId - the id of the alert to save
 * @param  {()=>any} onSettled - a callback when the promise has settled
 *
 * @returns UseMutationResult<AlertFormData, any, any>
 */

export function useSaveAlert(
	alertId: string,
	onSettled: () => any
): UseMutationResult<AlertFormData, any, any> {
	const queryClient = useQueryClient()
	return useMutation(
		['alert', alertId, 'save'],
		(alertData: AlertFormData) =>
			NetworkCommunicator.POST(`alerts/${alertId}`, { body: alertData }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['alerts'] })
				queryClient.invalidateQueries({ queryKey: ['alert', alertId] })
			},
			onSettled,
			onError: () => {
				AppToaster.danger({
					message: 'Failed to update alert',
				})
			},
		}
	)
}

/**
 * useCreateAlert - a hook used to create an alert
 *
 * @param  {(alertId:string)=>any} onSuccess - a callback for when the alert has been updated
 *
 * @returns UseMutationResult<AlertFormData, any, any>
 */
export function useCreateAlert(
	onSuccess: (alertId: string) => any
): UseMutationResult<AlertFormData, any, any> {
	const queryClient = useQueryClient()
	return useMutation(
		['alert', 'create'],
		(newAlertData: AlertFormData) =>
			NetworkCommunicator.POST('alerts', { body: newAlertData }).then(res =>
				onSuccess(res.newAlertId)
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['alerts'] })
			},
			onError: () => {
				AppToaster.danger({
					message: 'Failed to create alert',
				})
			},
		}
	)
}

/**
 * useDeleteAlert - a mutation hook to delete the given alert
 *
 * @param {string} alertId - the id of the alert to delete
 *
 * @returns UseMutationResult<any, any, any>
 */
export function useDeleteAlert(alertId: string): UseMutationResult<any, any, any> {
	const queryClient = useQueryClient()
	return useMutation(
		['alert', alertId, 'delete'],
		(alertData: AlertFormData) => NetworkCommunicator.DELETE(`alerts/${alertId}`),
		{
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['alerts'] })
				queryClient.invalidateQueries({ queryKey: ['alert', alertId] })
			},
			onError: () => {
				AppToaster.danger({
					message: 'Failed to delete alert',
				})
			},
		}
	)
}
