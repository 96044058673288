import * as missionCategoryIcons from '../../../../assets/icons/missionCategories'
import { MISSION_CATEGORIES } from '../../../../stores/types'

export const MissionCategoryIconMap: { [missionCategory: string]: () => React$Node } = {
	[MISSION_CATEGORIES.ON_SITE]: missionCategoryIcons.OnSiteMissionIcon,
	[MISSION_CATEGORIES.WHOLE_CLASS_REMOTE]: missionCategoryIcons.RemoteMissionIcon,
	[MISSION_CATEGORIES.SQUADRON_REMOTE]: missionCategoryIcons.RemoteMissionIcon,
	[MISSION_CATEGORIES.SQUADRON_HOMEWORK]: missionCategoryIcons.RemoteMissionIcon,
}

export const MissionCategoryToReadableCategoryMap: { [missionCategory: string]: string } = {
	[MISSION_CATEGORIES.ON_SITE]: `In Class`,
	[MISSION_CATEGORIES.WHOLE_CLASS_REMOTE]: `Remote`,
	[MISSION_CATEGORIES.SQUADRON_REMOTE]: `Group Remote`,
	[MISSION_CATEGORIES.SQUADRON_HOMEWORK]: `Group Homework`,
}
