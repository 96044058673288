import { Position, Toaster, Intent } from '@blueprintjs/core'

/** Singleton toaster instance. Create separate instances for different options. */
const toaster = Toaster.create({
	position: Position.BOTTOM_RIGHT,
})

export const AppToaster = {
	show: (arg: mixed) => {
		toaster.show(arg)
	},
	warning: (arg: {}) => {
		toaster.show({ ...arg, intent: Intent.WARNING })
	},
	danger: (arg: {}) => {
		toaster.show({ ...arg, intent: Intent.DANGER })
	},
}
