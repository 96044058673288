import React, { useReducer, useState, useRef } from 'react'
import uuid from 'uuid/v4'
import { useQueryParams, NumberParam } from 'use-query-params'
import { Checkbox, Button, InputGroup, Spinner, Intent, Divider } from '@blueprintjs/core'
import { AppToaster, EmptyMessage } from '../../../../components'
import produce, { produceWithPatches, applyPatches, type Patch } from 'immer'
import styled from 'styled-components'
import { updateCheckpoint, deleteCheckpoint, createCheckpoint } from '../helpers'
import 'styled-components/macro'
import type { Checkpoint } from '../../../../stores/types'
import type { StyledComponent } from 'styled-components'

type Props = {
	checkpoints: Checkpoint[],
	allowCreateDelete: boolean,
	allowUpdate: boolean,
	isMainPage?: boolean,
}

type SharedLoad = { changeId: string }
type ApplyAction = { type: 'APPLY', payload: SharedLoad }
type ApplyCreateAction = {
	type: 'APPLY_CREATE',
	payload: { remoteId: string, tempId: string } & SharedLoad,
}
type UpdateAction = {
	type: 'UPDATE',
	payload: { index: number, values: { completed: boolean } } & SharedLoad,
}
type DeleteAction = { type: 'DELETE', payload: { index: number } & SharedLoad }
type CreateAction = { type: 'CREATE', payload: { values: Checkpoint } & SharedLoad }
type RevertAction = { type: 'REVERT', payload: SharedLoad }

type Action =
	| UpdateAction
	| ApplyCreateAction
	| DeleteAction
	| CreateAction
	| RevertAction
	| ApplyAction

type CreativeInputProps = {
	setNewText: string => void,
	newTextValue: string,
	handleCreate: () => void,
}

const localCheckpointsManager = (
	state: {
		checkpoints: Checkpoint[],
		pendingChanges: { [id: string]: Patch[] },
	},
	action: Action
) => {
	const { changeId } = action.payload
	if (action.type === 'REVERT') {
		return produce(state, draft => {
			draft.checkpoints = applyPatches(draft.checkpoints, draft.pendingChanges[changeId])
			delete draft.pendingChanges[changeId]
		})
	} else if (action.type === 'APPLY' || action.type === 'APPLY_CREATE') {
		return produce(state, draft => {
			delete draft.pendingChanges[changeId]
			if (action.type === 'APPLY_CREATE') {
				const { checkpoints } = draft
				const { remoteId, tempId } = action.payload
				const createdIndex = checkpoints.findIndex(value => value.id === tempId)
				if (createdIndex < 0) return
				checkpoints[createdIndex].id = remoteId
			}
		})
	}
	const [checkpoints, patches, inversePatches] = produceWithPatches(state.checkpoints, draft => {
		switch (action.type) {
			case 'UPDATE':
				const { values } = action.payload
				if (!draft[action.payload.index]) return
				const checkpoint = draft[action.payload.index]
				draft[action.payload.index] = { ...checkpoint, ...values }
				break
			case 'DELETE':
				if (!draft[action.payload.index]) return
				draft.splice(action.payload.index, 1)
				break
			case 'CREATE':
				draft.push(action.payload.values)
				break
			default:
				return
		}
	})
	if (patches.length > 0) {
		return produce(state, draft => {
			draft.checkpoints = checkpoints // optimistically apply patches
			draft.pendingChanges[changeId] = inversePatches // save the inverse just in case
		})
	}
	return state
}

export default function WeeklyCheckpoints({
	checkpoints,
	allowCreateDelete,
	allowUpdate,
	isMainPage,
}: Props): React$Node {
	const [localState, dispatch] = useReducer(localCheckpointsManager, {
		checkpoints: checkpoints,
		pendingChanges: {},
	})
	const scrollRef = useRef(null)
	const [query] = useQueryParams({
		monday: NumberParam,
	})

	const [newText, setNewText] = useState('')

	const handleMarkCompleted = (event, id, index) => {
		const changeId = uuid()
		dispatch({
			type: 'UPDATE',
			payload: { index, values: { completed: event.target.checked }, changeId },
		})
		updateCheckpoint(id, { completed: event.target.checked })
			.then(checkpoints => {
				dispatch({ type: 'APPLY', payload: { changeId } })
			})
			.catch(() => {
				dispatch({ type: 'REVERT', payload: { changeId } })
				AppToaster.show({
					message: 'Failed updating checkpoint. Please try again',
					intent: 'danger',
				})
			})
	}

	const handleDelete = (id, index) => {
		if (!allowCreateDelete) return
		const changeId = uuid()
		dispatch({
			type: 'DELETE',
			payload: { index, changeId },
		})
		deleteCheckpoint(id)
			.then(checkpoints => {
				dispatch({ type: 'APPLY', payload: { changeId } })
			})
			.catch(() => {
				dispatch({ type: 'REVERT', payload: { changeId } })
				AppToaster.show({
					message: 'Failed deleting checkpoint. Please try again',
					intent: 'danger',
				})
			})
	}

	const scrollToBottom = () => {
		if (scrollRef?.current) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const handleCreate = () => {
		const changeId = uuid()
		const tempId = 'temp-' + localState.checkpoints.length

		if (!newText || newText.length === 0) {
			AppToaster.show({
				message: 'Missing text for checkpoint!',
				intent: 'warning',
			})
			return
		}
		dispatch({
			type: 'CREATE',
			payload: { values: { id: tempId, completed: false, text: newText }, changeId },
		})
		const text = newText
		setNewText('')
		createCheckpoint({ text, monday: query.monday })
			.then(result => {
				if (isMainPage) scrollToBottom()
				dispatch({
					type: 'APPLY_CREATE',
					payload: {
						changeId,
						remoteId: result.checkpointId,
						tempId: tempId,
					},
				})
			})
			.catch(() => {
				dispatch({ type: 'REVERT', payload: { changeId } })
				AppToaster.show({
					message: 'Failed creating checkpoint. Please try again',
					intent: 'danger',
				})
				setNewText(text)
			})
	}

	return (
		<>
			<Container isMainPage={isMainPage}>
				<CornerTiny>
					{Object.keys(localState.pendingChanges).length > 0 && (
						<Spinner size={20} intent={Intent.PRIMARY} />
					)}
				</CornerTiny>
				<ul css="list-style-type: none;">
					{localState.checkpoints.map((checkpoint, index) => {
						return (
							<li key={checkpoint.id}>
								<FlexCard>
									<Checkbox
										disabled={!allowUpdate}
										css="margin-bottom: 0px !important; flex: 1;"
										checked={checkpoint.completed}
										onChange={event => handleMarkCompleted(event, checkpoint.id, index)}>
										{checkpoint.text}
									</Checkbox>
									{allowCreateDelete && (
										<Button
											icon="trash"
											intent={Intent.DANGER}
											minimal
											onClick={() => handleDelete(checkpoint.id, index)}
										/>
									)}
								</FlexCard>
								{localState.checkpoints.length - 1 !== index && <Divider />}
							</li>
						)
					})}
				</ul>
				{allowCreateDelete && !isMainPage && (
					<CreationInput
						newTextValue={newText}
						setNewText={setNewText}
						handleCreate={handleCreate}
					/>
				)}
				{!allowCreateDelete && localState.checkpoints.length === 0 && (
					<EmptyMessage className="empty-message">No Checkpoints Available</EmptyMessage>
				)}
				<span ref={scrollRef} />
			</Container>
			{allowCreateDelete && isMainPage && (
				<CreationInput newTextValue={newText} setNewText={setNewText} handleCreate={handleCreate} />
			)}
		</>
	)
}

/**
 * Input component for the creation of new checkpoint
 * @param {(string) => void} setNewText function that takes onChange input value
 * @param {string} newTextValue current input value
 * @param {()=>void} handleCreate function for confirming checkpoint creations
 */
const CreationInput = ({ setNewText, newTextValue, handleCreate }: CreativeInputProps) => {
	return (
		<FlexCard>
			<InputGroup
				value={newTextValue}
				onChange={e => setNewText(e.currentTarget.value)}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						handleCreate()
					}
				}}
				placeholder="New Checkpoint"
				css="width: 60%; flex: 1;"
			/>
			<Button icon="plus" minimal onClick={handleCreate} />
		</FlexCard>
	)
}

const FlexCard = styled.div`
	display: flex;
	padding: 0 8px;
	align-items: center;
	flex: 1;
`

const CornerTiny = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	margin: 12px;
`

const Container: StyledComponent<{ isMainPage?: ?boolean }, Theme, HTMLDivElement> = styled.div`
	${({ isMainPage }) =>
		isMainPage
			? `overflow: auto;
	         max-height: 27vh;
			 margin-bottom: 10px;`
			: ''}
	& > *:not(:last-child) {
		margin-bottom: ${({ theme }) => theme.spacing};
	}
`
