import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import {
	Classes,
	Dialog,
	H4,
	H5,
	Pre,
	Spinner,
	Icon,
	Button,
	FormGroup,
	InputGroup,
	TextArea,
} from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import styled from 'styled-components/macro'

import { AppToaster } from './../../../components/basic/AppToaster'
import NetworkCommunicator from '../../../services/NetworkCommunicator'

type LicensePlatform = { url: string, baseUrl: string, clientId: string, notes: string, id: string }

/**
 * Fetches the list of platforms associated with the license for `licenseId`
 */
async function fetchLicensePlatforms(licenseId: string): Promise<LicensePlatform[]> {
	const response = await NetworkCommunicator.GET(`ltiPlatforms?licenseId=${licenseId}`)
	if (Array.isArray(response?.platforms)) {
		return response.platforms
	}

	throw new Error('Did not receive a platforms array')
}

/**
 * A dialog for managing integrations for the license with the given `licenseId`
 */
export function IntegrationsDialog({
	onRequestClose,
	licenseId,
}: {
	onRequestClose: () => mixed,
	licenseId: string,
}): React$Node {
	const [isAddingIntegration, setIsAddingIntegration] = useState(false)

	const { data: platforms, isLoading, error, refetch } = useQuery(
		['licensePlatforms', licenseId],
		() => fetchLicensePlatforms(licenseId)
	)

	return (
		<Dialog
			isOpen
			title="License Integrations"
			onClose={onRequestClose}
			canOutsideClickClose
			canEscapeKeyClose
			icon="settings"
			isCloseButtonShown>
			<div className={Classes.DIALOG_BODY}>
				<H4>Canvas Integrations</H4>
				{error ? (
					<>
						<p>There was an error loading Canvas integrations:</p>
						<Pre>{error.message}</Pre>
					</>
				) : isLoading ? (
					<Spinner />
				) : platforms ? (
					<Platforms platforms={platforms} />
				) : (
					<p>
						An unexpected error occurred. You probably need to let the engineering team know about
						this one
					</p>
				)}
				<div css="&& { margin-top: var(--spacing-2x); }">
					{isAddingIntegration ? (
						<NewIntegration
							onCreated={() => {
								setIsAddingIntegration(false)
								refetch()
							}}
							onCancel={() => setIsAddingIntegration(false)}
							licenseId={licenseId}
						/>
					) : (
						<Button onClick={() => setIsAddingIntegration(true)}>New Integration</Button>
					)}
				</div>
			</div>
		</Dialog>
	)
}

/**
 * Displays a list of platforms
 */
function Platforms({ platforms }: { platforms: LicensePlatform[] }) {
	return platforms.length === 0 ? (
		<p>No Canvas integrations</p>
	) : (
		<div
			css={`
				display: grid;
				grid-template-columns: 1fr 1fr 10px;
				grid-gap: var(--spacing-half);
				align-items: center;
			`}>
			{platforms.map((platform: LicensePlatform) => (
				<React.Fragment key={platform.id}>
					<span>
						<PropertyTitle>URL</PropertyTitle>
						<span>{platform.baseUrl}</span>
					</span>
					<span>
						<PropertyTitle>Client ID</PropertyTitle>
						<span>{platform.clientId}</span>
					</span>
					<Tooltip2 content={platform.notes || 'No notes provided'}>
						<Icon icon="document" />
					</Tooltip2>
				</React.Fragment>
			))}
		</div>
	)
}

/**
 * Creates a new platform on the database using the given data
 */
async function createLicensePlatform(data: FormData): Promise<LicensePlatform> {
	const response = await NetworkCommunicator.POST('ltiPlatforms', { body: data })
	if (!response?.platform) {
		throw new Error('Did not receive the platform key')
	}

	return response.platform
}

/**
 * A form to create a new integration for the given license.
 * @param {Function} onCreated callback that is called after a successful api call to create the platform
 * @param {Function} onCancel callback that is called when the user presses the cancel button
 * @param {string} licenseId The licenseId
 */
function NewIntegration({
	onCreated,
	onCancel,
	licenseId,
}: {
	onCreated: () => mixed,
	onCancel: () => mixed,
	licenseId: string,
}) {
	const { mutate: createPlatform, isLoading: isCreatingPlatform } = useMutation(
		createLicensePlatform,
		{
			onError: error => {
				console.error(error)
				AppToaster.danger({
					message: (
						<div>
							<b>Failed to create the integration:</b>
							<br />
							<br />
							{error.message || 'Unknown error'}
						</div>
					),
				})
			},
			onSuccess: () => {
				onCreated()
			},
		}
	)

	return (
		<>
			<H5>New Integration</H5>
			<form
				onSubmit={event => {
					event.preventDefault()

					createPlatform(new FormData(event.target))
				}}>
				<div
					css={`
						display: grid;
						grid-template-columns: 1fr 1fr;
						column-gap: var(--spacing-2x);
					`}>
					<FormGroup label="URL" labelFor="integration-url-input" fill={false}>
						<InputGroup
							name="institutionUrl"
							id="integration-url-input"
							placeholder="https://example.com"
							required
							type="url"
							pattern="https://.*"
						/>
					</FormGroup>
					<FormGroup label="Client ID" labelFor="integration-clientid-input">
						<InputGroup name="clientId" id="integration-clientid-input" required />
					</FormGroup>
				</div>
				<FormGroup
					label="Notes"
					helperText="Email of Canvas contact, unique details, etc."
					labelFor="integration-notes-input">
					<TextArea name="notes" id="integration-notes-input" fill />
				</FormGroup>
				<input type="hidden" name="licenseId" value={licenseId} />
				<input type="hidden" name="vendor" value="canvas" />
				<Button type="submit" loading={isCreatingPlatform}>
					Add
				</Button>
				<Button onClick={onCancel} css="margin-left: var(--spacing);">
					Cancel
				</Button>
			</form>
		</>
	)
}

const PropertyTitle = styled.span`
	font-weight: bold;
	margin-right: var(--spacing);
`
