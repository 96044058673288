import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { Criteria, UpdateCriteria } from '../../localTypes'
import type { FieldType, Range } from '../../clientTypes'

import React from 'react'
import { useEffect, useState } from 'react'
import { RangeEntry } from '.'
import { InputGroup } from '@blueprintjs/core'
import { assign, get } from '../../../../../../utility/dotProxy'

/**
 * NumberRangeEntry - adds a range entry for entering numbers
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {FieldType} props.fieldType - the field type which caused this component to be rendered
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the numbers for ths range
 *
 * @returns React$Node
 */
export function NumberRangeEntry({
	updateCriteria,
	currentCriteria,
	currentPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?Range<?number>>,
	fieldType: FieldType,
}): React$Node {
	return (
		<RangeEntry
			updateCriteria={updateCriteria}
			currentCriteria={currentCriteria}
			currentPath={currentPath}
			formRenderer={NumberRenderer}
			fieldType={fieldType}
		/>
	)
}

/**
 * NumberRenderer - a component used to render a form for inputting a number
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data number
 *
 * @returns React$Node
 */
function NumberRenderer({
	updateCriteria,
	currentCriteria,
	currentPath,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?number>,
}): React$Node {
	const currentCriteriaValue = get(currentPath, currentCriteria)
	const [currentDisplayValue, setCurrentDisplayValue] = useState(currentCriteriaValue)

	useEffect(() => {
		if (currentCriteriaValue != null) {
			setCurrentDisplayValue(currentCriteriaValue)
		}
	}, [currentCriteriaValue])

	return (
		<InputGroup
			type="number"
			value={currentDisplayValue ?? ''}
			aria-invalid={currentCriteriaValue == null}
			onChange={e => {
				const value = Number(e.target.value)
				if (value != null && !isNaN(value)) {
					updateCriteria(oldCriteria => assign(currentPath, oldCriteria, value))
				} else {
					setCurrentDisplayValue(e.target.value)
				}
			}}
		/>
	)
}
