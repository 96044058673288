import React from 'react'
import { InputGroup } from '@blueprintjs/core'

// Filtering search bar for react-tables
export default function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	placeholderText,
}: {
	preGlobalFilteredRows: any,
	globalFilter: any,
	setGlobalFilter: any,
	placeholderText: string,
}): React$Node {
	const [value, setValue] = React.useState(globalFilter)
	const onChange = value => {
		setGlobalFilter(value || undefined)
	}

	return (
		<InputGroup
			value={value || ''}
			onChange={e => {
				setValue(e.target.value)
				onChange(e.target.value)
			}}
			leftIcon={'search'}
			placeholder={placeholderText}
		/>
	)
}
