import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { getUserId } from '../../../stores/auth'

export function updateCheckpoint(
	id: string,
	values: { text?: string, completed?: boolean }
): Promise<{| updatedCheckpointId: string |}> {
	return NetworkCommunicator.PUT(`checkpoints/${id}`, {
		body: values,
	})
}

export function deleteCheckpoint(id: string): Promise<{| deletedCheckpointId: string |}> {
	return NetworkCommunicator.DELETE(`checkpoints/${id}`)
}

export function createCheckpoint(body: {
	text: string,
	monday: number,
}): Promise<{| updatedCheckpointId: string |}> {
	const employeeId = getUserId()
	return NetworkCommunicator.POST(`checkpoints`, {
		body: { ...body, employeeId },
	})
}
