import type { ClientCriteriaForm } from './clientTypes'
import type { UseQueryResult } from 'react-query'

import NetworkCommunicator from '../../../../services/NetworkCommunicator'

import { useQuery } from 'react-query'

/**
 * useCriteriaForm - a hook for getting the form for criteria
 *
 * @returns UseQueryResult
 */
export function useCriteriaForm(): UseQueryResult<ClientCriteriaForm> {
	return useQuery(
		'criteria-form',
		() => NetworkCommunicator.GET(`reports/criteria`).then(res => res.form),
		{
			staleTime: Infinity,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		}
	)
}

type RelativeTimeFormEntry = {|
	id: string,
	displayName: string,
	resolvedToAtTimeOfRequest: number,
	timeOfRequest: number,
|}

/**
 * useRelativeTimeFormData - a hook for getting the form data for inputting relative time data
 *
 * @returns UseQueryResult<RelativeTimeFormEntry[]>
 */
export function useRelativeTimeFormData(): UseQueryResult<RelativeTimeFormEntry[]> {
	return useQuery(
		['relative-dates'],
		() =>
			NetworkCommunicator.GET(`reports/criteria/relative-time`).then(
				res => res.availableRelativeDates
			),
		{
			staleTime: Infinity,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		}
	)
}
