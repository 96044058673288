import React from 'react'
import { useState } from 'react'
import { Button, Card, Intent, Dialog, H5 } from '@blueprintjs/core'
import Loader from '../../../components/basic/Loader'
import { useAlerts, useDeleteAlert } from './hooks'
import type { BasicAlertData } from './types'
import { NewAlert } from './NewAlert'
import { useQueryParams, StringParam, BooleanParam } from 'use-query-params'
import { AlertViewer } from './AlertViewer'
import styled from 'styled-components'
import { FlexWrapper } from '../../../components/basic'
import moment from 'moment'
import { Popover2 } from '@blueprintjs/popover2'
import { AlertEditorForId } from './AlertEditorForId'

/**
 * Alerts - the root of the alerts page. Displays the main alerts page and handles the logic of coordinating the viewing, creating, and editing component.s
 *
 * @returns React$Node
 */
export default function Alerts(): React$Node {
	const { isLoading, error, data: alerts } = useAlerts()
	const [createNew, setCreateNew] = useState(false)
	const [{ alertId, isEditing }, setParams] = useQueryParams({
		alertId: StringParam,
		isEditing: BooleanParam,
	})

	if (isLoading) {
		return <Loader />
	}

	if (error) {
		return <div>an error occurred while loading data</div>
	}

	return (
		<>
			<PushRight>
				<Button onClick={() => setCreateNew(true)} intent={Intent.PRIMARY}>
					Create New Alert
				</Button>
			</PushRight>
			{alerts ? (
				<AlertList
					alerts={alerts}
					onSelectAlert={(alertId: string, isEditing: boolean) => setParams({ alertId, isEditing })}
				/>
			) : (
				'No Alerts'
			)}
			<StyledDialog
				isOpen={createNew || alertId}
				onClose={() => {
					setCreateNew(false)
					setParams({ alertId: null, isEditing: null })
				}}>
				{createNew ? (
					<NewAlert
						redirectToId={(alertId: string) => {
							setParams({ alertId, isEditing: false })
							setCreateNew(false)
						}}
					/>
				) : alertId && !isEditing ? (
					<AlertViewer alertId={alertId} />
				) : alertId && isEditing ? (
					<AlertEditorForId
						alertId={alertId}
						onSaved={() => setParams({ alertId: null, isEditing: null })}
					/>
				) : null}
			</StyledDialog>
		</>
	)
}

/**
 * AlertList - displays a list  overview of all the alerts given.
 *
 * @param {BasicAlertData[]} alerts - the alerts to display in the list
 * @param  {(alertId: string, isEditing: boolean) => void} onSelectAlert - a callback for when an alert is selected
 *
 * @returns React$Node
 */
function AlertList({
	alerts,
	onSelectAlert,
}: {
	alerts: BasicAlertData[],
	onSelectAlert: (alertId: string, isEditing: boolean) => void,
}): React$Node {
	return (
		<List column>
			{alerts.map(alert => (
				<AlertOverview alert={alert} onSelect={onSelectAlert} key={alert._id} />
			))}
		</List>
	)
}

/**
 * AlertOverview - A react component for showing the overview card for an alert
 *
 * @param {Object} props - the react props
 * @param {BasicAlertData} props.alert - the alert to show the overview for
 * @param {(alertId: string, isEditing: boolean) => void} onSelect - a callback for when the alert is selected
 *
 * @returns React$Node
 */
function AlertOverview({
	alert: { _id, name, lastUpdated, entryCount, documentType, notifiers },
	onSelect,
}: {
	alert: BasicAlertData,
	onSelect: (alertId: string, isEditing: boolean) => void,
}) {
	const { mutate: onDelete, isLoading: isDeleting } = useDeleteAlert(_id)
	if (isDeleting) {
		return (
			<StyledCard>
				<DeleteAlertRow>
					<StyledLoader />
					<div>Deleting Alert</div>
				</DeleteAlertRow>
			</StyledCard>
		)
	}
	return (
		<StyledCard key={_id}>
			<SpaceApart>
				<Column>
					<BlueH5>{name}</BlueH5>
					{lastUpdated != null ? (
						<SmallText>
							{entryCount} {documentType}
							{entryCount !== 1 ? 's' : ''} in list | last updated{' '}
							{moment(new Date(lastUpdated)).format('L LTS')}
						</SmallText>
					) : (
						'Has Not Ran'
					)}
				</Column>
				<Column>
					<ButtonRow>
						<Popover2
							placement="bottom"
							content={
								<DetailsWrapper>
									<div>Resource: {documentType}</div>
									{lastUpdated != null ? (
										<div>
											<div>Entries in list: {entryCount}</div>
											<div>Last update: {moment(new Date(lastUpdated)).format('L LTS')}</div>
										</div>
									) : (
										<div>Has not ran</div>
									)}
									<div>
										<div>Notifications Sent To: {!notifiers.length ? 'no one' : null}</div>
										<Indent>
											{notifiers.map(({ target: { sendTo } }, index) => (
												<div key={String(index)}>{sendTo}</div>
											))}
										</Indent>
									</div>
								</DetailsWrapper>
							}>
							<Button rightIcon="caret-down">Details</Button>
						</Popover2>
						<Button onClick={() => onSelect(_id, false)}>View Entries</Button>
						<Button onClick={() => onSelect(_id, true)}>Edit</Button>
						<Button onClick={() => onDelete()} icon="trash" intent={Intent.DANGER}>
							Delete
						</Button>
					</ButtonRow>
				</Column>
			</SpaceApart>
		</StyledCard>
	)
}

const List = styled(FlexWrapper)`
	overflow-y: auto;
	overflow-x: hidden;

	border: ${({ theme }) => `1px solid ${theme.primary}`};
	border-radius: 6px;
	padding: 12px;
	margin-top: 8px;

	max-height: 80%;
`

const DetailsWrapper = styled(Card)`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Indent = styled.div`
	margin-left: 8px;
	opacity: 0.5;
`

const StyledCard = styled(Card)`
	cursor: pointer;
	background: ${({ theme }) => theme.white};
	color: ${({ theme }) => theme.primary};
	margin: 4px 8px;
	transition: all 0.3s ease;
`

const PushRight = styled.div`
	display: flex;
	flex-direction: row-reverse;
`

const SpaceApart = styled.div`
	display: flex;
	justify-content: space-between;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
`

const ButtonRow = styled(Row)`
	gap: 4px;
`

const SmallText = styled.div`
	font-size: 0.75em;
	opacity: 0.6;
`

const StyledDialog = styled(Dialog)`
	&& {
		width: 75vw;
		overflow-x: auto;
	}
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const BlueH5 = styled(H5)`
	&& {
		color: ${({ theme }) => theme.primary};
	}
`

const StyledLoader = styled(Loader)`
	width: min-content;
	height: 1em;
`

const DeleteAlertRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: ${({ theme }) => theme.spacing};
	padding: ${({ theme }) => theme.spacing};
`
