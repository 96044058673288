import React, { useState } from 'react'
import { Popover, Intent, Button, Classes, H5 } from '@blueprintjs/core'
import 'styled-components/macro'

/**
 * Makes a delete button that, when clicked, opens a popover to confirm the deletion.
 * The `confirmationText` prop will be displayed in the popover.
 */
export default function DeleteButton({
	confirmationText,
	onDelete,
	className,
}: {
	confirmationText: string,
	onDelete: () => Promise<mixed>,
	className?: string,
}): React$Node {
	const [deleting, setDeleting] = useState(false)
	return (
		<Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} className={className}>
			<Button intent={Intent.DANGER}>Delete OKR</Button>
			<div>
				<H5>Confirm deletion</H5>
				<p>{confirmationText}</p>
				<div css="display: flex; justify-content: flex-end; margin-top: 15px">
					<Button className={Classes.POPOVER_DISMISS} css="margin-right: 10px;">
						Cancel
					</Button>
					<Button
						intent={Intent.DANGER}
						className={Classes.POPOVER_DISMISS}
						onClick={() => {
							setDeleting(true)
							onDelete().finally(() => setDeleting(false))
						}}
						loading={deleting}>
						Delete
					</Button>
				</div>
			</div>
		</Popover>
	)
}
