import React from 'react'
import { Card, H4, H2, EditableText, Divider } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import type { FullOkr as Okr } from './types'
import type { User } from '../../../stores/auth'
import { useRestorableState } from '../../../utility/hooks'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { AppToaster } from '../../../components/basic/AppToaster'
import OkrsList from './OkrsList'
import Owners from './Owners'
import OkrProgress from './OkrProgress'
import { FlexWrapper } from '../../../components'
import ParentOkrSelectorPopup from './ParentOkrSelectorPopup'

const MainOkrStyleCard = styled(Card)`
	${({ theme }) => `
		background-color: rgba(14, 90, 138, 0.1) !important;
		box-shadow: 0 0 0 1px rgba(14, 90, 138, 0.25), 0 0 0 rgba(14, 90, 138, 0),
			0 0 0 rgba(14, 90, 26, 138) !important;
	`}
`

const PaddedLabel = styled.div`
	padding: 6px 0px;
`

type Props = {
	okr: Okr,
	canUpdate: boolean,
	currentUser: User,
}

export default function MainOkr({ okr, canUpdate, currentUser }: Props): React$Node {
	return (
		<MainOkrStyleCard>
			<FlexWrapper spaceBetween>
				<OkrTitle canUpdate={canUpdate} okrId={okr.id} title={okr.title} />
				<div css="width: 40%;">
					<OkrProgress value={okr.progress} okrId={okr.id} canUpdate={canUpdate ?? false} />
				</div>
			</FlexWrapper>
			<FlexWrapper spaceBetween>
				<div>
					<FlexWrapper css="height: auto;">
						<PaddedLabel>Created by {okr.createdBy}</PaddedLabel>
						<Divider />
						<PaddedLabel>Quarter: {okr.quarter}</PaddedLabel>
					</FlexWrapper>
					<FlexWrapper css="height: auto;">
						<span css="margin-right: 16px; align-self: center;">Owners:</span>
						<Owners canUpdate={true} owners={okr.owners || []} okrId={okr.id} />
					</FlexWrapper>
				</div>
				<div>
					Parent OKR: <ParentOkrSelectorPopup okr={okr} />
				</div>
			</FlexWrapper>
			<br />
			<H4>Key Results</H4>
			{okr.children && (
				<div>
					<OkrsList
						condensed
						okrs={okr.children}
						allowOkrCreation={true}
						owner={{ id: currentUser.id, type: 'EMPLOYEE', displayName: currentUser.displayName }}
						quarter={okr.quarter}
						newOkrParentId={okr.id}
						currentUser={currentUser}
						placeholder="New key result"
					/>
				</div>
			)}
		</MainOkrStyleCard>
	)
}

function OkrTitle({
	canUpdate,
	okrId,
	title: originalTitle,
}: {
	canUpdate?: boolean,
	okrId: number,
	title: string,
}) {
	const [title, setTitle, , updateFallback] = useRestorableState(originalTitle)

	/**
	 * Sends a request to the server to update the OKR title
	 */
	const updateOkr = () => {
		NetworkCommunicator.PUT(`okrs/${okrId}`, { body: { title } })
			.then(() => {
				updateFallback(title)
			})
			.catch(() => {
				AppToaster.danger({
					message: 'Unable to update OKR title',
					action: {
						text: 'Try Again',
						onClick: updateOkr,
					},
				})
			})
	}

	return (
		<H2>
			{canUpdate ? (
				<EditableText
					value={title}
					onChange={setTitle}
					onConfirm={updateOkr}
					multiline
					confirmOnEnterKey
					css="margin-right: 10px;"
				/>
			) : (
				title
			)}
		</H2>
	)
}
