type Key = string
import type { TableColumns } from '../components/basic/AdvancedTable'

// A function that does nothing (necessary for use cases where a function must be provided but we don't want anything to happen)
export function noop() {}

/**
 * A basic debounce function found at https://gist.github.com/peduarte/7ee475dd0fae1940f857582ecbb9dc5f
 * @param  {Function} func The function to debounce
 * @param {number}    wait How long to wait after the last call before running the debounced function
 * @return {Function}      The debounced function
 */
export function debounce<T: Array<mixed>>(
	func: (...args: T) => void,
	wait?: number = 100
): (...args: T) => void {
	let timeout
	return function(...args: T) {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			func.apply(this, args)
		}, wait)
	}
}

/**
 * from an array of objects with ids, create an object where keys are the ids and
 * values are elments from  the array
 *
 * @param{Array<T: { _id: string }>} dataArray the elements to put into and id map
 * @return {{id: T}} an id map
 */
export function toIdMap<T: { _id: string }>(dataArray: T[]): { [dataId: string]: T } {
	let map = {}
	dataArray.forEach((data: T) => (map[data._id] = data))
	return map
}

/**
 * Gets the values of an object as an array. Like `Object.values`, but now with flow!
 * @param {object} object the object
 */
export function values<T>(object: { [Key]: T }): T[] {
	const returnValues: T[] = []

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			returnValues.push(object[key])
		}
	}

	return returnValues
}

/**
 * The array map function, implemented for object. The keys will all stay the same,
 * while the values at each key will be the result of the callback function when called
 * on the given entry.
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function mapObject<U, T>(
	object: $ReadOnly<{ [Key]: T }>,
	callback: (currentValue: T, Key) => U
): { [Key]: U } {
	const newObject: { [Key]: U } = {}

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			newObject[key] = callback(object[key], key)
		}
	}

	return newObject
}

/**
 * The array map function, implemented for object. The result will be an array of
 * values resulting from running the given callback on each entry in the object.
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function mapEntries<U, T>(
	object: $ReadOnly<{ [Key]: T }>,
	callback: (currentValue: T, Key) => U
): U[] {
	const mappedValues: U[] = []

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			mappedValues.push(callback(object[key], key))
		}
	}

	return mappedValues
}

/**
 * When given a string of the format TYPE_STRING, we prettify it by changing it to lower case, replacing '_' with spaces,
 * and capitalizing the first letter.
 * @param {string} type
 */
export function prettifyTypeEnum(type: string): string {
	let prettyStr: string = type.toLowerCase()
	prettyStr = prettyStr.replace(/_/g, ' ')
	prettyStr = prettyStr.charAt(0).toUpperCase() + prettyStr.slice(1)
	return prettyStr
}

/**
 * Prettify a camel case string
 */
export function prettifyCamelCase(str: string): string {
	const words = str.match(/^[a-z]+|[A-Z][a-z]*/g)
	if (words) {
		return words
			.map(function(x) {
				return x[0].toUpperCase() + x.substr(1).toLowerCase()
			})
			.join(' ')
	} else return str
}

/**
 * The array reduce function implemented for object
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function reduceObject<U, T>(
	object: { [Key]: T },
	callback: (previousValue: U, currentValue: T, Key) => U,
	initialValue: U
): U {
	let previousValue = initialValue

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			previousValue = callback(previousValue, object[key], key)
		}
	}

	return previousValue
}

/*
 * Test whether all values in an object pass the test implemented by the provided function
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function everyEntry<T>(object: { [Key]: T }, callback: ([Key, T]) => boolean): boolean {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (!callback([key, object[key]])) {
				return false
			}
		}
	}
	return true
}

/**
 * Test whether any value in an object passes the test implemented by the provided function
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function someEntry<T>(object: { [Key]: T }, callback: (T, Key) => boolean): boolean {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key], key)) {
				return true
			}
		}
	}
	return false
}

export function isObject(param: mixed): boolean %checks {
	return typeof param === 'object' && !!param
}

/**
 * Gets the current timestamp of the monday of the week
 */
export function getCurrentMonday(): number {
	const monday = new Date()
	const mondayIndex = 1
	const today = new Date()
	const dayOfWeek = today.getDay()
	const diff = dayOfWeek >= mondayIndex ? dayOfWeek - mondayIndex : 6 - dayOfWeek
	monday.setDate(today.getDate() - diff)
	monday.setHours(0, 0, 1, 0)
	return monday.getTime()
}

/*
 * Gets the year and quarter as numbers (e.g. [2019, 4]). Optionally pass a number as the `quarterDifference`
 * to get quarters from the future or past
 * @param {1 | -1} quarterDifference The number of quarters to move forward or backwards
 */
export function getYearAndQuarter(quarterDifference?: -1 | 1): [number, number] {
	const now = new Date()
	let year = now.getFullYear()
	let quarter = Math.floor(now.getMonth() / 3) + 1

	if (quarterDifference) {
		quarter += quarterDifference

		if (quarter > 4) {
			quarter = 1
			year++
		} else if (quarter < 1) {
			quarter = 4
			year--
		}
	}

	return [year, quarter]
}

/**
 * Given a year and quarter as numbers, make a string of the format '2019Q4'
 */
export const makeQuarterString = (year: number, quarter: number): string => year + 'Q' + quarter

/**
 * Function that takes columns prepared for React-Table
 * and convert it to use for CSVLink
 * @param {Array<TableColumns>} columns that prepared for React-Table
 **/
export const formatToCsvColumns = (
	columns: Array<TableColumns>
): ?Array<{ label: string, key: string }> => {
	return columns.map((column: TableColumns) => {
		return {
			label: typeof column.Header === 'string' ? column.Header : column.accessor,
			key: column.accessor,
		}
	})
}

const TRIVIAL_TYPES = ['boolean', 'number', 'string']
/**
 *  * Function that takes table data prepared for React-Table
 * and convert it to use for a CSV export
 * @param {Array<{values: {[key:string]: string}}>} data that prepared for React-Table
 **/
export const formatToCsvData = (
	columns: Array<TableColumns>,
	data: ?Array<{ [key: string]: string }>
): ?Array<{ [key: string]: string }> => {
	if (!data) return
	const filteredData: Array<{ [key: string]: string }> = data.map(item => {
		// order fields to match pattern and takes only matched fields
		const orderedLikeInAPattern: { [string]: string } = columns.reduce(
			(acc: { [string]: string }, column: TableColumns) => {
				if (column.hideCSV) {
					return acc
				}
				const tableCellValue = item[column.accessor]
				let csvValue = column.csvWriter ? column.csvWriter(tableCellValue) : tableCellValue
				if (!TRIVIAL_TYPES.some(type => typeof csvValue === type)) {
					csvValue = JSON.stringify(csvValue)
					console.warn(
						`Found non-trivial variable type for column ${column.accessor} while parsing csv data. Casting to string: ${csvValue}. Please add a csvWriter function in the TableHeader for this column.`
					)
				}
				if (csvValue && typeof csvValue === 'string') {
					csvValue = csvValue.replace(/"/g, '""')
				}
				return { ...acc, [column.accessor]: csvValue }
			},
			{}
		)
		return orderedLikeInAPattern
	})
	return filteredData
}
