import React from 'react'
import type { SharedProps } from './types'
import type { BooleanFilterType } from '../../../../stores/types'
import { Switch } from '@blueprintjs/core'
import { useFilterStore, selectors } from '../../../../stores'

type Props = SharedProps & {
	filterType: BooleanFilterType,
	value: { label: string },
}

export default function CheckboxFilter(props: Props): React$Node {
	const value =
		useFilterStore(state => selectors.getBooleanFilterValue(state, props.filterType)) || false
	const toggleElement = element => {
		if (!value) {
			props.select({ [props.filterType]: { type: props.filterType, value: true } })
		} else props.unselect([props.filterType])
	}
	return (
		<label style={{ width: 'fit-content' }}>
			<Switch checked={value} onChange={toggleElement} label={props.value.label} />
		</label>
	)
}
