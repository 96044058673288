import { createPrompt } from './createPrompt'
import states from '../Licenses/states'

/**
 * This function takes object with {key: value} and returned formData object {key : { prompt: label , value: value }}
 * @param {Object} data
 * @param {boolean} isCustomSchool
 */

type Item = {
	prompt: string,
	value: any,
}
type SchoolFields = {
	[key: string]: Item,
}

type Data = {|
	[key: string]: string | boolean,
|}
export const createEditFields = (data: Data, isCustomSchool: boolean): SchoolFields => {
	if (!data) return {}

	let schoolField: SchoolFields = {}

	Object.keys(data).forEach((key: string) => {
		if (
			[
				'locationState',
				'locationStreet',
				'locationCity',
				'locationZip',
				'district',
				'name',
				'ncesId',
				'state',
				'stateCode',
				'phone',
				'zip',
				'street',
				'city',
				'verified',
			].includes(key)
		) {
			schoolField = {
				...schoolField,
				[key]: { prompt: createPrompt(key), value: data[key] },
			}
		}
	})

	let state = {}
	if (isCustomSchool) {
		state = states.find(state => state.abbreviation === schoolField?.state?.value)
	} else if (!isCustomSchool) {
		state = states.find(state => state.stateCode === schoolField?.stateCode?.value)
	}

	return {
		...schoolField,
		state: { prompt: 'State', value: state?.name || '' },
		locationState: { prompt: 'Location', value: state?.abbreviation || '' },
		stateCode: { prompt: 'State Code', value: state?.stateCode || '' },
	}
}
