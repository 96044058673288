import {
	useQuery,
	useMutation,
	type UseQueryResult,
	type UseQueryOptions,
	type UseMutationResult,
} from 'react-query'
import NetworkCommunicator from '../services/NetworkCommunicator'
import { mapEntries } from '../utility/functions'
import type { TeamType } from '../routes/Teams/types'

type SimpleEmployee = {
	id: string,
	firstName: string,
	lastName: string,
	email: string,
	teams: Array<{ id: string, name: string }>,
	endDate: ?string,
}

/**
 * Uses react-query to get all employees.
 * Returns the entire UseQueryResult with an array of employees as `data`
 * @param {UseQueryOptions} options
 * @return {UseQueryResult}
 */
export function useEmployees(
	options?: UseQueryOptions<SimpleEmployee[]>
): UseQueryResult<SimpleEmployee[]> {
	return useQuery(
		'employees',
		() => {
			return NetworkCommunicator.GET('employees').then(res => res.employees)
		},
		{
			staleTime: 1000 * 60 * 60,
			...options,
		}
	)
}

export type SimpleTeam = {
	id: string | null,
	name: string,
	members: Array<SimpleEmployee>,
}

/**
 * Makes a list of teams for the given employees. Any of the provided employees without a team will
 * be added to an extra team, "Unassigned". Employees with an endDate will not be included.
 */
const getTeams = (employees: SimpleEmployee[]): SimpleTeam[] => {
	const teams = {}
	employees.forEach(employee => {
		if (employee.endDate) {
			return
		}
		if (employee.teams.length === 0) {
			if (!teams.none) {
				teams.none = { id: null, name: 'Unassigned', members: [] }
			}
			teams.none.members.push(employee)
		}
		employee.teams.forEach(team => {
			if (teams[team.id]) {
				teams[team.id].members.push(employee)
			} else {
				teams[team.id] = {
					id: team.id,
					name: team.name,
					members: [employee],
				}
			}
		})
	})
	return mapEntries(teams, team => team).sort((a, b) => (a.name < b.name ? -1 : 1))
}

/**
 * Gets a list of all teams that any employees belong to. Adds an extra team with no id, "Unassigned", which
 * includes all employees with no team.
 * Returns a subset of a UseQueryResult. This function is dependent on `useEmployees`
 */
export function useTeams({
	includeUnassigned = true,
	...options
}: {| ...UseQueryOptions<SimpleEmployee[]>, includeUnassigned?: boolean |} = {}): {
	isLoading: boolean,
	teams: SimpleTeam[],
	error: mixed,
} {
	const { data: employees, isLoading, error } = useEmployees(options)
	const teams = employees ? getTeams(employees) : []
	return {
		isLoading,
		teams: includeUnassigned ? teams : teams.filter(team => !!team.id),
		error,
	}
}

/**
 * Uses react query to get a single team.
 * Returns the entire UseQueryResult with the requested team as `data`.
 * @param {?string} teamId The teamId to get
 * @param {UseQueryOptions} options
 * @return {UseQueryResult}
 */
export function useTeam(
	teamId: ?string,
	{ enabled = true, ...options }: UseQueryOptions<TeamType | null>
): UseQueryResult<TeamType | null> {
	return useQuery(
		['teams', teamId],
		() => {
			if (!teamId) {
				return null
			}
			return NetworkCommunicator.GET(`employees?team=${teamId}`).then(res => res.team)
		},
		{
			staleTime: 1000 * 60 * 60,
			enabled: enabled && !!teamId,
			...options,
		}
	)
}

/**
 * A passthrough to useMutation. The mutation will update an employee's teams.
 * @return {UseMutationResult}
 */
export function useUpdateEmployeeTeams(): UseMutationResult<
	mixed,
	mixed,
	{ employeeId: number, teams: $ReadOnlyArray<{ id: number }> }
> {
	return useMutation(({ employeeId, teams }) => {
		return NetworkCommunicator.PUT(`employees/${employeeId}/teams`, { body: { teams } })
	})
}
