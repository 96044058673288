// @flow
import React, { useState } from 'react'
import {
	Checkbox,
	NumericInput,
	Alignment,
	Classes,
	FormGroup,
	HTMLSelect,
	Card,
	H1,
	H2,
	Label,
	Button,
	Intent,
} from '@blueprintjs/core'
import 'styled-components/macro'

import { CARD_ALGORITHM_DEFINITION, type CardState } from './cardAlgorithm'
import { ASSET_2D_ALGORITHM_DEFINITION } from './asset2dAlgorithm'
import { ASSET_3D_ALGORITHM_DEFINITION } from './asset3dAlgorithm'

import { PageContainer } from '../../components'
import type { AlgorithmDefinition, FieldDefinition } from './sharedTypes'

/**
 * Creates a state and setState function for the given AlgorithmDefinition. Creates an initial state based on the definition.
 * The returned setState function accepts a partial state for the given definition, i.e. you can set a single field at a time
 * similar to the class component setState function
 * @param {AlgorithmDefinition} algorithmDefinition The algorithm definition
 * @return {[State, ($Shape<State>) => void]} A state and setState tuple similar to the `useState` return type
 */
function useAlgorithmState<State: {}>(
	algorithmDefinition: AlgorithmDefinition<State>
): [State, ($Shape<State>) => void] {
	const [state, _setState] = useState<State>(() => getInitialStateForAlgorithm(algorithmDefinition))

	function setState(newState: $Shape<State>): void {
		// $FlowIgnore[speculation-ambiguous]
		return _setState((state: State) => ({ ...state, ...newState }))
	}
	return [state, setState]
}

/**
 * Gets the initial state for the given algorithm.
 */
function getInitialStateForAlgorithm<State: {}>(
	algorithmDefinition: AlgorithmDefinition<State>
): State {
	const initialState: $Shape<State> = {}
	Object.keys(algorithmDefinition.fields).forEach((key: $Keys<State>) => {
		initialState[key] = algorithmDefinition.fields[key].default
	})
	return initialState
}

/**
 * A group of forms used for Graphics to calculate how long a specific asset or card should take to make. Consumes
 * algorithm definitions of type AlgorithmDefinition
 */
export default function GraphicsCalculator(): React$Node {
	const [cardState, setCardState] = useAlgorithmState<CardState>(CARD_ALGORITHM_DEFINITION)

	function addAssetToCard(value: number) {
		setCardState({ newAssets: [...cardState.newAssets, value] })
	}

	const renderAddAssetToCardButton = (value: number) => (
		<Button onClick={() => addAssetToCard(value)}>Add to Card</Button>
	)

	return (
		<PageContainer css="padding: 16px; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 16px;">
			<AlgorithmFormFields
				algorithmDefinition={ASSET_2D_ALGORITHM_DEFINITION}
				render={renderAddAssetToCardButton}
			/>
			<AlgorithmFormFields
				algorithmDefinition={ASSET_3D_ALGORITHM_DEFINITION}
				render={renderAddAssetToCardButton}
			/>
			<AlgorithmFormFields
				state={cardState}
				setState={setCardState}
				algorithmDefinition={CARD_ALGORITHM_DEFINITION}
			/>
		</PageContainer>
	)
}

/**
 * Given an AlgorithmDefinition, manages displaying all necessary inputs, the state of each input, and the algorithm output.
 * The state and setState values can also be provided as a prop.
 * @param {AlgorithmDefinition} algorithmDefinition The algorithm definition
 * @param {() => React$Node} render An optional render prop that will display its output next to the algorithm output
 */
function AlgorithmFormFields<State: {}>({
	algorithmDefinition,
	state: controlledState,
	setState: controlledSetState,
	render,
}: {
	algorithmDefinition: AlgorithmDefinition<State>,
	state?: State,
	setState?: ($Shape<State>) => void,
	render?: number => React$Node,
}) {
	const [_state, _setState] = useAlgorithmState(algorithmDefinition)
	const state = controlledState || _state
	const setState = controlledSetState || _setState

	const output = Object.keys(algorithmDefinition.fields).reduce((output, currentKey) => {
		if (state[currentKey] == null) {
			throw new Error(`You forgot to add ${currentKey} to the component state!`)
		}
		return output + getScoreForInput(state, currentKey, algorithmDefinition.fields[currentKey])
	}, 0)

	return (
		<Card elevation={2} css="display: inline-block;">
			<H1>{algorithmDefinition.name}</H1>
			<form>
				{Object.keys(algorithmDefinition.fields).map(key => {
					const definition = algorithmDefinition.fields[key]
					if (shouldSkipField(state, key, definition)) {
						return null
					}
					return definition.type === 'boolean' ? (
						<div key={key}>
							<Checkbox
								checked={state[key]}
								onChange={e => {
									const newState = {}
									newState[key] = e.currentTarget.checked
									setState(newState)
								}}
								label={definition.label}
								alignIndicator={Alignment.RIGHT}
								inline
								large
							/>
							<span className={Classes.TEXT_LARGE}>
								({getScoreForInput(state, key, definition)})
							</span>
						</div>
					) : definition.type === 'number' ? (
						<FormGroup
							label={definition.label}
							labelFor={key}
							inline
							className={Classes.TEXT_LARGE}>
							<NumericInput
								id={key}
								value={state[key]}
								onValueChange={value => {
									const newState = {}
									newState[key] = value
									setState(newState)
								}}
								min={definition.min || 0}
								max={definition.max}
								minorStepSize={null}
								selectAllOnFocus
								inline
								css="&& {display: inline-flex; margin-right: 20px;}"
							/>
							({getScoreForInput(state, key, definition)})
						</FormGroup>
					) : definition.type === 'number_array' ? (
						<div>
							<Label className={Classes.TEXT_LARGE}>
								{definition.label} ({getScoreForInput(state, key, definition)})
								<Button
									css="margin-left: 20px;"
									icon="add"
									onClick={() => {
										const newState = {}
										newState[key] = [...state[key], definition.defaultValue]
										setState(newState)
									}}>
									Add
								</Button>
							</Label>
							{state[key].map((value, i) => (
								<NumericInput
									css="&& {display: inline-flex; margin-right: 16px; margin-bottom: 16px}"
									key={i}
									value={value}
									onValueChange={value => {
										const newState = {}
										const newArray = [...state[key]]
										newArray.splice(i, 1, value)
										newState[key] = newArray
										setState(newState)
									}}
									min={definition.min || 0}
									max={definition.max}
									minorStepSize={null}
									selectAllOnFocus
									inline
									rightElement={
										<Button
											icon="delete"
											intent={Intent.DANGER}
											minimal
											onClick={() => {
												const newState = {}
												const newArray = [...state[key]]
												newArray.splice(i, 1)
												newState[key] = newArray
												setState(newState)
											}}
										/>
									}
								/>
							))}
						</div>
					) : (
						<FormGroup
							label={definition.label}
							labelFor={key}
							inline
							className={Classes.TEXT_LARGE}>
							<HTMLSelect
								id={key}
								value={state[key]}
								onChange={e => {
									const newState = {}
									newState[key] = e.currentTarget.value
									setState(newState)
								}}
								inline
								css="&& {display: inline-flex; margin-right: 20px;}">
								{definition.options.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</HTMLSelect>
							({getScoreForInput(state, key, definition)})
						</FormGroup>
					)
				})}
			</form>
			<H2 css="display: flex; justify-content: space-between; align-items: flex-end;">
				<div>
					Result: {output}
					{render && <span css="margin-left: 16px;">{render(output)}</span>}
				</div>
				<Button onClick={() => setState(getInitialStateForAlgorithm(algorithmDefinition))}>
					Reset
				</Button>
			</H2>
		</Card>
	)
}

/**
 * Whether the given field `key` should be skipped given the current `state` and the `definition` of that field.
 * @param {State} state The current state of the form
 * @param {string} key The key for the field in question
 * @param {FieldDefinition} definition The definition of the field in question
 * @return {boolean} Whether the field should be skipped
 */
function shouldSkipField<State: {}>(
	state: State,
	key: string,
	definition: FieldDefinition<State>
): boolean {
	if (definition.condition) {
		const condition = definition.condition
		return Object.keys(condition).some(key => state[key] !== condition[key].value)
	}
	return false
}

/**
 * Gets the output score for a single field in the algorithm.
 * @param value The value of the field
 * @param {FieldDefinition} definition The definition for the field. Defines how the score should be calculated.
 */
function getScoreForInput<State: {}>(
	state: State,
	key: string,
	definition: FieldDefinition<State>
): number {
	const value = state[key]
	if (shouldSkipField(state, key, definition)) {
		return 0
	}

	if (definition.type === 'boolean') {
		if (typeof value !== 'boolean') {
			throw new Error(
				`The type for ${definition.label} should be 'boolean', but got ${typeof value}`
			)
		}
		return value ? definition.weight : 0
	} else if (definition.type === 'number') {
		if (typeof value !== 'number') {
			throw new Error(`The type of ${definition.label} should be number, but got a ${typeof value}`)
		}
		if (typeof definition.weight === 'function') {
			return definition.weight(value)
		}

		return value * definition.weight
	} else if (definition.type === 'number_array') {
		if (!Array.isArray(value)) {
			throw new Error(
				`The type of ${definition.label} should be number[], but got a ${typeof value}`
			)
		}
		return value.reduce((sum, currentValue) => {
			if (typeof definition.weight === 'function') {
				return sum + definition.weight(currentValue)
			}
			return sum + currentValue * definition.weight
		}, 0)
	} else if (definition.type === 'option') {
		if (typeof value !== 'string') {
			throw new Error(`The type of ${definition.label} should be string, but got a ${typeof value}`)
		}
		return definition.weight(value)
	} else {
		throw new Error(`Incorrect definition type ${definition.type}`)
	}
}
