import React from 'react'

import type { Option } from '../../../../../../utility/hooks'

import {
	MIN_DISTRICT_SEARCH_STRING_LENGTH,
	useAllFetchedDistricts,
	useDistricts,
	useDistrictsByIds,
} from '../../../../../../services/hooks'
import { useMemo, useState } from 'react'
import { MassiveMultiSelect } from './MassiveMultiSelect'
import type { District } from '../../../../../../services/hooks'

/**
 * DistrictSelector - a form which allows the user to select districts from a searchable multi-select box
 *
 * @param {Object} props - the react props
 * @param {?Array<string>} props.selection - the selected districtIds
 * @param {(newSelection: ?Array<string>) => any} props.onChange - a callback when the selection changes
 *
 * @returns React$Node
 */
export default function DistrictSelector({
	selection,
	onChange,
}: {
	selection: ?Array<string>,
	onChange: (newSelection: ?Array<string>) => any,
}): React$Node {
	const [districtSearchString, setDistrictSearchString] = useState('')

	let areDistrictsLoading = useDistrictsByIds(selection || []).some(({ isLoading }) => isLoading)
	areDistrictsLoading = useDistricts(districtSearchString).isLoading || areDistrictsLoading

	const allFetchedDistricts = useAllFetchedDistricts()
	const [districtLookup, districtOptions] = useMemo(() => {
		const districtLookup = {}
		allFetchedDistricts.forEach(district => (districtLookup[district.ncesId] = district))

		return [
			districtLookup,
			allFetchedDistricts
				.filter(({ ncesId }) => Boolean(ncesId))
				.map(districtToOption)
				.sort((a, b) => a.label.localeCompare(b.label)),
		]
	}, [allFetchedDistricts])

	return (
		<MassiveMultiSelect
			selectedOptions={selection ?? []}
			onChange={onChange}
			valueMapper={values =>
				values.map(value =>
					districtToOption(
						districtLookup[value] ?? {
							ncesId: value,
							district: 'Unknown',
						}
					)
				)
			}
			options={!areDistrictsLoading ? districtOptions : null}
			needsMoreText={districtSearchString.length < MIN_DISTRICT_SEARCH_STRING_LENGTH}
			onSearchStringChange={setDistrictSearchString}
		/>
	)
}

/**
 * districtToOption - convert a district object to a select input option
 *
 * @param {District} district - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function districtToOption({ ncesId, district }: District): Option {
	return {
		value: ncesId,
		label: district,
	}
}
