import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Tab, Tabs } from '@blueprintjs/core'
import { PageContainer } from './basic'
import { useCurrentUser } from '../stores/auth'
import styled from 'styled-components/macro'

type NavType = {
	id: string,
	name: string,
	header?: string,
	component: React$ComponentType<*>,
	roleRequired?: string, // if user does not have this role, the tab will not appear
	hide?: boolean, // will take precedence over roleRequired if false
}

/**
 * This component will render a page with tabs on the left side.
 * When a tab is selected the url query param 'tab' will be updated and the main component on the page will change.
 * If a provided tab contains the value `hide` then it will not be displayed in the tab list.
 * The default tab component to be displayed on the the page will be the first tab defined in the tabs array
 * provided unless otherwise specified in the optional prop `defaultTabKey`
 */
export default function PageWithSideNav({
	tabs,
	defaultTabKey,
}: {
	tabs: { [key: string]: NavType },
	defaultTabKey?: string,
}): React$Node {
	const currentUser = useCurrentUser()
	const currentUserRoles = new Set(currentUser?.roles || [])
	const startingTab = defaultTabKey || Object.keys(tabs)[0]
	const [_tabKey, _setTab] = useQueryParam('tab', StringParam)
	const selectedTab = tabs[_tabKey] || tabs[startingTab]

	function setTab(tab: string) {
		// the same query params key can exist in different tabs and expect different types, remove them when changing tabs from the nav bar
		_setTab(tab, 'replace')
	}

	const displayTabs = []
	Object.keys(tabs).forEach(key => {
		const tab = tabs[key]
		if (tab.hide || (tab.roleRequired && !currentUserRoles.has(tab.roleRequired))) {
			return
		}
		displayTabs.push(
			<Tab
				key={key}
				id={key}
				panel={
					selectedTab.id === key ? (
						<>
							<NoMarginH1>{tab.header}</NoMarginH1>
							<tab.component />
						</>
					) : null
				}
				panelClassName={PANEL_CLASS_NAME}>
				{tabs[key].name}
			</Tab>
		)
	})
	return (
		<Container>
			<StyledTabs
				id="tabs-list"
				vertical
				onChange={key => setTab(key)}
				selectedTabId={selectedTab.id}
				large>
				{displayTabs}
			</StyledTabs>
		</Container>
	)
}

const NoMarginH1 = styled.h1`
	margin-top: 0;
`

const Container = styled(PageContainer)`
	color: ${props => props.theme.primary};
	display: flex;
`

const PANEL_CLASS_NAME = 'panel'

const StyledTabs = styled(Tabs)`
	width: 100%;
	.${PANEL_CLASS_NAME} {
		width: 100%;
	}
`
