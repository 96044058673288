import React from 'react'
import { HTMLSelect } from '@blueprintjs/core'

import { getYearAndQuarter, makeQuarterString } from '../utility/functions'

export default function QuarterSelector({
	value,
	onChange,
	...props
}: {
	value: ?string,
	onChange: string => mixed,
}): React$Node {
	const { currentQuarter, quarters } = getQuarters()

	return (
		<HTMLSelect
			{...props}
			value={value || currentQuarter}
			onChange={e => onChange(e.currentTarget.value)}>
			{quarters.map(quarter => (
				<option key={quarter}>{quarter}</option>
			))}
		</HTMLSelect>
	)
}

/**
 * Gets a list of quarters to use in the quarter dropdown. The format of the quarters is 2019Q4, 2020Q1, etc.
 * Returns an object with the current quarter at the `currentQuarter` key, and the complete list of quarters to include
 * in the dropdown at the `quarters` key
 */
function getQuarters(): { currentQuarter: string, quarters: string[] } {
	const currentQuarterString = makeQuarterString(...getYearAndQuarter())
	const nextQuarterString = makeQuarterString(...getYearAndQuarter(1))
	const previousQuarterString = makeQuarterString(...getYearAndQuarter(-1))

	return {
		currentQuarter: currentQuarterString,
		quarters: [previousQuarterString, currentQuarterString, nextQuarterString],
	}
}
