export const FILTER_TYPES = {
	START_DATE: 'START_DATE',
	END_DATE: 'END_DATE',
	FINISHED: 'FINISHED',
	STARTED: 'STARTED',
	PREPPED: 'PREPPED',
	SCHOOL: 'SCHOOL',
	USER: 'USER',
}

export const ONE_SECOND = 1000
export const ONE_MINUTE = 60 * ONE_SECOND
export const ONE_HOUR = 60 * ONE_MINUTE
