import React from 'react'
import { Button, Popover, Classes, Intent } from '@blueprintjs/core'
import styled from 'styled-components'

const BtnWrapper = styled.div`
	padding-top: 2px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

/**
 * A button with an extra confirmation step. Takes Props like a normal Blueprint Button along with additional props:
 * confirmMessage - a string which will display in a popup confirming the button's action.
 * actionWord - a string which will be displayed inside the button to confirm the action. Defaults to 'Confirm'
 * @param {{confirmMessage: string, actionWord?: string } & IButtonProps} props
 */
export function ConfirmActionButton(
	props: {
		confirmMessage: string,
		actionWord?: string,
		popOverDisabled?: boolean,
		confirmBtnIntent?: string,
	} & { onClick: () => void, children?: React$Node, minimal?: boolean, icon?: string }
): React$Node {
	const {
		confirmMessage,
		actionWord = 'Confirm',
		onClick,
		children,
		popOverDisabled = false,
		confirmBtnIntent = Intent.DANGER,
		...buttonProps
	} = props

	return (
		<Popover
			popoverClassName={Classes.POPOVER_CONTENT_SIZING}
			disabled={popOverDisabled}
			content={
				<div>
					<h5>Confirm Action</h5>
					<p>{confirmMessage}</p>
					<BtnWrapper>
						<Button className={Classes.POPOVER_DISMISS} css="margin-right: 10;">
							Cancel
						</Button>
						<Button intent={confirmBtnIntent} className={Classes.POPOVER_DISMISS} onClick={onClick}>
							{actionWord}
						</Button>
					</BtnWrapper>
				</div>
			}>
			<Button {...buttonProps} disabled={popOverDisabled}>
				{children}
			</Button>
		</Popover>
	)
}
