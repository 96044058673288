import type { Statistics, BasicStatsData, StatsType } from './types'
import type { ConversionMap } from './converters'

// functions used to get box plot data from a statistics object
export type BoxPlotSelector = (
	statistics: Statistics,
	includeOutliers: boolean,
	conversionMap: ConversionMap
) => [?number, ?number, ?number, ?number, ?number]
export const BOX_PLOT_SELECTOR: {
	[selectorType: string]: BoxPlotSelector,
} = {
	quartiles: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) => {
		const baseStats = getBasicStatsData(statistics, includeOutliers, conversionMap)

		return [baseStats.min, baseStats.q1, baseStats.q2, baseStats.q3, baseStats.max]
	},
	average: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) => {
		const baseStats = getBasicStatsData(statistics, includeOutliers, conversionMap)

		return [
			baseStats.mean - baseStats.standardDeviation,
			baseStats.mean,
			baseStats.mean,
			baseStats.mean,
			baseStats.mean + baseStats.standardDeviation,
		]
	},
}

// functions used to get line plot data from a statistics object
export type LineSelector = (
	statistics: Statistics,
	includeOutliers: boolean,
	conversionMap: ConversionMap
) => ?number
export const LINE_SELECTOR: {
	[selectorType: string]: LineSelector,
} = {
	min: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) =>
		getBasicStatsData(statistics, includeOutliers, conversionMap).min,
	q1: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) =>
		getBasicStatsData(statistics, includeOutliers, conversionMap).q1,
	median: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) =>
		getBasicStatsData(statistics, includeOutliers, conversionMap).q2,
	q3: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) =>
		getBasicStatsData(statistics, includeOutliers, conversionMap).q3,
	max: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) =>
		getBasicStatsData(statistics, includeOutliers, conversionMap).max,
	average: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) =>
		getBasicStatsData(statistics, includeOutliers, conversionMap).mean,
	standardDeviation: (
		statistics: Statistics,
		includeOutliers: boolean,
		conversionMap: ConversionMap
	) => getBasicStatsData(statistics, includeOutliers, conversionMap).standardDeviation,
	sampleCount: (statistics: Statistics, includeOutliers: boolean, conversionMap: ConversionMap) =>
		getBasicStatsData(statistics, includeOutliers, conversionMap).samples,
	outlierCountAboveMax: (statistics: Statistics) => statistics.outlierStats.countAboveMax,
	outliersUpperRange: (statistics: Statistics, _, conversionMap: ConversionMap) =>
		statistics.type && conversionMap[statistics.type]
			? conversionMap[statistics.type](statistics.outlierStats.rangeMax)
			: statistics.outlierStats.rangeMax,
	outlierCountBelowMin: (statistics: Statistics) => statistics.outlierStats.countBelowMin,
	outliersLowerRange: (statistics: Statistics, _, conversionMap: ConversionMap) =>
		statistics.type && conversionMap[statistics.type]
			? conversionMap[statistics.type](statistics.outlierStats.rangeMin)
			: statistics.outlierStats.rangeMin,
	outlierCount: (statistics: Statistics) =>
		statistics.outlierStats.countAboveMax + statistics.outlierStats.countBelowMin,
}

/**
 * convertBasicStats - apply conversion of basic stats values to user defined units (such as milliseconds (TIME) to minutes)
 *
 * @param {BasicStatsData} basicStats - the stats to convert
 * @param {?StatsType} type? - the type of data the stats represents
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {BasicStatsData}
 */
export function convertBasicStats(
	basicStats: BasicStatsData,
	type?: ?StatsType,
	conversionMap: ConversionMap
): BasicStatsData {
	const converterFunction = type && conversionMap[type]

	if (!converterFunction) {
		return basicStats
	}

	const convertedStats = { ...basicStats }
	Object.keys(convertedStats).forEach(key => {
		convertedStats[key] = converterFunction(basicStats[key])
	})
	convertedStats.samples = basicStats.samples

	return convertedStats
}

/**
 * getBasicStatsData - get the basic statistics data from the stats data depending on how the outliers should be handled
 *
 * @param {Statistics} statistics - the stats to get the basic statistics from
 * @param {boolean} useOutliers - if we should select the stats calculated with outliers or without outliers
 * @param {ConversionMap} conversionMap - a map of StatsType to functions which convert StatsType into the user wanted units
 *
 * @return {BasicStatsData}
 */
export function getBasicStatsData(
	statistics: Statistics,
	useOutliers: boolean,
	conversionMap: ConversionMap
): BasicStatsData {
	return convertBasicStats(
		useOutliers ? statistics.withOutliers : statistics.withoutOutliers,
		statistics.type,
		conversionMap
	)
}
