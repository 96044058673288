// @flow
import React, { useState, useCallback } from 'react'
import { useMutation } from 'react-query'
import StateSelector from '../../components/selectors/StateSelector'
import 'styled-components/macro'
import styled from 'styled-components'

import { mapEntries } from '../../utility/functions'
import { Loader, FlexWrapper, AppToaster } from '../../components'
import { useClientStore, selectors } from '../../stores/index.js'

import NetworkCommunicator from '../../services/NetworkCommunicator'
import { ConfirmActionButton } from '../../components/ConfirmActionButton'
import { fetchDistricts } from '../Clients/Licenses/networkCalls'
import { type DistrictType } from '../Clients/Licenses/types'
import states from '../Clients/Licenses/states'
import { createEditFields } from '../Clients/helpers/createEditFields'

import AsyncCreatableSelect from 'react-select/async-creatable'

import { Intent, InputGroup, FormGroup, Card, Elevation } from '@blueprintjs/core'
import { fetchSchools } from '../Clients/Licenses/networkCalls'

export type StateItem = { name: string, abbreviation: string, stateCode: string }

type FormData = {|
	[name: string]: {
		prompt: string,
		value: string,
	},
|}

const NEW_DISTRICT_ID = 'NEW DISTRICT'

/**
 * This component adding a new Custom School to DB
 */
export default function AddSchool(): React$Node {
	const fetchClients = useClientStore(selectors.getClientFetch)
	const IS_CUSTOM_SCHOOL = true

	const objectOrderTemplate = {
		state: '',
		stateCode: '',
		district: '',
		ncesId: '',
		name: '',
		phone: '',
		street: '',
		city: '',
		zip: '',
	}

	const [formData, setFormData] = useState<FormData>(
		createEditFields(objectOrderTemplate, IS_CUSTOM_SCHOOL)
	)

	const getState = (state: string, abbreviation: string) => {
		return { name: state || '', abbreviation: abbreviation || '' }
	}

	const handleChange = (event, name, key) => {
		event.preventDefault()
		const value: string = event.target.value
		const keyValue: string = key
		setFormData(formData => {
			return {
				...formData,
				[keyValue]: { ...formData[keyValue], value: value },
			}
		})
	}

	const handleChangeDistrict = (district: { label: string, value: string, __isNew__: boolean }) => {
		setFormData(formData => {
			return {
				...formData,
				district: { ...formData.district, value: district.label || '' },
				ncesId: {
					...formData.ncesId,
					value: district.__isNew__ ? NEW_DISTRICT_ID : district.value || '',
				},
			}
		})
	}
	const handleChangeSchoolName = name => {
		setFormData(formData => {
			return {
				...formData,
				name: {
					prompt: 'Name',
					value: name.value,
				},
			}
		})
	}
	const handleChangeState = value => {
		const state = states.find(state => state.name === value?.name)
		setFormData(formData => {
			return {
				...formData,
				state: {
					prompt: 'State',
					value: value?.name || '',
					abbreviation: value?.abbreviation || '',
				},
				stateCode: {
					prompt: 'State Code',
					value: state?.stateCode || '',
				},
				locationState: {
					prompt: 'Location',
					value: state?.abbreviation || '',
				},
			}
		})
	}

	const getEditedValues = (formData, isCustomSchool) => {
		let submittedValuesAccumulator = {}
		Object.keys(formData).forEach(key => {
			submittedValuesAccumulator = { ...submittedValuesAccumulator, [key]: formData[key].value }
		})

		if (isCustomSchool && submittedValuesAccumulator?.state) {
			// store abbreviation in state field
			const submittedState: string = submittedValuesAccumulator.state
			const state: StateItem | null = states.find(state => state.name === submittedState) || null
			if (state) {
				submittedValuesAccumulator = {
					...submittedValuesAccumulator,
					state: state?.abbreviation || '',
				}
			}
		}
		return submittedValuesAccumulator
	}

	const handleAddSchool = useMutation(
		() =>
			NetworkCommunicator.POST(`schools`, {
				body: {
					...getEditedValues(formData, IS_CUSTOM_SCHOOL),
				},
			}),
		{
			onSuccess: () => {
				fetchClients()
				setFormData(createEditFields(objectOrderTemplate, IS_CUSTOM_SCHOOL))
				AppToaster.show({
					message: 'This school was added!',
				})
			},
			onError: () => {
				AppToaster.danger({
					message: 'Something went wrong, please try again!',
				})
			},
		}
	)

	/**
	 * Checks if all the fields have a values
	 * @returns {boolean} if true that means that we have some empty field in a form, and cannot submit button
	 * if false - all fields have a value and we can submit button
	 */
	const validateSubmission = useCallback(formData => {
		let emptyValuesCount = 0
		Object.keys(formData).forEach(key => {
			if (!formData[key].value) {
				emptyValuesCount = emptyValuesCount + 1
			}
		})
		if (!emptyValuesCount) {
			return false
		} else {
			return true
		}
	}, [])

	return (
		<>
			<CardWrapper elevation={Elevation.TWO} role="container">
				{handleAddSchool.isLoading ? (
					<Loader />
				) : (
					<>
						{mapEntries(formData || {}, (data, key) => (
							<DataPoint key={key} spaceBetween>
								{(() => {
									switch (key) {
										case 'locationState':
											return null
										case 'name':
											return (
												<>
													<label htmlFor="name" label="Name">
														{data.prompt}{' '}
													</label>
													<InputContainer>
														<AsyncCreatableSelect
															cacheOptions
															defaultOptions
															name="name"
															inputId="name"
															createOptionPosition="first"
															loadOptions={(
																inputValue: string,
																callback: (Array<{ schoolName: string, schoolId: string }>) => void
															) => {
																fetchSchools(
																	inputValue,
																	{ abbreviation: formData.locationState.value },
																	fetchedSchools => {
																		// need to use label and name because of the bug with AsyncCreatableSelect visibility of disabled options:
																		callback(
																			fetchedSchools.map(school => {
																				return {
																					...school,
																					label: school.schoolName,
																					value: school.schoolId,
																				}
																			})
																		)
																	}
																)
															}}
															onChange={values => handleChangeSchoolName(values)}
															isOptionDisabled={option => !option?.__isNew__}
															className="react-select-container search-schools-container"
															classNamePrefix="react-select"
														/>
													</InputContainer>{' '}
												</>
											)
										case 'state':
											return (
												<>
													<label htmlFor="state" label="State">
														{data.prompt}{' '}
													</label>

													<InputContainer>
														<StateSelector
															stateValue={formData.state.value}
															onChangeState={value => {
																handleChangeState(value)
															}}
														/>
													</InputContainer>
												</>
											)
										case 'district':
											return (
												<>
													<label htmlFor="district" label="District">
														{data.prompt}{' '}
													</label>
													<InputContainer>
														<AsyncCreatableSelect
															name="district"
															inputId="district"
															isDisabled={!formData.state.value}
															onChange={values => handleChangeDistrict(values)}
															loadOptions={(
																inputValue: string,
																callback: (Array<DistrictType>) => void
															) => {
																fetchDistricts(
																	inputValue,
																	getState(formData.state.value, formData.locationState.value),
																	fetchedDistricts => {
																		// AsyncCreatableSelect does not handle getOptionLabel and getOptionValue very well, so we need to use `label` and `value`
																		callback(
																			fetchedDistricts.map(district => {
																				return {
																					...district,
																					label: district.district,
																					value: district.ncesId,
																				}
																			})
																		)
																	}
																)
															}}
															isSearchable
															cacheOptions
															className="react-select-container"
															classNamePrefix="react-select"
														/>
													</InputContainer>{' '}
												</>
											)
										default:
											return (
												<>
													<label htmlFor={key} label={data.prompt}>
														{data.prompt}{' '}
													</label>
													<InputContainer>
														<InputGroup
															id={key}
															value={data.value}
															disabled={key === 'ncesId' || key === 'stateCode'}
															onChange={e => handleChange(e, data.prompt, key)}></InputGroup>{' '}
													</InputContainer>
												</>
											)
									}
								})()}
							</DataPoint>
						))}{' '}
					</>
				)}
				<DataPoint spaceBetween>
					<span />
					<ConfirmActionButton
						intent={validateSubmission(formData) ? Intent.NONE : Intent.PRIMARY}
						confirmBtnIntent={Intent.PRIMARY}
						onClick={() => {
							handleAddSchool.mutate()
						}}
						popOverDisabled={validateSubmission(formData)}
						confirmMessage="Are you sure you want to add this school?">
						Save
					</ConfirmActionButton>
				</DataPoint>
			</CardWrapper>
		</>
	)
}

const DataPoint = styled(FlexWrapper)`
	padding-top: 8px;
`

const CardWrapper = styled(Card)`
	max-width: 400px;
`

const InputContainer = styled(FormGroup)`
	width: 190px;
	.react-select__control--is-focused {
		border-color: #137cbd !important;
		box-shadow: 0 0 0 1px #137cbd, inset 0 1px 1px rgb(16 22 26 /20%);
	}

	.react-select-container {
		.react-select__control {
			min-height: 0;
			height: 30px;
			border-radius: 3px;
			.react-select__indicators {
				height: 30px;
			}
		}
	}
`
