import React from 'react'
import DateTimeInput from './DateTimeInput'
import type { SharedInputFields } from './types'
import { type ProxyData } from '../../../../utility/dotProxy'
import { Row } from '../sharedComponents'

/**
 * DateRangeInput - a custom input for date a time ranges
 *
 * @param {Object} props - the react props
 * @param {ProxyData<?string>} startProxyValue - a proxyData describing the location of the range's start timestamp
 * @param {ProxyData<?string>} endProxyValue - a proxyData describing the location of the range's end timestamp
 * @param {T} values - the base container of the value
 * @param {UpdateCallback<T>} onChange - a callback used to update the base container
 *
 * @return {React$Node}
 */
export default function DateRangeInput<T>({
	startProxyValue,
	endProxyValue,
	values,
	onChange,
}: {
	startProxyValue: ProxyData<?number>,
	endProxyValue: ProxyData<?number>,
	...SharedInputFields<T>,
}): React$Node {
	return (
		<Row>
			<DateTimeInput valueProxy={startProxyValue} values={values} onChange={onChange} /> and{' '}
			<DateTimeInput valueProxy={endProxyValue} values={values} onChange={onChange} />
		</Row>
	)
}
