import React, { useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import styled from 'styled-components'

const LEAGUE_SIMULATIONS = [
	{ name: 'Jr-Plus (2024)', simulationId: '66286e50e0ffbc001deed142' },
	{ name: 'K-3 (2024)', simulationId: '66315af7e0ffbc001df1112b' },
]

/**
 * Leaderboard - a react component used to download the leagues leaderboards
 *
 * @returns React$Node
 */
export default function Leaderboard(): React$Node {
	return (
		<div>
			<div>League Simulations</div>
			{LEAGUE_SIMULATIONS.map(({ name, simulationId }) => (
				<HorizontalRow key={simulationId}>
					<h2>{name}</h2>
					<LeagueLeaderBoard simulationId={simulationId} />
				</HorizontalRow>
			))}
		</div>
	)
}

/**
 * LeagueLeaderBoard - a react component used to handle the downloading of a leagues leaderboard
 *
 * @param  {{simulationId:string}} {simulationId} - the id of the leagues simulation to download data for
 *
 * @returns React$Node
 */
function LeagueLeaderBoard({ simulationId }: { simulationId: string }): React$Node {
	const { data: leaderboard, isLoading, error } = useQuery(
		['leagues-leaderboard', simulationId],
		() => NetworkCommunicator.GET(`leaderboards/league/${simulationId}`)
	)

	const [allDataCSVUrl, qualifiedCSVUrl] = useMemo(() => {
		if (!leaderboard?.leaderboard?.order) {
			return []
		}
		return [
			URL.createObjectURL(
				new Blob([toCSV(leaderboard.leaderboard.order)], {
					type: 'text/plain',
				})
			),
			URL.createObjectURL(
				new Blob(
					[toCSV(leaderboard.leaderboard.order.filter(({ disqualified }) => !disqualified))],
					{
						type: 'text/plain',
					}
				)
			),
		]
	}, [leaderboard])

	useEffect(
		() => () => {
			if (allDataCSVUrl) {
				URL.revokeObjectURL(allDataCSVUrl)
			}
			if (qualifiedCSVUrl) {
				URL.revokeObjectURL(qualifiedCSVUrl)
			}
		},
		[allDataCSVUrl, qualifiedCSVUrl]
	)

	if (error) {
		return <div>An Unexpected Error Occurred</div>
	}

	if (!allDataCSVUrl || !qualifiedCSVUrl || isLoading) {
		return <div>Loading</div>
	}
	return (
		<>
			<a
				href={qualifiedCSVUrl}
				download={`${leaderboard?.leaderboard?.name || simulationId}-leagues-qualified.csv`}>
				Download Qualified
			</a>
			<a
				href={allDataCSVUrl}
				download={`${leaderboard?.leaderboard?.name || simulationId}-leagues-all-entries.csv`}>
				Download All Entries
			</a>
		</>
	)
}

/**
 * toCSV - convert ana array of objects into a csv string
 *
 * @param  {Array<{ [key: string]: ?(number | string | boolean) }>} data - the data to convert
 *
 * @returns string - a csv string
 */
function toCSV(data: Array<{ [key: string]: ?(number | string | boolean) }>): string {
	const fieldSet = new Set()
	data.forEach(value => Object.keys(value).forEach(field => fieldSet.add(field)))
	const fields = Array.from(fieldSet)

	const CSVRows = []

	let csvHeaders = []
	fields.forEach(fieldName => csvHeaders.push(fieldName))
	CSVRows.push(csvHeaders.join(','))

	data.forEach(data => {
		const row = []
		fields.forEach(field => {
			if (data[field] != null) {
				row.push(`"${String(data[field])}"`)
			} else {
				row.push('')
			}
		})
		CSVRows.push(row.join(','))
	})

	return CSVRows.join('\n')
}

const HorizontalRow = styled.div`
	display: flex;
	align-items: baseline;
	gap: 16px;
`
