import React from 'react'
import type { MissionType } from '../../../../stores/types'
import styled from 'styled-components'
import 'styled-components/macro'

import { darken } from 'polished'
import { FlexWrapper } from '../../../../components/basic'
import { eventTypesEnum } from '../../../../stores/types'
import type { ClientType } from '../../../../stores/types'
import { Card, Icon, Intent, Tag, Tooltip } from '@blueprintjs/core'
import { useClientStore, selectors } from '../../../../stores'
import { missionHasIssue, missionHasSuggestion, missionHasFeedback } from '../../helpers'
import { MissionCategoryIconMap } from './constants'

type IndicatorProps = {
	icon: string,
	intent: string,
	tooltip: string,
}

const INDICATORS = [
	{
		showFor: missionHasIssue,
		icon: 'warning-sign',
		intent: Intent.DANGER,
		tooltip: 'Issues reported',
	},
	{
		showFor: missionHasSuggestion,
		icon: 'comment',
		intent: Intent.WARNING,
		tooltip: 'Suggestions provided',
	},
	{
		showFor: missionHasFeedback,
		icon: 'comment',
		intent: Intent.INFO,
		tooltip: 'Feedback provided',
	},
]

export default function MissionListItem({
	mission,
	selected,
	children,
	onSelect,
}: {
	mission: MissionType,
	selected: boolean,
	children?: React$Node,
	onSelect: () => mixed,
}): React$Node {
	const status = getMissionStatus(mission.events)
	const missionOwner: ?ClientType = useClientStore(state =>
		selectors.getClient(state, mission.owner)
	)
	const type = mission.controlSet

	const MissionCategoryIcon = StyledMissionCategoryIconMap[mission.missionCategory]

	return (
		<MissionThumbnailStyle onClick={onSelect}>
			<FlexWrapper spaceBetween>
				{missionOwner ? missionOwner.schoolName : 'Pending'}: {status}
				<IndicatorFlex>
					{INDICATORS.map(({ showFor, ...indicator }, i) =>
						showFor(mission) ? <Indicator key={`indicator-${i}`} {...indicator} /> : null
					)}
					<Tag minimal css="margin-right: 8px;">
						{mission.ranOnWeb ? 'WEB' : 'DESKTOP'}
					</Tag>
					<Tag>{type}</Tag>
					{MissionCategoryIcon && <MissionCategoryIcon />}
				</IndicatorFlex>
			</FlexWrapper>
			{children && (
				<Expander show={selected}>
					<hr />
					{children}
				</Expander>
			)}
		</MissionThumbnailStyle>
	)
}

const Indicator = (props: IndicatorProps) => {
	return (
		<Tooltip content={props.tooltip}>
			<Icon icon={props.icon} intent={props.intent} css="margin-right: 8px" />
		</Tooltip>
	)
}

const enumMessages = {
	BEGIN_PREP: 'Prepped',
	OPEN_MISSION: 'Opened',
	START_MISSION: 'Started',
	RESTORE_MISSION: 'Restored',
	SAVE_FOR_LATER: 'Saved',
	END_MISSION: 'Ended',
}

function getMissionStatus(
	missionEvents: Array<{
		type: $Keys<typeof eventTypesEnum>,
		timestamp: Date,
	}>
): string {
	const lastEvent = missionEvents[missionEvents.length - 1]
	// if (lastEvent.type === eventTypesEnum.OPEN_MISSION && students are connected) { TODO: Add this once we know when students are connected
	// return 'Active'
	// }
	if (!lastEvent) return 'Unavailable'
	return enumMessages[lastEvent.type]
}

const Expander = styled.div`
	overflow-y: auto;
	max-height: ${({ show }) => (show ? '500px' : '0px')};
	transition: all 0.3s ease-in-out;
	hr {
		border-top: 1px solid ${({ theme }) => theme.brandGrey};
	}
`

const MissionThumbnailStyle = styled(Card)`
	cursor: pointer;
	background: ${({ theme }) => theme.white};
	color: ${({ theme }) => theme.primary};
	margin: 4px 8px;
	:hover {
		background: ${({ theme }) => darken(0.1, theme.white)};
		transform: scale(1.02);
	}
	transition: all 0.3s ease;
`

const IndicatorFlex = styled.div`
	display: flex;
	align-items: center;
`

const StyleMissionCategoryIcon = icon => styled(icon)`
	${({ theme }) => `
		width: calc(1.25 * ${theme.baseFontSize});
		height: calc(1.25 * ${theme.baseFontSize});
		margin-left: ${theme.spacing};
	`}
`

const StyledMissionCategoryIconMap: typeof MissionCategoryIconMap = ((): typeof MissionCategoryIconMap => {
	const map = {}
	Object.keys(MissionCategoryIconMap).forEach((category: string) => {
		map[category] = StyleMissionCategoryIcon(MissionCategoryIconMap[category])
	})
	return map
})()
