import React from 'react'
import { useEffect, useState } from 'react'

import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { DocumentSelectionFieldType, Enumeration } from '../../clientTypes'
import type { Criteria, UpdateCriteria } from '../../localTypes'
import { assign, get, minimizeDelete } from '../../../../../../utility/dotProxy'
import { IncludeExcludingSwitch } from '../includingExcludingSwitch'
import UserSelector from './Users'
import SchoolSelector from './Schools'
import SimulationSelector from './Simulations'
import DistrictSelector from './Districts'
import { FlexRowCenterer } from '../../sharedComponents'

const DOCUMENT_SELECTOR = {
	USER: 'USER',
	SCHOOL: 'SCHOOL',
	DISTRICT: 'DISTRICT',
	SIMULATION: 'SIMULATION',
}

const DOCUMENT_SELECTORS: {
	[resourceType: string]: (props: {
		selection: ?Array<string>,
		onChange: (newSelection: ?Array<string>) => any,
	}) => React$Node,
} = {
	[DOCUMENT_SELECTOR.USER]: UserSelector,
	[DOCUMENT_SELECTOR.SCHOOL]: SchoolSelector,
	[DOCUMENT_SELECTOR.DISTRICT]: DistrictSelector,
	[DOCUMENT_SELECTOR.SIMULATION]: SimulationSelector,
}

/**
 * DocumentSelector - a react component used to select documents from collection described in the fieldType
 *
 * @param {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - the current criteria
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data for ths range
 * @param  {fieldType} props.fieldType - the DocumentSelectionFieldType containing the resource name documents to select from
 *
 * @returns React$Node
 */
export default function DocumentSelector({
	updateCriteria,
	currentCriteria,
	currentPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?Enumeration<string>>,
	fieldType: DocumentSelectionFieldType,
}): React$Node {
	const [excludingFallback, setExcludingFallback] = useState(false)
	const currentField = get(currentPath, currentCriteria)
	useEffect(() => {
		if (!currentField) {
			return
		}
		if (currentField.include) {
			setExcludingFallback(false)
			return
		}
		if (currentField.exclude) {
			setExcludingFallback(true)
			return
		}
	}, [currentField])

	const SelectionComponent = DOCUMENT_SELECTORS[fieldType.resource]
	if (!SelectionComponent) {
		return <div>Could not find component for selecting resources of type {fieldType.resource}</div>
	}

	return (
		<FlexRowCenterer>
			<IncludeExcludingSwitch
				excluding={excludingFallback}
				onChange={excluding => {
					if (currentField) {
						const array = currentField.include || currentField.exclude
						updateCriteria((oldCriteria: Criteria) =>
							assign(currentPath, oldCriteria, excluding ? { exclude: array } : { include: array })
						)
					}
					setExcludingFallback(excluding)
				}}
			/>
			<SelectionComponent
				selection={currentField?.include ? currentField.include : currentField?.exclude ?? []}
				onChange={(newSelection: ?Array<string>) => {
					if (!newSelection?.length) {
						updateCriteria((oldCriteria: Criteria) => minimizeDelete(currentPath, oldCriteria))
						return
					}
					let field = { ...currentField }
					if (field.include) {
						field.include = newSelection
					} else if (field.exclude) {
						field.exclude = newSelection
					} else if (excludingFallback) {
						field = { exclude: newSelection }
					} else {
						field = { include: newSelection }
					}
					updateCriteria((oldCriteria: Criteria) => assign(currentPath, oldCriteria, field))
				}}
			/>
		</FlexRowCenterer>
	)
}
