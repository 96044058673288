import React, { useEffect } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { Loader } from '../../../components'

import { useHistory } from 'react-router-dom'
import { getLicenseString, canViewUsers } from './licenseHelpers'
import { useRouteQuery, useSearchParameterUpdate } from '../../../utility/hooks'
import { AdvancedTable } from '../../../components/basic'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import ViewUsersDialog from './ViewUsers'
import { Icon, Button, ButtonGroup } from '@blueprintjs/core'
import EditLicenseModal from './EditLicenseModal'
import { type LicenseFlow, type LicenseOwnerWithDetail, licenseTypesEnum } from './types'
import 'styled-components/macro'
import { prettifyTypeEnum } from '../../../utility/functions'
import { ONE_MINUTE } from '../../../utility/constants'

const columns = [
	{
		Header: 'Owner',
		accessor: 'ownerName',
	},
	{
		Header: 'Type',
		accessor: 'type',
	},
	{
		Header: 'District',
		accessor: 'district',
	},
	{
		Header: 'Expiration Date',
		accessor: 'expiresOn',
		Cell: function ExpirationComponent(props: ?{ value: number }) {
			if (!props?.value) {
				return <div />
			}
			return (
				<div>
					{props?.value}
					{new Date(props?.value) < new Date() && (
						<Icon css="padding-left: 8px;" icon="warning-sign" title="License Expired" />
					)}
				</div>
			)
		},
	},
	{
		Header: '',
		hideCSV: true,
		disableFilters: true,
		accessor: 'actions',
		Cell: function ActionComponent(
			props: ?{ value: { canViewUsers: boolean, clickViewUsers: () => ?any } }
		) {
			return (
				<ButtonGroup css="float: right;">
					<Button icon="edit" text="Edit" />
					{props?.value.canViewUsers && (
						<Button
							icon="eye-open"
							text="View Users"
							onClick={e => {
								e.stopPropagation()
								return props?.value.clickViewUsers()
							}}
						/>
					)}
				</ButtonGroup>
			)
		},
	},
]

/**
 * Pulls latest license data from the 'server' and compiles it into a searchable, orderable list
 */
export default function LicenseList(): React$Node {
	const updateParameter = useSearchParameterUpdate()
	const query = useRouteQuery()
	const history = useHistory()
	const licenseId = query.get('edit')
	const newLicense = query.get('new')
	const viewUsersForLicenseId: ?string =
		query.get('view-users') === 'true' ? licenseId : query.get('view-users')
	const { isLoading, error, data } = useQuery(
		'licenses',
		ctx => {
			return NetworkCommunicator.GET('licenses')
		},
		{
			staleTime: 30 * ONE_MINUTE,
			refetchOnWindowFocus: false,
		}
	)
	const licenseData: LicenseFlow<LicenseOwnerWithDetail>[] = React.useMemo(
		() =>
			(data &&
				data.licenses.map(row => {
					const newRow = { ...row }
					const licenseType = row.owner.type
					newRow.type = prettifyTypeEnum(licenseType)
					newRow.expiresOn = moment(row.expiresOn).format('l')
					switch (licenseType) {
						case licenseTypesEnum.FREE:
						case licenseTypesEnum.SINGLE_OWNER:
							const user = row.owner.user
							newRow.ownerName = user ? `${user.firstName} ${user.lastName}` : 'Unknown'
							newRow.district = row.owner.districtName
							break
						case licenseTypesEnum.SCHOOL:
							newRow.ownerName = row.owner.schoolName
							newRow.district = row.owner.districtName
							break
						case licenseTypesEnum.DISTRICT:
							newRow.ownerName = row.owner.districtName
							newRow.district = row.owner.districtName
							break
						default:
							newRow.ownerName = `Unknown`
					}
					newRow.actions = {
						canViewUsers: canViewUsers(row),
						clickViewUsers: function() {
							return updateParameter({ type: 'SET', paramKey: 'view-users', paramValue: row._id })
						},
					}
					return newRow
				})) ||
			[],
		[data, updateParameter]
	)
	const [license, setLicense] = React.useState()
	const [viewUsersLicenseName, setViewUsersLicenseName] = React.useState()

	useEffect(() => {
		if (licenseId && data && data.licenses) {
			setLicense(data.licenses.find(lic => lic._id === licenseId))
		} else {
			setLicense(null)
		}
	}, [licenseId, data])

	useEffect(() => {
		let viewLicense
		if (data && viewUsersForLicenseId) {
			if (viewUsersForLicenseId === license?._id) {
				viewLicense = license
			} else {
				viewLicense = data.licenses.find(lic => lic._id === viewUsersForLicenseId)
			}
		}
		if (viewLicense) {
			setViewUsersLicenseName(
				getLicenseString(
					viewLicense.owner.type,
					[licenseTypesEnum.SINGLE_OWNER, licenseTypesEnum.FREE].includes(viewLicense.owner.type)
						? viewLicense.owner.user
						: null,
					viewLicense.owner.type === 'SCHOOL' ? viewLicense.owner : null,
					viewLicense.owner.type === 'DISTRICT' ? viewLicense.owner : null
				)
			)
		}
	}, [viewUsersForLicenseId, data, license])

	if (isLoading) {
		return <Loader />
	}
	if (error) {
		return <p>Error loading License page</p>
	}

	return (
		<>
			<AdvancedTable
				interactive
				enableColumnFilters
				striped
				bordered
				disableMultiSort
				data={licenseData}
				columns={columns}
				rowProps={row => ({
					onClick: () => {
						if (row?.original._id) {
							history.replace(`/clients?tab=licenses&edit=${row.original._id}`)
						}
					},
				})}
				topRight={
					<Button
						icon="add"
						onClick={() => {
							history.push(`/clients?tab=licenses&new=true`)
						}}>
						Add License
					</Button>
				}
			/>
			{(license || newLicense) && (
				<EditLicenseModal
					license={license}
					onClose={() => {
						history.replace('/clients?tab=licenses')
					}}
				/>
			)}
			{viewUsersForLicenseId && (
				<ViewUsersDialog
					licenseId={viewUsersForLicenseId}
					licenseName={viewUsersLicenseName || ''}
				/>
			)}
		</>
	)
}
