import type { AlgorithmDefinition } from './sharedTypes'

type Asset3dState = {
	requiresConcept: boolean,
	requiresCustomTexture: boolean,
	difficulty: number,
}

export const ASSET_3D_ALGORITHM_DEFINITION: AlgorithmDefinition<Asset3dState> = {
	name: '3D Asset Calculator-inator',
	fields: {
		requiresConcept: {
			type: 'boolean',
			label: 'Requires a Fleshed out Concept',
			weight: 5,
			default: false,
		},
		requiresCustomTexture: {
			type: 'boolean',
			label: 'Requires Custom Texture',
			weight: 4,
			default: false,
		},
		difficulty: {
			type: 'number',
			max: 3,
			min: 1,
			label: 'Difficulty',
			default: 1,
			weight: value => {
				if (value === 0 || value === 1) {
					return value
				} else if (value === 2) {
					return 3
				} else {
					return 5
				}
			},
		},
	},
}
