import React from 'react'
import type { LinkObject, Key } from './types'
import { Spinner, Colors, Breadcrumbs, CollapsibleList, Classes, MenuItem } from '@blueprintjs/core'
import styled, { type StyledComponent } from 'styled-components'

/**
 * LinksViewer - a react component to show links which (when clicked) will navigate to the linked location
 *
 * @param {Object} props - the react props
 * @param {LinkObject[]} props.links - the links to display
 * @param {(key: Key) => void} props.setCurrentLocation - a callback to navigate to the given location
 *
 * @return {React$Node}
 */
export function LinksViewer({
	links,
	setCurrentLocation,
}: {
	links: LinkObject[],
	setCurrentLocation: (key: Key) => void,
}): React$Node {
	const uniqueLinks = {}
	links.forEach(link => {
		uniqueLinks[link.name] = link.link
	})

	return (
		<div>
			{Object.keys(uniqueLinks).map(name => {
				const linkTo = uniqueLinks[name]

				return (
					<Link onClick={() => setCurrentLocation(linkTo)} key={linkTo.join('/') + name}>
						{name}: {linkTo.join('/')}
					</Link>
				)
			})}
		</div>
	)
}

/**
 * LocationBreadCrumbs - a react component to show the breadcrumbs of the current location
 *
 * @param {Object} props - the react props
 * @param {Key} props.location - the current location being displayed
 * @param {string[]} props.childrenOptions - the children of the current location
 * @param {(key: Key) => void} props.setCurrentLocation - a callback to navigate to the given location
 *
 * @return {React$Node}
 */
export function LocationBreadCrumbs({
	location,
	childrenOptions,
	setCurrentLocation,
}: {
	location: Key,
	childrenOptions: string[],
	setCurrentLocation: (newLocation: Key) => void,
}): React$Node {
	return (
		<BreadcrumbsRow>
			<Breadcrumbs
				items={['All', ...location].map((segment: string, index: number) => ({
					text: segment,
					onClick: () => {
						setCurrentLocation(location.slice(0, index))
					},
				}))}
			/>
			{childrenOptions.length ? (
				<CollapsibleList
					visibleItemCount={0}
					className={Classes.BREADCRUMBS}
					dropdownTarget={<span className={Classes.BREADCRUMBS_COLLAPSED} />}>
					{childrenOptions.map(option => (
						<MenuItem
							key={option}
							text={option}
							onClick={() => {
								setCurrentLocation([...location, option])
							}}
						/>
					))}
				</CollapsibleList>
			) : null}
		</BreadcrumbsRow>
	)
}

/**
 * Loading - a component to use when data is loading
 *
 * @param {?string} message? - a message to show while loading
 *
 * @return {React$Node}
 */
export function Loading({ message }: { message?: string }): React$Node {
	return (
		<NetworkFormatting>
			<Spinner size={20} />
			{message ?? 'Loading'}
		</NetworkFormatting>
	)
}

/**
 * ErrorComponent - a component to use when there is an error
 *
 * @param {?string} message? - a message to show while loading
 *
 * @return {React$Node}
 */
export function ErrorComponent({ message }: { message?: ?string }): React$Node {
	return (
		<ErrorFormatting>
			An error occurred while loading data{message ? `: ${message}` : '.'}
		</ErrorFormatting>
	)
}

const NetworkFormatting = styled.div`
	display: flex;
	gap: 1em;
	margin-top: 2em;
`

export const ErrorFormatting: StyledComponent<any, any, any> = styled(NetworkFormatting)`
	color: ${Colors.RED1};
`

export const WarningFormatting: StyledComponent<any, any, any> = styled(NetworkFormatting)`
	color: ${Colors.ORANGE1};
`

export const Row: StyledComponent<any, any, any> = styled.div`
	display: flex;
	align-items: center;
	gap: 1em;
`

const BreadcrumbsRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`

const Link = styled.a`
	cursor: pointer;
	display: block;
	margin-bottom: 4px;
`
