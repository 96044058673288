/**
 * hasEmailDomain - check that the email has the given domain
 *
 * @param  {string} email - the email to check
 * @param  {string} expectedDomain - the wanted domain (only the string after the "@" sign)
 *
 * @returns boolean - true if the email has the expected domain, false otherwise
 */
export function hasEmailDomain(email: string, expectedDomain: string): boolean {
	// must have at least one character before the domain
	if (email.length <= expectedDomain.length + 1) {
		return false
	}
	// must end with the domain
	if (!email.endsWith(`@${expectedDomain}`)) {
		return false
	}
	// must only have one domain
	if (email.indexOf('@') !== email.length - expectedDomain.length - 1) {
		return false
	}
	return true
}
