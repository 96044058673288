import React, { useCallback, useState } from 'react'
import {
	Dialog,
	Tabs,
	Tab,
	ButtonGroup,
	Button,
	Classes,
	Intent,
	Icon,
	FormGroup,
	TagInput,
} from '@blueprintjs/core'
import { useQuery } from 'react-query'
import {
	useAdminRoleMutation,
	useRemoveUserMutation,
	useRemoveInviteMutation,
	useAddInvitesMutation,
} from './hooks'
import { useSearchParameterUpdate } from '../../../utility/hooks'
import styled from 'styled-components/macro'
import { AppToaster, Loader } from '../../../components'
import { ConfirmActionButton } from '../../../components/ConfirmActionButton'
import { rolesEnum } from '../../../stores/types'
import { addInvitesNetworkCall, getUsersForLicenseCall } from './networkCalls'
import type { LicenseClient } from './types'

const TABS = {
	USERS: 'USERS',
	INVITES: 'INVITES',
}

type ViewUsersProps = {
	licenseId: string,
	licenseName: string,
}

/**
 * A dialog which shows all the users on a license and the pending invitations.
 * @param {{licenseId: string, licenseName: string}} props
 */
export default function ViewUsers({ licenseId, licenseName }: ViewUsersProps): React$Node {
	const updateParameter = useSearchParameterUpdate()
	const close = useCallback(() => {
		updateParameter({ type: 'DELETE', paramKey: 'view-users' })
	}, [updateParameter])

	const { isLoading, error, data } = useQuery('get-users', () => getUsersForLicenseCall(licenseId))
	const [tabId, setTabId] = useState(TABS.USERS)

	return (
		<Dialog
			title={`${licenseName} Users`}
			isOpen="true"
			onClose={close}
			className={Classes.DIALOG}
			isCloseButtonShown>
			<Body>
				{isLoading ? (
					<Loader />
				) : error ? (
					<p css="padding: 16px;">Error fetching users for license</p>
				) : (
					<Tabs id="tabs" onChange={setTabId} selectedTabId={tabId}>
						<Tab
							id={TABS.USERS}
							title="Users"
							panel={<ClientsPanel clients={data?.users || []} licenseId={licenseId} />}
						/>
						<Tab
							id={TABS.INVITES}
							title="Invitations"
							panel={
								<InvitesPanel
									pendingInvitations={data?.pendingInvitations || []}
									licenseId={licenseId}
								/>
							}
						/>
					</Tabs>
				)}
			</Body>
		</Dialog>
	)
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * A function to check if email is valid
 * @param {string} email
 * @returns {boolean}
 */
function emailIsValid(email: string): boolean {
	return emailRegex.test(email.toLowerCase())
}

/**
 * Takes in an email string and returns props for the tag which will display the email.
 * @param {string} email
 * @returns { {intent: string, aria-label: string, role: 'tag' }} props for tag
 */
function getTagProps(email: string): { intent: string } {
	const validEmail = emailIsValid(email)
	const props = {
		role: 'tag',
	}
	if (!validEmail) {
		return { ...props, intent: Intent.DANGER, 'aria-label': `${email} bad` }
	} else {
		return { ...props, intent: Intent.PRIMARY, 'aria-label': `${email} ok` }
	}
}
const INPUT_SPLITTER = /[,\n\r,\s]/
/**
 * A component to display pending invitations, takes a list of strings which represent emails that have been invited to join a license.
 * @param {{pendingInvitations: string[]}}
 */
export function InvitesPanel({
	pendingInvitations,
	licenseId,
}: {
	pendingInvitations: string[],
	licenseId: string,
}): React$Node {
	const [emails, setEmails] = useState([])
	const [input, setInput] = useState('')
	const onInviteSuccess = useCallback(
		(emailsThatShouldStay: ?(string[])) => {
			setEmails(emailsThatShouldStay || [])
		},
		[setEmails]
	)
	const addInvitesMutation = useAddInvitesMutation(licenseId, onInviteSuccess)
	return (
		<>
			<FormGroup label="Invite people" labelFor="tag-input">
				<TagInput
					inputId="tag-input"
					placeholder="List as many email addresses as you'd like"
					leftIcon="envelope"
					separator={INPUT_SPLITTER}
					addOnBlur
					addOnPaste
					tagProps={getTagProps}
					inputValue={input}
					onInputChange={e => setInput(e.target.value)}
					values={emails}
					onChange={updatedTags => {
						setEmails(updatedTags)
						const currentTags = emails
						// clear input if a tag is added
						if (updatedTags.length > currentTags.length) {
							setInput('')
						}
					}}
					onKeyDown={(event, activeTag) => {
						// user is focused on input if activeTag is falsy
						if (!activeTag) {
							if (event.key === 'Tab') {
								// Prevent focus on current element to change
								event.preventDefault()
							}
							if (event.key === 'Tab' || event.key === ' ') {
								setEmails(emails => {
									const newEmails = input.split(INPUT_SPLITTER).filter(Boolean)
									if (newEmails.length === 0) {
										return emails
									}
									setInput('')
									return [...emails, ...newEmails]
								})
							}
						}
					}}
				/>
				<Button
					css="float: right;"
					intent="primary"
					icon="send-message"
					onClick={() => {
						const noDuplicates = new Set(emails)
						addInvitesMutation.mutate({ emails: Array.from(noDuplicates) })
					}}>
					Send
				</Button>
			</FormGroup>
			<List>
				{pendingInvitations.map(email => (
					<InviteItem key={email} email={email} licenseId={licenseId} />
				))}
			</List>
		</>
	)
}

/**
 * Renders a single invitation (email address) to a license
 * @param {{licenseId: string, email: string}} props
 */
export function InviteItem({ licenseId, email }: { licenseId: string, email: string }): React$Node {
	const removeInviteMutation = useRemoveInviteMutation(email, licenseId)
	return (
		<Row>
			<span>{email}</span>
			<ButtonGroup>
				<ConfirmActionButton
					minimal
					onClick={() => {
						addInvitesNetworkCall({ emails: [email], licenseId }).then(res => {
							if (res.resentEmails && res.resentEmails.includes(email)) {
								AppToaster.show({ message: `Resent email to ${email}`, intent: Intent.SUCCESS })
							} else {
								AppToaster.danger({ message: `Failed to resend email to ${email}` })
							}
						})
					}}
					icon="refresh"
					actionWord="Resend Email"
					confirmMessage={`Are you sure you want to send another email to ${email}? The invitation link will remain the same.`}>
					Resend
				</ConfirmActionButton>
				<ConfirmActionButton
					minimal
					onClick={removeInviteMutation.mutate}
					icon="cross"
					actionWord="Remove"
					confirmMessage={`Are you sure you want to remove the invitation to ${email}?`}>
					Delete
				</ConfirmActionButton>
			</ButtonGroup>
		</Row>
	)
}

/**
 * Determines if user on license is a license admin
 * @param {LicenseClient} client
 * @returns boolean
 */
function isLicenseAdmin(client: LicenseClient) {
	return client.roles.some(
		roleData => roleData.role === rolesEnum.LICENSE_ADMIN && roleData.verified
	)
}

/**
 * Sorts license alphabetically, and put all license admins at the top.
 * @param {LicenseClient} a
 * @param {LicenseClient} b
 */
function licenseUserSort(a: LicenseClient, b: LicenseClient) {
	let sortValue = 0
	let isAdminWeight = 2
	let alphabeticalWeight = 1
	if (a.lastName < b.lastName) sortValue += -alphabeticalWeight
	if (a.lastName > b.lastName) sortValue += alphabeticalWeight
	const isALicenseAdmin = isLicenseAdmin(a)
	const isBLicenseAdmin = isLicenseAdmin(b)
	if (isALicenseAdmin && !isBLicenseAdmin) sortValue += -isAdminWeight
	if (!isALicenseAdmin && isBLicenseAdmin) sortValue += isAdminWeight
	return sortValue
}

/**
 * A component to display users on a license
 * @param {{
 * users: LicenseClient[],
 * licenseId: string}}
 */
function ClientsPanel({ clients, licenseId }: { clients: LicenseClient[], licenseId: string }) {
	return (
		<List>
			{clients.sort(licenseUserSort).map(client => (
				<ClientItem key={client._id} client={client} licenseId={licenseId} />
			))}
		</List>
	)
}

/**
 * A component which renders one client on a license.
 * User may revoke/add admin privileges for a petra user and remove given user from the license.
 * @param {{client: LicenseClient, licenseId: string}}
 */
function ClientItem({ client, licenseId }: { client: LicenseClient, licenseId: string }) {
	const clientName = `${client.firstName} ${client.lastName} (${client.email})`
	const adminRoleMutation = useAdminRoleMutation(client, licenseId)
	const removeUserMutation = useRemoveUserMutation(client, licenseId)

	const isAdminRole = isLicenseAdmin(client)

	const toggleRole = () => {
		if (isAdminRole) {
			adminRoleMutation.mutate({ makeAdmin: false })
		} else {
			adminRoleMutation.mutate({ makeAdmin: true })
		}
	}

	return (
		<Row>
			<div>
				{isAdminRole && (
					<Icon
						role="img"
						aria-label="key"
						icon="key"
						title="user is license admin"
						intent={Intent.PRIMARY}
						css="margin-right: 8px;"
					/>
				)}
				{clientName}
			</div>
			<ButtonGroup>
				<ConfirmActionButton
					onClick={toggleRole}
					confirmMessage={`Are you sure you want to ${
						isAdminRole ? 'revoke admin privileges for' : 'give admin privileges to'
					} ${clientName}?`}>
					{isAdminRole ? 'Revoke Admin' : 'Make Admin'}
				</ConfirmActionButton>
				<ConfirmActionButton
					actionWord="Remove"
					confirmMessage={`Are you sure you want to remove ${clientName} from this license?`}
					onClick={removeUserMutation.mutate}>
					<Icon icon="cross" title="Remove from license" />
				</ConfirmActionButton>
			</ButtonGroup>
		</Row>
	)
}

const Body = styled.div`
	overflow-y: auto;
	height: 40vh;
	.bp3-tab-list {
		padding: ${({ theme }) => `${theme.spacing2x} ${theme.spacing2x} 0px ${theme.spacing2x}`};
		background-color: #ebf1f5;
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 5;
		border-radius: 6px;
	}
	.bp3-tab-panel {
		padding: 0px ${({ theme }) => `${theme.spacing}`};
	}
`

const Row = styled.li`
	:nth-child(odd) {
		background-color: ${({ theme }) => theme.brandWhite};
	}
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${({ theme }) => `${theme.spacing}`};
`

const List = styled.ul`
	height: 100%;
	list-style: none;
`
