import React from 'react'
import { lighten } from 'polished'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Navbar, Classes, Alignment, Button } from '@blueprintjs/core'
import { DLogo, Logo } from '../assets'

const Nav = styled(NavLink)`
	text-decoration: none !important;
`

const Heading = styled(Nav)`
	display: flex;
	flex-direction: row;
	align-items: center;
	svg {
		width: 24px;
		margin-right: 8px;
	}
	color: ${({ theme }) => theme.primary};
	:hover {
		color: ${({ theme }) => lighten(0.4, theme.primary)};
	}
`

export default function Header(): React$Node {
	return (
		<Navbar>
			<Navbar.Group align={Alignment.LEFT}>
				<Heading to="/home">
					<Logo />
					<h2>Petra</h2>
				</Heading>
				<Navbar.Divider />
			</Navbar.Group>
			<Navbar.Group align={Alignment.RIGHT}>
				<Nav to="/reports">
					<Button className={Classes.MINIMAL} icon="grouped-bar-chart" text="Reports" />
				</Nav>
				<Nav className={Classes.MINIMAL} to="/clients">
					<Button className={Classes.MINIMAL} icon="people" text="Clients" />
				</Nav>
				<Nav to="/goals">
					<Button className={Classes.MINIMAL} icon="flag" text="Goals" />
				</Nav>
				<Nav to="/teams">
					<Button className={Classes.MINIMAL} icon={<DLogo width={20} />} text="Us" />
				</Nav>
				<Navbar.Divider />
				<Nav to="/employees/me">
					<Button className={Classes.MINIMAL} icon="user" />
				</Nav>
			</Navbar.Group>
			<Navbar.Group align={Alignment.RIGHT}></Navbar.Group>
		</Navbar>
	)
}
