import React from 'react'
import { Switch, Route, useParams } from 'react-router-dom'

import TeamPage from './TeamPage'
import TeamList from './TeamList'
import { Button } from '@blueprintjs/core'
import { useCurrentUser } from '../../stores/auth'
import { useTeams, useTeam } from './../../resources/employees'

import { Loader, PageContainer } from '../../components'
import 'styled-components/macro'
import styled from 'styled-components'

export default function Teams(): React$Node {
	const currentUser = useCurrentUser()

	const params = useParams()
	const teamId = params.teamId
	const { isLoading: isLoadingTeams, teams, error: teamsError } = useTeams({
		enabled: !!currentUser,
	})
	const { isLoading: isLoadingTeam, data: team, error: teamError } = useTeam(teamId, {
		enabled: !!currentUser,
	})

	const queryInfo = teamId
		? { isLoading: isLoadingTeam, error: teamError }
		: { isLoading: isLoadingTeams, error: teamsError }

	return (
		<PageContainer>
			{queryInfo.isLoading ? (
				<Loader />
			) : queryInfo.error || !currentUser ? (
				<h1>
					Sorry, there seems to have been a problem loading the teams
					<Button>Click here to retry</Button>
				</h1>
			) : (
				<EmployeeContainer>
					<Switch>
						<Route path="/teams" exact>
							{teams ? <TeamList teams={teams} /> : 'TROUBLE GETTING Teams'}
						</Route>
						<Route path="/teams/:teamId">
							{team ? <TeamPage team={team} currentUser={currentUser} /> : 'TROUBLE Getting team'}
						</Route>
					</Switch>
				</EmployeeContainer>
			)}
		</PageContainer>
	)
}

const EmployeeContainer = styled.div`
	width: 100%;
`
