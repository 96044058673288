import React from 'react'
import type { Filter, SelectFilterType } from '../../../../stores/types'
import { Select } from '../../../../components'
import type { SelectValue } from './types'

type Props<T> = {
	select: (IdMap<Filter>) => void,
	unselect: (string[]) => void,
	filterType: SelectFilterType,
	value: SelectValue<T>,
}

export default function Dropdown<T>(props: Props<T>): React$Node {
	const { filterType, select, unselect } = props
	const options = props.value

	let result = null
	options.selectChain.forEach(select => {
		// $FlowFixMe we still want to pass 'result' because some functions DO take a second parameter
		result = select.useStore(store => select.fn(store, result))
	})
	const values = result || []

	const onChange = value => {
		if (!value) {
			unselect([filterType])
			return
		}
		select({ [filterType]: { type: filterType, value: (options.getValue(value): string) } })
	}

	return (
		<Select
			clearable
			fill
			onChange={onChange}
			placeholder={options.placeholder}
			options={values}
			getOptionLabel={options.getLabel}
		/>
	)
}
