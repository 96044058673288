// @flow
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useMutation, useQueryClient } from 'react-query'
import { Select, AppToaster } from '../../../components'
import ClientSearch from '../../../components/ClientSearch'
import { useSearchParameterUpdate } from '../../../utility/hooks'
import { usePermissionDefaults, useSimulationGroups } from './hooks'
import { mapEntries, prettifyCamelCase } from '../../../utility/functions'
import {
	Button,
	Switch,
	Divider,
	Dialog,
	TextArea,
	FormGroup,
	Label,
	Classes,
} from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { DateInput } from '@blueprintjs/datetime'
import states from './states'
import AsyncSelect from 'react-select/async'
import ReactSelect, { createFilter, components } from 'react-select'
import {
	getLicenseString,
	licenseFields,
	isValidLicense,
	getLicenseType,
	canViewUsers,
	getPermissions,
} from './licenseHelpers'
import { fetchDistricts, fetchSchools, postOrUpdateLicense } from './networkCalls'
import { IntegrationsDialog } from './LicenseIntegrationsDialog'

import {
	licenseTypesEnum,
	type DistrictType,
	type LicenseDetailFlow,
	type LicenseType,
	type PermissionsFlow,
} from './types'
const futureDate = new Date(new Date().getFullYear() + 10, 11, 31)
/**
 * Modal for creating/editing license settings (owner, description, permissionsOverride, expiresOn, and deleted)
 * If a license is provided the modal will edit the existing license, otherwise it will create a new license
 */
export default function EditLicenseModal({
	license,
	onClose,
}: {
	license: ?LicenseDetailFlow,
	onClose: () => void,
}): React$Node {
	const queryClient = useQueryClient()
	const permissionDefaults = usePermissionDefaults()

	const updateParameter = useSearchParameterUpdate()
	const handlePostOrUpdateLicense = useMutation(postOrUpdateLicense, {
		onSuccess: () => {
			queryClient.invalidateQueries('licenses')
		},
		onError: error => {
			AppToaster.danger({
				message: error.message,
			})
		},
	})

	const [showIntegrationsDialog, setShowIntegrationsDialog] = useState(false)

	const isUpdatingLicense = Boolean(license)

	// License properties
	const [description, setDescription] = useState(licenseFields.description(license))
	const [licenseType, setLicenseType] = useState(licenseFields.owner.type(license))
	const [client, setClient] = useState(licenseFields.owner.client(license))
	const [school, setSchool] = useState(licenseFields.owner.school(license))
	const [district, setDistrict] = useState(licenseFields.owner.district(license))
	const [permissions, setPermissions] = useState(() => getPermissions(license, permissionDefaults))
	const [expiresOn, setExpiresOn] = useState(licenseFields.expiresOn(license))
	const [state, setState] = useState(() => licenseFields.owner.state(license))
	const licenseName = getLicenseString(licenseType, client, school, district)

	useEffect(() => {
		setPermissions(getPermissions(license, permissionDefaults, licenseType))
	}, [licenseType, permissionDefaults, license])

	return (
		<>
			<Dialog
				isOpen
				isCloseButtonShown
				onClose={onClose}
				title={
					<span data-testid="license-name" id="edit-license-modal-header">
						{isUpdatingLicense ? `License for ${licenseName}` : 'Create License'}
					</span>
				}
				css="width: 80vw !important;">
				<div className={Classes.DIALOG_BODY}>
					<form aria-labelledby="edit-license-modal-header">
						<FormGroup label="Notes:">
							<TextArea
								fill
								placeholder="Enter notes for this license..."
								growVertically
								value={description || ''}
								onChange={event => {
									setDescription(event.target.value)
								}}
							/>
						</FormGroup>
						<Divider />
						<FormGroup label="License Type:">
							<Select
								value={licenseType}
								onChange={value => {
									if (value) {
										setLicenseType(value)
									}
								}}
								placeholder={licenseType || licenseTypesEnum.FREE}
								options={[
									licenseTypesEnum.FREE,
									licenseTypesEnum.SINGLE_OWNER,
									licenseTypesEnum.SCHOOL,
									licenseTypesEnum.DISTRICT,
								]}
								getOptionLabel={size => size}
								filterable={false}></Select>
						</FormGroup>
						{licenseType === licenseTypesEnum.SINGLE_OWNER ||
						licenseType === licenseTypesEnum.FREE ? (
							<ClientSearch
								value={client}
								onChange={value => setClient(value)}
								placeholder={'User...'}
							/>
						) : (
							<div>
								<FormGroup label="State" labelFor="state">
									<ReactSelect
										inputId="state"
										name="state"
										value={state}
										onChange={value => {
											if (value !== state) {
												if (school) {
													setSchool(null)
												}
												if (district) {
													setDistrict(null)
												}
											}
											setState(value)
										}}
										getOptionLabel={option => option.name}
										getOptionValue={option => option.abbreviation}
										options={states}
										filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
										isClearable
										isSearchable
									/>
								</FormGroup>
								{licenseType === licenseTypesEnum.SCHOOL ? (
									<FormGroup label="School" labelFor="school">
										<AsyncSelect
											inputId="school"
											name="school"
											value={school}
											isDisabled={!state}
											onChange={value => {
												setSchool(value)
											}}
											loadOptions={(
												inputValue: string,
												callback: (Array<{ schoolName: string, schoolId: string }>) => void
											) => {
												fetchSchools(inputValue, state, callback)
											}}
											getOptionLabel={school => (school ? school.schoolName : '')}
											getOptionValue={school => (school ? school.schoolId : '')}
											components={{ Option: SchoolOption }}
											isSearchable
											cacheOptions
										/>
									</FormGroup>
								) : (
									<FormGroup label="District" labelFor="district">
										<AsyncSelect
											name="district"
											inputId="district"
											value={district}
											isDisabled={!state}
											onChange={value => {
												setDistrict(value)
											}}
											loadOptions={(
												inputValue: string,
												callback: (Array<DistrictType>) => void
											) => {
												fetchDistricts(inputValue, state, callback)
											}}
											getOptionLabel={district => (district ? district.district : '')}
											getOptionValue={district => (district ? district.ncesId : '')}
											isSearchable
											cacheOptions
										/>
									</FormGroup>
								)}
							</div>
						)}
						<Divider />
						<FormGroup label="Permissions applied for this license:">
							{!permissionDefaults ? (
								<Label>Loading Permissions</Label>
							) : (
								mapEntries(permissions, (value, permission) => {
									if (typeof value === 'boolean') {
										return (
											<Switch
												key={permission}
												checked={value}
												onChange={event => {
													setPermissions(old => ({ ...old, [permission]: event.target.checked }))
												}}
												innerLabelChecked="on"
												innerLabel="off"
												labelElement={`${prettifyCamelCase(permission)} ${
													permissionDefaults[licenseType]?.[permission] === value ? '(Default)' : ''
												}`}
												css="margin-top: 8px;"></Switch>
										)
									} else if (Array.isArray(value)) {
										const ArrayInputComponent = ARRAY_INPUT_COMPONENTS[permission]

										if (!ArrayInputComponent) {
											return (
												<Label key={permission}>
													Unknown Array Permissions {permission}, please contact the engineering
													team
												</Label>
											)
										}

										return (
											<ArrayInputComponent
												key={permission}
												permissions={permissions}
												licenseType={licenseType}
												setPermissions={setPermissions}
											/>
										)
									}
								})
							)}
						</FormGroup>
						<Divider />

						<Label>
							Expiration Date:
							<DateInput
								popoverProps={{
									usePortal: false,
								}}
								onChange={value => {
									setExpiresOn(value)
								}}
								value={expiresOn}
								maxDate={futureDate}
								formatDate={date => moment(expiresOn).format('l')}
								parseDate={str => new Date(str)}
							/>
						</Label>
					</form>
				</div>
				<div css="display:flex; justify-content: space-between;" className={Classes.DIALOG_FOOTER}>
					<Button
						disabled={
							!isValidLicense({
								licenseType,
								client,
								school,
								district,
								expiresOn,
							})
						}
						icon={isUpdatingLicense ? 'edit' : 'add'}
						onClick={() => {
							handlePostOrUpdateLicense.mutate({
								_id: license?._id || '',
								owner: getLicenseType(licenseType, client, school, district),
								description,
								permissionsOverride: permissions,
								expiresOn: expiresOn,
							})
							onClose()
						}}
						text={isUpdatingLicense ? 'Save Changes' : 'Create License'}
					/>
					{isUpdatingLicense && (
						<div>
							<Button icon="settings" onClick={() => setShowIntegrationsDialog(true)}>
								Integrations
							</Button>
							{canViewUsers(license) && (
								<Button
									css="margin-left: var(--spacing);"
									icon="eye-open"
									onClick={() => {
										updateParameter({
											type: 'SET',
											paramKey: 'view-users',
											paramValue: 'true',
										})
									}}>
									View Users
								</Button>
							)}
						</div>
					)}
				</div>
			</Dialog>
			{isUpdatingLicense && !!license && showIntegrationsDialog && (
				<IntegrationsDialog
					onRequestClose={() => setShowIntegrationsDialog(false)}
					licenseId={license._id}
				/>
			)}
		</>
	)
}

type SchoolOptionProps = {
	data: {
		schoolName: string,
		district: string,
		street: string,
		city: string,
	},
}

const OptionHeader = styled.div`
	font-weight: ${({ theme }) => theme.fontBold};
	font-size: 20px;
`

/**
 * The selector option display for a school
 */
const SchoolOption = ({ data: school, ...props }: SchoolOptionProps) => {
	return (
		<components.Option {...props}>
			<OptionHeader>{school.schoolName}</OptionHeader>
			<div>
				{school.street}, {school.city}
			</div>
			<div>District - {school.district}</div>
		</components.Option>
	)
}

// a mapping of array permission names to their corresponding editing components
const ARRAY_INPUT_COMPONENTS = {
	simulationGroups: SimulationGroupsInput,
}

/**
 * SimulationGroupsInput - used to edit the the license simulation group permissions
 *
 * @param {Object} props - the react props
 * @param {PermissionsFlow} props.permissions - the permissions of the license
 * @param {LicenseType} props.licenseType - the type of license the permissions is from
 * @param {((old: PermissionsFlow) => PermissionsFlow) => void} props.setPermissions - a callback to update the permissions of the license
 *
 * @return React$Node - the react component
 */
function SimulationGroupsInput({
	permissions,
	licenseType,
	setPermissions,
}: {
	permissions: PermissionsFlow,
	licenseType: LicenseType,
	setPermissions: ((old: PermissionsFlow) => PermissionsFlow) => void,
}): React$Node {
	const { data: simulationGroupData } = useSimulationGroups()
	const permissionDefaults = usePermissionDefaults()

	if (!simulationGroupData || !permissionDefaults) {
		return <Label>Allowed Simulation Groups: Loading</Label>
	}

	const labelMap: { [groupId: string]: string } = {}
	simulationGroupData.forEach(({ name, _id }) => (labelMap[_id] = name))

	const isDefault: Set<string> = new Set()
	if (permissionDefaults[licenseType]?.simulationGroups) {
		permissionDefaults[licenseType].simulationGroups.forEach(id => {
			isDefault.add(id)
			if (labelMap[id]) {
				labelMap[id] += ' (default - locked)'
			}
		})
	}

	return (
		<Label>
			Allowed Simulation Groups:
			<ReactSelect
				value={Array.from(
					new Set([
						...(permissionDefaults[licenseType].simulationGroups || []),
						...(permissions.simulationGroups || []),
					])
				).map(id => ({
					value: id,
					label: labelMap[id] || 'unknown group',
					canBeRemoved: !isDefault.has(id),
				}))}
				isMulti={true}
				name="colors"
				options={simulationGroupData.map(({ name, _id }) => ({
					value: _id,
					label: labelMap[_id],
					canBeRemoved: !isDefault.has(_id),
				}))}
				onChange={selected => {
					setPermissions(old => ({
						...old,
						simulationGroups: selected.map(({ value }) => value).filter(id => !isDefault.has(id)),
					}))
				}}
				className="basic-multi-select"
				classNamePrefix="select"
				getOptionLabel={({ label }) => label}
				styles={{
					multiValueRemove: (base, { data }) => {
						// disable showing the remove icon on attributes which can not be removed
						return { ...base, ...(data.canBeRemoved ? {} : { display: 'none' }) }
					},
				}}
			/>
		</Label>
	)
}
