import type { AlgorithmDefinition } from './sharedTypes'
type Asset2dState = {
	hasMovingParts: boolean,
	viewCount: number,
	assetType: 'HERO' | 'ENVIRONMENT' | 'SENSORS_MAP' | 'ICON',
	additionalImages: number,
}

export const ASSET_2D_ALGORITHM_DEFINITION: AlgorithmDefinition<Asset2dState> = {
	name: '2D Asset Calculator-inator',
	fields: {
		hasMovingParts: {
			type: 'boolean',
			label: 'Has Moving Parts',
			weight: 2,
			default: false,
		},
		viewCount: {
			type: 'number',
			label: 'Number of Views',
			weight: value => {
				switch (value) {
					case 0:
						return 0
					case 1:
						return 1
					case 2:
						return 3
					default:
						return 5
				}
			},
			default: 1,
		},
		assetType: {
			type: 'option',
			options: [
				{
					value: 'HERO',
					label: 'Hero',
				},
				{
					value: 'ENVIRONMENT',
					label: 'Environment',
				},
				{
					value: 'SENSORS_MAP',
					label: 'Sensors Map',
				},
				{
					value: 'ICON',
					label: 'Icon',
				},
				{
					value: 'CONCEPT',
					label: 'Concept',
				},
			],
			label: 'Asset Type',
			weight: (value: string): number => {
				switch (value) {
					case 'HERO':
					case 'ENVIRONMENT':
						return 4
					case 'SENSORS_MAP':
					case 'ICON':
						return 1
					case 'CONCEPT':
						return 5
					default:
						throw new Error('Invalid value for assetType')
				}
			},
			default: 'HERO',
		},
		additionalImages: {
			type: 'number',
			label: 'Additional Images',
			weight: 1,
			default: 0,
			condition: {
				assetType: {
					value: 'SENSORS_MAP',
				},
			},
		},
	},
}
