import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Card, Label, Intent, Divider, Button, EditableText, Icon } from '@blueprintjs/core'
import { AppToaster } from '../../../components'
import { friendlyRoleNamesEnum } from '../../../stores/types'
import type { UserRole, RoleRequestUser } from '../../../stores/types'
import { useCurrentUser } from '../../../stores/auth'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import 'styled-components/macro'

/**
 * Takes a target client with a list of roles and a new role
 * Removes all roles with matching 'role' to the new Role
 * Submits new roles to petra-server --> missions-api to save to database
 */
async function handleSubmitRoleChange(changes: { client: RoleRequestUser, newRole: UserRole }) {
	const client = changes.client
	const newRole = changes.newRole
	let roles = []
	if (client.roles) {
		roles = [...client.roles]
	}
	roles = roles.filter(r => r.role !== newRole.role)
	roles.push(newRole)
	await NetworkCommunicator.PUT(`users/${client._id}`, {
		body: {
			user: { roles },
		},
	}).catch(e => {
		console.error('Failed to push update to mission control', e)
		AppToaster.show({
			message: `Could not update user on server: ${e.message}`,
			intent: Intent.WARNING,
			icon: 'error',
		})
	})
}

/**
 * Creates single card with 'user' information and an approve/deny ui for the 'role' request
 * Updates are sent to database after request is approved or denied
 * RoleRequests component refreshes data from database after each update
 */
export default function RoleRequest({
	user,
	role,
	onResolve,
}: {
	user: RoleRequestUser,
	role: string,
	onResolve?: () => mixed,
}): React$Node {
	const [rejecting, setRejecting] = useState(false)
	const [rejectionMessage, setRejectionMessage] = useState('')
	const currentUser = useCurrentUser()
	const queryClient = useQueryClient()

	const handleRoleChange = useMutation(handleSubmitRoleChange, {
		onSuccess: () => {
			queryClient.invalidateQueries('users/role-requests')
			if (onResolve) {
				onResolve()
			}
		},
	})

	if (!currentUser) {
		return <p>Error loading requests</p>
	}

	return (
		<Card css="margin: 16px;" elevation={2}>
			<Label>{user.firstName + ' ' + user.lastName}</Label>
			<div>
				Email: {user.email}
				{!user.emailVerified && (
					<Icon
						icon="error"
						intent={Intent.DANGER}
						css="margin-left: var(--spacing);"
						title="Email is not verified"
						htmlTitle="Email is not verified"
					/>
				)}
			</div>
			<div>School: {user.schoolName}</div>
			<div>State: {user.state}</div>
			<div>District: {user.district || 'NA'}</div>
			<Divider />
			<h3>Approve Role?</h3>

			{rejecting ? (
				<>
					<div css="padding-top: 5px;">{friendlyRoleNamesEnum[role]}</div>
					<EditableText
						multiline={true}
						alwaysRenderInput={true}
						value={rejectionMessage}
						onChange={setRejectionMessage}
						placeholder="Add reason for rejection"
					/>
					<div css="padding-top: 5px;">
						<Button
							onClick={() => {
								handleRoleChange.mutate({
									client: user,
									newRole: {
										role: role,
										verified: false,
										reasonForRejection: rejectionMessage,
										verifier: currentUser.email,
									},
								})
								setRejectionMessage('')
								setRejecting(false)
							}}>
							Reject
						</Button>
						<Button onClick={() => setRejecting(false)}>Cancel</Button>
					</div>
				</>
			) : (
				<div css="display: flex; justify-content: space-between;">
					<div css="padding-top: 5px;">{friendlyRoleNamesEnum[role]}</div>
					<div css="padding: 0px 4px;">
						<Button
							icon="tick"
							onClick={() => {
								if (!user.emailVerified) {
									AppToaster.show({
										message: 'Cannot approve role request for user with unverified email',
										intent: Intent.WARNING,
										icon: 'warning-sign',
									})
									return
								}

								handleRoleChange.mutate({
									client: user,
									newRole: {
										role: role,
										verified: true,
										verifier: currentUser.email,
									},
								})
								setRejectionMessage('')
								setRejecting(false)
							}}></Button>
						<Button onClick={() => setRejecting(true)}>
							<Icon icon="cross" title="Reject role request" />
						</Button>
					</div>
				</div>
			)}
		</Card>
	)
}
