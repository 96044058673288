import React from 'react'
import { BrowserRouter, Route, Switch, useLocation, Redirect } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { animated, useTransition } from 'react-spring'
import { usePrevious } from '../utility/hooks'
import styled, { createGlobalStyle } from 'styled-components'

import { Header } from '../components'
import Home from './Missions'
import MyCheckpoints from './Goals/Checkpoints'
import Okrs from './Goals/Okrs'
import Goals from './Goals'
import Teams from './Teams'
import Report from './Reports'
import Clients from './Clients'
import Employee from './Employee/Employee'
import GraphicsCalculator from './GraphicsCalculator/GraphicsCalculator'
import { parse, stringify } from 'query-string'

const Container = styled(animated.div)`
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 1200px;
	margin: auto;
`
const GlobalStyle = createGlobalStyle`
	html {
		overflow: hidden;
	}
  
	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}

	:root {
		${({ theme }) => `
	// Spacing
		--spacing-half: ${theme.spacingHalf};
		--spacing: ${theme.spacing};
		--spacing-2x: ${theme.spacing2x};
		--spacing-3x: ${theme.spacing3x};
		`}
	}

	ul {
		padding-inline-start: 0;
	}
`

/**
 * Checks 2 urls, compares them to see if the root value of the url, changed and returns true if it did not.
 * A url is a string starting with the character '/'
 * @param {string} previousUrl
 * @param {string} currentUrl
 */
function rootUrlDidNotChange(previousUrl: string, currentUrl: string): boolean {
	// Check if we have been given urls
	if (previousUrl.startsWith('/') && currentUrl.startsWith('/')) {
		const indexWhereRootEnds = previousUrl.substring(1).indexOf('/')
		let root = previousUrl
		if (indexWhereRootEnds > 0) {
			root = previousUrl.substring(0, indexWhereRootEnds)
		}
		return currentUrl.startsWith(root)
	}
	return false
}

function Main() {
	const location = useLocation()
	const previousPath = usePrevious(location.pathname)
	const transitions = useTransition(location, location => location.pathname, {
		immediate: () => previousPath && rootUrlDidNotChange(previousPath, location.pathname),

		from: { opacity: 0, transform: 'perspective(900px) rotateY(180deg)' },
		enter: { opacity: 1, transform: 'perspective(900px) rotateY(0deg)', height: '90%' },
		leave: { opacity: 0, transform: 'perspective(900px) rotateY(-180deg)', pointerEvents: 'none' },
	})
	return transitions.map(({ item: location, props, key }) => (
		<Container key={key} style={props}>
			<Switch location={location}>
				<Route path="/graphics" component={GraphicsCalculator} />
				<Route path="/home" component={Home} />
				<Route path="/okrs/:okrId?" component={Okrs} />
				<Route path="/goals" component={Goals} />
				<Route path="/reports" component={Report} />
				<Route path="/clients" component={Clients} />
				<Route path="/teams/:teamId?" component={Teams} />
				<Route path="/checkpoints" component={MyCheckpoints} />
				<Route path="/employees/:employeeId" component={Employee} />
				<Redirect from="/" to="/home" />
			</Switch>
		</Container>
	))
}

export default function MainWithRouter(): React$Node {
	return (
		<BrowserRouter>
			<QueryParamProvider
				ReactRouterRoute={Route}
				options={{
					searchStringToObject: parse,
					objectToSearchString: stringify,
				}}>
				<GlobalStyle />
				<Header />
				<Main />
			</QueryParamProvider>
		</BrowserRouter>
	)
}
