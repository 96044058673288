import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { mapEntries } from '../../../utility/functions'
import { Loader, FlexWrapper, ApprovalPopup, AppToaster } from '../../../components'
import { useClientStore, selectors } from '../../../stores/index.js'
import styled from 'styled-components'
import 'styled-components/macro'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import SchoolSearch from './SchoolSearch'
import EditSchool from './EditSchool'
import { ConfirmActionButton } from '../../../components/ConfirmActionButton'

import {
	Button,
	Card,
	Elevation,
	Popover,
	PopoverInteractionKind,
	Intent,
	Classes,
	Position,
	Tooltip,
} from '@blueprintjs/core'
import type { StyledComponent } from 'styled-components'

type Admin = {
	firstName: string,
	lastName: string,
	email: string,
	_id: string,
}

export type SchoolData = {
	_id: string,
	schoolType: string,
	name?: string,
	ncesId?: string,
	phone?: string,
	district?: string,
	street?: string,
	city?: string,
	locationStreet?: string,
	locationCity?: string,
	locationState?: string,
	admins?: Admin[],
	congressionalDistrictCode?: string,
	countyName?: string,
	countyNumber?: string,
	locationCity?: string,
	locationState?: string,
	state?: string,
	locationStreet?: string,
	locationZip?: string,
	mailingCity?: string,
	mailingState?: string,
	mailingStreet?: string,
	mailingZip?: string,
	stateCode?: string,
	countyName?: string,
	verified?: boolean,
}

type SchoolProps = {
	schoolId: string,
	schoolName: string,
}

const SchoolControl: StyledComponent<{}, Theme, HTMLDivElement> = styled.div`
	color: ${props => props.theme.primary};
`

/**
 * This component takes school id, fetch more detailed school data and render it to a Card
 * @param {*} school
 * @param {*} setSchool
 */

export default function School({
	school,
	setSchool,
}: {
	school: SchoolProps,
	setSchool: null => void,
}): React$Node {
	const [mergedInSchoolId, setMergedInSchoolId] = useState(null)
	const [showEdit, setShowEdit] = useState(false)
	const fetchClients = useClientStore(selectors.getClientFetch)
	const schoolId = school?.schoolId

	const { isLoading, error, data: fetchedData } = useQuery(['schools', schoolId], () =>
		school ? NetworkCommunicator.GET(`schools/${schoolId}`) : null
	)

	useEffect(() => {
		setShowEdit(false)
	}, [schoolId])

	const handleMergeSchool = useMutation(
		() =>
			NetworkCommunicator.PUT(`schools/merge`, {
				body: {
					customSchoolId: school.schoolId,
					mergedInSchoolId: mergedInSchoolId,
				},
			}),
		{
			onSuccess: () => {
				setSchool(null)
				fetchClients()
				AppToaster.warning({
					message: 'The school was successful merged',
				})
			},
		}
	)

	const data: ?SchoolData = fetchedData?.school

	const isCustomSchool = data?.schoolType !== 'public'

	const DATA_POINTS =
		// in case of custom school render
		data && isCustomSchool
			? {
					state: {
						prompt: 'State',
						value: data.state ?? '--',
					},
					city: {
						prompt: 'City',
						value: data.city,
					},
					district: {
						prompt: 'District',
						value: data.district,
					},
					street: {
						prompt: 'Street',
						value: data.street,
					},
			  }
			: // in case of public school render
			  data &&
			  !isCustomSchool && {
					state: {
						prompt: 'State',
						value: data.locationState ?? '--',
					},
					locationCity: {
						prompt: 'City',
						value: data.locationCity,
					},
					district: {
						prompt: 'District',
						value: data.district,
					},
					street: {
						prompt: 'Street',
						value: data.locationStreet,
					},
			  }

	useEffect(() => {
		if (error || handleMergeSchool.isError) {
			AppToaster.danger({
				message: 'Something went wrong, please try again!',
			})
		}
	}, [error, handleMergeSchool.isError])

	return (
		<>
			<Card elevation={Elevation.TWO} role="container">
				{(school && !isLoading && data) || (handleMergeSchool.isLoading && data) ? (
					<SchoolControl>
						{!showEdit && (
							<>
								<ContentWrapper spaceBetween>
									<div>
										<h2>{data?.name}</h2> {isCustomSchool && <span>(User Created)</span>}
									</div>
									<div>
										<EditBtn
											text="Edit"
											intent={Intent.WARNING}
											position={Position.LEFT}
											onClick={() => setShowEdit(!showEdit)}
										/>

										{isCustomSchool && (
											<Popover
												popoverClassName={Classes.POPOVER_CONTENT_SIZING}
												enforceFocus={false}
												usePortal={true}
												hasBackdrop={false}
												position={Position.RIGHT}
												interactionKind={PopoverInteractionKind.CLICK}>
												<Tooltip content="Merge into an existing school" position={Position.LEFT}>
													<MergeButton text="Merge school" intent={Intent.WARNING} />
												</Tooltip>

												<ApprovalPopup
													headerText="Merge Schools"
													message="Which school would you like to merge  into?"
													replaceApproveBtn={
														<ConfirmActionButton
															intent={Intent.WARNING}
															onClick={() => {
																handleMergeSchool.mutate()
															}}
															popOverDisabled={
																!mergedInSchoolId || mergedInSchoolId === school?.schoolId
															}
															confirmMessage={`Are you sure you want to merge ${school?.schoolName} into another school? It will be deleted and all of its users will be moved to the new school.`}>
															MERGE
														</ConfirmActionButton>
													}>
													{' '}
													<SchoolSearch setPublicSchoolId={setMergedInSchoolId} />
												</ApprovalPopup>
											</Popover>
										)}
									</div>
								</ContentWrapper>
								{mapEntries(DATA_POINTS || {}, (data, key) => (
									<DataPoint key={key} spaceBetween>
										<span>{data.prompt} </span>
										{data.value}
									</DataPoint>
								))}
								<p />
								{data?.admins?.length ? (
									<>
										<h3>School Admins:</h3>
										{data.admins.map(data => (
											<AdminUser key={data._id} userId={data._id} />
										))}
									</>
								) : null}
							</>
						)}
						{showEdit && <EditSchool schoolData={data} setShowEdit={setShowEdit} />}
					</SchoolControl>
				) : (
					<Loader />
				)}
			</Card>
		</>
	)
}

/**
 * AdminUser - a react component used to render user information about an admin of a school
 *
 * @param {Object} props - the react props
 * @param {string} props.userId - the id of the user who is an admin of a school
 *
 * @return {React$Node}
 */
function AdminUser({ userId }: { userId: string }): React$Node {
	const client = useClientStore(state => selectors.getClientDetail(state, userId))
	const fetchClientDetails = useClientStore(selectors.getClientDetailFetch)
	useEffect(() => {
		fetchClientDetails(userId)
	}, [userId, fetchClientDetails])

	if (!client) {
		return (
			<FlexedLoading>
				<StyledLoader />
				Loading User...
			</FlexedLoading>
		)
	}

	return (
		<div>
			<DataPoint spaceBetween>
				<span>name</span>
				{client.firstName} {client.lastName}
			</DataPoint>

			<DataPoint spaceBetween>
				<span>email</span>
				{client.email}
			</DataPoint>
		</div>
	)
}

const DataPoint = styled(FlexWrapper)`
	padding-top: 8px;
`

const ContentWrapper = styled(FlexWrapper)`
	margin-bottom: 8px;
`

const EditBtn = styled(Button)`
	height: 10px;
`

const MergeButton = styled(Button)`
	margin-left: 15px;
`

const FlexedLoading = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: var(--spacing);
`

const StyledLoader = styled(Loader)`
	width: unset;
`
