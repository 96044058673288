import NetworkCommunicator from '../services/NetworkCommunicator'

type ZustandSetFunction<T> = ($Shape<T>) => void

export type FetchingStore<T: {}> = {
	fetch: () => Promise<mixed>,
	fetching: boolean,
	error: ?Error,
	...T,
}

type MakeFetchingStoreReturnType<T> = {
	makeStore: (set: ZustandSetFunction<T>) => FetchingStore<*>,
	selectors: { isFetching: T => boolean, getError: T => ?Error, getFetcher: T => () => {}, ... },
}

/**
 * A helper for creating a zustand store that can fetch data from a url and set it in the store. This function will return an object
 * with 2 properties: `makeStore` which is a function to call inside of the zustand `create` function, and `selectors` which is an object with selectors
 * for the new store.
 *
 * @param {string} options.url The url to use to fetch the data
 * @param {?string} options.dataKey The key where you want to store and access your data. If dataKey is set to 'missions', you can use the selector `getMissions`. If
 *                                     no dataKey is provided, defaults to 'data' (i.e. selectors will include `getData`)
 * @param {?mixed} options.defaultData The default data to store at `dataKey`. Defaults to `null`
 *
 * @return {value.selectors} The selectors `isFetching`, `getError`, and `getData` (or getMissions if dataKey is 'missions', etc)
 *

 EXAMPLE USAGE:

    import create from zustand

    const {makeStore, selectors} = makeFetchingStore({
		url: 'missions',
		dataKey: 'missions',
		defaultData: {},
		getDataFromResponse: response => response.missions
	})

    const [useMissionStore] = create(set => ({
        ...makeStore(set),
    }))

    // In react...
    const fetching = useMissionStore(selectors.isFetching)
    const missions = useMissionStore(selectors.getMissions)
    const errors = useMissionStore(selectors.getError)
 */
export function makeFetchingStore<T>({
	url,
	dataKey = 'data',
	defaultData = null,
	getDataFromResponse,
}: {
	url: string,
	dataKey?: string,
	defaultData?: mixed,
	getDataFromResponse?: mixed => mixed,
}): MakeFetchingStoreReturnType<
	T & { fetching: boolean, error: ?Error, fetch: (?string) => Promise<mixed> }
> {
	const capitalizedDataKey = dataKey.charAt(0).toUpperCase() + dataKey.slice(1)
	return {
		makeStore: set => ({
			fetch: (urlExtension?: string) => {
				set({ fetching: true })
				return NetworkCommunicator.GET(url + (urlExtension || ''))
					.then(data => {
						set({ [dataKey]: getDataFromResponse ? getDataFromResponse(data) : data })
					})
					.catch(e => {
						set({ error: e })
					})
					.finally(() => set({ fetching: false }))
			},
			[dataKey]: defaultData,
			fetching: false,
			error: null,
		}),
		selectors: {
			isFetching: store => store.fetching,
			getError: store => store.error,
			['get' + capitalizedDataKey]: store => store[dataKey],
			getFetcher: store => store.fetch,
		},
	}
}
