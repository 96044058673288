import React from 'react'
import PageWithSideNav from '../../components/PageWithSideNav'
import Generator from './Generator'
import Missions from './Missions'
import Usage from './Usage'
import Leaderboard from './Leaderboard'
import AnalyticsOfAnalytics from './AnalyticsOfAnalytics'
import Alerts from './alerts/Alerts'

const tabs = {
	usage: {
		id: 'usage',
		name: 'Usage Stats',
		header: 'Client Usage',
		component: Usage,
	},
	missions: {
		id: 'missions',
		name: 'Missions',
		header: 'Missions',
		component: Missions,
	},
	generator: {
		id: 'generator',
		name: 'Generate Report',
		component: Generator,
	},
	leaderboard: {
		id: 'leaderboard',
		name: 'Leaderboards',
		component: Leaderboard,
	},
	analytics: {
		id: 'analytics',
		name: 'Analytics',
		component: AnalyticsOfAnalytics,
		roleRequired: 'ANALYTICS_VIEWER',
	},
	alerts: {
		id: 'alerts',
		name: 'Alerts Menu',
		header: 'Alerts Menu',
		component: Alerts,
	},
}

export default function Report(): React$Node {
	return <PageWithSideNav tabs={tabs} />
}
