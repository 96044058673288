import React from 'react'

import type { ProxyData } from '../../../../utility/dotProxy'
import type { SharedInputFields } from '../../AnalyticsOfAnalytics/customInputs/types'
import { Intent, InputGroup, Icon, Tooltip } from '@blueprintjs/core'

import { assign, get } from '../../../../utility/dotProxy'

/**
 * StringForm - a custom input for handling string proxies
 *
 * @param {Object} props - the react props
 * @param {ProxyData<?string>} props.valueProxy - a proxyData describing the location of the numeric value to show and update
 * @param {T} props.values - the base container of the value
 * @param {?string} props.className - the css class name
 * @param {?string} props.invalidMessage - a message to show to the user stating why the input is invalid
 * @param {UpdateCallback<T>} props.onChange - a callback used to update the base container
 *
 * @return {React$Node}
 */
export function StringForm<T>({
	valueProxy,
	values,
	className,
	invalidMessage,
	onChange,
}: {
	valueProxy: ProxyData<string>,
	className?: ?string,
	invalidMessage?: ?string,
	...SharedInputFields<T>,
}): React$Node {
	const currentValue = get(valueProxy, values)

	return (
		<InputGroup
			type="text"
			value={currentValue ?? ''}
			className={className}
			intent={invalidMessage == null ? null : Intent.WARNING}
			onChange={e => onChange(oldTarget => assign(valueProxy, oldTarget, e.target.value ?? ''))}
			rightElement={
				invalidMessage ? (
					<Tooltip content={invalidMessage}>
						<Icon htmlTitle={invalidMessage} icon="warning-sign" intent={Intent.WARNING} />
					</Tooltip>
				) : null
			}
		/>
	)
}
