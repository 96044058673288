import React from 'react'
import { Slider, ProgressBar, Tag, Intent } from '@blueprintjs/core'
import 'styled-components/macro'

import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { AppToaster } from '../../../components/basic/AppToaster'
import { useRestorableState } from '../../../utility/hooks'

type Props = {
	okrId: number,
	value: number,
	canUpdate: boolean,
	className?: string,
}

export default function OkrProgress({
	okrId,
	value: originalValue,
	canUpdate,
	className,
}: Props): React$Node {
	const [value, setValue, restore, updateFallback] = useRestorableState(originalValue || 0)
	return (
		<div css="display: flex; align-items: center;">
			<div css="width: 80%;">
				{canUpdate ? (
					<Slider
						className={className}
						min={0}
						max={100}
						value={value}
						labelRenderer={false}
						onChange={setValue}
						onRelease={newValue => {
							setValue(newValue)
							NetworkCommunicator.PUT(`okrs/${okrId}`, { body: { progress: newValue } })
								.then(() => {
									updateFallback(newValue)
								})
								.catch(() => {
									restore()
									AppToaster.danger({ message: 'Unable to update OKR progress' })
								})
						}}
					/>
				) : (
					<ProgressBar
						value={value / 100}
						stripes={false}
						animate={false}
						intent={Intent.PRIMARY}
					/>
				)}
			</div>
			<Tag css="margin-left: 10px;">{value}%</Tag>
		</div>
	)
}
