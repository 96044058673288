import React from 'react'
import { useState } from 'react'
import { Classes, Button, Intent } from '@blueprintjs/core'
import type { AlertFormData, InDepthAlertData } from './types'
import { AlertForm } from './AlertForm'
import styled from 'styled-components'

/**
 * AlertEditor - a component to coordinate editing an alert
 *
 * @param {Object} props - the react props
 * @param  {InDepthAlertData} props.alert - the alert to edit
 * @param  {(alert: AlertFormData) => void} props.onSave - a callback to save the edited alert
 *
 * @returns React$Node
 */
export function AlertEditor({
	alert,
	onSave,
}: {
	alert: InDepthAlertData,
	onSave: (alert: AlertFormData) => void,
}): React$Node {
	const [alertFormData, setAlertFormData] = useState(
		({
			criteria: alert.criteria,
			documentType: alert.documentType,
			name: alert.name,
			notifiers: alert.notifiers,
		}: AlertFormData)
	)

	return (
		<>
			<div className={Classes.DIALOG_HEADER}>Edit Alert</div>
			<div className={Classes.DIALOG_BODY}>
				<AlertForm currentAlertData={alertFormData} updateAlertData={setAlertFormData} />
			</div>
			<Footer className={Classes.DIALOG_FOOTER}>
				<Button onClick={() => onSave(alertFormData)} intent={Intent.PRIMARY}>
					Save
				</Button>
			</Footer>
		</>
	)
}

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
`
