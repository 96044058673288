import Loader from './Loader'
import FlexWrapper from './FlexWrapper'
import Select from './Select'
import styled from 'styled-components'
import { AppToaster } from './AppToaster'
import AdvancedTable from './AdvancedTable'
import BooleanDisplay from './BooleanDisplay'
import GlobalFilter from './GlobalFilter'
import Copiable from './Copiable'
import MultiSelect from './MultiSelect'
import Tabs from './Tabs'
import { rgba } from 'polished'
import type { StyledComponent } from 'styled-components'

const PageContainer: StyledComponent<{}, Theme, React$AbstractComponent<any, any>> = styled(
	FlexWrapper
)`
	background: ${props => props.theme.brandWhite};
	padding: ${props => props.theme.spacing2x};
	height: calc(100vh - 50px);
	overflow-y: auto;
	width: 100%;
`

const EmptyMessage: StyledComponent<{}, Theme, HTMLDivElement> = styled.div`
	text-align: center;
	color: ${({ theme }) => rgba(theme.brandGrey, 0.6)};
`

export {
	Loader,
	FlexWrapper,
	PageContainer,
	Select,
	BooleanDisplay,
	Copiable,
	MultiSelect,
	AppToaster,
	Tabs,
	AdvancedTable,
	EmptyMessage,
	GlobalFilter,
}
