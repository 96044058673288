import React from 'react'
import { Loader, FlexWrapper } from './basic'
import { Card, Elevation, AnchorButton, Intent } from '@blueprintjs/core'
import styled from 'styled-components'
import { useAuthStore, selectors } from '../stores'

const SignInContainer = styled(Card)`
	width: 80vw;
	height: 40vh;
`

export default function SignIn(): React$Node {
	const googleUrl = useAuthStore(selectors.getSignInURL)
	return (
		<FlexWrapper alignCenter>
			<SignInContainer interactive={true} elevation={Elevation.TWO}>
				{googleUrl ? (
					<FlexWrapper alignCenter>
						<AnchorButton
							minimal
							href={googleUrl}
							intent={Intent.PRIMARY}
							style={{ marginRight: '4px' }}
							text="Sign In"
						/>
						with Mission.io or Infini-D Account
					</FlexWrapper>
				) : (
					<Loader withMsg />
				)}
			</SignInContainer>
		</FlexWrapper>
	)
}
