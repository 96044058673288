import React, { useState, useEffect, useCallback } from 'react'
import { Select } from '@blueprintjs/select'
import { MenuItem, Button } from '@blueprintjs/core'
import { Loader } from '.'

type Props<T> = {
	getOptionLabel: T => string,
	placeholder: string,
	value?: ?T,
	onChange?: (?T) => void,
	getOptionKey?: T => string,
	options?: T[],
	fill?: boolean,
	clearable?: boolean,
	filterable?: boolean,
}

function defaultKeyMaker(value: any): string {
	return value ? JSON.stringify(value) : 'error'
}

export default function SelectValue<T>({
	value,
	onChange,
	placeholder,
	getOptionLabel,
	getOptionKey = defaultKeyMaker,
	options,
	fill,
	clearable = false,
	filterable = true,
	...popoverProps
}: Props<T>): React$Node {
	const [selected, setSelected] = useState(value)

	const onItemSelect = useCallback(
		value => {
			onChange && onChange(value)
			setSelected(value)
		},
		[onChange, setSelected]
	)

	const defaultPredicate = (query, value, _index, exactMatch) => {
		const comparableValue = getOptionLabel(value)
		return comparableValue.toLowerCase().indexOf(query.toLowerCase()) >= 0
	}

	const onClear = value => {
		onItemSelect(null)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const props: any = {
		resetOnSelect: true,
		popoverProps: { minimal: true, fill: true, usePortal: false, ...popoverProps },
		itemPredicate: defaultPredicate,
		items: options || [],
		onItemSelect,
		noResults: <MenuItem disabled={true} text="No results." />,
	}

	useEffect(() => {
		if (value !== undefined) {
			props.activeItem = value
			setSelected(value)
			if (!props.hasOwnProperty('onActiveItemChange')) {
				props.onActiveItemChange = onItemSelect
			}
		}
	}, [value, setSelected, props, onItemSelect])

	const extraStyle = {}
	extraStyle.display = 'inline-flex'
	if (fill) {
		extraStyle.width = '100%'
	} else {
		extraStyle.maxWidth = '500px'
	}

	return (
		<div style={extraStyle}>
			<Select
				{...props}
				filterable={filterable}
				itemRenderer={(value, { handleClick, modifiers, query }) => {
					const showAll = !modifiers.matchesPredicate && !query
					return (
						(modifiers.matchesPredicate || showAll) && (
							<MenuItem
								key={getOptionKey(value)}
								onClick={handleClick}
								active={modifiers.active}
								disabled={modifiers.disabled}
								text={getOptionLabel(value)}
							/>
						)
					)
				}}>
				<Button
					loading={!options}
					fill
					alignText="left"
					rightIcon="caret-down"
					text={options ? selected ? `${getOptionLabel(selected)}` : placeholder : <Loader />}
				/>
			</Select>
			{clearable && <Button icon="cross" onClick={onClear} />}
		</div>
	)
}
