// @flow
import React, { useEffect, useCallback, useState } from 'react'
import type { QueryInfo, StatsQueryResponse, ComparerData } from './types'
import styled from 'styled-components'
import { useQueries } from 'react-query'
import { useQueryParams, JsonParam } from 'use-query-params'
import { Intent, Card, ButtonGroup, Button, Collapse, Icon } from '@blueprintjs/core'
import uuid from 'uuid/v4'
import QueryCustomization from './QueryCustomization'
import EditableHeader from './customInputs/EditableHeader'
import { getProxyData } from '../../../utility/dotProxy'
import { AppToaster } from '../../../components'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import {
	INCLUDE_EXCLUDE_OPERATIONS,
	MISSION_SCHOOL_ID,
	DEFAULT_KEY,
	PLOT_TYPES,
	ALLOW_QUERY_TIME_PASS_PHRASE,
	SUBROUTES,
} from './constants'
import Comparison from './Comparison'
import StatsViewer from './statsViewer'
import Navigation from './Navigation'
import { produce } from 'immer'

/**
 * AnalyticsOfAnalytics - a react component used to filter, inspect, conglomerate, customize, visualize, and compare analytics data.
 *
 * @returns React$Node
 */
export default function AnalyticsOfAnalytics(): React$Node {
	const [data, setData] = useQueryParams({ queryInfo: JsonParam, comparerData: JsonParam })
	const [allowCustomQueryTime, setAllowCustomQueryTime] = useState(false)
	const [currentSubRoute, setSubRoute] = useState(SUBROUTES.STATS)

	const handleAllowCustomQueryTime = (userPassPhrase: string) => {
		if (userPassPhrase !== ALLOW_QUERY_TIME_PASS_PHRASE) {
			AppToaster.warning({
				message: 'You did not provide the required pass phrase. Will ignore custom query times',
			})
			return
		}
		setAllowCustomQueryTime(true)
		AppToaster.show({
			message: 'Will now allow setting the query timeouts, be careful.',
		})
	}

	useEffect(() => {
		if (
			data?.queryInfo?.some(
				(queryInfo: QueryInfo) => typeof queryInfo.params?.queryTime === 'number'
			)
		) {
			handleAllowCustomQueryTime(
				prompt(
					'At least one of the stats contain a custom calculation timeout. Enter the passphrase to use the custom calculation timeout.'
				)
			)
		}
		// only alert the user when first navigating to the page
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// automatically generate at least one query info
	useEffect(() => {
		const newData = { ...(data || {}) }
		let updated = false
		if (!newData.queryInfo || !newData.queryInfo.length) {
			updated = true
			newData.queryInfo = [createQueryInfo(0)]
		}
		if (!newData.comparerData) {
			updated = true
			newData.comparerData = []
		}
		if (updated) {
			setData(newData)
		}
	}, [data, setData])

	const queryInfo = data?.queryInfo || []
	const setQueriesParams = useCallback(
		(updater: QueryInfo[] | ((getterInfo: QueryInfo[]) => QueryInfo[])) => {
			if (!data) {
				return
			}
			return setData({
				...data,
				queryInfo: typeof updater === 'function' ? updater(data.queryInfo) : updater,
			})
		},
		[setData, data]
	)

	const comparerInfo: ComparerData[] = data?.comparerData || []
	const setComparerData = useCallback(
		(updater: ComparerData[] | ((getterInfo: ComparerData[]) => ComparerData[])) => {
			if (!data) {
				return
			}
			return setData({
				...data,
				comparerData: typeof updater === 'function' ? updater(data.comparerData) : updater,
			})
		},
		[setData, data]
	)

	const queries: Array<StatsQueryResponse> = useQueries(
		queryInfo.map(({ params, isEditing, id }) => {
			let sterilizedQueryParams = params
			if (!allowCustomQueryTime) {
				sterilizedQueryParams = { ...params }
				delete sterilizedQueryParams.queryTime
			}
			const queryParams = JSON.stringify(sterilizedQueryParams || {})
			const queryData = {
				queryKey: ['analytics-of-analytics', queryParams],
				queryFn: () =>
					NetworkCommunicator.GET(`analytics-of-analytics?filter=${queryParams}`).then(res => res),
				onError: error => {
					AppToaster.danger({ message: error.message })
					if (Array.isArray(error.body?.typeErrors)) {
						error.body.typeErrors.forEach(message => AppToaster.danger({ message }))
					}
				},
				enabled: !isEditing,
				cacheTime: Infinity,
				staleTime: Infinity,
				retry: 0,
			}
			return queryData
		})
	)

	function addStats() {
		setData({
			...data,
			queryInfo: [...data.queryInfo, createQueryInfo(data.queryInfo?.length || 0)],
		})
	}

	function addComparer() {
		setData({
			...data,
			comparerData: [...data.comparerData, createComparerData(data.comparerData?.length || 0)],
		})
	}

	return (
		<Column>
			<AllowCustomQueryTimeButton
				onClick={() => {
					handleAllowCustomQueryTime(
						prompt(
							'Would you like to be able to determine the length of time a query can take before being terminated? (Enter the pass phrase)'
						)
					)
				}}
			/>
			{currentSubRoute === SUBROUTES.STATS
				? queryInfo.map((queryInfo: QueryInfo, index) => {
						const updateQueryInfo = (callback: (data: QueryInfo) => QueryInfo) => {
							setQueriesParams(oldData => {
								const newData = [...oldData]
								newData[index] = callback(oldData[index])
								return newData
							})
						}

						const reactQuery: StatsQueryResponse = queries[index]

						const ToggleCustomizingButton = (
							<Button
								onClick={() =>
									setQueriesParams(oldData =>
										produce(oldData, draft => {
											draft[index].isEditing = !draft[index].isEditing
										})
									)
								}
								icon={queryInfo.isEditing ? 'eye-open' : 'edit'}
								intent={Intent.PRIMARY}>
								{queryInfo.isEditing ? 'View Data' : 'Customize'}
							</Button>
						)

						return (
							<Card key={queryInfo.id} id={queryInfo.id}>
								<Header>
									<VerticalCenter>
										<ClickableIcon
											icon={queryInfo.isCollapsed ? 'caret-right' : 'caret-down'}
											size={22}
											onClick={() =>
												updateQueryInfo((oldData: QueryInfo) => ({
													...oldData,
													isCollapsed: !oldData.isCollapsed,
												}))
											}
										/>
									</VerticalCenter>
									<PositionCenter>
										<EditableHeader
											valueProxy={getProxyData((trace: QueryInfo) => trace?.name)}
											onChange={updateQueryInfo}
											values={queryInfo}
										/>
									</PositionCenter>
									<PositionRight>
										<PositionRight>
											<ButtonGroup>
												{queryInfo.isEditing ? (
													ToggleCustomizingButton
												) : (
													<Button
														icon="refresh"
														intent={Intent.INFO}
														disabled={reactQuery?.isFetching}
														onClick={() => {
															queries[index]?.refetch()
														}}>
														{reactQuery?.isFetching ? 'Loading' : 'Refresh'}
													</Button>
												)}
												<Button
													intent={Intent.DANGER}
													icon="delete"
													onClick={() =>
														setQueriesParams(oldData =>
															produce(oldData, draft => {
																draft.splice(index, 1)
															})
														)
													}>
													Remove
												</Button>
											</ButtonGroup>
										</PositionRight>
									</PositionRight>
								</Header>
								<Collapse isOpen={!queryInfo.isCollapsed}>
									{queryInfo.isEditing ? (
										<QueryCustomization
											key={index}
											queryInfo={queryInfo}
											updateQueryInfo={updateQueryInfo}
											allowCustomQueryTime={allowCustomQueryTime}
										/>
									) : (
										<StatsViewer info={queryInfo} query={reactQuery} updateInfo={updateQueryInfo} />
									)}
								</Collapse>
								<Footer>
									<PositionRight>{ToggleCustomizingButton}</PositionRight>
								</Footer>
							</Card>
						)
				  })
				: comparerInfo.map((comparer: ComparerData, index) => {
						const updateComparerData = (callback: (data: ComparerData) => ComparerData): void => {
							setComparerData(oldData => {
								const newData = [...oldData]
								newData[index] = callback(oldData[index])
								return newData
							})
						}

						return (
							<Card key={comparer.id} id={comparer.id}>
								<Header>
									<VerticalCenter>
										<ClickableIcon
											icon={comparer.isCollapsed ? 'caret-right' : 'caret-down'}
											size={22}
											onClick={() =>
												updateComparerData((oldData: ComparerData) => ({
													...oldData,
													isCollapsed: !oldData.isCollapsed,
												}))
											}
										/>
									</VerticalCenter>
									<PositionCenter>
										<EditableHeader
											valueProxy={getProxyData((trace: ComparerData) => trace?.name)}
											onChange={updateComparerData}
											values={comparer}
										/>
									</PositionCenter>
									<PositionRight>
										<PositionRight>
											<ButtonGroup>
												<Button
													intent={Intent.DANGER}
													icon="delete"
													onClick={() =>
														setComparerData(oldData =>
															produce(oldData, draft => {
																draft.splice(index, 1)
															})
														)
													}>
													Remove
												</Button>
											</ButtonGroup>
										</PositionRight>
									</PositionRight>
								</Header>
								<Collapse isOpen={!comparer.isCollapsed}>
									<Comparison
										comparerData={comparer}
										queryInfo={queryInfo}
										stats={queries}
										updateComparer={updateComparerData}
									/>
								</Collapse>
							</Card>
						)
				  })}
			<PositionRight>
				<Button
					intent={Intent.PRIMARY}
					icon="add"
					onClick={() => {
						if (currentSubRoute === SUBROUTES.STATS) {
							return addStats()
						}
						addComparer()
					}}>
					Add {currentSubRoute === SUBROUTES.STATS ? 'Stats' : 'Comparison'}
				</Button>
			</PositionRight>
			<Navigation
				setSubroute={setSubRoute}
				addStats={addStats}
				addComparer={addComparer}
				statsInfo={data.queryInfo || []}
				comparers={data.comparerData || []}
			/>
		</Column>
	)
}

/**
 * createQueryInfo - create default queryInfo. By default, the Mission school is excluded.
 *
 * @param {number} statsCount - the number of stats currently existing
 *
 * @return {QueryInfo}
 */
function createQueryInfo(statsCount: number): QueryInfo {
	return {
		name: `Stats ${statsCount + 1}`,
		id: uuid(),
		params: {
			filter: {
				user: {
					schools: {
						values: [MISSION_SCHOOL_ID],
						operation: INCLUDE_EXCLUDE_OPERATIONS.EXCLUDE,
					},
				},
				analytics: {
					generatedAtStart: getSchoolYearStartMonth().getTime(),
					minStudents: 3,
				},
			},
		},
		isEditing: true,
		isCollapsed: false,
		viewer: {
			location: DEFAULT_KEY,
			selector: 'quartiles',
			bin: 'ALL',
			plotType: PLOT_TYPES.BOX_PLOT,
			includeOutliers: false,
			timeConverter: 'seconds',
		},
	}
}

/**
 * createComparerInfo - generate a default comparer data object
 *
 * @param {number} comparerCount - the current count of comparers
 *
 * @return {ComparerData} - a default comparer data object
 */
function createComparerData(comparerCount: number): ComparerData {
	return {
		name: `Comparison ${comparerCount}`,
		id: uuid(),
		isCollapsed: false,
		comparingStats: [],
		viewer: {
			location: DEFAULT_KEY,
			selector: 'quartiles',
			bin: 'ALL',
			plotType: PLOT_TYPES.BOX_PLOT,
			includeOutliers: false,
			timeConverter: 'seconds',
		},
	}
}

/**
 * Gets a date that represents the beginning of the current school year.
 * In other words, gets the Date of the most recent August 1.
 *
 * @returns {Date}
 */
export function getSchoolYearStartMonth(): Date {
	const date = new Date()
	const currentMonth = date.getMonth()
	const currentYear = date.getFullYear()
	return new Date(`August ${currentMonth < 7 ? currentYear - 1 : currentYear}`)
}

const Column = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	gap: 20px;
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	> * {
		flex: 1 1 0;
	}
`

const ClickableIcon = styled(Icon)`
	cursor: pointer;
`

const PositionRight = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const PositionCenter = styled(PositionRight)`
	justify-content: center;
`

const VerticalCenter = styled.div`
	display: flex;
	align-items: center;
`

const Footer = styled.div`
	with: 100%;
	margin-top: 8px;
`

const AllowCustomQueryTimeButton = styled.div`
	position: fixed;
	width: 16px;
	height: 16px;
	top: 16px;
	right: 16px;
	background-color: Transparent;
	cursor: pointer;
`
