import React, { useState } from 'react'
import type { SharedProps } from './types'
import { DateRangeInput } from '@blueprintjs/datetime'
import { FILTER_TYPES } from '../../../../utility/constants'
import { selectors, useFilterStore } from '../../../../stores'
import { Label, Classes } from '@blueprintjs/core'

export default function Calendar(props: SharedProps): React$Node {
	const currentDateRange = useFilterStore(selectors.getDateRange)
	const [dateRange, setDateRange] = useState(currentDateRange)

	const handleDateChange = range => {
		setDateRange(range)
		const filters = {}
		const filterIdsToUnselect = []
		const start = range[0]
		const end = range[1]
		if (start) {
			filters[FILTER_TYPES.START_DATE] = { type: FILTER_TYPES.START_DATE, value: start }
		} else {
			filterIdsToUnselect.push(FILTER_TYPES.START_DATE)
		}
		if (end) {
			filters[FILTER_TYPES.END_DATE] = { type: FILTER_TYPES.END_DATE, value: end }
		} else {
			filterIdsToUnselect.push(FILTER_TYPES.END_DATE)
		}

		if (Object.keys(filters).length > 0) props.select(filters)
		if (filterIdsToUnselect.length > 0) props.unselect(filterIdsToUnselect)
	}
	return (
		<Label className={Classes.INLINE}>
			Date Range
			<DateRangeInput
				css="overflow: none;"
				formatDate={date => date.toDateString()}
				parseDate={str => new Date(str)}
				allowSingleDayRange
				shortcuts
				range={dateRange}
				defaultValue={currentDateRange}
				onChange={handleDateChange}
			/>
		</Label>
	)
}
