import React from 'react'
import 'styled-components/macro'
import { Button, Classes, Intent, H5 } from '@blueprintjs/core'

type Props = {
	headerText?: string,
	message: string,
	children?: React$Node,
	replaceApproveBtn?: React$Node,
	onApprove?: () => void,
	onCancel?: () => void,
	onApproveBtnText?: string,
	onCancelBtnText?: string,
	onApprovelDisable?: boolean,
	onCancelDisable?: boolean,
}

export default function ApprovalPopup(props: Props): React$Node {
	const {
		headerText = 'Confirm action',
		onApprove,
		onCancel,
		message,
		onApproveBtnText = 'Confirm',
		onApprovelDisable = false,
		onCancelBtnText = 'Cancel',
		onCancelDisable = false,
		children,
		replaceApproveBtn,
	} = props

	return (
		<div>
			<H5>{headerText}</H5>
			<p>{message}</p>
			{children}
			<div css="display: flex; justify-content: flex-end; margin-top: 15px;">
				<Button
					className={Classes.POPOVER_DISMISS}
					css="margin-right: 10px;"
					onClick={onCancel}
					disabled={onCancelDisable}>
					{onCancelBtnText}
				</Button>
				{replaceApproveBtn ? (
					<>{replaceApproveBtn}</>
				) : (
					<Button
						intent={Intent.DANGER}
						className={Classes.POPOVER_DISMISS}
						onClick={onApprove}
						disabled={onApprovelDisable}>
						{onApproveBtnText}
					</Button>
				)}
			</div>
		</div>
	)
}
