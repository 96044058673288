// @flow
import React from 'react'
import { useEffect, useState } from 'react'
import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { Criteria, UpdateCriteria } from '../../localTypes'
import { Dialog, Classes, HTMLSelect, NumericInput, Button } from '@blueprintjs/core'
import type { RelativeDate, RelativeDateOffsetData } from '../../clientTypes'
import Loader from '../../../../../../components/basic/Loader'
import { DATE_TYPES } from '../../clientTypes'
import { assign, get, getProxyData, minimizeDelete } from '../../../../../../utility/dotProxy'
import styled from 'styled-components'
import { useRelativeTimeFormData } from '../../hooks'

export const DEFAULT_RELATIVE_DATE_FROM = 'NOW'

const BASE_RELATIVE_DATE: RelativeDate = {
	type: DATE_TYPES.RELATIVE_DATE,
	data: {
		from: DEFAULT_RELATIVE_DATE_FROM,
	},
}

const CUSTOM_ID = 'THIS IS A CUSTOM ID FOR ADDING RELATIVE DATES'
const FUTURE_ID = 'FUTURE'
const PAST_ID = 'PAST'

type OffsetTimeUnits = $Keys<RelativeDateOffsetData>

/**
 * RelativeDateEntry - a field used to input relative dates
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {ProxyData<?RelativeDate>} props.currentPath - the location in the criteria which holds the data for ths range
 *
 * @returns React$Node
 */
export function RelativeDateEntry({
	criteria,
	updateCriteria,
	currentPath,
}: {
	criteria: Criteria,
	updateCriteria: UpdateCriteria,
	currentPath: ProxyData<?RelativeDate>,
}): React$Node {
	const [isEditing, setIsEditing] = useState(false)

	const { isLoading, data: availableRelativeDates, error } = useRelativeTimeFormData()

	function updateRelativeData(updateCallback: (relativeDate: RelativeDate) => ?RelativeDate) {
		updateCriteria((oldCriteria: Criteria) => {
			const newRelativeDate = updateCallback(get(currentPath, oldCriteria) ?? BASE_RELATIVE_DATE)
			if (!newRelativeDate) {
				return minimizeDelete(currentPath, oldCriteria)
			}
			return assign(currentPath, oldCriteria, newRelativeDate)
		})
	}

	if (error) {
		return <div>A problem occurred loading relative date form</div>
	}

	if (isLoading) {
		return <Loader />
	}

	if (!availableRelativeDates) {
		return <div>No relative dates in form</div>
	}

	const value = get(currentPath, criteria)
	const options = [
		...availableRelativeDates.map(({ id, displayName }) => ({
			label: displayName,
			value: id,
		})),
		{
			label: customRelativeDateString(value?.data?.offsets),
			value: CUSTOM_ID,
		},
	]
	const idToData = {}
	availableRelativeDates.forEach(relativeData => (idToData[relativeData.id] = relativeData))

	const selectedValue = Object.keys(value?.data?.offsets || {}).length
		? CUSTOM_ID
		: value?.data?.from

	return (
		<>
			<HTMLSelect
				value={selectedValue}
				options={options}
				onChange={e => {
					let id = e.target.value
					if (id === CUSTOM_ID) {
						setIsEditing(true)
						id = DEFAULT_RELATIVE_DATE_FROM
					}
					updateRelativeData(oldRelativeDate =>
						assign(
							getProxyData((relativeDate: RelativeDate) => relativeDate?.data),
							oldRelativeDate,
							{ from: id }
						)
					)
				}}
			/>
			{selectedValue === CUSTOM_ID ? (
				<Button onClick={() => setIsEditing(true)}>edit</Button>
			) : null}
			<StyledDialog
				isOpen={isEditing}
				onClose={() => setIsEditing(false)}
				title="Relative Date Entry">
				<div className={Classes.DIALOG_BODY}>
					<RelativeOffsetForm
						updateRelativeDate={updateRelativeData}
						offsetData={value?.data?.offsets}
					/>
				</div>
			</StyledDialog>
		</>
	)
}

/**
 * RelativeOffsetForm - a form for setting the "add" fields to a relative date
 *
 * @param  {Object} props - the react props
 * @param  {((oldDate: RelativeDate) => ?RelativeDate) => any} props.updateRelativeDate - a callback to update the current relative date
 * @param  {?RelativeDateOffsetData} props.offsetData - the current offset data
 *
 * @returns React$Node
 */
function RelativeOffsetForm({
	updateRelativeDate,
	offsetData,
}: {
	updateRelativeDate: ((oldDate: RelativeDate) => ?RelativeDate) => any,
	offsetData: ?RelativeDateOffsetData,
}): React$Node {
	const idInCriteria: ?OffsetTimeUnits = offsetData ? Object.keys(offsetData)[0] : null
	const [defaultSelectedId, setDefaultSelectedId] = useState<OffsetTimeUnits>(idInCriteria ?? 'day')
	const idToUse = idInCriteria ?? defaultSelectedId

	useEffect(() => {
		if (idToUse && idToUse !== defaultSelectedId) {
			setDefaultSelectedId(idToUse)
		}
	}, [idToUse, defaultSelectedId])

	const number = offsetData?.[idToUse]?.add ?? null
	const [defaultNumber, setDefaultNumber] = useState(number)

	useEffect(() => {
		if (number && number !== defaultNumber) {
			setDefaultNumber(number)
		}
	}, [number, defaultNumber])

	const signInCriteria = number ? Math.sign(number) : null
	const [defaultSign, setDefaultSign] = useState(signInCriteria) // 1 or -1
	const signToUse = signInCriteria || defaultSign || -1

	useEffect(() => {
		if (signToUse && signToUse !== defaultSign) {
			setDefaultSign(signToUse)
		}
	}, [signToUse, defaultSign])

	const addProxy = getProxyData((trace: RelativeDate) => trace?.data?.offsets?.[idToUse]?.add)

	function updateDate(num: ?number, sign: number, id: string) {
		if (num && sign && id) {
			updateRelativeDate((oldDate: RelativeDate) => ({
				type: DATE_TYPES.RELATIVE_DATE,
				data: {
					from: DEFAULT_RELATIVE_DATE_FROM,
					offsets: { [id]: { add: Math.abs(num) * sign } },
				},
			}))
			return
		}
		setDefaultSign(sign)
		if (id === 'day' || id === 'month' || id === 'year') {
			setDefaultSelectedId(id)
		}
		setDefaultNumber(num ? Math.abs(num) : null)
		updateRelativeDate((oldDate: RelativeDate) => minimizeDelete(addProxy, oldDate))
	}

	return (
		<FlexRow>
			<NumericInput
				value={Math.abs(number ?? 0) ?? null}
				min={0}
				onValueChange={(num: number) => updateDate(Math.abs(num), signToUse, idToUse)}
			/>
			<HTMLSelect
				value={idToUse}
				options={[
					{ label: 'days', value: 'day' },
					{ label: 'months', value: 'month' },
					{ label: 'years', value: 'year' },
				]}
				onChange={e => updateDate(number, signToUse, e.target.value)}
			/>
			<HTMLSelect
				value={signToUse > 0 ? FUTURE_ID : PAST_ID}
				options={[
					{ label: 'in the future', value: FUTURE_ID },
					{ label: 'ago', value: PAST_ID },
				]}
				onChange={e => updateDate(number, e.target.value === FUTURE_ID ? 1 : -1, idToUse)}
			/>
		</FlexRow>
	)
}

/**
 * customRelativeDateString - get a human readable string representing the offsets
 *
 * @param  {?RelativeDateOffsetData} offset - the offsets to get a string for
 */
function customRelativeDateString(offset: ?RelativeDateOffsetData) {
	if (!offset) {
		return 'customize'
	}
	if (Object.keys(offset).length > 1) {
		return 'broken, more than one key'
	}
	let number = 0
	let timeUnit = 'day'
	if (offset?.day?.add) {
		number = offset.day.add
		timeUnit = 'day'
	}
	if (offset?.month?.add) {
		number = offset.month.add
		timeUnit = 'month'
	}
	if (offset?.year?.add) {
		number = offset.year.add
		timeUnit = 'year'
	}

	return `${Math.abs(number)} ${Math.abs(number) === 1 ? timeUnit : `${timeUnit}s`} ${
		number > 0 ? 'in the future' : 'ago'
	}`
}

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: middle;
`

const StyledDialog = styled(Dialog)`
	&& {
		padding-bottom: 0;
		width: max-content;
	}
`
