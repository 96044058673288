import { forceLogin } from '../stores/auth'
import config from '../config'

type ModifiedRequestOptions = {
	...RequestOptions,
	body: string | {},
}

export default class NetworkCommunicator<R = {}> {
	static GET(path: string, options?: ModifiedRequestOptions): Promise<R> {
		return this.request(path, { ...options, method: 'GET' })
	}

	static PUT(path: string, options?: ModifiedRequestOptions): Promise<R> {
		return this.request(path, { ...options, method: 'PUT' })
	}

	static POST(path: string, options?: ModifiedRequestOptions): Promise<R> {
		return this.request(path, { ...options, method: 'POST' })
	}

	static DELETE(path: string, options?: ModifiedRequestOptions): Promise<R> {
		return this.request(path, { ...options, method: 'DELETE' })
	}

	static request(path: string, options: ModifiedRequestOptions): Promise<R> {
		const { body: objectBody, ...restOptions } = options
		let body: $PropertyType<RequestOptions, 'body'>
		if (objectBody) {
			if (objectBody instanceof FormData) {
				const data = {}
				for (let [key, value] of objectBody) {
					data[key] = value
				}
				body = JSON.stringify(data)
			} else if (typeof objectBody !== 'string') {
				body = JSON.stringify(objectBody)
			}
		}
		const fullOptions: RequestOptions = {
			...restOptions,
			credentials: 'include',
			headers: {
				Accept: 'application/json',
			},
			redirect: 'follow',
		}
		if (body) {
			// $FlowFixMe This is a valid Content-Type
			fullOptions.headers['Content-Type'] = 'application/json'
			fullOptions.body = body
		}
		const url = `${config.petraServerUrl}/${path}`
		let status
		let responseOK
		return fetch(url, fullOptions)
			.then((jsonResult: Response) => {
				const contentType = jsonResult.headers.get('content-type')
				responseOK = jsonResult.ok
				status = jsonResult.status
				if (contentType && contentType.includes('application/json')) {
					return jsonResult.json()
				} else {
					console.error('Incorrect content type')
					return null
				}
			})
			.then(result => {
				if (status === 401 && result && result.authenticationUrl) {
					forceLogin(result.authenticationUrl)
				}
				if (!responseOK) {
					// eslint-disable-next-line no-throw-literal
					throw { status, message: result?.message || result?.body }
				}
				return result
			})
	}
}
