// @flow
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import type { QueryInfo, UpdateQueryInfoCallback } from './types'
import NumericInput from './customInputs/NumericInput'
import DateRangeInput from './customInputs/DateTimeRangeInput'
import {
	BIN_TYPES,
	DISCRIMINATION_TYPES,
	DISCRIMINATION_TYPES_INFORMATION,
	BIN_TYPES_INFORMATION,
	TIME_BINNING_OPTIONS,
	CONTROL_SETS,
	ANALYTIC_VERSIONS,
	OVERRIDABLE_WEIGHTS,
	ANALYTICS_MODELS,
} from './constants'
import { Label, RadioGroup, Radio, Tabs, Tab, Tooltip } from '@blueprintjs/core'
import { Row } from './sharedComponents'
import { getProxyData, assign, get, minimizeDelete } from '../../../utility/dotProxy'
import {
	useClients,
	useSchools,
	useSchoolsByIds,
	useAllFetchedSchools,
	useDistricts,
	useDistrictsByIds,
	useAllFetchedDistricts,
	useAutomatedSimulations,
	MIN_DISTRICT_SEARCH_STRING_LENGTH,
	MIN_SCHOOL_SEARCH_STRING_LENGTH,
	type School,
	type District,
	type Simulation,
} from '../../../services/hooks'
import type { ClientType } from '../../../stores/types'
import { toIdMap } from '../../../utility/functions'
import { MultiReactSelect } from './customInputs/MultiSelect'
import states, { type State } from '../../../utility/states'
import type { Option } from '../../../utility/hooks'

/**
 * QueryCustomization - customize the query to get analytics of analytics stats
 *
 * @param {Object} props - the react props
 * @param {QueryInfo} props.queryInfo - the current setup of the query
 * @param {UpdateQueryInfoCallback} props.updateQueryInfo - a callback to update the queryInfo
 * @param {boolean} props.allowCustomQueryTime - true if the user has permission to edit the timeout for the query
 *
 * @return {React$Node}
 */
export default function QueryCustomization({
	queryInfo,
	updateQueryInfo,
	allowCustomQueryTime,
}: {
	queryInfo: QueryInfo,
	updateQueryInfo: UpdateQueryInfoCallback,
	allowCustomQueryTime: boolean,
}): React$Node {
	const sharedProxyData = {
		values: queryInfo,
		onChange: updateQueryInfo,
	}

	const { data: clients } = useClients()
	const [clientLookup, clientOptions] = useMemo(
		() => [
			toIdMap(clients || []),
			clients?.map(clientToOption).sort((a, b) => a.label.localeCompare(b.label)),
		],
		[clients]
	)

	const [schoolSearchString, setSchoolSearchString] = useState('')

	let areSchoolsLoading = false
	useSchoolsByIds(queryInfo.params?.filter?.user?.schools?.values || []).forEach(
		({ isLoading }) => (areSchoolsLoading = areSchoolsLoading || isLoading)
	)
	areSchoolsLoading = useSchools(schoolSearchString).isLoading || areSchoolsLoading

	const allFetchedSchools = useAllFetchedSchools()
	const [schoolLookup, schoolOptions] = useMemo(() => {
		const schoolLookup = {}
		allFetchedSchools.forEach(school => (schoolLookup[school._id] = school))

		return [
			schoolLookup,
			allFetchedSchools.map(schoolToOption).sort((a, b) => a.label.localeCompare(b.label)),
		]
	}, [allFetchedSchools])

	const [districtSearchString, setDistrictSearchString] = useState('')

	let areDistrictsLoading = false
	useDistrictsByIds(queryInfo.params?.filter?.user?.districts?.values || []).forEach(
		({ isLoading }) => (areSchoolsLoading = areDistrictsLoading || isLoading)
	)
	areSchoolsLoading = useDistricts(districtSearchString).isLoading || areDistrictsLoading

	const allFetchedDistricts = useAllFetchedDistricts()
	const [districtLookup, districtOptions] = useMemo(() => {
		const districtLookup = {}
		allFetchedDistricts.forEach(district => (districtLookup[district.ncesId] = district))

		return [
			districtLookup,
			allFetchedDistricts
				.filter(({ ncesId }) => Boolean(ncesId))
				.map(districtToOption)
				.sort((a, b) => a.label.localeCompare(b.label)),
		]
	}, [allFetchedDistricts])

	const [stateLookup, stateOptions] = useMemo(() => {
		const stateLookup = {}
		states.forEach(state => (stateLookup[state.abbreviation] = state))
		return [stateLookup, states?.map(stateToOption).sort((a, b) => a.label.localeCompare(b.label))]
	}, [])

	const { data: simulations } = useAutomatedSimulations()
	const [simulationLookup, simulationOptions] = useMemo(
		() => [
			toIdMap(simulations || []),
			simulations?.map(simulationToOption).sort((a, b) => a.label.localeCompare(b.label)),
		],
		[simulations]
	)

	const controlSetProxy = getProxyData(
		(trace: QueryInfo) => trace?.params?.filter?.mission?.controlSet
	)
	const selectedControlSet = get(controlSetProxy, queryInfo)

	const selectedAnalyticsCollectionProxy = getProxyData(
		(trace: QueryInfo) => trace?.params?.collection
	)
	const selectedAnalyticsCollection =
		get(selectedAnalyticsCollectionProxy, queryInfo) ?? ANALYTICS_MODELS.PRODUCTION
	return (
		<Tabs>
			<Tab
				id="filtering"
				title="Filter"
				panel={
					<div>
						<h3>Analytics</h3>
						<Indent>
							<table>
								<tbody>
									<tr>
										<td>Analytics Generated Between:</td>
										<td>
											<DateRangeInput
												startProxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.analytics?.generatedAtStart
												)}
												endProxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.analytics?.generatedAtEnd
												)}
												{...sharedProxyData}
											/>
										</td>
									</tr>
									<tr>
										<td>Analytics Versions:</td>
										<td>
											<MultiReactSelect
												proxyValue={getProxyData(
													(trace: QueryInfo) => trace?.params?.filter?.analytics?.versions?.values
												)}
												proxyOperation={getProxyData(
													(trace: QueryInfo) =>
														trace?.params?.filter?.analytics?.versions?.operation
												)}
												defaultValue={([]: any)}
												valueMapper={values => values.map(value => ({ value, label: value }))}
												options={ANALYTIC_VERSIONS.map(version => ({
													value: String(version),
													label: String(version),
												}))}
												values={queryInfo}
												onChange={updateQueryInfo}
											/>
										</td>
									</tr>

									<tr>
										<td>Min Students:</td>
										<td>
											<NumericInput
												valueProxy={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.analytics?.minStudents
												)}
												{...sharedProxyData}
											/>
										</td>
									</tr>
									<tr>
										<td>Max Students:</td>
										<td>
											<NumericInput
												valueProxy={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.analytics?.maxStudents
												)}
												{...sharedProxyData}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</Indent>
						<h3>Mission</h3>
						<Indent>
							<table>
								<tbody>
									<tr>
										<td>Simulations:</td>
										<td>
											<MultiReactSelect
												proxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.mission?.simulations?.values
												)}
												proxyOperation={getProxyData(
													(trace: QueryInfo) =>
														trace.params?.filter?.mission?.simulations?.operation
												)}
												defaultValue={([]: any)}
												valueMapper={values =>
													values.map(value =>
														simulationToOption(
															simulationLookup[value] ?? {
																_id: value,
																name: 'Unknown',
															}
														)
													)
												}
												options={simulationOptions}
												values={queryInfo}
												onChange={updateQueryInfo}
											/>
										</td>
									</tr>
									<tr>
										<td>Control Set:</td>
										<td>
											<ReactSelect
												value={
													(selectedControlSet && {
														value: selectedControlSet,
														label: selectedControlSet,
													}) || {
														value: 'Any',
														label: 'Any',
													}
												}
												name="Control Set"
												options={[
													{ label: 'Any', value: 'Any' },
													...Object.keys(CONTROL_SETS).map(controlSet => ({
														value: controlSet,
														label: controlSet,
													})),
												]}
												onChange={selected => {
													updateQueryInfo((oldData: QueryInfo) => {
														if (CONTROL_SETS.hasOwnProperty(selected.value)) {
															return assign(controlSetProxy, oldData, selected.value)
														}
														return minimizeDelete(controlSetProxy, oldData)
													})
												}}
												className="basic-multi-select"
												classNamePrefix="select"
												getOptionLabel={({ label }) => label}
											/>
										</td>
									</tr>
									<tr>
										<td>Ended Between:</td>
										<td>
											<DateRangeInput
												startProxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.mission?.endedAtStart
												)}
												endProxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.mission?.endedAtEnd
												)}
												{...sharedProxyData}
											/>
										</td>
									</tr>
									<tr>
										<td>Min Mission Length (Milliseconds):</td>
										<td>
											<NumericInput
												valueProxy={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.mission?.ranTimeMin
												)}
												{...sharedProxyData}
											/>
										</td>
									</tr>
									<tr>
										<td>Max Mission Length (Milliseconds):</td>
										<td>
											<NumericInput
												valueProxy={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.mission?.ranTimeMax
												)}
												{...sharedProxyData}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</Indent>
						<h3>User</h3>
						<Indent>
							<table>
								<tbody>
									<tr>
										<td>State:</td>
										<td>
											<MultiReactSelect
												proxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.states?.values
												)}
												proxyOperation={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.states?.operation
												)}
												defaultValue={([]: any)}
												valueMapper={values =>
													values.map(value =>
														stateToOption(
															stateLookup[value] ?? {
																abbreviation: value,
																name: 'Unknown',
															}
														)
													)
												}
												options={stateOptions}
												values={queryInfo}
												onChange={updateQueryInfo}
											/>
										</td>
									</tr>
									<tr>
										<td>Districts:</td>
										<td>
											<MultiReactSelect
												proxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.districts?.values
												)}
												proxyOperation={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.districts?.operation
												)}
												defaultValue={([]: any)}
												valueMapper={values =>
													values.map(value =>
														districtToOption(
															districtLookup[value] ?? {
																ncesId: value,
																district: 'Unknown',
															}
														)
													)
												}
												options={!areDistrictsLoading ? districtOptions : null}
												needsMoreText={
													districtSearchString.length < MIN_DISTRICT_SEARCH_STRING_LENGTH
												}
												values={queryInfo}
												onChange={updateQueryInfo}
												onSearchStringChange={setDistrictSearchString}
											/>
										</td>
									</tr>
									<tr>
										<td>Schools:</td>
										<td>
											<MultiReactSelect
												proxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.schools?.values
												)}
												proxyOperation={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.schools?.operation
												)}
												defaultValue={([]: any)}
												valueMapper={values =>
													values.map(value =>
														schoolToOption(
															schoolLookup[value] ?? {
																_id: value,
																name: 'Unknown',
																state: 'Unknown',
															}
														)
													)
												}
												options={!areSchoolsLoading ? schoolOptions : null}
												needsMoreText={schoolSearchString.length < MIN_SCHOOL_SEARCH_STRING_LENGTH}
												values={queryInfo}
												onChange={updateQueryInfo}
												onSearchStringChange={setSchoolSearchString}
											/>
										</td>
									</tr>
									<tr>
										<td>Users:</td>
										<td>
											<MultiReactSelect
												proxyValue={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.users?.values
												)}
												proxyOperation={getProxyData(
													(trace: QueryInfo) => trace.params?.filter?.user?.users?.operation
												)}
												defaultValue={([]: any)}
												valueMapper={values =>
													values.map(value =>
														clientToOption(
															clientLookup[value] ?? {
																_id: value,
																email: 'unknown',
																firstName: 'unknown user',
																lastName: value,
															}
														)
													)
												}
												options={clientOptions}
												values={queryInfo}
												onChange={updateQueryInfo}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</Indent>
					</div>
				}
			/>
			<Tab
				id="grouping"
				title="Grouping"
				panel={
					<div>
						<h3>Group By</h3>
						<RadioGroup
							onChange={e =>
								updateQueryInfo((oldData: QueryInfo) =>
									assign(
										getProxyData((trace: QueryInfo) => trace.params?.bin?.by),
										e.target.value === BIN_TYPES.TIME
											? assign(
													getProxyData((trace: QueryInfo) => trace.params?.bin?.timeData),
													oldData,
													{ month: 1 }
											  )
											: oldData,
										e.target.value
									)
								)
							}
							selectedValue={queryInfo.params?.bin?.by || BIN_TYPES.NONE}>
							{Object.keys(BIN_TYPES).map(binType => (
								<Radio
									label={<Tooltip content={BIN_TYPES_INFORMATION[binType]}>{binType}</Tooltip>}
									value={binType}
									key={binType}
								/>
							))}
						</RadioGroup>
						{queryInfo.params?.bin?.by === BIN_TYPES.TIME ? (
							<>
								<h3>Time Options</h3>
								<Indent>
									<RadioGroup
										selectedValue={Object.keys(queryInfo.params?.bin?.timeData || {})[0]}
										onChange={e =>
											updateQueryInfo((oldData: QueryInfo) =>
												assign(
													getProxyData((trace: QueryInfo) => trace.params?.bin?.timeData),
													oldData,
													{ [e.target.value]: 1 }
												)
											)
										}>
										{TIME_BINNING_OPTIONS.map(timeType => (
											<Radio label={timeType} value={timeType} key={timeType} />
										))}
									</RadioGroup>
									{Object.keys(queryInfo.params?.bin?.timeData || {}).map(key => (
										<Label key={key}>
											<Row>
												<div>Bin every:</div>
												<NumericInput
													valueProxy={getProxyData(
														(trace: QueryInfo) => trace.params?.bin?.timeData?.[key]
													)}
													{...sharedProxyData}
												/>
												<div>{key}s</div>
											</Row>
										</Label>
									))}
								</Indent>
							</>
						) : null}
						<h3>Discriminate By:</h3>
						<RadioGroup
							onChange={e =>
								updateQueryInfo((oldData: QueryInfo) =>
									assign(
										getProxyData((trace: QueryInfo) => trace.params?.discriminateBy),
										oldData,
										e.target.value
									)
								)
							}
							selectedValue={queryInfo.params?.discriminateBy || DISCRIMINATION_TYPES.MISSION}>
							{Object.keys(DISCRIMINATION_TYPES).map(discriminationType => (
								<Radio
									label={
										<Tooltip content={DISCRIMINATION_TYPES_INFORMATION[discriminationType]}>
											{discriminationType}
										</Tooltip>
									}
									value={discriminationType}
									key={discriminationType}
								/>
							))}
						</RadioGroup>
					</div>
				}
			/>
			<Tab
				id="overrides"
				title="Overrides"
				panel={<WeightOverrideForm queryInfo={queryInfo} onChange={updateQueryInfo} />}
			/>
			<Tab
				id="source"
				title="Source"
				panel={
					<div>
						<h3>Get Analytics From</h3>
						<ReactSelect
							value={[{ value: selectedAnalyticsCollection, label: selectedAnalyticsCollection }]}
							name="Get Analytics From Collection"
							options={Object.keys(ANALYTICS_MODELS).map(collection => ({
								value: collection,
								label: collection,
							}))}
							onChange={selected => {
								updateQueryInfo((oldData: QueryInfo) =>
									assign(selectedAnalyticsCollectionProxy, oldData, selected.value)
								)
							}}
							placeholder="Defaults to production"
							className="basic-multi-select"
							classNamePrefix="select"
							getOptionLabel={({ label }) => label}
						/>
					</div>
				}
			/>
			{allowCustomQueryTime ? (
				<Tab
					id="Advanced"
					title="Dangerously Advanced"
					panel={
						<table>
							<tr>
								<td>
									<h3>Query Time</h3>
								</td>
								<td>
									<NumericInput
										valueProxy={getProxyData((trace: QueryInfo) => trace.params?.queryTime)}
										{...sharedProxyData}
									/>
								</td>
							</tr>
						</table>
					}
				/>
			) : null}
		</Tabs>
	)
}

/**
 * clientToOption - convert a client object to a select input option
 *
 * @param {ClientType} client - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function clientToOption(client: ClientType): Option {
	return {
		value: client._id,
		label: `${client.firstName} ${client.lastName} (${client.email} - ${client.schoolName ??
			'Unknown School'})`,
	}
}

/**
 * schoolToOption - convert a school object to a select input option
 *
 * @param {School} school - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function schoolToOption({ _id, state, name }: School): Option {
	return {
		value: _id,
		label: `${name} (${state})`,
	}
}

/**
 * districtToOption - convert a district object to a select input option
 *
 * @param {District} district - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function districtToOption({ ncesId, district }: District): Option {
	return {
		value: ncesId,
		label: district,
	}
}

/**
 * stateToOption - convert a state object to a select input option
 *
 * @param {State} state - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function stateToOption({ abbreviation, name }: State): Option {
	return {
		value: abbreviation,
		label: name,
	}
}

/**
 * simulationToOption - convert a simulation object to a select input option
 *
 * @param {Simulation} simulation - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function simulationToOption({ name, _id }: Simulation): Option {
	return {
		value: _id,
		label: name,
	}
}

/**
 * WeightOverrideForm - a component used to edit the weight overrides
 *
 * @param {Object} props - the react props
 * @param {QueryInfo} props.queryInfo - the current setup of the query
 * @param {UpdateQueryInfoCallback} props.updateQueryInfo - a callback to update the queryInfo
 *
 * @return {React$Node}
 */
function WeightOverrideForm({
	queryInfo,
	onChange,
}: {
	queryInfo: QueryInfo,
	onChange: UpdateQueryInfoCallback,
}): React$Node {
	const fields = []
	Object.keys(OVERRIDABLE_WEIGHTS).forEach(category => {
		Object.keys(OVERRIDABLE_WEIGHTS[category]).forEach(subCategory => {
			fields.push(
				<tr key={`${category}.${subCategory}`}>
					<td>
						{category}.{subCategory}
					</td>
					<td>
						<NumericInput
							placeholder={`usually ${
								// $FlowFixMe[prop-missing] we know subcategory exists in the category object type because we got it from Object.keys
								OVERRIDABLE_WEIGHTS[category][subCategory]
							}`}
							valueProxy={getProxyData(
								(trace: QueryInfo) => trace.params?.overrides?.weights?.[category]?.[subCategory]
							)}
							values={queryInfo}
							onChange={onChange}
						/>
					</td>
				</tr>
			)
		})
	})
	return (
		<>
			<h3>Weight Overrides</h3>
			<Indent>
				<table>
					<tbody>{fields}</tbody>
				</table>
			</Indent>
		</>
	)
}

const Indent = styled.div`
	margin-left: 8px;
`
