import { NUMBER_TYPES } from './constants'
import type { StatsType } from './types'

const SECOND = 1000 // ms
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

export type TimeConverter = (milliseconds: number) => number
export const TIME_CONVERTERS: { [converterType: string]: TimeConverter } = {
	milliseconds: milliseconds => milliseconds,
	seconds: milliseconds => milliseconds / SECOND,
	minutes: milliseconds => milliseconds / MINUTE,
	hour: milliseconds => milliseconds / HOUR,
	day: milliseconds => milliseconds / DAY,
}

export type ConversionMap = {
	[StatsType]: (baseValue: number) => number,
}

/**
 * generateConversionMap - generate a mapping of statsType to functions which convert those types into the units the users want
 *
 * @param {{convertTimeTo:string}} {convertTimeTo} - the time unit that time should be displayed as
 *
 * @return {ConversionMap}
 */
export function generateConversionMap({ convertTimeTo }: { convertTimeTo: string }): ConversionMap {
	return {
		[NUMBER_TYPES.TIME]: TIME_CONVERTERS[convertTimeTo] ?? TIME_CONVERTERS.milliseconds,
	}
}
