import { Dialog, Classes, Card, Tag } from '@blueprintjs/core'
import { useClientStore, selectors } from '../../../stores'
import styled from 'styled-components/macro'

import React from 'react'

type Feedback = {
	simulationId: string,
	missionCode: string,
	flightDirectorId: string,
	generalFeedback?: string,
}

/**
 * A dialog (modal) view to display feedback from specific users/simulations on the reports page
 */
export default function FeedbackView({
	title,
	feedbackList,
	close,
}: {
	title: string,
	feedbackList: Feedback[],
	close: () => void,
}): React$Node {
	return (
		<Dialog
			title={title}
			isOpen="true"
			onClose={close}
			className={Classes.DIALOG}
			css="width: 60vw !important;">
			<FeedbackBody>
				{feedbackList.map(feedback => (
					<SingleFeedback key={feedback.missionCode} data={feedback} />
				))}
			</FeedbackBody>
		</Dialog>
	)
}

/**
 * A single feedback for a mission
 */
function SingleFeedback({ data }: { data: Feedback }) {
	const missionOwner = useClientStore(state => selectors.getClient(state, data.flightDirectorId))
	return (
		<FeedbackCard>
			{data.generalFeedback && (
				<FeedbackType css="flex: 1">
					<Tag>General Feedback</Tag>
					{data.generalFeedback}
				</FeedbackType>
			)}
			<div css="font-style: italic; opacity: .4;">
				{missionOwner ? (
					<div css="display: flex; justify-content: space-between; align-items: flex-end;">
						<div>
							<div>
								{missionOwner.firstName} {missionOwner.lastName} ({missionOwner.email})
							</div>
							<div>Mission Code: {data.missionCode || 'Not Available'}</div>
						</div>
						<span>{missionOwner.schoolName}</span>
					</div>
				) : (
					'No owner available'
				)}
			</div>
		</FeedbackCard>
	)
}
const FeedbackCard = styled(Card)`
	margin: 4px;
	flex: 1;
	min-width: 45%;
	@media screen and (max-width: 1100px) {
		min-width: 90%;
	}
	display: flex;
	flex-direction: column;
`

const FeedbackBody = styled.div`
	padding: 8px;
	display: flex;
	flex-wrap: wrap;
	max-height: 75vh;
	overflow-y: auto;
`

const FeedbackType = styled.div`
	flex: 1;
	margin-bottom: 6px;
	.bp3-tag {
		margin-right: 6px;
	}
`
