import type { ClientCriteriaForm, FieldType, ResourceCriteria } from '../clientTypes'
import type { Criteria, ForceDisplayFields } from '../localTypes'

import { FIELD_TYPES } from '../clientTypes'

export type UnusedField = { field: FieldType, path: string[], displayPath: string[] }

/**
 * getUnusedFieldsForCriteria - gets a list of fields not used in the criteria or forceDisplay
 *
 * @param  {?Criteria} criteria - the current criteria
 * @param  {?ResourceCriteria} resource - the current resource being looked at
 * @param  {ClientCriteriaForm} resourceMap - a mapping of resource names to resource objects
 * @param  {ForceDisplayFields} forceDisplayFields - a tree of fields which are forced to be displayed even though they may not exist in the criteria
 * @param  {number} depthToTraverse - how far into the tree to look for fields
 * @param  {Array<string>=[]} currentPath - the current path into the tree (using ids)
 * @param  {Array<string>=[]} currentDisplayPath - the current path into the tree (using display names)
 *
 * @returns UnusedField[]
 */
export function getUnusedFieldsForCriteria(
	criteria: ?Criteria,
	resource: ?ResourceCriteria,
	resourceMap: ClientCriteriaForm,
	forceDisplayFields: ForceDisplayFields,
	depthToTraverse: number,
	currentPath: Array<string> = [],
	currentDisplayPath: Array<string> = []
): UnusedField[] {
	const unusedFields: UnusedField[] = []

	if (depthToTraverse === 0 || !resource) {
		return unusedFields
	}

	const availableFormFields = Object.keys(resource)
	const usedFields = new Set([
		...Object.keys(criteria ?? {}),
		...Object.keys(forceDisplayFields ?? {}),
	])

	availableFormFields.forEach(fieldName => {
		const field: FieldType = resource[fieldName]
		let fieldToAdd = {
			field,
			path: [...currentPath, fieldName],
			displayPath: [...currentDisplayPath, field.displayName],
		}
		if (field.type === FIELD_TYPES.COMPOSABLE) {
			if (!field.isExpectedOneToMany) {
				getUnusedFieldsForCriteria(
					criteria?.[fieldName]?.criteria,
					resourceMap[field.resource],
					resourceMap,
					forceDisplayFields?.[fieldName]?.criteria,
					depthToTraverse - 1,
					[...currentPath, fieldName],
					[...currentDisplayPath, field.displayName]
				).forEach(field => unusedFields.push(field))
			}
			fieldToAdd = {
				field,
				path: [...currentPath, fieldName],
				displayPath: [...currentDisplayPath, field.displayName, 'count'],
			}
			if (criteria?.[fieldName]?.count || forceDisplayFields?.[fieldName]?.count) {
				return
			}
			unusedFields.push(fieldToAdd)
			return
		}
		if (usedFields.has(fieldName)) {
			return
		}
		unusedFields.push(fieldToAdd)
	})

	return unusedFields
}
