import create from 'zustand'

import { makeFetchingStore } from './helpers'
import { isObject } from '../utility/functions'
import type { FetchingStore } from './helpers'

export type RoleEnum = 'FOUNDER' | 'LICENSE_MANAGER'

export type User = {
	id: number,
	email: string,
	firstName: string,
	lastName: string,
	displayName: string,
	teams: Array<{ id: number, name: string }>,
	roles: RoleEnum[],
}

export type Store = {
	...FetchingStore<{ getCurrentUser: () => ?User }>,
	loggedIn: boolean,
	googleUrl: ?string,
	forceLogin: string => void,
}

// $FlowFixMe I'm not sure how to make flow work for makeFetchingStore
const { makeStore: makeCurrentUserStore, selectors: currentUserSelectors } = makeFetchingStore({
	url: 'employees/me',
	dataKey: 'currentUser',
	// Gets the employee off of the response, adds a displayName, and then returns it
	getDataFromResponse: response => {
		if (!isObject(response) || !isObject(response.employee)) {
			return null
		}

		const employee: User = (response.employee: any) // Cast response to any, assuming the response is a valid user
		const { firstName, lastName } = employee
		if (!firstName && !lastName) {
			employee.displayName = 'No Name Available'
		} else {
			let displayName = ''
			if (firstName) {
				displayName += firstName
			}
			if (lastName) {
				if (displayName.length > 0) {
					displayName += ' '
				}
				displayName += lastName
			}
			employee.displayName = displayName
		}

		return employee
	},
})

const [useStore, api] = create((set): Store => ({
	...makeCurrentUserStore(set),
	loggedIn: false,
	googleUrl: null,
	forceLogin: url => set({ googleUrl: url, loggedIn: false }),
}))

export const selectors = {
	getSignInURL: (state: Store): ?string => {
		if (state.googleUrl && !state.loggedIn) {
			return state.googleUrl
		}
		return null
	},
	isLoggedIn: (state: Store): boolean => {
		return state.loggedIn
	},
	...currentUserSelectors,
}

export function useCurrentUser(): ?User {
	// Cast to any because the key `getCurrentUser` is created dynamically in `makeCurrentUserStore`
	return useStore((selectors: any).getCurrentUser)
}

export function setLoggedIn() {
	if (!api.getState().loggedIn) api.setState({ loggedIn: true })
}

export function forceLogin(loginURL: string) {
	api.setState({ googleUrl: loginURL, loggedIn: false })
}

export function getUserId(): ?string {
	return api.getState().currentUser?.id
}

export { api as __test_api }

export default useStore
