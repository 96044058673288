import create from 'zustand'
import { FILTER_TYPES } from '../utility/constants'

import type {
	Filter,
	DateFilter,
	BooleanFilter,
	SelectFilter,
	SelectFilterType,
	CalendarFilterType,
	BooleanFilterType,
	FilterType,
} from './types'

export type Store = {
	filters: IdMap<Filter>, // a list of tuples
	addGroup: (IdMap<Filter>) => void,
	removeKeys: (FilterType[]) => void,
}

const [useStore, api] = create((set): Store => ({
	filters: getDefaultFilters(),
	addGroup: (filters: IdMap<Filter>) =>
		set(state => ({ filters: { ...state.filters, ...filters } })),
	removeKeys: (filterKeys: FilterType[]) =>
		set(state => {
			const filters = { ...state.filters }
			filterKeys.forEach(key => delete filters[key])
			return { filters }
		}),
}))

export const selectors = {
	getActiveFilters: (state: Store): IdMap<Filter> => {
		return state.filters
	},
	getSelectFilterValue: (state: Store, filterType: SelectFilterType): ?string => {
		const filters = state.filters
		// $FlowFixMe: flow doesn't recognize that these filter types are connected to SelectFilters
		const filter: ?SelectFilter = filters[filterType]
		return filter ? filter.value : null
	},
	getSchoolFilterValue: (state: Store): ?string => {
		return selectors.getSelectFilterValue(state, FILTER_TYPES.SCHOOL)
	},
	getDateFilterValue: (state: Store, filterType: CalendarFilterType): ?Date => {
		const filters = state.filters
		// $FlowFixMe: flow doesn't recognize that these filter types are connected to DateFilters
		const filter: ?DateFilter = filters[filterType]
		return filter ? filter.value : null
	},
	getBooleanFilterValue: (state: Store, filterType: BooleanFilterType): ?boolean => {
		const filters = state.filters
		// $FlowFixMe: flow doesn't recognize that these filter types are connected to BooleanFilters
		const filter: ?BooleanFilter = filters[filterType]
		return filter ? filter.value : null
	},
	getDateRange: (state: Store): [?Date, ?Date] => {
		const rules: [?Date, ?Date] = [null, null]
		const startDate = selectors.getDateFilterValue(state, FILTER_TYPES.START_DATE)
		const endDate = selectors.getDateFilterValue(state, FILTER_TYPES.END_DATE)
		if (startDate) {
			rules[0] = new Date(startDate)
		}
		if (endDate) {
			rules[1] = new Date(endDate)
		}
		return rules
	},
}

function getDefaultFilters() {
	const oneMonthAgo = new Date()
	oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
	return {
		[FILTER_TYPES.STARTED]: { type: FILTER_TYPES.STARTED, value: true },
		[FILTER_TYPES.FINISHED]: { type: FILTER_TYPES.FINISHED, value: true },
		[FILTER_TYPES.PREPPED]: { type: FILTER_TYPES.PREPPED, value: true },
		[FILTER_TYPES.START_DATE]: { type: FILTER_TYPES.START_DATE, value: oneMonthAgo },
		[FILTER_TYPES.END_DATE]: { type: FILTER_TYPES.END_DATE, value: new Date() },
	}
}

export function getFiltersFromApi() {
	return api.getState().filters
}

export default useStore
