import create from 'zustand'
import { useQuery } from 'react-query'
import type { MissionType, Filter, MissionDetailType } from './types'
import NetworkCommunicator from '../services/NetworkCommunicator'
import { mapEntries } from '../utility/functions'
import { getFiltersFromApi } from './filters'

export type Store = {
	liveMissions: ?(MissionType[]),
	mission: ?MissionDetailType,
	fetchLiveMissions: () => void,
	fetchMission: (missionId: string) => void,
}

const [useStore] = create((set): Store => ({
	liveMissions: null,
	mission: null,
	fetchLiveMissions: () => {
		set({ liveMissions: null })
		NetworkCommunicator.GET('missions?active=true')
			.then(response => {
				set({ liveMissions: response.missions })
			})
			.catch(err => console.log(err))
	},
	fetchMission: (missionId: string) => {
		set({ mission: null })
		NetworkCommunicator.GET(`missions/${missionId}`)
			.then(response => {
				set({ mission: response.mission })
			})
			.catch(err => console.log(err))
	},
}))

/**
 * A light wrapper around useQuery that fetches past missions depending on the passed in `filters`. Accepts all options
 * for `useQuery`, as well as a `filters` property. If `filters` is not provided, the current filters in the filters store
 * will be used instead.
 * @param {Object} options options for react-query's `useQuery`
 * @param {IdMap<Filter>} options.filters The filters to pass to the end point
 * @return {Object} The result of `useQuery`, with the property `missions` instead of `data`
 */
export function usePastMissions({ filters, ...options }: { filters?: IdMap<Filter> } = {}): {
	missions: ?Array<MissionType>,
	refetch: () => mixed,
} {
	const { data: missions, ...rest } = useQuery(
		'past-missions',
		async () => {
			const selectedFilters = filters || getFiltersFromApi()
			const response = await NetworkCommunicator.GET(`missions${encodeFilters(selectedFilters)}`)
			return response.missions
		},
		options
	)

	return { missions, ...rest }
}

function encodeFilters(filters: IdMap<Filter>) {
	const filterValues: Filter[] = mapEntries(filters, filter => filter)
	return filterValues.length
		? '?' +
				'filters=true&' +
				filterValues
					.map(filter => {
						let value
						if (['START_DATE', 'END_DATE'].includes(filter.type)) {
							// $FlowFixMe START_DATE and END_DATE have Date values
							value = (filter.value: Date).toJSON()
						} else value = String(filter.value)
						return encodeURIComponent(filter.type) + '=' + encodeURIComponent(value)
					})
					.join('&')
		: ''
}

export const selectors = {
	getLiveMissions: (state: Store): ?(MissionType[]) => state.liveMissions,
	getMission: (state: Store, missionId: string): ?MissionDetailType => {
		const mission = state.mission
		if (mission && mission._id !== missionId) return null
		return mission
	},
	getLiveMissionsFetch: (state: Store) => state.fetchLiveMissions,
	getMissionFetch: (state: Store) => state.fetchMission,
}

export default useStore
