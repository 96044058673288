import type { AlertFormData } from './types'

import Loader from '../../../components/basic/Loader'
import { AlertEditor } from './AlertEditor'
import { AlertReportTable } from './AlertReportTable'
import { useAlert, useRefreshAlert, useSaveAlert } from './hooks'

import React from 'react'
import { useState } from 'react'
import { Button, Classes, Intent, Card } from '@blueprintjs/core'
import moment from 'moment'
import styled from 'styled-components'

/**
 * AlertViewer - show the information about the alert described by the alertId
 *
 * @param  {{alertId:string}} {alertId} - the id of the alert to show the information for
 *
 * @returns React$Node
 */
export function AlertViewer({ alertId }: { alertId: string }): React$Node {
	const { isLoading: isRefreshing, error: errorWhileRefreshing, mutate: refresh } = useRefreshAlert(
		alertId
	)
	const [isEditing, setIsEditing] = useState(false)
	const { isLoading: isSaving, mutate: saveAlert } = useSaveAlert(alertId, () => {
		setIsEditing(false)
	})
	const { data: alertData, isLoading, error } = useAlert(alertId)

	if (isLoading || isSaving || isRefreshing) {
		return <Loader />
	}

	if (errorWhileRefreshing) {
		return <div>An error occurred while refreshing the alerts</div>
	}

	if (error || !alertData) {
		return <div>An error occurred while loading the alert</div>
	}

	if (isEditing) {
		return (
			<AlertEditor
				alert={alertData}
				onSave={(newData: AlertFormData) => {
					saveAlert(newData)
				}}
			/>
		)
	}

	return (
		<>
			<SpaceBetween className={Classes.DIALOG_HEADER}>
				<div>Alert: {alertData.name}</div>
				{alertData.lastUpdated ? (
					<Fade>Last Updated: {moment(new Date(alertData.lastUpdated)).format('L LTS')}</Fade>
				) : null}
			</SpaceBetween>

			<Card className={Classes.DIALOG_BODY}>
				<AlertReportTable alertId={alertId} />
			</Card>
			<StyledFooter className={Classes.DIALOG_FOOTER}>
				<Button onClick={() => refresh()} icon="refresh">
					Refresh List
				</Button>
				<Button onClick={() => setIsEditing(true)} icon="edit" intent={Intent.PRIMARY}>
					Edit
				</Button>
			</StyledFooter>
		</>
	)
}

const Fade = styled.div`
	opacity: 0.5;
`

const SpaceBetween = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	&& {
		padding-right: 20px;
	}
`

const StyledFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 4px;
`
