import type { School } from '../../../../../../services/hooks'

import type { Option } from '../../../../../../utility/hooks'

import {
	MIN_SCHOOL_SEARCH_STRING_LENGTH,
	useAllFetchedSchools,
	useSchools,
	useSchoolsByIds,
} from '../../../../../../services/hooks'

import { MassiveMultiSelect } from './MassiveMultiSelect'

import React from 'react'
import { useMemo, useState } from 'react'

/**
 * SchoolSelector - a form which allows the user to select schools from a searchable multi-select box
 *
 * @param {Object} props - the react props
 * @param {?Array<string>} props.selection - the selected schoolIds
 * @param {(newSelection: ?Array<string>) => any} props.onChange - a callback when the selection changes
 *
 * @returns React$Node
 */
export default function SchoolSelector({
	selection,
	onChange,
}: {
	selection: ?Array<string>,
	onChange: (newSelection: ?Array<string>) => any,
}): React$Node {
	const [schoolSearchString, setSchoolSearchString] = useState('')

	let areSchoolsLoading = false
	useSchoolsByIds(selection ?? []).forEach(
		({ isLoading }) => (areSchoolsLoading = areSchoolsLoading || isLoading)
	)
	areSchoolsLoading = useSchools(schoolSearchString).isLoading || areSchoolsLoading

	const allFetchedSchools = useAllFetchedSchools()
	const [schoolLookup, schoolOptions] = useMemo(() => {
		const schoolLookup = {}
		allFetchedSchools.forEach(school => (schoolLookup[school._id] = school))

		return [
			schoolLookup,
			allFetchedSchools.map(schoolToOption).sort((a, b) => a.label.localeCompare(b.label)),
		]
	}, [allFetchedSchools])

	return (
		<MassiveMultiSelect
			selectedOptions={selection ?? []}
			onChange={onChange}
			valueMapper={values =>
				values.map(value =>
					schoolToOption(
						schoolLookup[value] ?? {
							_id: value,
							name: 'Unknown',
							state: 'Unknown',
						}
					)
				)
			}
			options={!areSchoolsLoading ? schoolOptions : null}
			needsMoreText={schoolSearchString.length < MIN_SCHOOL_SEARCH_STRING_LENGTH}
			onSearchStringChange={setSchoolSearchString}
		/>
	)
}

/**
 * schoolToOption - convert a school object to a select input option
 *
 * @param {School} school - the object to convert
 *
 * @return {Option} an object to use with a selection input
 */
function schoolToOption({ _id, state, name }: School): Option {
	return {
		value: _id,
		label: `${name} (${state})`,
	}
}
