import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { FocusStyleManager } from '@blueprintjs/core'
import Theme from './theme-styles.js'
import './App.css'
import styled from 'styled-components'
import Main from './routes'
import SignIn from './components/SignIn'
import { useClientStore, useMissionStore, selectors } from './stores/index.js'
import useAuthStore, {
	selectors as authSelectors,
	useCurrentUser,
	setLoggedIn,
} from './stores/auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Import all the blueprint css requirements
import 'normalize.css/normalize.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import '@blueprintjs/core/lib/css/blueprint.css'

FocusStyleManager.onlyShowFocusOnTabs()

const PageWrapper = styled.div`
	height: 100vh;
	width: 100vw;
	background: ${props => props.theme.secondary};
`
// The react-query client for this application. See https://react-query.tanstack.com/reference/QueryClient#queryclient for details
const queryClient = new QueryClient()

export default function AppWithQueryProvider(): React$Node {
	return (
		<QueryClientProvider client={queryClient}>
			<App />
			<ReactQueryDevtools />
		</QueryClientProvider>
	)
}

function App() {
	const fetchLiveMissions = useMissionStore(selectors.getLiveMissionsFetch)
	const fetchClients = useClientStore(selectors.getClientFetch)
	const loggedIn = useAuthStore(selectors.isLoggedIn)
	const fetchCurrentUser = useAuthStore(authSelectors.getFetcher)
	const currentUser = useCurrentUser()

	useEffect(() => {
		fetchCurrentUser()
	}, [fetchCurrentUser])

	useEffect(() => {
		if (currentUser) setLoggedIn()
	}, [currentUser])

	useEffect(() => {
		if (currentUser) {
			fetchLiveMissions()
			fetchClients()
		}
	}, [currentUser, fetchLiveMissions, fetchClients])

	return (
		<ThemeProvider theme={Theme}>
			<PageWrapper>{loggedIn ? <Main /> : <SignIn />}</PageWrapper>
		</ThemeProvider>
	)
}
