import React from 'react'
import styled from 'styled-components'
import { Card } from '@blueprintjs/core'
import { SUBROUTES } from './constants'
import type { QueryInfo, ComparerData } from './types'
import { scrollToElement } from './viewHelpers'

/**
 * Navigation - a react component which gives quick navigation between stats and comparers
 *
 * @param {Object} props - the react props
 * @param {(subRoute: $Keys<typeof SUBROUTES>) => void} props.setSubroute - a callback to update the subroute being displayed
 * @param {() => void} props.addStats - a callback to create a stats object
 * @param {() => void} props.addComparer - a callback to create a comparer object
 * @param {() => void} props.statsInfo - a list of all the stats queries
 * @param {() => void} props.comparers - a list of all the comparers
 *
 * @return {React$Node}
 */
export default function Navigation({
	setSubroute,
	addStats,
	addComparer,
	statsInfo,
	comparers,
}: {
	setSubroute: (subRoute: $Keys<typeof SUBROUTES>) => void,
	addStats: () => void,
	addComparer: () => void,
	statsInfo: QueryInfo[],
	comparers: ComparerData[],
}): React$Node {
	return (
		<NavigationCardPlacer>
			<NavigationCard>
				<NavigationHeader onClick={() => setSubroute(SUBROUTES.STATS)}>Stats</NavigationHeader>
				{statsInfo.length ? (
					<NavigationLinkList>
						{statsInfo.map(({ name, id }) => (
							<NavigationLink
								key={id}
								onClick={() => {
									setSubroute(SUBROUTES.STATS)
									scrollToElement(id)
								}}>
								{name}
							</NavigationLink>
						))}
					</NavigationLinkList>
				) : null}
				<NavigationCreationLink onClick={() => addStats()}>+ Stats</NavigationCreationLink>
				<NavigationHeader onClick={() => setSubroute(SUBROUTES.COMPARERS)}>
					Compare Stats
				</NavigationHeader>
				{comparers.length ? (
					<NavigationLinkList>
						{comparers.map(({ id, name }) => (
							<NavigationLink
								key={id}
								onClick={() => {
									setSubroute(SUBROUTES.COMPARERS)
									scrollToElement(id)
								}}>
								{name}
							</NavigationLink>
						))}
					</NavigationLinkList>
				) : null}
				<NavigationLink onClick={() => addComparer()}>+ Comparison</NavigationLink>
			</NavigationCard>
		</NavigationCardPlacer>
	)
}

const NavigationCardPlacer = styled.div`
	position: absolute;
	top: calc(100vh - 25em);
	left: 20px;
	max-width: 175px;
	min-width: 175px;

	> * {
		padding: 8px;
	}
`

const NavigationHeader = styled.h4`
	flex: 0 0 auto;
	margin-top: 0;
	margin-bottom: 0.5em;
	cursor: pointer;
`

const NavigationLink = styled.a`
	flex: 0 0 auto;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	white-space: nowrap;
`

const NavigationCreationLink = styled(NavigationLink)`
	margin-bottom: 1em;
`

const NavigationLinkList = styled.div`
	margin-bottom: 1em;
	flex: 1 1 0;
	overflow-y: auto;
`

const NavigationCard = styled(Card)`
	max-height: 20em;
	min-height: 20em;
	display: flex;
	flex-direction: column;
`
