import Loader from '../../../components/basic/Loader'

import { AlertForm } from './AlertForm'

import React, { useState } from 'react'
import { Classes, Button, Intent } from '@blueprintjs/core'
import styled from 'styled-components'
import { useCreateAlert } from './hooks'

/**
 * NewAlert - a component used to create an alert
 *
 * @param {Object} props - the react props
 * @param {(alertId: string) => void} props.redirectToId - a callback used to redirect to the given alert (will redirect after save)
 *
 * @returns React$Node
 */
export function NewAlert({
	redirectToId,
}: {
	redirectToId: (alertId: string) => void,
}): React$Node {
	const [currentAlert, setCurrentAlert] = useState({
		name: '',
		documentType: 'user',
		notifiers: [],
		criteria: {},
	})
	const { isLoading: isSaving, mutate: createAlert } = useCreateAlert((alertId: string) =>
		redirectToId(alertId)
	)

	if (isSaving) {
		return <Loader />
	}

	return (
		<>
			<div className={Classes.DIALOG_HEADER}>Create Alert</div>
			<div className={Classes.DIALOG_BODY}>
				<AlertForm currentAlertData={currentAlert} updateAlertData={setCurrentAlert} />
			</div>
			<Footer className={Classes.DIALOG_FOOTER}>
				<Button
					onClick={() => {
						createAlert(currentAlert)
					}}
					intent={Intent.PRIMARY}>
					Create Alert
				</Button>
			</Footer>
		</>
	)
}

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
`
