/**
 * This function takes string and creates google search link from that
 * @param {string} text
 */

export const createGoogleQuery = (text: string): string => {
	if (!text) return ''
	const splittedText = text.toLowerCase().split(' ')
	const queryChain = splittedText.reduce((acc, item, index) => {
		return `${acc}+${item}`
	})
	return `https://www.google.com/search?q=` + queryChain
}
