/**
 * This function takes object key and return prompt for label
 * @param {string} str
 */
export const createPrompt = (str: string): string => {
	switch (str) {
		case 'locationState':
			return 'Location'
		case 'locationStreet':
			return 'Street'
		case 'locationCity':
			return 'City'
		case 'locationZip':
			return 'Zip'
		default:
			// adding space between strings
			const result = str.replace(/([A-Z])/g, ' $1')
			// converting first character to uppercase and join it to the final string
			const final = result.charAt(0).toUpperCase() + result.slice(1)
			return final
	}
}
