import React, { useState } from 'react'
import { Select } from '../../../components'
import Client from './Client'
import School from './School'
import { useClientStore, selectors } from '../../../stores'
import ClientSearch from '../../../components/ClientSearch'

export default function FindClients({ className }: { className?: string }): React$Node {
	const [client, setClient] = useState(null)
	const [school, setSchool] = useState(null)
	const schools = useClientStore(selectors.getSchools)

	const getSchoolLabel = school => (school ? school.schoolName : '')

	return (
		<div className={className}>
			<Select
				clearable
				value={school}
				onChange={value => {
					setSchool(value)
					setClient(null)
				}}
				placeholder={'School...'}
				options={schools}
				getOptionLabel={getSchoolLabel}
				fill
			/>
			<p /> {/* Empty p tag is here to provide a gap between selects */}
			<ClientSearch
				value={client}
				onChange={value => {
					setClient(value)
				}}
				placeholder={'User...'}
				schoolId={school?.schoolId}
			/>
			<p /> {/* Empty p tag is here to provide a gap between selects */}
			{school && !client && <School school={school} setSchool={setSchool} />}
			{client && <Client id={client._id} />}
		</div>
	)
}
