import React, { useRef, useState } from 'react'
import {
	Popover,
	Tooltip,
	Classes,
	Position,
	PopoverInteractionKind,
	Icon,
} from '@blueprintjs/core'
import 'styled-components/macro'

export default function Copiable({ value }: { value: string }): React$Node {
	const copyRef = useRef()

	const [successMsg, setSuccessMsg] = useState(null)
	const copyToClipboard = () => {
		if (copyRef.current) {
			copyRef.current.select()
			document.execCommand('copy')
			setSuccessMsg('Copied to clipboard!')
			if (window.getSelection) {
				window.getSelection().removeAllRanges()
				// $FlowFixMe we check if selection exists first
			} else if (document.selection) {
				document.selection.empty()
			}
		}
	}
	const hideSuccessMsg = () => {
		setSuccessMsg(null)
	}

	return (
		<div css="display: inline-flex;">
			<input
				css="border: none; width: fit-content; user-select: none;"
				readOnly
				ref={copyRef}
				type="text"
				value={value}
				size={value.length}
			/>
			<Popover
				isOpen={!!successMsg}
				popoverClassName={Classes.TOOLTIP}
				interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
				content={<span>{successMsg}</span>}
				position={Position.RIGHT}
				hoverCloseDelay={1000}
				onClose={hideSuccessMsg}>
				<Tooltip content={`Copy '${value}'`} position={Position.RIGHT}>
					<Icon css="cursor: pointer;" onClick={copyToClipboard} icon="duplicate" />
				</Tooltip>
			</Popover>
		</div>
	)
}
