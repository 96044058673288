import React, { useCallback } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'
import { Button, Intent } from '@blueprintjs/core'
import styled from 'styled-components'
import 'styled-components/macro'
import { useCurrentUser } from '../../../stores/auth'
import { PageContainer, Loader } from '../../../components'
import OkrsList from './OkrsList'
import OkrPage from './OkrPage'
import QuarterSelector from '../../../components/QuarterSelector'
import { getCurrentQuarter, getOkrOwnerFromFilters, canDeleteOkr, userOwnsOkr } from './helpers'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { AppToaster } from '../../../components/basic/AppToaster'
import DeleteButton from '../../../components/DeleteButton'
import { useOkrs, useOkr } from '../../../resources/okrs'
import type { SimpleOkr as Okr } from '../../../stores/types'
import type { QueryParams, Owner } from './types'
import type { User } from '../../../stores/auth'

const PUSH_IN = 'pushIn'

const GridContainer = styled(PageContainer)`
	display: grid;
	grid-template:
		'. header' auto
		'nav main' 1fr
		/ auto 1fr;
	align-items: start;
	color: ${props => props.theme.primary};
`

const OkrNav = styled.aside`
	display: flex;
	flex-direction: column;
	grid-area: nav;
	button {
		margin-bottom: ${({ theme }) => theme.spacing};
	}
	margin-right: ${({ theme }) => theme.spacing2x};
`

const Main = styled.main`
	flex: 1;
	grid-area: main;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	grid-area: header;
`

function getTitle(query: QueryParams, owner: ?Owner, okr: ?Okr, currentUser: User): string {
	if (okr) {
		if (userOwnsOkr(currentUser, okr)) return 'Edit OKR'
		return 'View OKR'
	}
	if (owner && owner.type === 'TEAM') {
		return `${owner.displayName} OKRs`
	} else if (query.company) {
		return 'Company OKRs'
	}
	return 'My OKRs'
}

export default function Okrs(): React$Node {
	const currentUser = useCurrentUser()
	const currentUserId = currentUser?.id

	const params = useParams()
	const okrId = params.okrId

	const history = useHistory()
	const [query, _setQuery] = useQueryParams({
		employeeId: StringParam,
		teamId: NumberParam,
		company: NumberParam,
		quarter: StringParam,
	})

	const setQuery = useCallback(
		(query: QueryParams, updateType?: 'pushIn' | 'replaceIn' = PUSH_IN) => {
			const mutuallyExclusiveQueryParams = ['employeeId', 'teamId', 'company']
			const usedQueryParam = mutuallyExclusiveQueryParams.find(queryParam => query[queryParam])
			if (usedQueryParam) {
				mutuallyExclusiveQueryParams
					.filter(param => param !== usedQueryParam)
					.forEach(queryParam => (query[queryParam] = null))
			}
			_setQuery(query, updateType)
		},
		[_setQuery]
	)

	const { quarter, teamId, company } = query
	const hasQuery = Object.values(query).some(val => !!val)

	let optionsForUseOkrs = {}
	if (hasQuery) {
		optionsForUseOkrs = { ...query }
	} else if (currentUserId) {
		optionsForUseOkrs.employeeId = currentUserId
	}

	const { okrs, isLoading: isLoadingOkrs, error: okrsError } = useOkrs({
		queryParams: optionsForUseOkrs,
		queryOptions: { enabled: !okrId },
	})

	const { okr, isLoading: isLoadingSingleOkr, error: singleOkrError } = useOkr(okrId, {
		enabled: !!okrId,
	})

	const onChangeQuarter = useCallback(
		(newQuarter: string) => {
			// If okr is set, we are on a single okr page so we don't need or want to search by quarter
			if (!okr) {
				const newQueryParams = {}
				newQueryParams.quarter = newQuarter
				if (!teamId && !company) {
					newQueryParams.employeeId = currentUserId
				}
				setQuery(newQueryParams)
			}
		},
		[setQuery, okr, teamId, company, currentUserId]
	)

	if (!currentUser) {
		// This should never happen because there must be a user before passing the login screen
		console.error('No user in goals screen') // TODO: log in sentry
		return <p>No current user</p>
	}

	const okrsOwner = getOkrOwnerFromFilters(query, currentUser)
	const title = getTitle(query, okrsOwner, okr, currentUser)
	return (
		<GridContainer alignCenter>
			<OkrNav>
				<OkrNavLink urlQuery="">My OKRs</OkrNavLink>
				{currentUser &&
					currentUser.teams &&
					currentUser.teams.map(team => (
						<OkrNavLink urlQuery={`?teamId=${team.id}`} key={team.id}>
							{team.name} OKRs
						</OkrNavLink>
					))}
				<OkrNavLink urlQuery={`?company=1`}>Company OKRs</OkrNavLink>
				{!okr && <QuarterSelector value={quarter} onChange={onChangeQuarter} />}
			</OkrNav>
			<Header>
				<h1 css="margin-top: 0;">{title}</h1>
				{okr && canDeleteOkr(currentUser, okr) && (
					<DeleteButton
						confirmationText="Are you sure you want to delete this OKR?"
						onDelete={() =>
							NetworkCommunicator.DELETE(`okrs/${okr.id}`)
								.then(result => {
									history.replace('/okrs')
								})
								.catch(({ status }) => {
									AppToaster.show({ message: 'Failed to delete OKR', intent: Intent.DANGER })
								})
						}
						css="align-self: center;"
					/>
				)}
			</Header>
			<Main>
				{okrId ? (
					<>
						{isLoadingSingleOkr ? <Loader /> : null}
						{singleOkrError ? 'ERROR' : null}
						{!isLoadingSingleOkr && !singleOkrError && okr ? (
							<OkrPage okr={okr} currentUser={currentUser} />
						) : (
							'TROUBLE GETTING OKR'
						)}
					</>
				) : (
					<>
						{isLoadingOkrs ? <Loader /> : null}
						{okrsError ? 'ERROR' : null}
						{!isLoadingOkrs &&
							!okrsError &&
							(okrs ? (
								<OkrsList
									queryOverrides={optionsForUseOkrs}
									okrs={okrs}
									quarter={quarter || getCurrentQuarter()}
									owner={okrsOwner}
									allowOkrCreation={!!okrsOwner}
									currentUser={currentUser}
								/>
							) : (
								'TROUBLE GETTING OKRS'
							))}
					</>
				)}
			</Main>
		</GridContainer>
	)
}

function OkrNavLink({ urlQuery, children }: { urlQuery: string, children: React$Node }) {
	return (
		<Link to={`/okrs${urlQuery}`} css="&:hover { text-decoration: none; }">
			<Button fill>{children}</Button>
		</Link>
	)
}
