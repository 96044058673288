import React from 'react'
import ReactSelect from 'react-select'
import ReactApexChart from 'react-apexcharts'
import type {
	StatsQueryResponse,
	ComparerData,
	Key,
	LinkObject,
	QueryInfo,
	BinnedClientData,
} from './types'
import {
	LocationBreadCrumbs,
	Loading,
	ErrorComponent,
	ErrorFormatting,
	WarningFormatting,
} from './sharedComponents'
import { getLinksAtLocation, getSubPathsAtLocation } from './statsHelpers'
import { getComparisonBoxPlot, getComparisonLinePlot, getSortedBinsIds } from './chartHelpers'
import { PLOT_TYPES } from './constants'
import { getProxyData } from '../../../utility/dotProxy'
import ChartOptions from './customInputs/ChartOptions'
import { LINE_SELECTOR, BOX_PLOT_SELECTOR } from './selectors'
import { generateConversionMap } from './converters'

// $FlowIgnore[prop-missing] this value does exist in the chrome browser
const AND_FORMATTER = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })

/**
 * Comparison - a react component used to compare multiple stats query
 *
 * @param {QueryInfo[]} queryInfo - the query configuration for all stats (MUST BE THE SAME LENGTH OF `stats`)
 * @param {StatsQueryResponse[]} stats - the stats generated from each query (MUST BE THE SAME LENGTH OF `queryInfo`)
 * @param {ComparerData} comparerData - the data of what this comparison is comparing
 * @param {((oldData: ComparerData) => ComparerData) => void} updateComparer - a callback to update what this component is comparing
 */
export default function Comparison({
	queryInfo,
	stats,
	comparerData,
	updateComparer,
}: {
	queryInfo: QueryInfo[],
	stats: StatsQueryResponse[],
	comparerData: ComparerData,
	updateComparer: ((oldData: ComparerData) => ComparerData) => void,
}): React$Node {
	const statsIdToQueryInfo = {}
	queryInfo.forEach(queryInfo => (statsIdToQueryInfo[queryInfo.id] = queryInfo))

	const statsIdToStatsQuery = {}
	queryInfo.forEach(({ id }, index) => (statsIdToStatsQuery[id] = stats[index]))

	const usingStatsQueries: Array<{ info: QueryInfo, statsQuery: StatsQueryResponse }> = []
	comparerData.comparingStats.forEach(id => {
		if (!statsIdToStatsQuery[id] || !statsIdToQueryInfo[id]) {
			return
		}
		usingStatsQueries.push({ info: statsIdToQueryInfo[id], statsQuery: statsIdToStatsQuery[id] })
	})

	let HoldingComponent = null

	const statsBeingEdited = usingStatsQueries.filter(({ info }) => info.isEditing)

	const statsWithPartialResults: QueryInfo[] = []
	usingStatsQueries.forEach(
		({ info, statsQuery }: { info: QueryInfo, statsQuery: StatsQueryResponse }) => {
			if (statsQuery.data?.analyticsOfAnalytics?.onlyPartiallyCompleted) {
				statsWithPartialResults.push(info)
			}
		}
	)

	if (usingStatsQueries.some(({ statsQuery }) => statsQuery.error != null)) {
		HoldingComponent = <ErrorComponent />
	} else if (statsBeingEdited.length) {
		HoldingComponent = (
			<Loading
				message={`Waiting on stats ${AND_FORMATTER.format(
					statsBeingEdited.map(({ info: { name } }) => `"${name}"`)
				)} to finish being edited.`}
			/>
		)
	} else if (usingStatsQueries.some(({ statsQuery }) => statsQuery.isLoading || !statsQuery.data)) {
		HoldingComponent = <Loading />
	}

	const children: Set<string> = new Set()
	const links: { [linkName: string]: LinkObject } = {}

	const seenDiscriminationTypes: Set<string> = new Set()
	const seenBinTypes: Set<string> = new Set()
	const seenTimeData: Set<string> = new Set()
	const seenBins: BinnedClientData = {}

	usingStatsQueries.forEach(({ statsQuery }: { statsQuery: StatsQueryResponse }, index: number) => {
		const data = statsQuery.data?.analyticsOfAnalytics
		if (!data) {
			return
		}

		Object.keys(data.bins).forEach(binId => {
			const bin = data.bins[binId]
			seenDiscriminationTypes.add(bin.discriminationType)
			seenBins[binId] = bin
			seenBinTypes.add(bin.binType)
			seenTimeData.add(bin.timeBinning || 'NONE')
			getSubPathsAtLocation(comparerData.viewer.location, bin.stats).forEach(child =>
				children.add(child)
			)
			const locationLinks = getLinksAtLocation(comparerData.viewer.location, bin.stats)
			if (locationLinks) {
				locationLinks.forEach(linkObject => (links[linkObject.name] = linkObject))
			}
		})
	})

	const setLocation = (location: Key) => {
		updateComparer((oldData: ComparerData) => ({
			...oldData,
			viewer: { ...oldData.viewer, location },
		}))
	}

	let apexChartData = {}

	const queryData = usingStatsQueries.map(({ info, statsQuery }) => ({
		statsName: info.name,
		bins: statsQuery.data?.analyticsOfAnalytics?.bins,
	}))
	const conversionMap = generateConversionMap({ convertTimeTo: comparerData.viewer.timeConverter })
	if (comparerData.viewer.plotType === PLOT_TYPES.BOX_PLOT) {
		apexChartData = getComparisonBoxPlot(
			queryData,
			comparerData.viewer.bin,
			comparerData.viewer.location,
			BOX_PLOT_SELECTOR[comparerData.viewer.selector] || BOX_PLOT_SELECTOR.quartiles,
			comparerData.viewer.includeOutliers,
			conversionMap
		)
	} else {
		apexChartData = getComparisonLinePlot(
			queryData,
			comparerData.viewer.location,
			LINE_SELECTOR[comparerData.viewer.selector] || LINE_SELECTOR.average,
			comparerData.viewer.includeOutliers,
			conversionMap
		)
	}

	return (
		<div>
			Stats:{' '}
			<ReactSelect
				value={comparerData.comparingStats.map(statsId => ({
					value: statsId,
					label: statsIdToQueryInfo[statsId]?.name || 'Unknown Stats',
				}))}
				isMulti={true}
				name="Stats Selector"
				options={queryInfo.map(({ id, name }) => ({
					value: id,
					label: name,
				}))}
				onChange={selected => {
					updateComparer(oldData => ({
						...oldData,
						comparingStats: selected.map(({ value }) => value),
					}))
				}}
				placeholder="Select Stats To Compare..."
				className="basic-multi-select"
				classNamePrefix="select"
				getOptionLabel={({ label }) => label}
			/>
			{HoldingComponent || (
				<>
					{seenBinTypes.size > 1 ? (
						<ErrorFormatting>
							Mismatched stats types: {AND_FORMATTER.format(seenBinTypes)}. This comparison may be
							meaningless.
						</ErrorFormatting>
					) : null}
					{statsWithPartialResults.length > 1 ? (
						<ErrorFormatting>
							The following stats only contain partial results:{' '}
							{AND_FORMATTER.format(statsWithPartialResults.map(({ name }) => name))}. This
							comparison may be meaningless.
						</ErrorFormatting>
					) : null}
					{seenDiscriminationTypes.size > 1 ? (
						<ErrorFormatting>
							Mismatched element types: {AND_FORMATTER.format(seenDiscriminationTypes)}. This
							comparison may be meaningless.
						</ErrorFormatting>
					) : null}
					{seenTimeData.size > 1 ? (
						<WarningFormatting>
							Mismatched time scales: {AND_FORMATTER.format(seenTimeData)}. Be careful when
							interpreting this data.
						</WarningFormatting>
					) : null}
					<LocationBreadCrumbs
						location={comparerData.viewer.location}
						setCurrentLocation={setLocation}
						childrenOptions={Array.from(children)}
					/>
					<ReactApexChart
						key={JSON.stringify(comparerData.viewer)}
						options={apexChartData.options}
						series={apexChartData.series}
						type={comparerData.viewer.plotType === PLOT_TYPES.BOX_PLOT ? 'boxPlot' : 'line'}
						height={350}
					/>
					<ChartOptions
						includeOutliersProxy={getProxyData(
							(trace: ComparerData) => trace.viewer.includeOutliers
						)}
						plotTypeProxy={getProxyData((trace: ComparerData) => trace.viewer.plotType)}
						locationProxy={getProxyData((trace: ComparerData) => trace.viewer.location)}
						selectorProxy={getProxyData((trace: ComparerData) => trace.viewer.selector)}
						binProxy={getProxyData((trace: ComparerData) => trace.viewer.bin)}
						timeConverterProxy={getProxyData((trace: ComparerData) => trace.viewer.timeConverter)}
						values={comparerData}
						updateValues={updateComparer}
						binIds={getSortedBinsIds(seenBins)}
						links={Object.keys(links).map(name => links[name])}
					/>
				</>
			)}
		</div>
	)
}
