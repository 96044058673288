import React from 'react'
import styled from 'styled-components'
import { get, assign, type ProxyData } from '../../../../utility/dotProxy'
import { type SharedInputFields } from './types'
import { EditableText } from '@blueprintjs/core'

/**
 * EditableHeader - a custom input for handling editable header text
 *
 * @param {Object} props - the react props
 * @param {ProxyData<?string>} valueProxy - a proxyData describing the location of the numeric value to show and update
 * @param {T} values - the base container of the value
 * @param {UpdateCallback<T>} onChange - a callback used to update the base container
 *
 * @return {React$Node}
 */
export default function EditableHeader<T>({
	valueProxy,
	values,
	onChange,
}: {
	valueProxy: ProxyData<?string>,
	...SharedInputFields<T>,
}): React$Node {
	return (
		<HeaderEditor
			value={get(valueProxy, values) ?? ''}
			onChange={newValue => {
				onChange((oldData: T) => assign(valueProxy, oldData, newValue))
			}}
		/>
	)
}

const HeaderEditor = styled(EditableText)`
	font-size: 1.5em;
`
