import FlexWrapper from './FlexWrapper'
import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'
import type { StyledComponent } from 'styled-components'

const LoaderContainer: StyledComponent<
	{ color?: ?string },
	Theme,
	React$AbstractComponent<any, any>
> = styled(FlexWrapper)`
	color: ${props => props.color || props.theme.primary};
`

export default function renderLoader({
	color,
	withMsg,
	className,
}: {
	color?: string,
	withMsg?: boolean,
	className?: string,
}): React$Node {
	return (
		<LoaderContainer alignCenter column color={color} className={className}>
			{withMsg && <h4>Hang tight while we load your data!</h4>}
			<Spinner />
		</LoaderContainer>
	)
}
