import React from 'react'
import { get, assign, minimizeDelete, type ProxyData } from '../../../../utility/dotProxy'
import { type SharedInputFields } from './types'
import { InputGroup, Button } from '@blueprintjs/core'

/**
 * NumericInput - a custom input for handling numeric proxies
 *
 * @param {Object} props - the react props
 * @param {ProxyData<?number>} valueProxy - a proxyData describing the location of the numeric value to show and update
 * @param {?string} ?placeholder - text to show when there is no value
 * @param {?allowDelete} ?boolean - if false, will not show the button to delete the `value` from the `values` container
 * @param {T} values - the base container of the value
 * @param {UpdateCallback<T>} onChange - a callback used to update the base container
 *
 * @return {React$Node}
 */
export default function NumericInput<T>({
	valueProxy,
	placeholder,
	values,
	onChange,
	allowDelete,
}: {
	valueProxy: ProxyData<?number>,
	placeholder?: ?string,
	allowDelete?: ?boolean,
	...SharedInputFields<T>,
}): React$Node {
	const update = (value: ?string) => {
		if (value) {
			let number = Number(value)
			if (Number.isFinite(number) && !Number.isNaN(number)) {
				onChange((oldData: T) => assign(valueProxy, oldData, number))
			}
			return
		}
		onChange((oldData: T) => minimizeDelete(valueProxy, oldData))
	}

	return (
		<InputGroup
			placeholder={placeholder}
			type="number"
			value={get(valueProxy, values) ?? ''}
			onChange={e => update(e.target.value)}
			rightElement={
				allowDelete !== false ? <Button icon="cross" onClick={() => update(null)} /> : null
			}
		/>
	)
}
