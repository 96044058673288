import React from 'react'
import { Link } from 'react-router-dom'
import { FlexWrapper, Tabs } from '../../components'
import { getCurrentQuarter, getOkrOwnerFromFilters } from '../Goals/Okrs/helpers'
import { OkrsList, CheckpointsWeek } from '../Goals'
import 'styled-components/macro'
import styled, { css } from 'styled-components'
import { Card, Divider, Icon, Classes, H2 } from '@blueprintjs/core'
import type { User } from '../../stores/auth'
import type { TeamType, TeamEmployeeType } from './types'

type Props = {
	team: TeamType,
	currentUser: User,
}

const getTabs = (team: TeamType, currentUser: User) => [
	{
		id: 'TEAM_OKR_TAB',
		panel: <TeamOkrs team={team} currentUser={currentUser} />,
		title: 'Team Okrs',
	},
	{
		id: 'CHECKPOINT_TAB',
		panel: <CheckpointsGroup team={team} currentUser={currentUser} />,
		title: 'Member Checkpoints',
	},
	{
		id: 'OKR_TAB',
		panel: <OkrsGroup team={team} currentUser={currentUser} />,
		title: 'Member Okrs',
	},
]

export default function TeamPage({ team, currentUser }: Props): React$Node {
	const tabs = getTabs(team, currentUser)
	return (
		<FlexWrapper>
			<HighlightCard className={Classes.DARK}>
				<Link to={`/teams`}>
					<Icon icon="double-chevron-left" />
					{'	'}View All Teams
				</Link>
				<h1>{team.name} Team</h1>

				<div>
					{team.employees.map(employee => (
						<Employee key={employee.id} person={employee} />
					))}
				</div>
			</HighlightCard>
			<Card css="flex: 1; margin-left: 12px;">
				<FlexWrapper column>
					<TabDecorator>
						<Tabs tabs={tabs} />
					</TabDecorator>
				</FlexWrapper>
			</Card>
		</FlexWrapper>
	)
}

function Employee({ person }: { person: TeamEmployeeType }) {
	return (
		<div>
			<div css="padding: 4px; text-align: center;">
				<Link to={`/employees/${person.id}`}>{employeeName(person)}</Link>
			</div>
			<Divider />
		</div>
	)
}

function employeeName(employee: TeamEmployeeType, iAm: boolean = false) {
	return `${employee.firstName} ${employee.lastName}${iAm ? ' (me)' : ''}`
}

function CheckpointsGroup({ team, currentUser }: Props) {
	return (
		<ScrollContainer>
			{team.employees.map(employee => {
				const iAmMember = employee.id === currentUser.id
				return (
					<EmployeeContainer key={`checkpoint+owner+${employee.id}`}>
						<H2>
							<Link to={`/employees/${employee.id}`}>{employeeName(employee, iAmMember)}</Link>
						</H2>
						<div className="list">
							<CheckpointsWeek
								checkpoints={employee.checkpoints}
								allowCreateDelete={iAmMember}
								allowUpdate={iAmMember}
							/>
						</div>
					</EmployeeContainer>
				)
			})}
		</ScrollContainer>
	)
}

function OkrsGroup({ team, currentUser }: Props) {
	const quarter = getCurrentQuarter()
	return (
		<ScrollContainer>
			{team.employees.map(employee => {
				const iAmMember = employee.id === currentUser.id
				return (
					<EmployeeContainer key={`okrs+owner+${employee.id}`}>
						<H2>
							<Link to={`/employees/${employee.id}`}>{employeeName(employee, iAmMember)}</Link>
						</H2>
						<div className="list">
							<OkrsList
								queryOverrides={{ employeeId: employee.id, quarter }}
								condensed={true}
								okrs={employee.okrs}
								owner={getOkrOwnerFromFilters({}, currentUser)}
								quarter={quarter}
								allowOkrCreation={currentUser.id === employee.id}
								currentUser={currentUser}
							/>
						</div>
					</EmployeeContainer>
				)
			})}
		</ScrollContainer>
	)
}

function TeamOkrs({ team, currentUser }: Props) {
	const okrsOwner = getOkrOwnerFromFilters({ teamId: team.id }, currentUser)
	const quarter = getCurrentQuarter()
	return (
		<ScrollContainer>
			<H2>
				<Link to={`/okrs?teamId=${team.id}`}>Team Okrs</Link>
			</H2>
			<div className="list">
				<OkrsList
					queryOverrides={{ teamId: team.id, quarter }}
					okrs={team.okrs}
					quarter={quarter}
					owner={okrsOwner}
					allowOkrCreation={!!okrsOwner}
					currentUser={currentUser}
				/>
			</div>
		</ScrollContainer>
	)
}

const HighlightCard = styled(Card)`
	width: 20%;
	background: ${props => props.theme.primary}!important;
`

const TabDecorator = styled.div`
	flex: 1;
	min-height: 0;

	.${Classes.TABS} {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.${Classes.TAB_PANEL} {
		flex: 1;
		min-height: 0;

		overflow-y: auto;
	}
`

const EmployeeContainer = styled.div`
	.empty-message {
		text-align: left;
	}
`
const ScrollContainer = styled.div`
	overflow-y: auto;
	height: 100%;
	${({ theme }) => css`
		.list {
			margin-left: ${theme.spacing2x};
		}
		padding: ${theme.spacing2x};
		border-radius: ${theme.baseRadius};
		${EmployeeContainer} {
			& :not(:last-child) {
				margin-bottom: ${theme.spacing};
			}
		}
	`}
`
