import type { Option } from '../../../../../../utility/hooks'

import React from 'react'
import ReactSelect from 'react-select'

/**
 * EnumSelection - add component used to select multiple options from a set of options
 *
 *
 * @param  {Object} props - the react props
 * @param  {Array<string>} props.selectedOptions - the "value"s of the options which are selected
 * @param  {Options[]} props.options - the options available for the user to select
 * @param  {(value: string) => Option} props.valueMapper - a callback to convert a value to an option
 * @param  {(newSelection: string[]) => any} onChange - a callback for when a user changes the selection
 *
 * @returns React$Node
 */
export function EnumSelection({
	selectedOptions,
	valueMapper,
	options,
	onChange,
}: {
	selectedOptions: string[],
	options: Option[],
	valueMapper: (value: string) => Option,
	onChange: (newSelection: string[]) => any,
}): React$Node {
	return (
		<ReactSelect
			value={selectedOptions ? selectedOptions.map(valueMapper) : []}
			isMulti={true}
			options={options}
			onChange={selected => {
				onChange(selected.map(({ value }) => value))
			}}
			isLoading={!options}
			className="basic-multi-select"
			classNamePrefix="select"
			placeholder={'Select Options To Filter...'}
			getOptionLabel={({ label }) => label}
			styles={{ control: defaultStyles => ({ ...defaultStyles, 'min-width': '20vw' }) }}
		/>
	)
}
