import React from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '../../components'
import 'styled-components/macro'
import { useFetch } from '../../utility/hooks'
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo'
import type { EmployeeType } from '../../stores/types'
import { useCurrentUser } from '../../stores/auth'

export default function Employee(): React$Node {
	const { employeeId } = useParams()
	const currentUser = useCurrentUser()
	const { data, fetching, error } = useFetch(`employees/${employeeId ?? ''}`, {
		shouldFetch: !!employeeId,
	})

	if (fetching) {
		return <Loader />
	}
	if (error) {
		return <p>Error loading employee page</p>
	}
	const employee: ?EmployeeType = data?.employee

	if (!employee || !currentUser) {
		return <p>Error loading employee page</p>
	}
	return <EmployeeInfo employee={employee} currentUser={currentUser} />
}
