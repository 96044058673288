// @flow
import { useMutation, useQuery, type UseQueryOptions, type UseQueryResult } from 'react-query'
import NetworkCommunicator from '../services/NetworkCommunicator'
import type { SimpleOkr } from '../stores/types'
import type { FullOkr } from '../routes/Goals/Okrs/types'
import { AppToaster } from '../components'
import { useQueryClient } from 'react-query'

export const OKRS_QUERY_KEY = 'okrs'

export type QueryParams = {
	[string]: string | number | void,
}

/**
 * Fetches based on the given queryParams
 */
/**
 * Fetches Okrs based on the given `queryParams`
 * @param {QueryParams} queryParams An object representing query params to be added to the request url
 * @return {Promise<SimpleOkr[]>} A promise that resolves with the okrs from the server
 */
function fetchOkrs(queryParams: QueryParams): Promise<SimpleOkr[]> {
	const query = new URLSearchParams()
	Object.keys(queryParams).forEach(paramKey => {
		if (queryParams[paramKey]) {
			query.append(paramKey, String(queryParams[paramKey]))
		}
	})
	return NetworkCommunicator.GET(`okrs?${query.toString()}`).then(res => res.okrs)
}

/**
 * A hook used to fetch multiple OKRs.
 * @param {object} options
 * @param {QueryParams} options.queryParams An object representing query params to be added to the request url
 * @param {object} options.queryOptions Options that will be passed to `useQuery` from react-query
 * @return The returned object is the return value of useQuery, with the `data` key renamed to `okrs`
 */
export function useOkrs({
	queryParams,
	queryOptions,
}: {
	queryParams: QueryParams,
	queryOptions: UseQueryOptions<SimpleOkr[]>,
}): {
	okrs: ?Array<SimpleOkr>,
	...UseQueryResult<SimpleOkr[]>,
} {
	const query = useQuery<Array<SimpleOkr>>(
		[OKRS_QUERY_KEY, queryParams],
		() => fetchOkrs(queryParams),
		queryOptions
	)

	return { okrs: query.data, ...query }
}

export type CreateOkrPayloadType = {
	requestBody: {},
	temporaryOkr: SimpleOkr,
}

/**
 * Wrapper for creating a new okr.
 * Uses react-query mutation to add an okr optimistically to the query state.
 * If the update failed, a toast will appear, and the okr is removed.
 * @param {obj.QueryParams} queryParams parameters that are applied to the query data for the optimistic update.
 * If you are adding an okr to a list of other okrs, this should be an object of the parameters used to fetch that list of okrs.
 * (i.e. for creating an okr in a list of employee 1's okrs the queryParams should be {employeeId: 1}) If no query params are provided,
 * will refetch all okrs after update.
 * @param {obj.onSuccess} onSuccess an optional callback made when the create is successful.
 * @returns
 */
export function useCreateOkr({
	queryParams,
	onSuccess,
}: {
	queryParams?: ?QueryParams,
	onSuccess?: () => void,
}): { createOkr: (payload: CreateOkrPayloadType) => void } {
	const queryClient = useQueryClient()

	const queryKey = queryParams ? [OKRS_QUERY_KEY, queryParams] : OKRS_QUERY_KEY

	const mutation = useMutation(
		payload =>
			NetworkCommunicator.POST('okrs', {
				body: payload.requestBody,
			}),
		{
			onMutate: async payload => {
				await queryClient.cancelQueries(queryKey)
				const previousValue = queryClient.getQueryData(queryKey)
				queryClient.setQueryData(queryKey, state => {
					return (state || []).concat([payload.temporaryOkr])
				})
				return previousValue
			},
			onError: (_, __, previousValue) => {
				AppToaster.show({
					message: 'Failed creating OKR. Please refresh and try again',
					intent: 'danger',
				})
				return queryClient.setQueryData(queryKey, previousValue)
			},
			onSuccess: data => {
				if (data.okrId) {
					onSuccess && onSuccess()
				} else {
					throw new Error('Did not receive the expected okr id')
				}
				queryClient.invalidateQueries(queryKey)
			},
		}
	)

	return { createOkr: mutation.mutate }
}

/**
 * Fetches a single full OKR.
 * @param {string} okrId The id of the OKR to fetch
 * @return {Promise<FullOkr>} A promise that resolves with the full okr
 */
function fetchOkr(okrId: string): Promise<FullOkr> {
	return NetworkCommunicator.GET(`okrs/${okrId}`).then(res => res.okr)
}

/**
 * A hook used to fetch a single OKR.
 * @param {string} okrId The id of the OKR to fetch
 * @param {object} queryOptions Options that will be passed to `useQuery` from react-query
 * @return The returned object is the return value of useQuery, with the `data` key renamed to `okr`
 */
export function useOkr(
	okrId: ?string,
	queryOptions: UseQueryOptions<FullOkr>
): { okr: ?FullOkr, ...UseQueryResult<FullOkr> } {
	const query: UseQueryResult<FullOkr> = useQuery(
		[OKRS_QUERY_KEY, okrId],
		(): ?Promise<FullOkr> => {
			if (!okrId) {
				return null
			}
			return fetchOkr(okrId)
		},
		queryOptions
	)

	return { okr: query.data, ...query }
}
