// @flow
import React, { useEffect, useMemo, useState } from 'react'
import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { Criteria, UpdateCriteria } from '../../localTypes'
import type { FieldType, Range } from '../../clientTypes'

import { assign, get, getProxyData, minimizeDelete } from '../../../../../../utility/dotProxy'
import { HTMLSelect, Button, Intent } from '@blueprintjs/core'
import styled from 'styled-components'
import { FIELD_TYPES } from '../../clientTypes'

const OPERATIONS_TO_OPTIONS = {
	gt: {
		value: 'gt',
		label: 'greater than',
	},
	gte: {
		value: 'gte',
		label: 'greater than or equal to',
	},
	eq: {
		value: 'eq',
		label: 'equal to',
	},
	lte: {
		value: 'lte',
		label: 'less than or equal to',
	},
	lt: {
		value: 'lt',
		label: 'less than',
	},
}

const LESSER_OPERATIONS = [OPERATIONS_TO_OPTIONS.lt, OPERATIONS_TO_OPTIONS.lte]
const GREATER_OPERATIONS = [OPERATIONS_TO_OPTIONS.gt, OPERATIONS_TO_OPTIONS.gte]
const ALL_OPERATIONS = [...GREATER_OPERATIONS, OPERATIONS_TO_OPTIONS.eq, ...LESSER_OPERATIONS]

const DATE_OPERATIONS_TO_OPTIONS = {
	gt: {
		value: 'gt',
		label: 'after',
	},
	lt: {
		value: 'lt',
		label: 'before',
	},
}
const DATE_LESSER_OPERATIONS = [DATE_OPERATIONS_TO_OPTIONS.lt]
const DATE_GREATER_OPERATIONS = [DATE_OPERATIONS_TO_OPTIONS.gt]
const DATE_ALL_OPERATIONS = [...DATE_LESSER_OPERATIONS, ...DATE_GREATER_OPERATIONS]

/**
 * RangeEntry - a component used to render a form for a range criteria
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data for ths range
 * @param  {FormRenderer} props.formRenderer - a component used to render the data associated with the range
 * @param  {fieldType} props.fieldType - the tye of the form
 *
 * @returns React$Node
 */
export function RangeEntry<T>({
	updateCriteria,
	currentCriteria,
	currentPath,
	fieldType,
	formRenderer: FormRenderer,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?Range<?T>>,
	fieldType: FieldType,
	formRenderer: (props: {
		updateCriteria: UpdateCriteria,
		currentCriteria: Criteria,
		currentPath: ProxyData<?T>,
	}) => React$Node,
}): React$Node {
	const { lesserOperations, greaterOperations, allOperations } = useMemo(() => {
		if (fieldType.type === FIELD_TYPES.DATE) {
			return {
				lesserOperations: DATE_LESSER_OPERATIONS,
				greaterOperations: DATE_GREATER_OPERATIONS,
				allOperations: DATE_ALL_OPERATIONS,
			}
		}
		return {
			lesserOperations: LESSER_OPERATIONS,
			greaterOperations: GREATER_OPERATIONS,
			allOperations: ALL_OPERATIONS,
		}
	}, [fieldType])
	const { lesserOperationKeys, greaterOperationKeys } = useMemo(
		() => ({
			lesserOperationKeys: new Set(lesserOperations.map(({ value }) => value)),
			greaterOperationKeys: new Set(greaterOperations.map(({ value }) => value)),
		}),
		[lesserOperations, greaterOperations]
	)

	const [currentOperations, setCurrentOperations] = useState<Array<string>>(() => {
		const currentValue = get(currentPath, currentCriteria)
		if (!currentValue) {
			return [greaterOperations[0].value]
		}
		return Object.keys(currentValue)
	})
	const [addingSubCriteria, setAddingSubCriteria] = useState(!currentOperations.length)

	const [entries, addableOperations] = useMemo(() => {
		if (currentOperations.length === 0) {
			return [[], ALL_OPERATIONS]
		}
		let entries = []
		if (currentOperations.length === 1) {
			entries.push({
				operation: currentOperations[0],
				allowableOperations: allOperations,
				deleteable: false,
			})
			return [
				entries,
				currentOperations[0] === 'eq'
					? []
					: greaterOperationKeys.has(currentOperations[0])
					? lesserOperations
					: greaterOperations,
			]
		}
		let hadLowerBound = false
		let hadUpperBound = false

		const indicesToRemove = []
		currentOperations.forEach((operation, index) => {
			if (greaterOperationKeys.has(operation)) {
				if (hadLowerBound) {
					indicesToRemove.push(index)
					return
				}
				hadLowerBound = true
				entries.push({ operation, allowableOperations: greaterOperations, deleteable: true })
				return
			}
			if (lesserOperationKeys.has(operation)) {
				if (hadUpperBound) {
					indicesToRemove.push(index)
					return
				}
				hadUpperBound = true
				entries.push({ operation, allowableOperations: lesserOperations, deleteable: true })
				return
			}
			indicesToRemove.push(index)
		})

		// clean up bad entries (this should never happen)
		if (indicesToRemove.length) {
			const newOperations = [...currentOperations]
			for (let i = indicesToRemove.length; i >= 0; i--) {
				updateCriteria(oldCriteria =>
					minimizeDelete(
						// $FlowIgnore[prop-missing] this is fixing the types if they get out of sync
						getProxyData(criteria => get(currentPath, criteria)?.[newOperations[i]]),
						oldCriteria
					)
				)
				newOperations.splice(i, 1)
			}
			setCurrentOperations(newOperations)
		}

		return [entries, []]
	}, [
		currentOperations,
		updateCriteria,
		currentPath,
		lesserOperations,
		greaterOperations,
		lesserOperationKeys,
		greaterOperationKeys,
		allOperations,
	])

	const entryCount = entries.length
	useEffect(() => {
		if (!entryCount) {
			setAddingSubCriteria(true)
		}
	}, [entryCount])

	/**
	 * updateOperationInCriteria - update the range operations in the criteria
	 *
	 * @param  {?ProxyData<any>} path - the proxyData to the operation (NOTE: this may be null if an operation is not selected)
	 * @param  {?string} newOperation - the operation to replace the old operation (Null indicates that the operation should be deleted)
	 * @param  {number} index - the location in currentOperations
	 */
	function updateOperationInCriteria(path: ?ProxyData<any>, newOperation: ?string, index: number) {
		if (!newOperation) {
			const newOperations = [...currentOperations]
			newOperations.splice(index, 1)
			setCurrentOperations(newOperations)
			if (path) {
				updateCriteria(oldCriteria => minimizeDelete(path, oldCriteria))
			}
			return
		}

		const newOperations = [...currentOperations]
		newOperations[index] = newOperation
		setCurrentOperations(newOperations)

		if (!path) {
			return
		}
		// move over criteria from the old operation to the new operation
		updateCriteria((oldCriteria: Criteria) => {
			let valueAtOldLocation = get(path, oldCriteria)
			let updatedCriteria = minimizeDelete(path, oldCriteria)
			if (valueAtOldLocation != null) {
				updatedCriteria = assign(
					getProxyData((criteria: Criteria) => get(currentPath, criteria)?.[newOperation]),
					updatedCriteria,
					valueAtOldLocation
				)
			}
			return updatedCriteria
		})
	}

	return (
		<div>
			<Grid>
				{entries.map(({ operation, allowableOperations, deleteable }, index) => (
					<RenderSubOperationForm
						key={operation}
						operation={operation}
						allowableOperations={allowableOperations}
						currentCriteria={currentCriteria}
						currentPath={currentPath}
						formRenderer={FormRenderer}
						updateCriteria={updateCriteria}
						deleteable={deleteable}
						updateOperation={(path: ?ProxyData<any>, newOperation: ?string) =>
							updateOperationInCriteria(path, newOperation, index)
						}
					/>
				))}
				{addableOperations.length && addingSubCriteria ? (
					<RenderSubOperationForm
						operation={null}
						allowableOperations={addableOperations}
						currentCriteria={currentCriteria}
						currentPath={currentPath}
						formRenderer={FormRenderer}
						updateCriteria={updateCriteria}
						deleteable={Boolean(entries.length)}
						updateOperation={(path: ?ProxyData<any>, newOperation: ?string) => {
							setAddingSubCriteria(false)
							updateOperationInCriteria(path, newOperation, currentOperations.length)
						}}
					/>
				) : null}
				{addableOperations.length && !addingSubCriteria ? (
					<div>
						<Button
							onClick={() => setAddingSubCriteria(true)}
							small={true}
							text="Add Operation"
							icon="small-plus"
							intent={Intent.SUCCESS}
						/>
					</div>
				) : null}
			</Grid>
		</div>
	)
}
/**
 * RenderSubOperationForm - render a form with an operation and its corresponding criteria form
 *
 * @param  {Object} props - the react props
 * @param  {?string} props.operation - the current operation (null/undefined) if no operation is selected for the form
 * @param  {string[]} props.allowableOperations - the operations which are allowed for this form
 * @param  {(path: ?ProxyData<any>, newOperation: ?string) => void} props.updateOperation - a callback to update the operation
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - the current criteria
 * @param  {ProxyData<?Range<?T>>>} props.currentPath - the path to the range, NOT THE OPERATION IN THE RANGE
 * @param  {FormRenderer} props.formRenderer - a component to render the form which represents the data for the range operation
 * @param  {boolean} props.deleteable - true if this operation can be deleted, false otherwise
 *
 * @returns React$Node
 */
function RenderSubOperationForm<T>({
	operation,
	allowableOperations,
	updateOperation,
	updateCriteria,
	currentCriteria,
	currentPath,
	formRenderer: FormRenderer,
	deleteable,
}: {
	operation: ?string,
	allowableOperations: { label: string, value: string }[],
	updateOperation: (path: ?ProxyData<any>, newOperation: ?string) => void,
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?Range<?T>>,
	formRenderer: (props: {
		updateCriteria: UpdateCriteria,
		currentCriteria: Criteria,
		currentPath: ProxyData<?T>,
	}) => React$Node,
	deleteable: boolean,
}): React$Node {
	const path: ?ProxyData<?T> =
		operation == null
			? null
			: getProxyData((criteria: Criteria) => (get(currentPath, criteria): ?Range<?T>)?.[operation])
	let usableOperations = [...allowableOperations]
	if (!operation) {
		usableOperations = [{ value: '', label: 'Select Operation' }, ...usableOperations]
	}
	return (
		<>
			<HTMLSelect
				value={operation}
				options={usableOperations}
				onChange={e => {
					const selectedValue = e.target.value
					if (!selectedValue) {
						return
					}
					updateOperation(path, selectedValue)
				}}
			/>
			{path ? (
				<FormRenderer
					updateCriteria={updateCriteria}
					currentCriteria={currentCriteria}
					currentPath={path}
				/>
			) : (
				<Button disabled={true}>Select Operation First</Button>
			)}
			{deleteable ? (
				<Button
					icon="small-minus"
					small={true}
					intent={Intent.DANGER}
					onClick={() => updateOperation(null, null)}
				/>
			) : (
				<div />
			)}
		</>
	)
}

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 2em;
	grid-template-row: 1fr;
	grid-row-gap: 4px;
	grid-column-gap: 4px;
`
