import type { ProxyData } from '../../../../../utility/dotProxy'
import type { SingleSelectionFieldType, ValueSelect } from '../clientTypes'
import type { Criteria, UpdateCriteria } from '../localTypes'

import { assign, get } from '../../../../../utility/dotProxy'

import React from 'react'
import ReactSelect from 'react-select'

/**
 * NumberRangeEntry - adds a range entry for entering numbers
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the numbers for ths range
 * @param  {SingleSelectionFieldType} props.fieldType - the field type being rendered
 *
 * @returns React$Node
 */
export function SingleSelect({
	updateCriteria,
	currentCriteria,
	currentPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?ValueSelect>,
	fieldType: SingleSelectionFieldType,
}): React$Node {
	const selectedValue = get(currentPath, currentCriteria)?.value

	const options = fieldType.options.map(({ displayName, value }) => ({
		label: displayName,
		value,
	}))
	const valueToOption = {}
	options.forEach(option => (valueToOption[option.value] = option))

	return (
		<ReactSelect
			value={selectedValue ? valueToOption[selectedValue] : null}
			options={options}
			onChange={({ value }) => {
				updateCriteria(oldCriteria => assign(currentPath, oldCriteria, { value }))
			}}
			className="basic-multi-select"
			classNamePrefix="select"
			placeholder={'---- Select An Option ----'}
			getOptionLabel={({ label }) => label}
			styles={{ control: defaultStyles => ({ ...defaultStyles, 'min-width': '20vw' }) }}
		/>
	)
}
