import type { StyledComponent } from 'styled-components'
import styled from 'styled-components'

type Props = {
	column?: boolean,
	alignCenter?: boolean,
	spaceBetween?: boolean,
}

const FlexWrapper: StyledComponent<Props, Theme, HTMLDivElement> = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => (props.column ? 'column' : 'row')};
    align-items: ${props => (props.alignCenter ? 'center' : 'stretch')}
    justify-content: ${props =>
			props.spaceBetween ? 'space-between' : props.alignCenter ? 'center' : 'flex-start'};
	`
export default FlexWrapper
