// CONSTANTS SHARED WITH THE SERVER

export const DISCRIMINATION_TYPES = { STUDENT: 'STUDENT', MISSION: 'MISSION' }

export const BIN_TYPES = {
	MISSION_NUMBER: 'MISSION_NUMBER',
	TIME: 'TIME',
	NONE: 'NONE',
}

export const CONTROL_SETS = {
	'4-12': '4-12',
	'K-3': 'K-3',
}

export const INCLUDE_EXCLUDE_OPERATIONS = {
	INCLUDE: 'INCLUDE',
	EXCLUDE: 'EXCLUDE',
}

// Client Constants
export const DISCRIMINATION_TYPES_INFORMATION: {
	[discriminationType: $Keys<typeof DISCRIMINATION_TYPES>]: string,
} = {
	[DISCRIMINATION_TYPES.STUDENT]:
		'Considers each student a datapoint. This does remove some general mission data from the stats.',
	[DISCRIMINATION_TYPES.MISSION]:
		'Considers each mission a datapoint.  Student specific analytics become averages.',
}

export const BIN_TYPES_INFORMATION: { [binType: $Keys<typeof BIN_TYPES>]: string } = {
	[BIN_TYPES.MISSION_NUMBER]:
		'Consider each consecutive mission as a new bin (within the filter). When discriminating by student, this will result in seeing how a student did over time. When discriminating by mission, each mission is a new bin.',
	[BIN_TYPES.TIME]: 'Bin by the time the analytics was created (mission ended).',
	[BIN_TYPES.NONE]: 'Put all analytics into a single bin.',
}

export const TIME_BINNING_OPTIONS = ['year', 'month', 'day']

export const ANALYTIC_VERSIONS = [1, 2, 3, 4]

export const DEFAULT_KEY = ['Performance', 'Score']

export const OVERRIDABLE_WEIGHTS = {
	knowledge: { application: 1, question: 1 },
	collaboration: {
		endOfMissionSurvey: 1,
		socialHealthScore: 1,
		engineerPanelScore: 1,
	},
	criticalThinking: {
		endOfMissionSurvey: 1,
		scannedData: 1,
	},
	initiative: {
		endOfMissionSurvey: 1,
		alertResponse: 1,
		scannedDataResponse: 1,
		stationResponse: 1,
		mapParticipation: 1,
		boostParticipation: 1,
	},
	grit: {
		endOfMissionSurvey: 1,
		gritAfterNegativeEvents: 1,
	},
}

export const ANALYTICS_MODELS = {
	PRODUCTION: 'PRODUCTION',
	TEST_MIGRATION: 'TEST_MIGRATION',
}

export const MISSION_SCHOOL_ID = '5c7993492f28761603a7ca6d'

export const PLOT_TYPES = {
	BOX_PLOT: 'BOX_PLOT',
	LINE: 'LINE',
}

export const DECIMAL_PLACES = 4

export const SUBROUTES = {
	STATS: 'STATS',
	COMPARERS: 'COMPARERS',
}

export const NUMBER_TYPES = {
	TIME: 'TIME',
}

export const ALLOW_QUERY_TIME_PASS_PHRASE = 'I know what I am doing'
