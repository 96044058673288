import React from 'react'
import type { MissionType } from '../../../stores/types'
import { MissionListItem } from './shared'
import { Loader, FlexWrapper, BooleanDisplay } from '../../../components'
import { Icon } from '@blueprintjs/core'
import styled from 'styled-components'
import 'styled-components/macro'

import { rgba } from 'polished'
import { useMissionStore, selectors } from '../../../stores'

const Refresh = styled(Icon)`
	color: ${({ theme }) => theme.brandWhite};
	cursor: pointer;
	border-radius: 50%;
	height: fit-content;
	padding: 12px;
	background: ${({ theme }) => rgba(theme.brandWhite, 0.2)};
	:hover {
		color: ${({ theme }) => rgba(theme.brandWhite, 0.6)};
		transform: scale(1.2);
	}
	transition: all 0.3s ease;
`

export default function LiveMissions({
	selectedMissionId,
	onSelectMission,
}: {
	selectedMissionId: string,
	onSelectMission: string => mixed,
}): React$Node {
	const fetchLiveMissions = useMissionStore(selectors.getLiveMissionsFetch)
	const liveMissions = useMissionStore(selectors.getLiveMissions)
	return (
		<FlexWrapper column>
			<FlexWrapper spaceBetween style={{ height: 'fit-content', marginBottom: '12px' }}>
				<h1 style={{ margin: 'auto 0' }} align="center">
					Current Missions
				</h1>
				<Refresh icon="refresh" iconSize={30} onClick={() => fetchLiveMissions()} />
			</FlexWrapper>
			<div css="flex: 1; overflow-y: auto">
				{liveMissions ? (
					liveMissions.length > 0 ? (
						liveMissions.map(mission => (
							<MissionThumbnail
								key={mission._id}
								mission={mission}
								selected={selectedMissionId === mission._id}
								onSelect={() => onSelectMission(mission._id)}
							/>
						))
					) : (
						<FlexWrapper alignCenter>
							<h3>There are no missions running</h3>
						</FlexWrapper>
					)
				) : (
					<Loader color="white" />
				)}
			</div>
		</FlexWrapper>
	)
}

const ExtraContent = styled.div`
	padding: 4px;
	> div {
		margin-bottom: 4px;
		label {
			font-weight: ${({ theme }) => theme.fontBold};
		}
	}
`

const MissionThumbnail = ({
	mission,
	selected,
	onSelect,
}: {
	mission: MissionType,
	selected: boolean,
	onSelect: () => mixed,
}) => {
	const data = [
		{ label: 'Code', value: <span style={{ textTransform: 'uppercase' }}>{mission.code}</span> },
		{
			label: 'SimulationID',
			value: <a href={'http://infinid.us/' + mission.simulationId}>{mission.simulationId}</a>, // TODO: When we switch controls over to mission.io: this should change (i.e. http://play.mission.io/ ....)
		},
		{
			label: 'Flight Director Connected',
			value: <BooleanDisplay success={!!mission.flightDirector} />,
		},
	]

	const expandedContent = (
		<>
			<hr />
			<ExtraContent>
				{data.map(node => (
					<div key={node.label}>
						<label>{node.label}</label>: {node.value}
					</div>
				))}
			</ExtraContent>
		</>
	)
	return (
		<MissionListItem
			mission={mission}
			selected={selected}
			onSelect={onSelect}
			expandedContent={expandedContent}
		/>
	)
}
