import type { TableQueryProps } from '../../../components/basic/AdvancedTable'

import type { AlertReportType } from './types'

import AdvancedTable from '../../../components/basic/AdvancedTable'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { getColumnsFromServerFields } from '../Generator/resources'
import { useAlert } from './hooks'

import React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

/**
 * AlertReportTable - show a report table for the given alert
 *
 * @param  {{alertId:string}} {alertId} - the alert to show the table for
 *
 * @returns React$Node
 */
export function AlertReportTable({ alertId }: { alertId: string }): React$Node {
	const { isLoading, error, data: alert } = useAlert(alertId)
	const [params, setParams] = useState(
		(null: ?{|
			sort?: string,
			batchSize?: number,
			pageIndex?: number,
		|})
	)
	const { isLoading: isLoadingTableData, error: errorLoadingTable, data } = useQuery(
		['alert', alertId, 'report', params],
		async (): Promise<AlertReportType> => {
			const query = new URLSearchParams()
			query.append('batchSize', `${params?.batchSize ?? 10}`)
			query.append('index', `${params?.pageIndex ?? 0}`)
			if (params?.sort != null) {
				query.append('sort', params.sort)
			}

			const result = await NetworkCommunicator.GET(
				`alerts/${alertId}/report?${query.toString()}`
			).then(res => ({
				...res,
			}))
			return result
		},
		{
			enabled: Boolean(params),
			staleTime: Infinity,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		}
	)

	const fields = data?.fields
	// Get columns for given resource
	const columns = useMemo(() => (fields ? getColumnsFromServerFields(fields) : []), [fields])

	const fetchData = useCallback((data: TableQueryProps) => {
		let sort = null
		if (data?.sortBy?.length) {
			const sorter = data.sortBy[0]
			sort = sorter.id
			if (sorter.desc) {
				sort = `-${sort}`
			}
		}
		setParams({ batchSize: data.pageSize, pageIndex: data.pageIndex, sort })
	}, [])

	if (error) {
		return <TableWrapper>An error occurred while fetching the alert data</TableWrapper>
	}

	if (errorLoadingTable) {
		return <TableWrapper>An error occurred while fetching the table data</TableWrapper>
	}

	return (
		<TableWrapper>
			<AdvancedTable
				disableMultiSort
				data={data?.report?.list ?? []}
				columns={columns}
				fetchData={fetchData}
				loading={isLoading || isLoadingTableData}
				manualCount={alert?.entryCount ?? 0}
			/>
		</TableWrapper>
	)
}

const TableWrapper = styled.div`
	max-width: 100%;
	overflow-x: auto;
`
