export const DATE_TYPES = {
	ABSOLUTE_DATE: 'ABSOLUTE_DATE',
	RELATIVE_DATE: 'RELATIVE_DATE',
}

export type RelativeDateOffset = { set?: number, add?: number }
export type RelativeDateOffsetData = {|
	day?: RelativeDateOffset,
	month?: RelativeDateOffset,
	year?: RelativeDateOffset,
|}
export type RelativeDateData = {
	from?: string,
	offsets?: RelativeDateOffsetData,
}

export type AbsoluteDate = {| type: 'ABSOLUTE_DATE', date: number |}
export type RelativeDate = {| type: 'RELATIVE_DATE', data: RelativeDateData |}
export type EitherDateType = AbsoluteDate | RelativeDate

export type Range<T> =
	| {| gte: T |}
	| {| gt: T |}
	| {| eq: T |}
	| {| lt: T |}
	| {| lte: T |}
	| {| gte: T, lt: T |}
	| {| gte: T, lte: T |}
	| {| gt: T, lt: T |}
	| {| gt: T, lte: T |}

export type ClientDateRange = Range<EitherDateType>

export type CompositionCriteria = {
	count?: Range<number>,
	criteria?: any,
}

export type Enumeration<T> = {| include: Array<T> |} | {| exclude: Array<T> |}

export type ValueSelect = { value: string }

type BaseFieldValues = {|
	displayName: string,
|}

// client field types
export const FIELD_TYPES = {
	DATE: 'DATE',
	NUMBER: 'NUMBER',
	DURATION: 'DURATION',
	COMPOSABLE: 'COMPOSABLE',
	DOCUMENT_SELECTOR: 'DOCUMENT_SELECTOR',
	ENUM_SELECTOR: 'ENUM_SELECTOR',
	SINGLE_SELECTOR: 'SINGLE_SELECTOR',
}

export type ComposableFieldType = {
	type: 'COMPOSABLE',
	resource: string,
	isExpectedOneToMany: boolean,
	...BaseFieldValues,
}
export type EnumFieldType = {
	type: 'ENUM_SELECTOR',
	options: { displayName: string, value: string }[],
	...BaseFieldValues,
}
export type SingleSelectionFieldType = {
	type: 'SINGLE_SELECTOR',
	options: { displayName: string, value: string }[],
	...BaseFieldValues,
}
export type DocumentSelectionFieldType = {
	type: 'DOCUMENT_SELECTOR',
	resource: string,
	...BaseFieldValues,
}

export type FieldType =
	| {
			type: 'DATE' | 'NUMBER' | 'DURATION',
			...BaseFieldValues,
	  }
	| DocumentSelectionFieldType
	| EnumFieldType
	| ComposableFieldType
	| SingleSelectionFieldType

export type ResourceCriteria = { [criteriaFieldName: string]: FieldType }

export type ClientCriteriaForm = {
	[resourceType: string]: ResourceCriteria,
}
