// @flow
import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { PLOT_TYPES } from './constants'
import { LINE_SELECTOR, BOX_PLOT_SELECTOR } from './selectors'
import { Loading, ErrorComponent, LocationBreadCrumbs } from './sharedComponents'
import { produce } from 'immer'
import { getLinePlot, getBoxPlot, getSortedBinsIds } from './chartHelpers'
import { getSubPathsAtLocation, getLinksAtLocation } from './statsHelpers'
import ChartOptions from './customInputs/ChartOptions'
import { getProxyData } from '../../../utility/dotProxy'
import { generateConversionMap } from './converters'

import type { StatsQueryResponse, QueryInfo, Key, UpdateQueryInfoCallback } from './types'

/**
 * StatsViewer - a react component used to visualize the statistics representing the analytics
 *
 * @param {Object} props - the react props
 * @param {StatsQueryResponse} props.query - the react query for getting the analytics of analytics data associated with the `info`
 * @param {QueryInfo} props.info - the query information that this stats viewer is lined to and acted as the configuration for `query`
 * @param {UpdateQueryInfoCallback} props.updateInfo - a callback used to update values in the `info` object
 *
 * @return {React$Node}
 */
export default function StatsViewer({
	query: { data, isLoading, error },
	info,
	updateInfo,
}: {
	query: StatsQueryResponse,
	info: QueryInfo,
	updateInfo: UpdateQueryInfoCallback,
}): React$Node {
	const analyticsOfAnalytics = data?.analyticsOfAnalytics?.bins
	const { location, selector, bin, plotType } = info.viewer

	// navigate to an existent bin after analytics loads
	useEffect(() => {
		updateInfo((oldData: QueryInfo) => {
			if (analyticsOfAnalytics && !analyticsOfAnalytics[oldData.viewer.bin]) {
				return produce(oldData, (draft: QueryInfo) => {
					draft.viewer.bin = Object.keys(analyticsOfAnalytics)[0]
				})
			}
			return oldData
		})
		// updateInfo is different on each render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [analyticsOfAnalytics])

	if (error) {
		return <ErrorComponent message={error.message} />
	}

	if (isLoading || !analyticsOfAnalytics) {
		return <Loading />
	}

	if (!Object.keys(analyticsOfAnalytics).length) {
		return <div>The configuration did not result in any data.</div>
	}

	if (!analyticsOfAnalytics[bin]) {
		return <div>Redirecting to new bin.</div>
	}

	let apexChartData = {}

	const setLocation = (location: Key) =>
		updateInfo(oldData =>
			produce(info, (newInfo: QueryInfo) => {
				newInfo.viewer.location = location
			})
		)

	const conversionMap = generateConversionMap({ convertTimeTo: info.viewer.timeConverter })
	if (plotType === PLOT_TYPES.BOX_PLOT) {
		apexChartData = getBoxPlot(
			analyticsOfAnalytics,
			bin,
			location,
			BOX_PLOT_SELECTOR[selector] ?? BOX_PLOT_SELECTOR.quartiles,
			setLocation,
			info.viewer.includeOutliers,
			conversionMap
		)
	} else {
		apexChartData = getLinePlot(
			analyticsOfAnalytics,
			location,
			LINE_SELECTOR[selector] ?? LINE_SELECTOR.average,
			info.viewer.includeOutliers,
			conversionMap
		)
	}

	return (
		<div>
			{data?.analyticsOfAnalytics?.onlyPartiallyCompleted ? (
				<ErrorComponent message="Calculation took too long, showing partial results." />
			) : null}
			<LocationBreadCrumbs
				location={location}
				setCurrentLocation={setLocation}
				childrenOptions={getSubPathsAtLocation(location, analyticsOfAnalytics[bin]?.stats) || []}
			/>
			<ReactApexChart
				key={JSON.stringify(info.viewer)}
				options={apexChartData.options}
				series={apexChartData.series}
				type={plotType === PLOT_TYPES.BOX_PLOT ? 'boxPlot' : 'line'}
				height={350}
			/>
			<ChartOptions
				includeOutliersProxy={getProxyData((trace: QueryInfo) => trace.viewer.includeOutliers)}
				plotTypeProxy={getProxyData((trace: QueryInfo) => trace.viewer.plotType)}
				locationProxy={getProxyData((trace: QueryInfo) => trace.viewer.location)}
				selectorProxy={getProxyData((trace: QueryInfo) => trace.viewer.selector)}
				binProxy={getProxyData((trace: QueryInfo) => trace.viewer.bin)}
				timeConverterProxy={getProxyData((trace: QueryInfo) => trace.viewer.timeConverter)}
				values={info}
				updateValues={updateInfo}
				binIds={getSortedBinsIds(analyticsOfAnalytics || {})}
				links={getLinksAtLocation(location, analyticsOfAnalytics[bin]?.stats) || []}
			/>
		</div>
	)
}
