import React, { useReducer, useState } from 'react'
import type { OkrOwner } from '../../../stores/types'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import 'styled-components/macro'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { AppToaster, ApprovalPopup, Select, FlexWrapper } from '../../../components'
import type { User } from '../../../stores/auth'
import produce, { applyPatches, produceWithPatches, type Patch } from 'immer'
import {
	Tag,
	Button,
	PopoverInteractionKind,
	Classes,
	ControlGroup,
	Position,
	Popover,
} from '@blueprintjs/core'
import { useCurrentUser } from '../../../stores/auth'
import { userIsOwner, isUserFounder } from './helpers'

type SharedLoad = { changeId: string }
type DeleteAction = { type: 'DELETE', payload: { index: number } & SharedLoad }
type CreateAction = { type: 'CREATE', payload: { values: OkrOwner } & SharedLoad }
type RevertAction = { type: 'REVERT', payload: SharedLoad }
type ApplyAction = { type: 'APPLY', payload: SharedLoad }
type Action = DeleteAction | CreateAction | RevertAction | ApplyAction

const localOwnersManager = (
	state: {
		owners: OkrOwner[],
		pendingChanges: { [id: string]: Patch[] },
	},
	action: Action
) => {
	const { changeId } = action.payload
	if (action.type === 'REVERT') {
		return produce(state, draft => {
			draft.checkpoints = applyPatches(draft.owners, draft.pendingChanges[changeId])
			delete draft.pendingChanges[changeId]
		})
	} else if (action.type === 'APPLY') {
		return produce(state, draft => {
			delete draft.pendingChanges[changeId]
		})
	}
	const [owners, patches, inversePatches] = produceWithPatches(state.owners, draft => {
		switch (action.type) {
			case 'DELETE':
				if (!draft[action.payload.index]) return
				draft.splice(action.payload.index, 1)
				break
			case 'CREATE':
				draft.push(action.payload.values)
				break
			default:
				return
		}
	})
	if (patches.length > 0) {
		return produce(state, draft => {
			draft.owners = owners // optimistically apply patches
			draft.pendingChanges[changeId] = inversePatches // save the inverse just in case
		})
	}
	return state
}

const OwnerTag = styled(Tag)`
	margin: 2px 4px 2px 0px;
`

export default function Owners({
	owners,
	okrId,
	canUpdate,
}: {
	owners: OkrOwner[],
	okrId: number,
	canUpdate?: boolean,
}): React$Node {
	const [localState, dispatch] = useReducer(localOwnersManager, {
		owners: owners,
		pendingChanges: {},
	})

	const currentUser: ?User = useCurrentUser()
	const [indexOfOpenPopover, setIndexOfOpenPopover] = useState(false)

	const options: OkrOwner[] = getOwnerOptions({
		user: currentUser,
		includeCompany: currentUser ? isUserFounder(currentUser) : false,
	}).filter(owner => !localState.owners?.some(o => o.id === owner.id && o.type === owner.type))

	const addOwner = (owner: OkrOwner, onSuccess: () => void) => {
		if (!owner.type || !owner.id) {
			AppToaster.danger({
				message: `Cannot add empty owner!`,
			})
			return
		}
		const changeId = uuid()
		dispatch({ type: 'CREATE', payload: { changeId, values: owner } })
		NetworkCommunicator.POST(`okrs/${okrId}/owners`, {
			body: {
				owner,
			},
		})
			.then(() => {
				onSuccess()
				dispatch({ type: 'APPLY', payload: { changeId } })
			})
			.catch(() => {
				dispatch({ type: 'REVERT', payload: { changeId } })
				AppToaster.danger({
					message: `Unable to add OKR owner`,
					action: {
						text: 'Try Again',
						onClick: () => addOwner(owner, onSuccess),
					},
				})
			})
	}

	const removeOwner = ({
		ownerType,
		id,
		index,
	}: {
		ownerType: string,
		id: number | string,
		index: number,
	}) => {
		const changeId = uuid()

		dispatch({ type: 'DELETE', payload: { changeId, index } })
		NetworkCommunicator.DELETE(`okrs/${okrId}/owners/${id}?ownerType=${ownerType}`)
			.then(() => {
				dispatch({ type: 'APPLY', payload: { changeId } })
			})
			.catch(() => {
				dispatch({ type: 'REVERT', payload: { changeId } })
				AppToaster.danger({
					message: `Unable to remove OKR owner`,
					action: {
						text: 'Try Again',
						onClick: () => removeOwner({ ownerType, id, index }),
					},
				})
			})
	}

	return (
		<span>
			{localState.owners.map((owner, index) => {
				const canRemove =
					currentUser && userIsOwner(currentUser, owner) && localState.owners.length > 1
				return canRemove && canUpdate ? (
					<Popover
						key={owner.id}
						popoverClassName={Classes.POPOVER_CONTENT_SIZING}
						enforceFocus={false}
						position={Position.BOTTOM_RIGHT}
						isOpen={indexOfOpenPopover === index}
						onInteraction={newOpenState => newOpenState === false && setIndexOfOpenPopover(null)}>
						<OwnerTag
							onRemove={() => {
								setIndexOfOpenPopover(index)
							}}>
							{owner.name}
						</OwnerTag>
						<ApprovalPopup
							message={`Would you like to remove ${owner.name} from this okr?`}
							onApprove={() => {
								removeOwner({ ownerType: owner.type, id: owner.id, index })
							}}
						/>
					</Popover>
				) : (
					<OwnerTag key={owner.id}>{owner.name}</OwnerTag>
				)
			})}
			{canUpdate && <AddOwnerBtn options={options} addOwner={addOwner} icon="plus" minimal />}
		</span>
	)
}

/**
 * Builds a list of possible OKR owners for a given user. The list will include the user and all
 * teams that the user belongs to. If `includeCompany` is true, company will be included in the
 * options as well.
 * @param {?User} user The user to build an owner options list for
 * @param {?boolean} includeCompany Whether to include the company as one of the owners
 * @return {OkrOwner[]} The list of OKR owners
 */
export function getOwnerOptions({
	user,
	includeCompany,
}: {
	user: ?User,
	includeCompany?: boolean,
}): OkrOwner[] {
	const options = []

	if (user) {
		options.push(
			{ type: 'EMPLOYEE', id: user.id, name: user.displayName },
			...user.teams.map(team => ({ type: 'TEAM', id: team.id, name: team.name }))
		)
	}

	if (includeCompany) {
		options.push({ type: 'COMPANY', id: 'COMPANY', name: 'Company' })
	}

	return options
}

function AddOwnerBtn({
	addOwner,
	children,
	options,
	...buttonProps
}: {
	addOwner: (OkrOwner, () => void) => void,
	options: OkrOwner[],
	children?: string,
}) {
	const [owner, setOwner] = useState(options[0])

	const config = {
		placeholder: 'Select an owner...',
		getOptionLabel: (owner: ?OkrOwner) => (owner ? owner.name : 'Unavailable'),
		options,
	}
	if (options.length === 0) return null
	return (
		<Popover
			enforceFocus={false}
			position={Position.TOP_RIGHT}
			interactionKind={PopoverInteractionKind.CLICK}>
			<Button {...buttonProps}>{children}</Button>
			<FlexWrapper css="padding: 8px">
				<ControlGroup fill={true} vertical={false}>
					<Select
						value={owner}
						onChange={(newOwner: ?OkrOwner) => setOwner(newOwner)}
						{...config}
						captureDismiss
					/>
				</ControlGroup>
				{owner && (
					<Button
						onClick={() => {
							addOwner(owner, () => {
								setOwner(
									options.find(option => option.id !== owner.id || option.type !== owner.type)
								)
							})
						}}>
						Add
					</Button>
				)}
			</FlexWrapper>
		</Popover>
	)
}
