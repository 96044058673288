// @flow
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import AsyncSelect from 'react-select/async'
import StateSelector from '../../../components/selectors/StateSelector'
import 'styled-components/macro'
import styled from 'styled-components'

import { mapEntries, mapObject } from '../../../utility/functions'
import { Loader, FlexWrapper, AppToaster } from '../../../components'
import { useClientStore, selectors } from '../../../stores/index.js'

import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { ConfirmActionButton } from '../../../components/ConfirmActionButton'
import { fetchDistricts } from '../Licenses/networkCalls'
import { type DistrictType } from '../Licenses/types'
import states from '../Licenses/states'
import { createEditFields } from '../helpers/createEditFields'
import { type SchoolData } from './School'
import { Button, Intent, InputGroup, FormGroup, Checkbox } from '@blueprintjs/core'

type SchoolProps = {
	schoolData: SchoolData,
	setShowEdit: false => void,
}

export type StateItem = { name: string, abbreviation: string, stateCode: string }

type FormDataValue<ValueType> = {
	prompt: string,
	value: ValueType,
	isEdited: boolean,
}

type FormData = {|
	verified: FormDataValue<boolean>,
	name: FormDataValue<string>,
	state: FormDataValue<string>,
	locationState: FormDataValue<string>,
	ncesId: FormDataValue<string>,
	district: FormDataValue<string>,
	zip: FormDataValue<string>,
	street: FormDataValue<string>,
	stateCode: FormDataValue<string>,
	phone: FormDataValue<string>,
	locationState: FormDataValue<string>,
|}

/**
 * This component takes schoolData, and makes specific fields editable
 * @param {*} schoolData school data
 * @param {function} setShowEdit function for toggle show component mod
 */

export default function EditSchool({ schoolData, setShowEdit }: SchoolProps): React$Node {
	const fetchClients = useClientStore(selectors.getClientFetch)
	const queryClient = useQueryClient()
	const isCustomSchool = schoolData?.schoolType !== 'public'
	const objectOrderTemplate = {
		name: '',
		state: '',
		stateCode: '',
		locationState: '',
		district: '',
		ncesId: '',
	}
	// set object keys order from template
	// $FlowIgnore resolve Object.assign in flow
	const sortedData = Object.assign(objectOrderTemplate, schoolData)

	const [formData, setFormData]: [FormData, ((FormData => FormData) | FormData) => void] = useState(
		// $FlowFixMe This flow is difficult to get right with item.value being `string | boolean`
		mapObject(createEditFields(sortedData, isCustomSchool), item => ({ ...item, isEdited: false }))
	)

	const getState = (state: string, abbreviation: string) => {
		return { name: state || '', abbreviation: abbreviation || '' }
	}

	const handleChange = (event, key) => {
		event.preventDefault()
		const value: string = event.target.value
		const keyValue: string = key
		setFormData(formData => {
			return {
				...formData,
				[keyValue]: { ...formData[keyValue], value: value, isEdited: true },
			}
		})
	}

	const handleChangeDistrict = values => {
		setFormData(formData => {
			return {
				...formData,
				district: { prompt: 'District', value: values.district || '', isEdited: true },
				ncesId: { prompt: 'Nces Id', value: values.ncesId || '', isEdited: true },
			}
		})
	}

	const handleChangeVerified = e => {
		setFormData(formData => {
			return {
				...formData,
				verified: { ...formData['verified'], value: !formData['verified']?.value, isEdited: true },
			}
		})
	}

	const handleChangeState = value => {
		const state = states.find(state => state.name === value?.name)
		setFormData(formData => {
			return {
				...formData,
				state: {
					prompt: 'State',
					value: value?.name || '',
					abbreviation: value?.abbreviation || '',
					isEdited: true,
				},
				stateCode: {
					prompt: 'State Code',
					value: state?.stateCode || '',
					isEdited: true,
				},
				locationState: {
					prompt: 'Location',
					value: state?.abbreviation || '',
					isEdited: true,
				},
			}
		})
	}

	const getEditedValues = (formData: FormData, isCustomSchool) => {
		let submittedValuesAccumulator = {}
		Object.keys(formData).forEach((key: string) => {
			if (formData[key]?.isEdited) {
				submittedValuesAccumulator = { ...submittedValuesAccumulator, [key]: formData[key].value }
			}
		})

		if (isCustomSchool && submittedValuesAccumulator?.state) {
			// store abbreviation in state field
			const submittedState: string = submittedValuesAccumulator.state
			const state: StateItem | null = states.find(state => state.name === submittedState) || null
			if (state) {
				submittedValuesAccumulator = {
					...submittedValuesAccumulator,
					state: state.abbreviation,
				}
			}
		}
		return { ...submittedValuesAccumulator, schoolType: schoolData.schoolType }
	}

	const handleEditSchool = useMutation(
		() =>
			NetworkCommunicator.PUT(`schools/${schoolData._id}`, {
				body: {
					editableSchool: getEditedValues(formData, isCustomSchool),
				},
			}),
		{
			onSuccess: () => {
				setShowEdit(false)
				fetchClients()
				queryClient.invalidateQueries(['schools', schoolData._id])
				AppToaster.warning({
					message: 'The school was successfully edited',
				})
			},
			onError: () => {
				AppToaster.danger({
					message: 'Something went wrong, please try again!',
				})
			},
		}
	)

	return (
		<>
			<div>
				<h2>{schoolData?.name}</h2> {isCustomSchool && <span>(User Created)</span>}
			</div>
			{handleEditSchool.isLoading ? (
				<Loader />
			) : (
				<>
					{mapEntries(formData || {}, (data, key) => (
						<DataPoint key={key} spaceBetween>
							{(() => {
								switch (key) {
									case 'locationState':
										return null
									case 'state':
										return (
											<>
												<span>{data.prompt} </span>
												<InputContainer labelFor="state">
													<StateSelector
														stateValue={formData.state.value}
														onChangeState={value => {
															handleChangeState(value)
														}}
													/>
												</InputContainer>
											</>
										)
									case 'district':
										return (
											<>
												<span>{data.prompt} </span>
												<InputContainer labelFor="district">
													<AsyncSelect
														name="district"
														inputId="district"
														value={{
															ncesId: formData.ncesId.value,
															district: formData.district.value,
														}}
														isDisabled={!formData.state.value}
														onChange={values => handleChangeDistrict(values)}
														loadOptions={(
															inputValue: string,
															callback: (Array<DistrictType>) => void
														) => {
															fetchDistricts(
																inputValue,
																getState(formData.state.value, formData.locationState.value),
																callback
															)
														}}
														getOptionLabel={district => (district ? district.district : '')}
														getOptionValue={district => (district ? district.ncesId : '')}
														isSearchable
														cacheOptions
														className="react-select-container"
														classNamePrefix="react-select"
													/>
												</InputContainer>{' '}
											</>
										)
									case 'verified':
										return (
											<>
												<span>{data.prompt} </span>{' '}
												<InputContainer labelFor="verified">
													<Checkbox
														large
														checked={formData.verified.value}
														onChange={e => {
															handleChangeVerified(e.target.value)
														}}
													/>
												</InputContainer>{' '}
											</>
										)
									default:
										return (
											<>
												<span>{data.prompt} </span>
												<InputContainer>
													<InputGroup
														value={data.value}
														disabled={['ncesId', 'stateCode', 'locationState'].includes(key)}
														onChange={e => handleChange(e, key)}></InputGroup>{' '}
												</InputContainer>
											</>
										)
								}
							})()}
						</DataPoint>
					))}{' '}
				</>
			)}
			<DataPoint spaceBetween>
				<span></span>
				<div>
					<BackBtn intent={Intent.NONE} onClick={() => setShowEdit(false)}>
						Cancel
					</BackBtn>
					<ConfirmActionButton
						intent={Intent.PRIMARY}
						onClick={() => {
							handleEditSchool.mutate()
						}}
						confirmMessage="Edit school fields?">
						Save
					</ConfirmActionButton>
				</div>
			</DataPoint>
		</>
	)
}

const BackBtn = styled(Button)`
	margin: 0 15px;
`

const DataPoint = styled(FlexWrapper)`
	padding-top: 8px;
`
const InputContainer = styled(FormGroup)`
	width: 190px;
	.react-select__control--is-focused {
		border-color: #137cbd !important;
		box-shadow: 0 0 0 1px #137cbd, inset 0 1px 1px rgb(16 22 26 /20%);
	}
	.react-select-container {
		.react-select__control {
			min-height: 0;
			height: 30px;
			border-radius: 3px;
			.react-select__indicators {
				height: 30px;
			}
		}
	}
`
