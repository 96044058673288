import React from 'react'
import { Tabs, Tab } from '@blueprintjs/core'

export default function CustomTabs({
	tabs,
}: {
	tabs: Array<{ id: string, title: string, panel: React$Element<*> }>,
}): React$Node {
	return (
		<Tabs large>
			{tabs.map(tab => (
				<Tab key={tab.id} {...tab} />
			))}
		</Tabs>
	)
}
