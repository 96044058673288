import React from 'react'
import { PLOT_TYPES } from '../constants'
import { BOX_PLOT_SELECTOR, LINE_SELECTOR } from '../selectors'
import { Tabs, Tab, Radio, RadioGroup, Switch, Label } from '@blueprintjs/core'
import { get, assign, type ProxyData } from '../../../../utility/dotProxy'
import { LinksViewer } from '../sharedComponents'
import type { LinkObject, Key } from '../types'
import type { UpdateCallback } from './types'
import { TIME_CONVERTERS } from '../converters'

/**
 * ChartOptions - a react component used to allow users to select what data is displayed and how the data is displayed within the charts
 *
 * @param {Object} props - the react props
 * @param {ProxyData<boolean>} includeOutliersProxy - a proxy to where the variable for determining if outliers should be shown is stored
 * @param {ProxyData<$Keys<typeof PLOT_TYPES>>} plotTypeProxy - a proxy to where the variable for determines what type of plot to show is stored
 * @param {ProxyData<Key>} locationProxy - a proxy to where the variable for determines what stats should be displayed is stored
 * @param {ProxyData<string>} selectorProxy - a proxy to where the variable for determines what part of the statistics are shown is stored
 * @param {ProxyData<string>} binProxy - a proxy to where the variable that determines what bin the statistics are located in (if applicable) is stored
 * @param {ProxyData<string>} timeConverterProxy - a proxy to where the variable that determines what time unit to use is located
 * @param {Link[]} links - the amiable links
 * @param {string[]} binIds - the available bins
 * @param {T} values - the base container of the values
 * @param {UpdateCallback<T>} updateValues - a callback used to update the values
 */
export default function ChartOptions<T>({
	includeOutliersProxy,
	plotTypeProxy,
	locationProxy,
	selectorProxy,
	binProxy,
	timeConverterProxy,
	values,
	updateValues,
	links,
	binIds,
}: {
	includeOutliersProxy: ProxyData<boolean>,
	plotTypeProxy: ProxyData<$Keys<typeof PLOT_TYPES>>,
	locationProxy: ProxyData<Key>,
	selectorProxy: ProxyData<string>,
	binProxy: ProxyData<string>,
	timeConverterProxy: ProxyData<string>,
	values: T,
	updateValues: UpdateCallback<T>,
	links: LinkObject[],
	binIds: string[],
}): React$Node {
	const selectedPlotType = get(plotTypeProxy, values)
	const selectedSelector = get(selectorProxy, values)
	const selectedBin = get(binProxy, values)

	return (
		<>
			<Switch
				checked={get(includeOutliersProxy, values)}
				onChange={event => {
					updateValues((oldData: T) => assign(includeOutliersProxy, values, event.target.checked))
				}}
				innerLabelChecked="Including Outliers"
				innerLabel="Excluding Outliers"
			/>
			<Tabs>
				<Tab
					id="plotType"
					title="Plot Type"
					panel={
						<RadioGroup
							onChange={e =>
								updateValues((oldData: T) =>
									assign(
										plotTypeProxy,
										assign(
											selectorProxy,
											oldData,
											e.target.value === PLOT_TYPES.BOX_PLOT ? 'quartiles' : 'average'
										),
										e.target.value
									)
								)
							}
							selectedValue={get(plotTypeProxy, values)}>
							{Object.keys(PLOT_TYPES).map(type => (
								<Radio key={type} label={type} value={type} checked={type === selectedPlotType} />
							))}
						</RadioGroup>
					}
				/>
				<Tab
					id="dataToView"
					title="Data To View"
					panel={
						<RadioGroup
							onChange={e =>
								updateValues((oldData: T) => assign(selectorProxy, values, e.target.value))
							}
							selectedValue={selectedSelector}>
							{Object.keys(
								selectedPlotType === PLOT_TYPES.LINE ? LINE_SELECTOR : BOX_PLOT_SELECTOR
							).map(selectType => (
								<Radio value={selectType} label={selectType} key={selectType} />
							))}
						</RadioGroup>
					}
				/>
				<Tab
					id="Units"
					title="Units"
					panel={
						<>
							<Label>View Time As</Label>
							<RadioGroup
								onChange={e =>
									updateValues((oldData: T) => assign(timeConverterProxy, values, e.target.value))
								}
								selectedValue={get(timeConverterProxy, values)}>
								{Object.keys(TIME_CONVERTERS).map(converterType => (
									<Radio value={converterType} label={converterType} key={converterType} />
								))}
							</RadioGroup>
						</>
					}
				/>
				<Tab
					id="related"
					title="Related Data"
					panel={
						<LinksViewer
							links={links}
							setCurrentLocation={newLocation =>
								updateValues(oldData => assign(locationProxy, values, newLocation))
							}
						/>
					}
				/>
				{selectedPlotType === PLOT_TYPES.BOX_PLOT ? (
					<Tab
						id="bins"
						title="Data Sets (Bins)"
						panel={
							<RadioGroup
								onChange={e => updateValues(oldData => assign(binProxy, oldData, e.target.value))}
								selectedValue={selectedBin}>
								{binIds.map(binId => (
									<Radio type="radio" value={binId} label={binId} key={binId} />
								))}
							</RadioGroup>
						}
					/>
				) : null}
			</Tabs>
		</>
	)
}
