import React, { useState, useRef, useCallback } from 'react'
import { Card, InputGroup, Button, Divider, Tag, Classes } from '@blueprintjs/core'
import 'styled-components/macro'
import { type CreateOkrPayloadType, type QueryParams, useCreateOkr } from '../../../resources/okrs'
import styled, { css } from 'styled-components'
import SimpleOkr from './SimpleOkr'
import { userOwnsOkr } from './helpers'
import { AppToaster, EmptyMessage } from '../../../components'
import type { SimpleOkr as Okr } from '../../../stores/types'
import type { Owner, NestedOkr } from './types'
import type { User } from '../../../stores/auth'
import type { StyledComponent } from 'styled-components'

type Props = {
	okrs: Okr[],
	quarter: string,
	owner: ?Owner,
	queryOverrides?: ?QueryParams,
	allowOkrCreation?: boolean,
	newOkrParentId?: number,
	currentUser: User,
	placeholder?: string,
	condensed?: boolean,
	isMainPage?: boolean,
}

type OkrsCreationProps = {
	owner: Owner,
	newIndex: number,
	onCreate: CreateOkrPayloadType => void,
	placeholder: string | void,
	newTitle: string | null,
	setNewTitle: string => void,
	quarter: string,
	newOkrParentId: void | number,
}

const OkrsContainer: StyledComponent<
	{ isMainPage?: ?boolean, condensed?: ?boolean },
	Theme,
	HTMLDivElement
> = styled.div`
	display: flex;
	flex-direction: column;
	${({ isMainPage }) =>
		isMainPage
			? `overflow: auto;
	 max-height: 25.5vh;
	 padding: 1px;
	 margin-top: 8px;`
			: ''}

	${({ condensed }) =>
		condensed
			? css`
					.bp3-card {
						padding: 8px;
					}
			  `
			: ''}
`

export const SpacedOkr: StyledComponent<{}, Theme, React$AbstractComponent<any, any>> = styled(
	SimpleOkr
)`
	margin-bottom: ${({ theme }) => theme.spacing};
`

const FullHeightDivider = styled(Divider)`
	&& {
		margin-top: 0;
		margin-bottom: 0;
	}
`

/**
 * Removes all okrs from the given list that have a parent in the list and adds them as children
 * in a new field, children, on the okr.
 */
function nestChildrenOkrs(okrs: Okr[]): NestedOkr[] {
	const okrsObject = okrs.reduce((obj, okr) => {
		obj[okr.id] = { ...okr }
		return obj
	}, {})
	const filteredOkrs: NestedOkr[] = []
	okrs.forEach(okr => {
		if (okr.parentOkrId && okrsObject[okr.parentOkrId]) {
			if (!okrsObject[okr.parentOkrId].children) {
				okrsObject[okr.parentOkrId].children = []
			}
			okrsObject[okr.parentOkrId].children.push(okrsObject[okr.id])
		} else {
			filteredOkrs.push(okrsObject[okr.id])
		}
	})
	return filteredOkrs
}

export default function OkrsList({
	okrs,
	owner,
	quarter,
	allowOkrCreation,
	queryOverrides,
	newOkrParentId,
	currentUser,
	placeholder,
	condensed,
	isMainPage,
}: Props): React$Node {
	const [newTitle, setNewTitle] = useState('')
	const filteredOkrs = nestChildrenOkrs(okrs)
	const scrollRef = useRef(null)

	const onSuccess = useCallback(() => {
		if (isMainPage) {
			// scroll to bottom
			if (scrollRef?.current) {
				scrollRef.current.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}, [isMainPage])

	const { createOkr } = useCreateOkr({
		queryParams: queryOverrides,
		onSuccess,
	})

	return (
		<>
			<OkrsContainer condensed={condensed} isMainPage={isMainPage}>
				{filteredOkrs.map(okr => {
					const canUpdate = userOwnsOkr(currentUser, okr)
					return (
						<div key={okr.id}>
							<SpacedOkr okr={okr} canUpdate={canUpdate} isMainPage={isMainPage} />
							{okr.children && (
								<SimpleList okrs={okr.children} canUpdate={canUpdate} isMainPage={isMainPage} />
							)}
						</div>
					)
				})}
				{allowOkrCreation && owner && !isMainPage && (
					<OkrsCreation
						newIndex={okrs.length + 1}
						onCreate={createOkr}
						newTitle={newTitle}
						setNewTitle={setNewTitle}
						placeholder={placeholder}
						owner={owner}
						quarter={quarter}
						newOkrParentId={newOkrParentId}
					/>
				)}
				{!allowOkrCreation && filteredOkrs.length === 0 && !newOkrParentId && (
					<EmptyMessage className="empty-message">No Okrs Available</EmptyMessage>
				)}
				<span ref={scrollRef} />
			</OkrsContainer>
			{isMainPage && owner && allowOkrCreation && (
				<OkrsCreation
					newIndex={okrs.length + 1}
					onCreate={createOkr}
					newTitle={newTitle}
					setNewTitle={setNewTitle}
					placeholder={placeholder}
					owner={owner}
					quarter={quarter}
					newOkrParentId={newOkrParentId}
				/>
			)}
		</>
	)
}

/**
 * Input component for the creation of a new Okr
 * @param {string | null}  newTitle Okr input value
 * @param {(string) => void} setNewTitle function that takes onChange input value
 * @param {string | void} placeholder placeholder value for the input
 * @param {Owner} owner The owner of the OKRs on the current page
 * @param {string} quarter the string with the quarter label value
 * @param {number | void} newOkrParentId value of a parent id
 * @param {number | void} okrsBeingCreated number of a created okrs
 * @param {(any) => void} dispatch a function that dispatch actions for creating OKRs
 */
const OkrsCreation = ({
	newIndex,
	onCreate,
	newTitle,
	setNewTitle,
	placeholder,
	owner,
	quarter,
	newOkrParentId,
}: OkrsCreationProps) => {
	return (
		<Card css="display: flex; ">
			<div css="display: flex; justify-content: space-between; align-items: center; flex: 1; margin-right: 10px;">
				<InputGroup
					value={newTitle}
					onChange={e => setNewTitle(e.currentTarget.value)}
					placeholder={placeholder || 'New OKR'}
					css="width: 60%;"
				/>
				<span>
					Owned by: <Tag>{owner.displayName}</Tag>
				</span>
				<Tag>{quarter}</Tag>
			</div>
			<FullHeightDivider />
			<Button
				icon="plus"
				minimal
				onClick={() => {
					if (!newTitle) {
						AppToaster.show({
							message: 'Please provide a title for the OKR',
							intent: 'warning',
						})
						return
					}
					const body = {}
					body.title = newTitle
					body.quarter = quarter
					if (typeof newOkrParentId === 'number') {
						body.parentOkrId = newOkrParentId
					}

					if (owner.type === 'EMPLOYEE') {
						body.employeeId = owner.id
					} else if (owner.type === 'TEAM') {
						body.teamId = owner.id
					} else if (owner.type === 'COMPANY') {
						body.company = true
					} else {
						AppToaster.show({
							message: 'Please use a valid owner for this OKR',
							intent: 'warning',
						})
						return
					}
					const ownerToSend =
						owner.type === 'COMPANY'
							? { id: 'COMPANY', name: owner.displayName, type: 'COMPANY' }
							: owner.type === 'TEAM'
							? {
									id: owner.id,
									name: owner.displayName,
									type: 'TEAM',
							  }
							: { id: owner.id, name: owner.displayName, type: 'EMPLOYEE' }

					onCreate({
						requestBody: body,
						temporaryOkr: {
							id: newIndex,
							quarter: body.quarter,
							progress: 0,
							createdBy: owner.id,
							parentOkrId: body.parentOkrId,
							title: newTitle,
							owners: [ownerToSend],
						},
					})
					setNewTitle('')
				}}
			/>
		</Card>
	)
}

const SimpleOkrsContainer = styled(OkrsContainer)`
	.bp3-card {
		padding: 8px 20px;
	}
	margin: 0px !important;
`

/**
 * A simple okr list that shows a list of okrs, including any recursively nested key results within that okr
 */
function SimpleList({
	okrs,
	canUpdate,
	isMainPage,
}: {
	okrs: NestedOkr[],
	canUpdate: boolean,
	isMainPage?: boolean,
}) {
	return (
		<SimpleOkrsContainer condensed className={Classes.LIST}>
			{okrs.map(okr => (
				<div key={okr.id}>
					<SpacedOkr okr={okr} canUpdate={canUpdate} isMainPage={isMainPage} />
					{okr.children && <SimpleList okrs={okr.children} canUpdate={canUpdate} />}
				</div>
			))}
		</SimpleOkrsContainer>
	)
}
