import React from 'react'
import moment from 'moment'
import { useQueryParams, NumberParam } from 'use-query-params'
import { PageContainer } from '../../../components/basic'
import { Link } from 'react-router-dom'

import { Button, Intent, Tag, Classes, Divider, H1, Card } from '@blueprintjs/core'
import { getCurrentMonday } from '../../../utility/functions'
import styled from 'styled-components'
import 'styled-components/macro'
import CheckpointDisplay from './components/Display'

const Container = styled(PageContainer)`
	color: ${props => props.theme.primary};
`

const Header = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	align-items: center;
`

const HeaderTitle = styled.div`
	text-align: center;
	margin: 0.67em 0;
	& > * {
		margin: 0;
	}
`

const weekStatusProps = {
	CURRENT: {
		className: Classes.INTENT_SUCCESS,
		intent: Intent.SUCCESS,
		title: 'Current Week',
	},
	PAST: {
		className: Classes.INTENT_WARNING,
		intent: Intent.WARNING,
		title: 'Past Week',
		icon: 'disable',
	},
	FUTURE: {
		className: Classes.INTENT_PRIMARY,
		intent: Intent.PRIMARY,
		title: 'Future Week',
		icon: null,
	},
}

export default function MyCheckpoints(): React$Node {
	const [query, setQuery] = useQueryParams({
		monday: NumberParam,
	})
	const monday = query.monday || getCurrentMonday()
	const lastMonday = getOneWeekBefore(monday)
	const nextMonday = getOneWeekLater(monday)

	const fetchCheckpointsUrl = 'checkpoints' + (query.monday ? `?monday=${query.monday}` : '')
	const weekStatusType =
		monday === getCurrentMonday() ? 'CURRENT' : isPastWeek(monday) ? 'PAST' : 'FUTURE'

	const weekStatus = weekStatusProps[weekStatusType]

	return (
		<Container column>
			<Header>
				<Button icon="arrow-left" onClick={() => setQuery({ monday: lastMonday })}>
					Previous Week
				</Button>
				<HeaderTitle>
					<H1>
						<Link to="checkpoints">Checkpoints</Link>
					</H1>
					<h3 className={Classes.BUTTON_GROUP}>
						<Tag minimal intent={weekStatus.intent}>
							{weekStatus.title}
						</Tag>
						<Divider />
						{getWeekString(monday)}
					</h3>
				</HeaderTitle>
				<Button rightIcon="arrow-right" onClick={() => setQuery({ monday: nextMonday })}>
					Next Week
				</Button>
			</Header>
			<Card>
				<CheckpointDisplay
					url={fetchCheckpointsUrl}
					allowCreateDelete={!isPastWeek(monday)}
					allowUpdate={true}
				/>
			</Card>
		</Container>
	)
}

function getWeekString(mondayTimestamp: number) {
	const mondayMoment = moment(mondayTimestamp)
	let dateFormat = 'MMM Do'
	if (mondayMoment.year() !== moment().year()) dateFormat = 'MMM Do, YYYY'
	const monday = mondayMoment.format(dateFormat)
	const sunday = mondayMoment.add(7, 'days').format(dateFormat)
	return monday + ' - ' + sunday
}

function getOneWeekLater(timestamp: number) {
	const date = new Date(timestamp)
	date.setDate(date.getDate() + 7)
	return date.getTime()
}

function getOneWeekBefore(timestamp: number) {
	const date = new Date(timestamp)
	date.setDate(date.getDate() - 7)
	return date.getTime()
}

function isPastWeek(mondayTimestamp: number): boolean {
	const currentMonday = getCurrentMonday()
	return currentMonday > mondayTimestamp
}
