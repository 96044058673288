import React from 'react'
import { useQuery } from 'react-query'
import { Loader } from '../../components'
import { AdvancedTable } from '../../components/basic'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import { prettifyTypeEnum } from '../../utility/functions'

import 'styled-components/macro'

export type UsageRow = {
	_id: string,
	district: string,
	lastMissionRunTimestamp: ?Date,
	missionControlRoleCount: number,
	licenseTypes: string[],
	missionsRan: number,
	name: string,
	principalRoleCount: number,
	userCount: number,
	usersThatHaveRunMissions: number,
}

const columns = [
	{
		Header: 'School Name',
		accessor: 'name',
	},
	{
		Header: 'District',
		accessor: 'district',
	},
	{
		Header: 'Mission Control Roles',
		accessor: 'missionControlRoleCount',
	},
	{
		Header: 'Missions Ran',
		accessor: 'missionsRan',
	},

	{
		Header: 'Principal Roles',
		accessor: 'principalRoleCount',
	},
	{
		Header: 'Licenses used',
		accessor: 'licenseTypes',
	},
	{
		Header: 'User Count',
		accessor: 'userCount',
	},
	{
		Header: 'Users That Have Run Missions',
		accessor: 'usersThatHaveRunMissions',
	},
	{
		Header: 'Last Mission Interaction',
		accessor: 'lastMissionRunTimestamp',
	},
]

/**
 * Pulls latest usage data from the api server and compiles it into a searchable, orderable list
 */
export default function Usage(): React$Node {
	const { isLoading, error, data } = useQuery('users/usage', ctx => {
		return NetworkCommunicator.GET(ctx.queryKey)
	})
	const usageData: UsageRow[] = React.useMemo(
		() =>
			(data &&
				data.usage &&
				data.usage.map(row => {
					const newRow = { ...row }
					newRow.lastMissionRunTimestamp = newRow.lastMissionRunTimestamp
						? new Date(newRow.lastMissionRunTimestamp).toLocaleString()
						: ''
					newRow.licenseTypes = newRow.licenseTypes
						.sort()
						.map(prettifyTypeEnum)
						.join(', ')
					return newRow
				})) ||
			[],
		[data]
	)
	const usageTimestamp = data && data.generationTimestamp

	if (isLoading) {
		return <Loader />
	}
	if (error) {
		return <p>Error loading employee page</p>
	}

	return (
		<>
			<AdvancedTable
				interactive
				striped
				bordered
				disableMultiSort
				columns={columns}
				data={usageData}
				topRight={
					usageTimestamp && (
						<i>Data last generated at: {new Date(usageTimestamp).toLocaleString()}</i>
					)
				}
			/>

			<i css="padding: 16px; float: right;">
				Information accumulated from all missions run since August 1st, 2020
			</i>
		</>
	)
}
