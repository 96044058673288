import type { ProxyData } from '../../../../../../utility/dotProxy'
import type { Criteria, UpdateCriteria } from '../../localTypes'
import type { FieldType, Range } from '../../clientTypes'

import React from 'react'
import { useState } from 'react'
import { assign, get } from '../../../../../../utility/dotProxy'
import { RangeEntry } from '.'
import { InputGroup, HTMLSelect } from '@blueprintjs/core'

/**
 * DurationEntry - adds a duration entry for time durations
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {FieldType} props.fieldType - the field type which caused this component to be rendered
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data for ths range
 *
 * @returns React$Node
 */
export function DurationEntry({
	updateCriteria,
	currentCriteria,
	currentPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?Range<?number>>,
	fieldType: FieldType,
}): React$Node {
	return (
		<RangeEntry
			updateCriteria={updateCriteria}
			currentCriteria={currentCriteria}
			currentPath={currentPath}
			formRenderer={DurationRenderer}
			fieldType={fieldType}
		/>
	)
}

const DURATION_TYPES = {
	MILLISECOND: 'MILLISECOND',
	SECOND: 'SECOND',
	MINUTE: 'MINUTE',
	HOUR: 'HOUR',
	DAY: 'DAY',
}

const DURATION_LABELS = {
	[DURATION_TYPES.MILLISECOND]: 'milliseconds',
	[DURATION_TYPES.SECOND]: 'seconds',
	[DURATION_TYPES.MINUTE]: 'minutes',
	[DURATION_TYPES.HOUR]: 'hours',
	[DURATION_TYPES.DAY]: 'days',
}

const DURATION_IN_MILLISECONDS = (() => {
	const millisecondsInSeconds = 1000
	const millisecondsInMinutes = 60 * millisecondsInSeconds
	const millisecondsInHours = 60 * millisecondsInMinutes
	const millisecondsInDays = 24 * millisecondsInHours

	return {
		[DURATION_TYPES.MILLISECOND]: 1,
		[DURATION_TYPES.SECOND]: millisecondsInSeconds,
		[DURATION_TYPES.MINUTE]: millisecondsInMinutes,
		[DURATION_TYPES.HOUR]: millisecondsInHours,
		[DURATION_TYPES.DAY]: millisecondsInDays,
	}
})()

/**
 * DurationRenderer - adds a form for inputting durations with units
 *
 * @param  {Object} props - the react props
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {Criteria} props.currentCriteria - a the current criteria
 * @param  {ProxyData<?Range<?T>>} props.currentPath - the location in the criteria which holds the data for ths range
 *
 * @returns React$Node
 */
function DurationRenderer({
	updateCriteria,
	currentCriteria,
	currentPath,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	currentPath: ProxyData<?number>,
}): React$Node {
	const [selectedDisplayDuration, setSelectedDisplayDuration] = useState(DURATION_TYPES.MINUTE)
	const millisecondsInDisplayType = DURATION_IN_MILLISECONDS[selectedDisplayDuration]
	const currentValue = get(currentPath, currentCriteria)
	const displayValue = currentValue == null ? null : currentValue / millisecondsInDisplayType
	return (
		<InputGroup
			type="number"
			minorStepSize={millisecondsInDisplayType}
			majorStepSize={millisecondsInDisplayType * 10}
			value={displayValue}
			onChange={e => {
				const timeInMilliseconds = Number(e.target.value) * millisecondsInDisplayType
				if (isNaN(timeInMilliseconds)) {
					return
				}
				updateCriteria(oldCriteria => assign(currentPath, oldCriteria, timeInMilliseconds))
			}}
			rightElement={
				<HTMLSelect
					value={selectedDisplayDuration}
					options={Object.keys(DURATION_TYPES).map(type => ({
						value: type,
						label: DURATION_LABELS[type],
					}))}
					onChange={e => {
						setSelectedDisplayDuration(e.target.value)
					}}
				/>
			}
		/>
	)
}
