import type { UserRole } from '../../../stores/types'

export type SimulationGroup = {
	_id: string,
	name: string,
	canRun: string[],
	canView: string[],
	defaultPublish: { canView: boolean, canRun: boolean },
	isMajorGroup: boolean,
	unlockMessageType?: 'PAID_LICENSE',
}

export const licenseTypesEnum = {
	FREE: 'FREE',
	DISTRICT: 'DISTRICT',
	SCHOOL: 'SCHOOL',
	SINGLE_OWNER: 'SINGLE_OWNER',
}

export type LicenseType = $Keys<typeof licenseTypesEnum>
export type DistrictType = {
	ncesId: string,
	district: string,
}

export type LicenseClient = {
	roles: UserRole[],
	_id: string,
	firstName: string,
	lastName: string,
	email: string,
}

export type LicenseOwnerWithDetail =
	| {
			type: 'DISTRICT',
			districtId: string,
			districtName: string,
			state: string,
	  }
	| { type: 'SCHOOL', schoolId: string, schoolName: string, state: string, districtName: ?string }
	| {
			type: 'SINGLE_OWNER',
			user: { _id: string, firstName: string, lastName: string },
			districtName: ?string,
	  }
	| {
			type: 'FREE',
			user: { _id: string, firstName: string, lastName: string },
			districtName: ?string,
	  }

export type LicenseOwner =
	| {
			type: 'DISTRICT',
			districtId: string,
	  }
	| { type: 'SCHOOL', schoolId: string }
	| { type: 'SINGLE_OWNER', userId: string }
	| { type: 'FREE', userId: string }

export type PermissionsFlow = {
	physicalClassroom?: boolean,
	simulationGroups?: string[],
}

export type LicenseFlow<Owner> = {|
	_id: string,
	owner: Owner,
	description: string,
	permissionsOverride: PermissionsFlow,
	expiresOn: Date,
	deleted: boolean,
|}

export type LicenseDetailFlow = LicenseFlow<LicenseOwnerWithDetail>
