// @flow
import React, { useState } from 'react'

import { FormGroup } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import StateSelector from '../../../components/selectors/StateSelector'
import { fetchSchools } from '../Licenses/networkCalls'

export type State = {
	name: string,
	abbreviation: string,
	stateCode: string,
}

type SchoolData = {
	schoolName: string,
	schoolId: string,
}

/**
 * Components for searching public schools, using State and School selects
 */
export default function SearchSchool({
	setPublicSchoolId,
	onSelectState: passedSetState,
	state: passedState,
	initialSchool,
}: {
	setPublicSchoolId: string => void,
	onSelectState?: (?State) => void,
	state?: ?State,
	initialSchool?: ?SchoolData,
}): React$Node {
	const [school, _setSchool] = useState(initialSchool)
	const [_state, _setState] = useState<State | null>(null)

	/**
	 * A wrapper around the setSchool functions to keep local and external state in sync.
	 */
	function setSchool(school: ?SchoolData) {
		_setSchool(school)
		setPublicSchoolId(school?.schoolId || '')
	}

	const isControlled = !!passedSetState

	const state = isControlled ? passedState : _state
	const setState = passedSetState || _setState

	return (
		<form css="padding: 8px;" aria-labelledby="edit-license-modal-header">
			<div>
				<FormGroup label="State" labelFor="state">
					<StateSelector
						stateValue={state?.name}
						onChangeState={value => {
							if (value !== state) {
								if (school) {
									setSchool(null)
								}
							}
							setState(value)
						}}
					/>
				</FormGroup>

				<FormGroup label="School" labelFor="school">
					<AsyncSelect
						inputId="school"
						name="school"
						value={school}
						isDisabled={!state}
						onChange={(value: SchoolData) => {
							setSchool(value)
						}}
						loadOptions={(inputValue: string, callback: (Array<SchoolData>) => void) => {
							fetchSchools(inputValue, state, callback)
						}}
						getOptionLabel={school => (school ? school.schoolName : '')}
						getOptionValue={school => (school ? school.schoolId : '')}
						components={{ Option: SchoolOption }}
						isSearchable
						cacheOptions
					/>
				</FormGroup>
			</div>
		</form>
	)
}

type SchoolOptionProps = {
	data: {
		schoolName: string,
		district: string,
		street: string,
		city: string,
	},
}

const OptionHeader = styled.div`
	font-weight: ${({ theme }) => theme.fontBold};
	font-size: 20px;
`

/**
 * The selector option display for a school
 */
const SchoolOption = ({ data: school, ...props }: SchoolOptionProps) => {
	return (
		<components.Option {...props}>
			<OptionHeader>{school.schoolName}</OptionHeader>
			<div>
				{school.street}, {school.city}
			</div>
			<div>District - {school.district}</div>
		</components.Option>
	)
}
