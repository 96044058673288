import React from 'react'
import { Select } from './'
import 'styled-components/macro'
import { useClientStore, selectors } from '../stores'
import type { SimpleClientType } from '../stores/types'
import type { ClientType as ClientUserType } from '../stores/types'

/**
 * A searchable dropdown which displays all infini-D clients for a given schoolId (in props).
 * If schoolId is undefined, then the dropdown displays all clients.
 * @param {{
	value: ?ClientType,
	placeholder: string,
	onChange: (value: ?ClientType) => void,
	schoolId?: string,
}}
 */
export default function ClientSearch<T: SimpleClientType | ClientUserType>({
	value,
	placeholder,
	onChange,
	schoolId,
}: {
	value: ?T,
	placeholder: string,
	onChange: (value: ?T) => void,
	schoolId?: string,
}): React$Node {
	// Necessary information to make the SchoolSearch and ClientSearch work
	const clients: T[] = useClientStore(state => selectors.getClients(state, schoolId))
	const getClientLabel = (client: ?T) =>
		client
			? `${client.firstName} ${client.lastName} ${client.email != null ? client.email : ''}`
			: ''

	return (
		<Select
			clearable
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			options={clients}
			getOptionLabel={getClientLabel}
			fill
		/>
	)
}
