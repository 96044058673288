import type { Store as MissionStore } from './missions'
import type { Store as AuthStore } from './auth'
import type { Store as ClientStore } from './clients'
import type { Store as FilterStore } from './filters'
export type { MissionStore, AuthStore, ClientStore, FilterStore }

export const rolesEnum = {
	SCHOOL_ADMIN: 'SCHOOL_ADMIN',
	DEPRECATED_SCHOOL_ADMIN: 'DEPRECATED_SCHOOL_ADMIN',
	DISTRICT_ADMIN: 'DISTRICT_ADMIN',
	LICENSE_ADMIN: 'LICENSE_ADMIN',
}

export const friendlyRoleNamesEnum = {
	SCHOOL_ADMIN: 'School Admin',
	DEPRECATED_SCHOOL_ADMIN: 'School Flight Director',
	DISTRICT_ADMIN: 'District Admin',
	LICENSE_ADMIN: 'License Admin',
}

export const MISSION_CATEGORIES = {
	ON_SITE: 'ON_SITE',
	WHOLE_CLASS_REMOTE: 'WHOLE_CLASS_REMOTE',
	SQUADRON_REMOTE: 'SQUADRON_REMOTE',
	SQUADRON_HOMEWORK: 'SQUADRON_HOMEWORK',
}

export type RoleEnum = $Keys<typeof rolesEnum>
export type UserRole = {
	role: RoleEnum,
	verified: boolean,
	reasonForRejection?: string,
	verifier?: string,
}

export const eventTypesEnum = {
	BEGIN_PREP: 'BEGIN_PREP',
	OPEN_MISSION: 'OPEN_MISSION',
	START_MISSION: 'START_MISSION',
	RESTORE_MISSION: 'RESTORE_MISSION',
	SAVE_FOR_LATER: 'SAVE_FOR_LATER',
	END_MISSION: 'END_MISSION',
}
type ClassType = {
	grades: string[],
	studentIds: string[],
	_id: string,
	name: string,
}
type TrainingType = {
	_id: string,
	videoActions: string[],
	stageActions: string[],
}

type TeacherImpression = {
	missionRating: number,
	missionImprovementSuggestions: string,
	technicalDifficulties: string,
	generalFeedback: string,
}

export type SimpleClientType = {|
	_id: string,
	firstName: string,
	lastName: string,
|}

export type ClientType = {|
	...SimpleClientType,
	email: string,
	schoolId?: string,
	schoolName?: string,
|}

export type ClientDetail = {
	state: string,
	roles: UserRole[],
	classes: ClassType[],
	training: TrainingType,
	email: string,
	surveyResponses: TeacherImpression[],
	totalMissionsFlown: number,
	totalMissionsCreated: number,
	...ClientType,
}

export type SchoolType = {
	schoolId: string,
	schoolName: string,
}

export type DistrictType = {
	ncesId: string,
	district: string,
}

export type StudentType = {
	absent: boolean,
	_id: string,
	studentId: string,
	loginCode: string,
}

export type CrewType = {
	_id: string,
	students: StudentType[],
	classId: string,
	grades: string[],
}

const CONTROL_SETS = {
	'K-3': 'K-3',
	'4+': '4+',
}

export const READABLE_CONTROL_SETS = {
	JUNIOR: 'K-3',
	JUNIOR_PLUS: '4+',
}

type ControlSet = $Keys<typeof CONTROL_SETS>

export type MissionType = {
	_id: string,
	active: boolean,
	useBundledStations: boolean,
	ranOnWeb: Boolean,
	events: Array<{
		type: $Keys<typeof eventTypesEnum>,
		timestamp: Date,
	}>,
	code: string,
	owner: string,
	simulationId: string,
	crew: CrewType,
	flightDirector: ?string,
	controlSet: ControlSet,
	teacherResponse?: ?TeacherImpression,
	missionCategory: $Keys<typeof MISSION_CATEGORIES>,
}

export type MissionUser = {
	_id: string,
	firstName: string,
	lastName: string,
	state: string,
	schoolId: string,
	schoolName: string,
}

export type RoleRequestUser = {
	district?: string,
	email: string,
	firstName: string,
	lastName: string,
	roles: UserRole[],
	schoolId: string,
	schoolName: string,
	state: string,
	_id: string,
	emailVerified: boolean,
}

export type Event = {
	type: $Keys<typeof eventTypesEnum>,
	timestamp: Date,
	meta?: { delivery?: string, source?: string },
}

export type MissionDetailType = {
	_id: string,
	events: Array<Event>,
	controlSet: ControlSet,
	fullState?: {
		version: number,
		missionPhase: string,
		students: {
			[studentId: string]: {
				id: string,
				connected: boolean,
				firstName: string,
				lastName: string,
				absent?: boolean,
			},
		},
	},
	impression: ?{
		teacherResponse: ?TeacherImpression,
		averageRating: number,
	},
	simulationId: string,
	simulationName: string,
	code: string,
	score: number,
	grades: string[],
	flyer: ?MissionUser,
	owner: MissionUser,
	missionCategory: $Keys<typeof MISSION_CATEGORIES>,
	ranOnWeb: boolean,
}

export type CalendarFilterType = 'START_DATE' | 'END_DATE'

export type SelectFilterType = 'USER' | 'SCHOOL'

export type BooleanFilterType = 'STARTED' | 'FINISHED' | 'PREPPED'

export type DateFilter = {
	type: CalendarFilterType,
	value: Date,
}

export type BooleanFilter = {
	type: BooleanFilterType,
	value: boolean,
}

export type SelectFilter = {
	type: SelectFilterType,
	value: string,
}

export type Checkpoint = { id: string, completed: boolean, text: string }

export type OkrOwner =
	| { type: 'COMPANY', name: string, id: 'COMPANY' }
	| { type: 'EMPLOYEE', name: string, id: number }
	| { type: 'TEAM', name: string, id: number }

export type SimpleOkr = {|
	id: number,
	title: string,
	quarter: string,
	progress: number,
	createdBy: number,
	owners: Array<OkrOwner> | null,
	parentOkrId: number,
|}

export type ParentOkr = SimpleOkr & { parent?: ParentOkr }

export type EmployeeType = {
	id: number,
	firstName: string,
	lastName: string,
	email: string,
	startDate: ?string,
	birthday: ?string,
	phoneNumber: ?string,
	tShirtSize: ?string,
	employeeNumber: ?number,
	teams: Array<{ id: number, name: string }>,
	checkpoints: Checkpoint[],
	okrs: SimpleOkr[],
	roles: string[],
}

export type Filter = DateFilter | BooleanFilter | SelectFilter

export type FilterType = BooleanFilterType | SelectFilterType | CalendarFilterType
