// @flow
import React, { useMemo, useState, useCallback } from 'react'
import Sandbox from './Sandbox'
import 'styled-components/macro'
import { AdvancedTable } from '../../../components/basic'
import FeedbackView from './FeedbackView'
import { fetchReportForResource } from './networkCalls'
import { useQuery } from 'react-query'
import { getColumnsFromResource } from './resources'
import { Callout, Intent, Spinner } from '@blueprintjs/core'
import { INITIAL_PAGE_SIZE, type TableQueryProps } from '../../../components/basic/AdvancedTable'

import type { ResourceType } from './resources'
import type { Criteria } from './Criteria/localTypes'

// A component to allow users to customize and peruse reports for Infini-D resources
export default function Generator(): React$Node {
	const [resource, setResource] = useState<?ResourceType>()
	const [feedbackViewData, setFeedbackViewData] = useState()
	const [criteria, setCriteria] = useState((null: ?Criteria))
	const {
		isLoading,
		isFetching,
		data,
		fetchData,
		fetchedResource,
		totalEntries,
		warning,
	} = useResourceData(resource, criteria)
	// Get columns for given resource
	const columns = useMemo(() => {
		return resource ? getColumnsFromResource(resource, { setFeedbackViewData }) : []
	}, [resource])
	const showSpinner = isLoading || fetchedResource !== resource

	const fetchResource = ({ resource, criteria }) => {
		setResource(resource)
		setCriteria(criteria)
	}

	return (
		<div>
			<div css="display: flex;">
				<Sandbox fetchResource={fetchResource} css="flex: 1;" />{' '}
				{warning && (
					<Callout css="margin-left: 8px;" intent={Intent.WARNING} title="Warning">
						{warning}
					</Callout>
				)}
			</div>
			{showSpinner ? (
				<Spinner />
			) : (
				resource &&
				data && (
					<div css="padding: 16px 0;">
						<AdvancedTable
							disableMultiSort
							data={data}
							columns={columns}
							fetchData={fetchData}
							loading={isFetching}
							manualCount={totalEntries}
						/>
					</div>
				)
			)}
			{feedbackViewData && (
				<FeedbackView
					{...feedbackViewData}
					close={() => {
						setFeedbackViewData(null)
					}}
				/>
			)}
		</div>
	)
}

/**
 * Gets data from the server for a resource given specific query parameters defined in TableQueryProps.
 * Returns data for a table to render along with a fetch function for the table to call, the page count for the table to render and
 * isLoading and isFetching values provided by react-query
 * @param {?ResourceType} resource
 * @returns {{
 * 	fetchData: (TableQueryProps) => void,
 * 	data: Array<D>,
 * 	pageCount: number,
 *  isLoading: boolean,
 *  isFetching: boolean
 * }}
 */
function useResourceData(
	resource: ?ResourceType,
	criteria: ?Criteria
): {
	fetchData: TableQueryProps => void | Promise<any>,
	fetchedResource: ?ResourceType,
	data: Array<mixed>,
	totalEntries: number,
	isLoading: boolean,
	isFetching: boolean,
	warning: ?string,
} {
	const [queryParams, setQueryParams] = useState<TableQueryProps>({
		pageIndex: 0,
		pageSize: INITIAL_PAGE_SIZE,
		sortBy: [],
	})

	const { isLoading, isPreviousData, isFetching, data } = useQuery(
		[`report-${resource || 'disabled'}`, { ...queryParams, criteria }],
		fetchReportForResource,
		{
			keepPreviousData: true,
			enabled: !!resource,
			staleTime: Infinity,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		}
	)
	const totalEntries = data?.totalEntries
	const fetchData = useCallback(
		(fetchParams: TableQueryProps) => {
			if (fetchParams?.isAllData && resource) {
				return fetchReportForResource({
					queryKey: [
						`report-${resource}`,
						{
							pageSize: totalEntries ?? 0,
							pageIndex: 0,
							sortBy: [],
							criteria,
						},
					],
				})
			}

			if (!isPreviousData) {
				setQueryParams(fetchParams)
			}
		},
		[isPreviousData, resource, totalEntries, criteria]
	)

	const resourceData = useMemo(() => {
		if (data) {
			return data.list.map(value => {
				let newValue = {}
				Object.keys(value).forEach(field => {
					if (typeof value[field] === 'number') {
						newValue[field] = Math.round(value[field] * 100) / 100
					} else {
						newValue[field] = value[field]
					}
				})
				return newValue
			})
		}
		return []
	}, [data])

	return {
		fetchData,
		warning: data?.warning,
		data: resourceData,
		fetchedResource: data?.resource,
		totalEntries: data?.totalEntries || 0,
		isLoading,
		isFetching,
	}
}
