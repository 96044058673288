import { licenseTypesEnum } from './types'
import states, { DEFAULT_STATE } from './states'
import { prettifyTypeEnum } from '../../../utility/functions'
import type {
	LicenseOwner,
	LicenseType,
	DistrictType,
	LicenseDetailFlow,
	PermissionsFlow,
} from './types'
import type { SchoolType, SimpleClientType } from '../../../stores/types'

/** Simple logic used to determine if a license can add/view users
 * @param {LicenseDetailFlow} license
 * @returns { boolean }
 */
export function canViewUsers(license: ?LicenseDetailFlow): boolean {
	if (!license) {
		return false
	}
	if (
		license.owner.type === licenseTypesEnum.FREE ||
		license.owner.type === licenseTypesEnum.SINGLE_OWNER
	) {
		return false
	} else return true
}

/**
 * Returns a valid license type depending on the value of licenseType
 * @param {LicenseType} licenseType
 * @param {?ClientType} client
 * @param {?SchoolType} school
 * @param {?DistrictType} district
 * @return {?LicenseOwner} The owner type required to create/edit a license
 */
export const getLicenseType = (
	licenseType: LicenseType,
	client: ?$ReadOnly<SimpleClientType>,
	school: ?SchoolType,
	district: ?DistrictType
): ?LicenseOwner => {
	if (licenseType === licenseTypesEnum.SCHOOL && school) {
		return { type: licenseTypesEnum.SCHOOL, schoolId: school.schoolId }
	} else if (licenseType === licenseTypesEnum.SINGLE_OWNER && client) {
		return { type: licenseTypesEnum.SINGLE_OWNER, userId: client._id }
	} else if (licenseType === licenseTypesEnum.DISTRICT && district) {
		return { type: licenseTypesEnum.DISTRICT, districtId: district.ncesId }
	} else if (licenseType === licenseTypesEnum.FREE && client) {
		return { type: licenseTypesEnum.FREE, userId: client._id }
	}
	return undefined
}

/**
 * Returns the default string to describe a license
 * @param {LicenseType} licenseType
 * @param {ClientType} client
 * @param {SchoolType} school
 * @param {DistrictType} district
 * @return {string}
 */
export function getLicenseString(
	licenseType: LicenseType,
	client: ?$ReadOnly<SimpleClientType>,
	school: ?SchoolType,
	district: ?DistrictType
): string {
	if (licenseType === licenseTypesEnum.SCHOOL && school) {
		return school.schoolName
	} else if (licenseType === licenseTypesEnum.SINGLE_OWNER && client) {
		return `${client.firstName} ${client.lastName}`
	} else if (licenseType === licenseTypesEnum.DISTRICT && district) {
		return district.district
	} else if (licenseType === licenseTypesEnum.FREE && client) {
		return `${client.firstName} ${client.lastName} (Beta group)`
	} else {
		return `unknown ${prettifyTypeEnum(licenseType).toLowerCase()}`
	}
}

/**
 * Checks whether the license data provided includes a valid license type and is not expired
 * @param {Object} payload object with values for certain fields on a license
 * @returns {boolean} whether or not the license is valid
 */
export function isValidLicense({
	licenseType,
	client,
	school,
	district,
	expiresOn,
}: {
	licenseType: LicenseType,
	client: ?$ReadOnly<SimpleClientType>,
	school: ?SchoolType,
	district: ?DistrictType,
	expiresOn: ?Date,
}): boolean {
	if (!getLicenseType(licenseType, client, school, district)) {
		return false
	}
	if (!expiresOn || expiresOn <= new Date()) {
		return false
	}
	return true
}

// This object contains all the default values for each editable field on a license.
export const licenseFields = {
	description: (license: ?LicenseDetailFlow): ?string => license?.description,
	owner: {
		type: (license: ?LicenseDetailFlow): LicenseType =>
			license?.owner.type || licenseTypesEnum.FREE,
		state: (
			license: ?LicenseDetailFlow
		): {|
			abbreviation: string,
			name: string,
			stateCode: string,
		|} => {
			const stateAbbreviation =
				license &&
				(license.owner.type === licenseTypesEnum.DISTRICT ||
					license.owner.type === licenseTypesEnum.SCHOOL)
					? license.owner.state
					: null
			if (stateAbbreviation) {
				return states.find(state => stateAbbreviation === state.abbreviation) || DEFAULT_STATE
			}
			return DEFAULT_STATE
		},
		district: (license: ?LicenseDetailFlow): ?{ ncesId: string, district: string } =>
			license && license.owner.type === licenseTypesEnum.DISTRICT
				? { ncesId: license.owner.districtId, district: license.owner.districtName }
				: null,
		school: (license: ?LicenseDetailFlow): ?{ schoolId: string, schoolName: string } =>
			license && license.owner.type === licenseTypesEnum.SCHOOL
				? { schoolId: license.owner.schoolId, schoolName: license.owner.schoolName }
				: null,
		client: (license: ?LicenseDetailFlow): ?SimpleClientType =>
			license &&
			(license.owner.type === licenseTypesEnum.SINGLE_OWNER ||
				license.owner.type === licenseTypesEnum.FREE)
				? {
						firstName: license.owner.user.firstName,
						lastName: license.owner.user.lastName,
						_id: license.owner.user._id,
				  }
				: null,
	},
	expiresOn: (license: ?LicenseDetailFlow): ?Date =>
		(license?.expiresOn && new Date(license?.expiresOn)) ||
		new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
	permissionsOverride: {
		physicalClassroom: (license: ?LicenseDetailFlow): ?boolean => {
			return license?.permissionsOverride.physicalClassroom || false
		},
	},
}

/**
 * Get permissions for a license, including whatever default permissions are included.
 * @param {?LicenseDetailFlow} license
 * @param {?{ [LicenseType]: PermissionsFlow} } permissionDefaults a map of license types to permission defaults for each license type
 * @param {LicenseType} type a license type
 */
export function getPermissions(
	license: ?LicenseDetailFlow,
	permissionDefaults: ?{ [LicenseType]: PermissionsFlow },
	type?: LicenseType
): PermissionsFlow {
	const licenseType = type || license?.owner.type
	if (!licenseType || !permissionDefaults) return {}
	return {
		...permissionDefaults[licenseType],
		...(license?.permissionsOverride || {}),
	}
}
