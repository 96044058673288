// @flow

import type { ProxyData } from '../../../../../utility/dotProxy'
import type { ClientCriteriaForm, ComposableFieldType, ResourceCriteria } from '../clientTypes'
import type {
	Criteria,
	ForceDisplayFields,
	UpdateCriteria,
	UpdateForceDisplayFields,
} from '../localTypes'

import { assign, get, getProxyData, minimizeDelete } from '../../../../../utility/dotProxy'

import { FIELD_TYPES } from '../clientTypes'

import { SingleSelect } from './SingleSelect'
import { ComposableEntry } from './composition'
import { EnumEntry } from './enum'
import { DateRangeEntry } from './rangeEntries/dates'

import { NumberRangeEntry } from './rangeEntries/numeric'

import React from 'react'
import { useMemo, useState } from 'react'
import ReactSelect from 'react-select'
import DocumentEntry from './documentSelectors'
import { CriteriaBox, Row } from '../sharedComponents'
import styled from 'styled-components'
import { getUnusedFieldsForCriteria } from './treeTraversers'
import { DurationEntry } from './rangeEntries/duration'

const COMPONENT_LOOKUP = {
	[FIELD_TYPES.DATE]: DateRangeEntry,
	[FIELD_TYPES.NUMBER]: NumberRangeEntry,
	[FIELD_TYPES.COMPOSABLE]: ComposableEntry,
	[FIELD_TYPES.ENUM_SELECTOR]: EnumEntry,
	[FIELD_TYPES.DOCUMENT_SELECTOR]: DocumentEntry,
	[FIELD_TYPES.SINGLE_SELECTOR]: SingleSelect,
	[FIELD_TYPES.DURATION]: DurationEntry,
}

const MAX_UNUSED_FIELDS_DEPTH_IN_TREE = 2

/**
 * CriteriaForm - render a form for the given resource
 *
 * @param  {Object} props - the react props
 * @param  {Criteria} props.currentCriteria - the current criteria
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {ForceDisplayFields} props.forceDisplayFields - the fields which are forced to render even though they may not exist in the currentCriteria
 * @param  {UpdateForceDisplayFields} props.updateForceDisplayFields - a callback to update the fields to force being displayed
 * @param  {ProxyData<{ count?: Range<?number>, criteria?: Criteria }>} props.currentPath - the location in the criteria object the field's data is located
 * @param  {String[]} props.displayPath - the path to display in front of the field name when rendering a field
 * @param  {ResourceCriteria} props.resource - get the resource to render the fields for
 * @param  {ClientCriteriaForm} props.resourceMap - a mapping of the resource name to the resource form
 * @param  {?ComposableFieldType} props.fieldType? - the current field being rendered
 *
 * @returns React$Node
 */
export default function CriteriaForm({
	updateCriteria,
	currentCriteria,
	forceDisplayFields,
	updateForceDisplayFields,
	displayPath,
	resource,
	resourceMap,
	currentPath,
	fieldType,
}: {
	updateCriteria: UpdateCriteria,
	currentCriteria: Criteria,
	forceDisplayFields: ForceDisplayFields,
	updateForceDisplayFields: UpdateForceDisplayFields,
	currentPath: ProxyData<?Criteria>,
	resource: ResourceCriteria,
	resourceMap: ClientCriteriaForm,
	displayPath: string[],
	fieldType?: ComposableFieldType,
}): React$Node {
	const [searchString, setSearchString] = useState('')

	const currentCriteriaAtPath = get(currentPath, currentCriteria)
	const fieldsToForceRender = get(currentPath, forceDisplayFields)
	const usingFields = useMemo<string[]>(
		() =>
			Array.from(
				new Set([
					...Object.keys(currentCriteriaAtPath || {}),
					...Object.keys(fieldsToForceRender || {}),
				])
			).sort(),
		[fieldsToForceRender, currentCriteriaAtPath]
	)
	const criteriaAtPath = get(currentPath, currentCriteria)
	const forceDisplayAtPath = get(currentPath, forceDisplayFields)
	const unusedFields = useMemo(() => {
		return getUnusedFieldsForCriteria(
			criteriaAtPath,
			resource,
			resourceMap,
			forceDisplayAtPath,
			MAX_UNUSED_FIELDS_DEPTH_IN_TREE
		)
			.map(({ displayPath, path }) => ({
				label: displayPath.join(' '),
				value: path.join(' '),
				path,
			}))
			.filter(({ label }) => label.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()))
			.sort((a, b) => a.path.length - b.path.length)
	}, [searchString, criteriaAtPath, resource, resourceMap, forceDisplayAtPath])

	return (
		<>
			{usingFields.map(fieldName => {
				const fieldType = resource[fieldName]

				const Wrapper = displayPath.length ? React.Fragment : CriteriaBox

				return (
					<Wrapper key={fieldName} doBox={true}>
						<Row
							displayPath={
								fieldType?.type !== 'COMPOSABLE' || fieldType.isExpectedOneToMany
									? [...displayPath, fieldType.displayName]
									: []
							}
							key={fieldName}
							onDelete={
								fieldType?.type !== FIELD_TYPES.COMPOSABLE
									? () => {
											const pathProxy = getProxyData(
												(criteria: Criteria) => get(currentPath, criteria)?.[fieldName]
											)
											updateCriteria(oldCriteria => minimizeDelete(pathProxy, oldCriteria))
											updateForceDisplayFields(oldFields => minimizeDelete(pathProxy, oldFields))
									  }
									: undefined
							}>
							<CriteriaEntry
								updateCriteria={updateCriteria}
								currentCriteria={currentCriteria}
								forceDisplayFields={forceDisplayFields}
								updateForceDisplayFields={updateForceDisplayFields}
								displayPath={displayPath}
								fieldName={fieldName}
								resource={resource}
								resourceMap={resourceMap}
								resourcePath={currentPath}
							/>
						</Row>
					</Wrapper>
				)
			})}
			{fieldType?.isExpectedOneToMany ? (
				<MinWithWrapper>
					<ReactSelect
						value={{ value: 'Add Filter', label: `add filter for ${fieldType.displayName}` }}
						options={unusedFields}
						styles={{
							menuPortal: defaultStyles => ({
								...defaultStyles,
								// display options in front of the modal
								zIndex: 20,
							}),
						}}
						menuPortalTarget={document.body}
						onChange={selected => {
							let currentProxyPath = currentPath
							let currentResource = resource
							selected.path.forEach((fieldName, index) => {
								const fieldProxy = getProxyData(
									criteria => get(currentProxyPath, criteria)?.[fieldName]
								)
								const fieldType = currentResource[fieldName]
								if (
									fieldType.type === FIELD_TYPES.COMPOSABLE &&
									index === selected.path.length - 1
								) {
									updateCriteria(oldCriteria => {
										const compositionCountProxy = getProxyData(
											criteria => get(fieldProxy, criteria)?.count
										)
										if (!get(compositionCountProxy, oldCriteria)) {
											return assign(compositionCountProxy, oldCriteria, { gt: 0 })
										}
										return oldCriteria
									})
								}

								if (index !== selected.path.length - 1) {
									if (fieldType.type !== FIELD_TYPES.COMPOSABLE) {
										throw new Error(
											`field ${fieldName} is not a composable field but was given in part of a path`
										)
									}
									currentResource = resourceMap[fieldType.resource]
									currentProxyPath = getProxyData(criteria => get(fieldProxy, criteria)?.criteria)
								} else {
									updateForceDisplayFields(oldForceDisplayFields => {
										if (!get(fieldProxy, oldForceDisplayFields)) {
											return assign(fieldProxy, oldForceDisplayFields, {})
										}
										return oldForceDisplayFields
									})
								}
							})
						}}
						onInputChange={(newSearchString: string) => setSearchString(newSearchString)}
						isOptionDisabled={() => false}
						className="basic-multi-select"
						classNamePrefix="select"
						getOptionLabel={({ label }) => label}
					/>
				</MinWithWrapper>
			) : null}
		</>
	)
}

/**
 * CriteriaEntry - render a form for the given fieldType
 *
 * @param  {Object} props - the react props
 * @param  {Criteria} props.currentCriteria - the current criteria
 * @param  {UpdateCriteria} props.updateCriteria - a callback to update the criteria
 * @param  {ForceDisplayFields} props.forceDisplayFields - the fields which are forced to render even though they may not exist in the currentCriteria
 * @param  {UpdateForceDisplayFields} props.updateForceDisplayFields - a callback to update the fields to force being displayed
 * @param  {ProxyData<{ count?: Range<?number>, criteria?: Criteria }>} props.currentPath - the location in the criteria object the field's data is located
 * @param  {String[]} props.displayPath - the path to display in front of the field name when rendering a field
 * @param  {String} props.fieldName - the name of the field to render
 * @param  {ResourceCriteria} props.resource - get the resource to render the fields for
 * @param  {ClientCriteriaForm} props.resourceMap - a mapping of the resource name to the resource form
 * @param  {?ComposableFieldType} props.resourcePath - the path in the criteria to the parent resource fields
 *
 * @returns React$Node
 */
function CriteriaEntry({
	updateCriteria,
	currentCriteria,
	forceDisplayFields,
	updateForceDisplayFields,
	displayPath,
	fieldName,
	resource,
	resourceMap,
	resourcePath,
}: {
	fieldName: string,
	updateCriteria: UpdateCriteria,
	forceDisplayFields: ForceDisplayFields,
	updateForceDisplayFields: UpdateForceDisplayFields,
	currentCriteria: Criteria,
	displayPath: string[],
	fieldName: string,
	resource: ResourceCriteria,
	resourceMap: ClientCriteriaForm,
	resourcePath: ProxyData<any>,
}): React$Node {
	const [Component, fieldType] = useMemo(() => {
		const field = resource[fieldName]
		const fieldType = field?.type
		if (!COMPONENT_LOOKUP.hasOwnProperty(fieldType)) {
			console.error(`Unknown criteria entry type ${fieldType}`)
			return []
		}
		return [COMPONENT_LOOKUP[fieldType], field]
	}, [resource, fieldName])

	if (!Component || !fieldType) {
		return null
	}
	return (
		<Component
			updateCriteria={updateCriteria}
			currentCriteria={currentCriteria}
			forceDisplayFields={forceDisplayFields}
			updateForceDisplayFields={updateForceDisplayFields}
			currentPath={
				// $FlowIgnore this type is based off of the fieldType
				getProxyData((criteria: Criteria) => get(resourcePath, criteria)?.[fieldName])
			}
			displayPath={displayPath}
			resourceMap={resourceMap}
			fieldType={
				// $FlowIgnore the component was based off of the fieldType
				fieldType
			}
		/>
	)
}

const MinWithWrapper = styled.div`
	min-width: 20vw;
	grid-column: field-start / row-end;
`
