import type { ClientStats, Key, LinkObject, Statistics } from './types'

/**
 * getDataAtLocation - get the ClientStats data at the given location
 *
 * @param {Key} location - the location of the stats to get
 * @param {?ClientStats} data - the ClientStats to traverse
 *
 * @return {?ClientStats} - the data at the location or null if no such data exists
 */
export function getDataAtLocation(location: Key, data: ?ClientStats): ?ClientStats {
	let currentLocation = data
	if (!currentLocation) {
		return null
	}
	for (let i = 0; i < location.length; i++) {
		currentLocation = currentLocation.$children[location[i]]
		if (!currentLocation) {
			return null
		}
	}

	return currentLocation
}

/**
 * getStatsAtLocation - get the stats at the given location
 *
 * @param {Key} location - the location of the stats to get
 * @param {?ClientStats} data - the ClientStats to traverse
 *
 * @return {?Statistics} - the stats at the location or null if no such stats exist
 */
export function getStatsAtLocation(location: Key, data: ?ClientStats): ?Statistics {
	if (!data) {
		return null
	}
	return getDataAtLocation(location, data)?.$statistics
}

/**
 * getLinksAtLocation - get the links at the given location
 *
 * @param {Key} location the location of the links to get
 * @param {?ClientStats} data - the ClientStats to traverse
 *
 * @return {?Array<LinkObject>}- the links at the location or null if no such links exist
 */
export function getLinksAtLocation(location: Key, data: ?ClientStats): ?Array<LinkObject> {
	return (data && getStatsAtLocation(location, data)?.links) || null
}

/**
 * getSubPathsAtLocation - get the subpath children at the given location
 *
 * @param {Key} location - the location of the subpath children to get
 * @param {?ClientStats} data - the ClientStats to traverse
 *
 * @return {?Array<LinkObject>}- the subpath children at the location or null if no such subpath children exist
 */
export function getSubPathsAtLocation(location: Key, data: ?ClientStats): string[] {
	return Object.keys(getDataAtLocation(location, data)?.$children || {})
}

export type StatsWithPaths = {|
	...Statistics,
	path: Key,
|}

/**
 * getAllStats - get all stats at the given location and sub-locations
 *
 * @param {Key} location - the location to start with
 * @param {?ClientStats} data - the ClientStats to traverse
 *
 * @return {StatsWithPaths[]} - the stats at the location and sub-locations
 */
export function getAllStats(location: Key, data: ?ClientStats): StatsWithPaths[] {
	const stats = []
	const currentLocation = getDataAtLocation(location, data)
	if (!currentLocation) {
		return stats
	}

	return recursivelyGetStats(currentLocation, stats, location)
}

/**
 * recursivelyGetStats - recursively navigate stats and children and add statistics to the accumulator
 *
 * @param {ClientStats} currentClientStats - the ClientStats to handle
 * @param {StatsWithPaths[]} accumulator - [MUTATES] the list of statistics to encountered
 * @param {Key} location - the location of the the `currentClientStats`
 *
 * @return {StatsWithPaths[]}
 */
function recursivelyGetStats(
	currentClientStats: ClientStats,
	accumulator: StatsWithPaths[],
	location: Key
): StatsWithPaths[] {
	const stat = currentClientStats.$statistics
	if (stat) {
		accumulator.push({ ...stat, path: location })
	}
	const children = currentClientStats.$children
	Object.keys(children).forEach(key => {
		recursivelyGetStats(children[key], accumulator, [...location, key])
	})
	return accumulator
}
